import axios from 'axios';
import store from '@/store/store.js'
import router from '@/router/index.js';
import isMobile from "is-mobile";

export default {
  async fnTestCall(){
    console.log("fnTestCall in Function");

    let result = undefined;

    await axios.post(`${store.getters.apiUrl}/v1/next`, {"quiz_set_uuid" : store.getters['testManage/quizSetToken']}).then((res) => {
      //console.log('axios res : ', res);

      store.commit('testManage/setNowQuizType', res.data.type);
      store.commit('testManage/setNowQuizNum', res.data.index);

      if(res.data.type === 1){
          
        result = {
          questionNum: res.data.index,
          arrayForQuestionText: res.data.question.split("[     ]"),
          answerArr: [res.data.answer, res.data.exam1, res.data.exam2, res.data.exam3].sort(() => Math.random() - 0.5),
          audio: res.data.audio_file,
          level: res.data.level,
          score: res.data.score,
          questionId: res.data.number,
          direction: res.data.direction,
          quizFinishNumber: res.data.count,
          quizType: res.data.type,
        }

      } else if(res.data.type === 2){

        result = {
          questionNum: res.data.index,
          questionText: res.data.question,
          answerArr: [res.data.answer, res.data.exam1, res.data.exam2, res.data.exam3].sort(() => Math.random() - 0.5),
          level: res.data.level,
          score: res.data.score,
          questionId: res.data.number,
          direction: res.data.direction,
          quizFinishNumber: res.data.count,
          quizType: res.data.type,
        }

      } else if(res.data.type === 3){

        result = {
          questionNum: res.data.index,
          arrayForQuestionText: res.data.question.split("_____"),
          questionTextOrigin: res.data.question,
          examList: [{exam: res.data.answer1, check:false}, 
          {exam: res.data.answer2, check:false}, 
          {exam: res.data.exam1, check:false}, 
          {exam: res.data.exam2, check:false}, 
          {exam: res.data.exam3, check:false}, 
          {exam: res.data.exam4, check:false}].sort(() => Math.random() - 0.5),
          level: res.data.level,
          score: res.data.score,
          questionId: res.data.number,
          direction: res.data.direction,
          quizFinishNumber: res.data.count,
          quizType: res.data.type,
        }

      } else if(res.data.type === 4){

        result = {
          questionNum: res.data.index,
          audio: res.data.question_audio_file,
          level: res.data.level,
          score: res.data.score,
          questionId: res.data.number,
          direction: res.data.direction,
          quizFinishNumber: res.data.count,
          quizType: res.data.type,
        }
      } else if(res.data.type === 5){

        const temp = [res.data.dialog1, res.data.dialog2, res.data.dialog3, res.data.dialog4];

        for(let i = 0; i < temp.length; i++){
          if(temp[i].indexOf('_') !== -1){
            const numOfUnderBar = temp[i].split('_').length - 1;
            let underBar = '';
            for (let underBarI = 0; underBarI < numOfUnderBar; underBarI++){
              underBar = underBar + '_';
            }
            console.log('TestReadingNormal underbar Counter : ', underBar);
            const tempInsert = temp[i].split(underBar);
            temp[i] = tempInsert;
          }
        }

        result = {
          questionNum: res.data.index,
          originalDialogArr: [res.data.dialog1, res.data.dialog2, res.data.dialog3, res.data.dialog4],
          dialog1: temp[0],
          dialog2: temp[1],
          dialog3: temp[2],
          dialog4: temp[3],
          answerArr: [res.data.answer, res.data.exam1, res.data.exam2, res.data.exam3].sort(() => Math.random() - 0.5),
          level: res.data.level,
          score: res.data.score,
          questionId: res.data.number,
          direction: res.data.direction,
          quizFinishNumber: res.data.count,
          quizType: res.data.type,
        }

      } else if(res.data.type === 7){

        result = {
          questionNum: res.data.index,
          audio: res.data.audio_file,
          answerArr: [res.data.answer, res.data.exam1, res.data.exam2, res.data.exam3].sort(() => Math.random() - 0.5),
          level: res.data.level,
          score: res.data.score,
          questionId: res.data.number,
          direction: res.data.direction,
          quizFinishNumber: res.data.count,
          quizType: res.data.type,
        }

      } else if(res.data.type === 6){

        let examList = []

        for(let i = 0; i < res.data.exam.length; i++){
          const temp = {exam: res.data.exam[i], check: false};
          examList.push(temp);
        }

        result = {
          questionNum: res.data.index,
          examDefault: res.data.exam.slice(),
          examList: examList,
          endCharacter: res.data.end_character,
          questionText: res.data.question,
          level: res.data.level,
          score: res.data.score,
          questionId: res.data.number,
          direction: res.data.direction,
          quizFinishNumber: res.data.count,
          quizType: res.data.type,
        }

      } else if (res.data.type === 9 || res.data.type === 10){
        result = res.data
      }
    });

    return result;
  },

  async saveAnswer(answer){
    //console.log("saveAnswer in Function", answer);

    let nextType = undefined;
    const toUrl = `${store.getters.apiUrl}/v1/save`;

    await axios.post(toUrl, {
      index: store.getters['testManage/nowQuizNum'],
      answer: answer,
      "quiz_set_uuid" : store.getters['testManage/quizSetToken'],
    }).then((res) => {
      //console.log("saveAnswer axios res : ", res);

      nextType = res.data.quiz_type;

    }).catch((err) => {
      console.log("axios err : ", err);
    });

    return nextType;
  },
  async sendProposal(proposal){
    console.log('sendProposal testControl', proposal);

    const toUrl = `${store.getters.apiUrl}/v1/proposal`;

    await axios.put(toUrl, {
      "quiz_set_uuid" : store.getters['testManage/quizSetToken'],
      "proposal": proposal, 
    }).then((res) => {
      console.log("sendProposal axios res : ", res);
      
    }).catch((err) => {
      console.log("axios err : ", err);
    });;

  },
  async toNextType(nextType){
    //console.log("toNextType in Function", nextType);

    if(nextType !== "result" && nextType !== "TestResult"){
      let routeName = ''

      if(nextType === 2) routeName = 'TestReadingWord'
      else if(nextType === 3) routeName = 'TestReadingContext'
      else if(nextType === 4) routeName = 'TestListeningNormal'
      else if(nextType === 5) routeName = 'TestReadingNormal'
      else if(nextType === 6) routeName = 'TestWritingContext'
      else if(nextType === 7) routeName = 'TestListeningHard'
      else if(nextType === 9) routeName = 'TestTalkingText'
      else if(nextType === 10) routeName = 'TestTalkingContext'

      /*
      store.commit('testManage/setRoute', routeName)
      router.replace({
        name: routeName,
      })
       */

      this.goRoute(routeName, false)
    } else {
      /*
      store.commit('testManage/setRoute', 'TestResult')
      router.push({
        name: 'TestResult',
        params: { quizToken: store.getters['testManage/quizSetToken'], userToken: store.getters['testManage/userToken'] },
      })
       */
      this.goRoute('TestResult', false)
    }

  },
  
  imgUrl(filePath) {
    const feUrl = store.getters.feUrl
    return feUrl ? `${feUrl}${filePath}` : filePath
  },
  goRoute(routeName, isPush) {
    console.log(`route name: ${routeName}`)
    if (routeName) {
      if (store.getters['testManage/isModal']) {
        console.log(`route name: ${routeName}`)
        store.commit('testManage/setRoute', routeName)
      } else {
        const value = {
          name: routeName
        }

        if (isPush) {
          router.push(value)
        } else {
          router.replace(value)
        }
      }
    }
  },
  setDomValueResultPage() {
    const value = document.getElementById('ai-leveltest-value')

    if (value) {
      value.setAttribute('page', 'TestResult')
    }
  },
  loadDomValue() {
    const domValue = document.getElementById('ai-leveltest-value')
    console.log(`dom value: ${domValue}`)

    if (domValue) {
      const companyCode = domValue.getAttribute('company')
      console.log(`company code: ${companyCode}`)
      store.commit('testManage/setCompanyCode', companyCode)

      const user = domValue.getAttribute('user')
      console.log(`user: ${user}`)
      store.commit('testManage/setUserToken', user)

      const quizSet = domValue.getAttribute('quiz_set')
      console.log(`quiz set: ${quizSet}`)
      store.commit('testManage/setQuizSetToken', quizSet)

      const name = domValue.getAttribute('name')
      console.log(`name: ${name}`)
      store.commit('testManage/setName', name)

      const ingangIdx = domValue.getAttribute('ingang_idx')
      console.log(`ingang_idx: ${ingangIdx}`)
      store.commit('testManage/setIngangIdx', ingangIdx)

      const page = domValue.getAttribute('page')
      console.log(`page: ${page}`)

      const landingPath = domValue.getAttribute('landing_path')
      console.log(`landingPath: ${landingPath}`)
      store.commit('testManage/setLandingPath', landingPath)

      store.commit('testManage/setRoute', page)
    }
  },
  loadUrlParams(query) {
    console.log(`query value: ${JSON.stringify(query)}`)
    const companyCode = query.company_code
    const userToken = query.user_token
    const quizToken = query.quiz_token
    const name = query.name
    const accessToken = query.access_token
    const ingangIdx = query.ingang_idx
    const page = query.page
    const landingPath = query.landing_path

    if (companyCode) {
      store.commit('testManage/setCompanyCode', companyCode)
    }

    if (userToken) {
      store.commit('testManage/setUserToken', userToken)
    }

    if (quizToken) {
     store.commit('testManage/setQuizSetToken', quizToken)
    }

    if (name) {
      store.commit('testManage/setName', name)
    }

    if (accessToken) {
      store.commit('testManage/setAccessToken', accessToken)
    }

    if (ingangIdx) {
      store.commit('testManage/setIngangIdx', ingangIdx)
    }

    if (landingPath) {
      store.commit('testManage/setLandingPath', landingPath)
    }

    const redirectPage = page && page !== 'enter' ? page : 'lobby'
    console.log(redirectPage)
    store.commit('testManage/setRoute', redirectPage)
  },
  validateCompanyCode (companyCode) {
    return companyCode && companyCode.length <= 20 ? null : '회사 코드의 길이는 20 이하여야 합니다'
  },
  validateUserToken(userToken) {
    return userToken && userToken.length >= 4 ? null : '유저 토큰의 길이는 4 이상이여야 합니다'
  },
  showAlert(message) {
    window.alert(message)
  },
  closePage() {
    if (store.getters['testManage/landingPath'] === 'url') {
      window.close()
    } else if (store.getters['testManage/landingPath'] === 'qr') {
      // todo company code 와 링크 페이지를 연결 시킬 필요가 있다. company code 관리자 페이지 작성시 구성 할것
      if (store.getters['testManage/companyCode'] === 'ybm_class') {
        location.href = 'https://m.eng.ybmclass.com/aileveltest_v2/aileveltest.asp';
      } else if(store.getters['testManage/companyCode'] ===  'ybm_publwiz'){
        location.href = 'https://www.ybmpublwiz.co.kr/publwiz/ai_leveltest.php';
      } else if(store.getters['testManage/companyCode'] ===  'ybm_edu'){
        location.href = 'https://www.ybmedu.com/event/2023/leveltest/ai_leveltest.asp';
      }
    }
  },
  isShowCloseButton() {
    return store.getters['testManage/landingPath'] === 'qr' || store.getters['testManage/landingPath'] === 'url'
  },
  newLinkPage(url_pc, url_mobile) {
    const url = isMobile() && url_mobile ? url_mobile : url_pc
    if (url) window.open(url, "_blank");
  }
}