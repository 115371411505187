<template>
  <div class="ListeningModal">
    <div class="ListeningModalBack"></div>
    <div class="ListeningModalArea">
      <div class="ListeningModalTxt">듣기유형입니다.<br/>
      음원을 재생해주세요</div>
      <div class="ListeningModalBtn" @click="closeListeningModal">확인</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Help',
  data(){
    return{
    }
  }, 
  methods:{
    closeListeningModal(){
      console.log('closeListeningModal');
      this.$emit("closeListeningModal");
    }
  }
}
</script>

<style lang="scss" scoped>
.ListeningModal{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;  

  width: 100%;
  height: 100%;

  z-index: 1;

  .ListeningModalBack{
    display: flex;
    position: absolute;  
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.8;

    z-index: -1;
  }

  .ListeningModalArea{
    display: flex;
    flex-direction: column;
    width: 18.438rem;
    height: 10.75rem;
    background: #fff;
    border-radius: 20px;

    align-items: center;
    justify-content: center;
    animation-name: fadeDown;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    visibility: visible;   
    @keyframes fadeDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2rem);
        -ms-transform: translateY(-2rem);
        transform: translateY(-2rem);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

    .ListeningModalTxt{
      display: flex;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      width: 10.063rem;
      text-align: center;
      color: #222222;
      

    }

    .ListeningModalBtn{
      display: flex;
      width: 7.688rem;
      height: 2.75rem;

      margin-top: 1.25rem;

      background: #3E40F4;
      border-radius: 8px;

      align-items: center;
      justify-content: center;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;

      text-align: center;

      color: #FFFFFF;
    }
  }
}
</style>