<template>
  <div class="UserInfoModal">
    <div class="userInfoModalBack"></div>
    <div class="userInfoModalArea">
      <div class="userInfoModalTxt">개인정보 수집 및 이용에 동의해주세요.</div>
      <div class="userInfoModalModalBtn" @click="closeModal">확인</div>
    </div>
  </div>
</template>

<script>
import XOutButton from "@/assets/img/block/XOutButtonHelp.svg";

export default {
  name: 'UserInfoModal',
  data(){
    return{
      outBtn: XOutButton,
    }
  },
  methods:{
    closeModal(){
      this.$emit('closeModal');
    }
  }
}
</script>

<style lang="scss" scoped>
.UserInfoModal{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;

  width: 100%;
  height: 100%;

  left: 0;
  top: 0;

  .userInfoModalBack{
    display: flex;
    position: absolute;  
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.8;

    align-self: center;

    z-index: -1;
  }

  .userInfoModalArea{
    display: flex;
    flex-direction: column;
    width: 18.438rem;
    height: 10.75rem;
    background: #fff;
    border-radius: 20px;

    align-items: center;
    justify-content: center;
    animation-name: fadeDown;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    visibility: visible;   
    @keyframes fadeDown {
      0% {
          opacity: 0;
          -webkit-transform: translateY(-2rem);
          -ms-transform: translateY(-2rem);
          transform: translateY(-2rem);
      }
      100% {
          opacity: 1;
          -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
          transform: translateY(0);
      }
    }

    .userInfoModalTxt{
      display: flex;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      width: 12.063rem;
      text-align: center;
      color: #222222;
      word-break: keep-all;
    }

    .userInfoModalModalBtn{
      display: flex;
      width: 7.688rem;
      height: 2.75rem;

      margin-top: 1.25rem;

      background: #3E40F4;
      border-radius: 8px;

      align-items: center;
      justify-content: center;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;

      text-align: center;

      color: #FFFFFF;
    }
  }
}
</style>