<template>
  <div class="suggestionAndQuestion">
    <div class="suggestionArea">
      <div class="suggestionTop" @click="openSuggestion()">
        <div class="suggestionIcon">
          <img :src="$testControl.imgUrl(iconSuggest)"/>
        </div>
        <div class="suggestionText">
          <div class="suggestionTextLabel">
            제안하기
          </div>
          <div class="suggestionTextTip">
            더 좋은 서비스를 위한 제안을 환영합니다.
          </div>
        </div>
        <div class="suggestionVactor"><img :src="suggestionOpen ? $testControl.imgUrl(vectorUp) : $testControl.imgUrl(vectorUnder) "/></div>
      </div>
      <div v-if="suggestionOpen" class="suggestionMiddle">
        <textarea class="suggestionTextarea" v-model="suggestion" :placeholder="suggestionPlaceholder" maxlength='1000'></textarea>
        <div class="showTextLength">{{ textLength }}/1000</div>
      </div>
      <div v-if="suggestionOpen" class="suggestionDown">
        <div class="suggestionBtn" @click="sendProposal()">제출하기</div>
      </div>
    </div>
    <!--<div class="separation"></div>
    <div class="questionArea">
      <div class="questionTop" @click="openQnA()">
        <div class="questionIcon">
          <img :src="$testControl.imgUrl(iconQna)"/>
        </div>
        <div class="questionText">
          <div class="questionTextLabel">
            문의하기
          </div>
          <div class="questionTextTip">
            질문을 주시면 확인 후 답변드리겠습니다.
          </div>
        </div>
        <div class="questionVactor"><img :src="qnaOpen ? $testControl.imgUrl(vectorUp) : $testControl.imgUrl(vectorUnder) "/></div>
      </div>
      <div v-if="qnaOpen" class="questionMiddle">
        <textarea class="questionTextarea" v-model="questionText"></textarea>
      </div>
      <div v-if="qnaOpen" class="questionDown">
        <div class="questionTxtUp">
          <div class="questionEmail">이메일</div>
          <div class="questionTip">(답변을 받으실 이메일 주소를 입력해주세요.)</div>
        </div>
        <div class="questionTxtDown">
          <input class="questionEmail" type="email" v-model="email">
          <div class="questionBtn">메일 보내기</div>
        </div>
      </div>
    </div>-->
  </div>
  <ProposalSendModal v-if="openProposalModal" @checkProposal="closeProposalModal()"/>
</template>

<script>
import iconSuggestSVG from "@/assets/img/block/icon_suggest.svg";
//import iconQnaSVG from "@/assets/img/block/icon_qna.svg";

import vectorUnderSVG from '@/assets/img/block/vectorUnder.svg';
import vectorUpSVG from '@/assets/img/block/vectorUp.svg';

import ProposalSendModal from '@/components/Result/ProposalSendModal.vue';

export default {
  name: 'TestResultSuggestion',
  props: {},
  components:{
    ProposalSendModal
  },
  data(){
    return{
      iconSuggest: iconSuggestSVG,
      //iconQna: iconQnaSVG,

      vectorUnder: vectorUnderSVG,
      vectorUp: vectorUpSVG,

      suggestionOpen: false,
      qnaOpen: false,
      suggestion: '',
      questionText:'',
      /*suggestionPlaceholder: `최대 1000자 입력 가능함을 보여주는 글자수 체크영역.
(비정상값 입력 방지로 1000자로 입력값 제한 두려 합니다).
글자 입력시 21/1000 과 같은 식으로 해당 영역에 글자수 입력이 실시간으로 될지 확인해주세요.`,*/
      suggestionPlaceholder:'',
      textLength: 0,
      //email: '',
      openProposalModal: false,
    }
  },
  watch:{
    suggestion: {
      handler: function (){
        this.textLength = this.suggestion.length;
      }
    }
  },
  methods: {
    openSuggestion(){
      this.suggestionOpen = !this.suggestionOpen;
    },
    sendProposal(){
      console.log('sendProposal', this.suggestion);

      if(this.suggestion !== '' && this.suggestion !== undefined){
        this.$testControl.sendProposal(this.suggestion);
        this.suggestion = '';
        this.openProposalModal = true;
        this.suggestionOpen = false;
      } else {
        console.log('no message');
        this.suggestionPlaceholder = '내용을 입력해주세요.';
      }
    },
    closeProposalModal(){
      this.openProposalModal = false;
    }
    /*openQnA(){
      this.qnaOpen = !this.qnaOpen;
    }*/
  }
}
</script>

<style lang="scss" scoped>
.suggestionAndQuestion{
  scroll-margin-top: 4.375rem;
  display: flex;

  flex-direction: column;

  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 8px;

  .suggestionArea{
    display: flex;
    min-height: 5.375rem;
    max-height: 21.875rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;


    .suggestionTop{
      display: flex;
      width: calc(100% - 2.25rem);
      height: 5.375rem;
      align-self: center;
      flex-direction: row;
      //margin-top: 1.25rem;

      .suggestionIcon{
        display: flex;
      }

      .suggestionText{
        display: flex;
        margin-left: 0.75rem;
        flex-direction: column;

        gap: 0.313rem;
        align-self: center;

        .suggestionTextLabel{
          display: flex;

          font-family: 'Pretendard';
          font-size: 1rem;
          font-weight: 700;
          line-height: 19px;
          text-align: left;
        }

        .suggestionTextTip{
          display: flex;

          font-family: 'Pretendard';
          font-size: 0.813rem;
          font-weight: 400;
          line-height: 18px;
          text-align: left;
        }
      }

      .suggestionVactor{
        display: flex;
        align-self: center;
        margin-left: auto;
      }
    }

    .suggestionMiddle{
      display: flex;
      width: calc(100% - 2.25rem);
      border: 1px solid #E5E5E5;
      border-radius: 4px;
      flex-direction: column;

      .suggestionTextarea{
        display: flex;
        height: 10.25rem;
        width: 100%;
        padding: 0.75rem;

        font-family: 'Pretendard';
        font-size: 0.813rem;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        background-color: #fff;

        color: #222222;

        resize: none;
      }

      .showTextLength{
        display: flex;

        font-family: 'Pretendard';
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 18px;

        color: #9E9E9E;

        align-self: flex-end;
        margin-right: 0.625rem;
        margin-bottom: 0.625rem;
      }
    }

    .suggestionDown{
      display: flex;
      margin-top: 1rem;
      width: calc(100% - 2.25rem);
      justify-content: flex-end;
      margin-bottom: 1.5rem;

      .suggestionBtn{
        display: flex;
        width: 4.85rem;
        height: 2.063rem;
        background: #3E40F4;
        color: #fff;
        padding: 8px 14px 8px 14px;
        border-radius: 6px;

        font-family: 'Pretendard';
        font-size: 0.875rem;
        font-weight: 600;
        line-height: 16.71px;

        align-self: flex-end;
      }
    }
  }

  .separation{
    display: flex;
    width: calc(100% - 2.5rem);
    height: 0.1rem;
    background: #F5F6F7;
    align-self: center;
  }

  .questionArea{
    display: flex;
    min-height: 5.375rem;
    max-height: 21.875rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .questionTop{
      display: flex;
      width: calc(100% - 2.25rem);
      height: 5.375rem;
      align-self: center;
      flex-direction: row;
      //margin-top: 1.25rem;

      .questionIcon{
        display: flex;
      }

      .questionText{
        display: flex;
        margin-left: 0.75rem;
        flex-direction: column;

        gap: 0.313rem;
        align-self: center;

        .questionTextLabel{
          display: flex;

          font-family: 'Pretendard';
          font-size: 1rem;
          font-weight: 700;
          line-height: 19px;
          text-align: left;
        }

        .questionTextTip{
          display: flex;

          font-family: 'Pretendard';
          font-size: 0.813rem;
          font-weight: 400;
          line-height: 18px;
          text-align: left;
        }
      }

      .questionVactor{
        display: flex;
        align-self: center;
        margin-left: auto;
      }
    }

    .questionMiddle{
      display: flex;
      width: calc(100% - 2.25rem);
      border: 1px solid #E5E5E5;
      border-radius: 4px;
      flex-direction: column;

      .questionTextarea{
        display: flex;
        height: 10.25rem;
        width: 100%;
        padding: 0.75rem;

        font-family: 'Pretendard';
        font-size: 0.813rem;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        background-color: #fff;

        color: #222222;

        resize: none;
      }
    }

    .questionDown{
      display: flex;
      flex-direction: column;
      width: calc(100% - 2.25rem);
      margin-top: 1.375rem;

      .questionTxtUp{
        display: flex;
        flex-direction: row;
        gap: 0.25rem;

        .questionEmail{
          display: flex;

          font-family: "Pretendard";
          font-size: 14px;
          font-weight: 600;
          line-height: 17px;
          letter-spacing: -0.01em;
          text-align: left;
        }

        .questionTip{
          display: flex;

          font-family: "Pretendard";
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: -0.01em;
          text-align: left;

          align-self: flex-end;
        }
      }

      .questionTxtDown{
        display: flex;
        flex-direction: row;

        margin-top: 0.75rem;
        margin-bottom: 1.5rem;

        gap: 0.625rem;

        .questionEmail{
          display: flex;
          height: 2.063rem;
          width: calc(100% - 6.413rem);
          border: 1px solid #E5E5E5;
          border-radius: 4px;

          font-family: 'Pretendard';
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 16.71px;
        }

        .questionBtn{
          display: flex;

          width: 5.788rem;
          height: 2.063rem;
          background: #3E40F4;
          color: #fff;
          padding: 8px 14px 8px 14px;
          border-radius: 6px;

          font-family: 'Pretendard';
          font-size: 0.875rem;
          font-weight: 600;
          line-height: 16.71px;

          align-self: flex-end;
        }
      }
    }
  }
}
</style>