<template>
  <div class="TestResultOverallDiagnosis">
    <!--<ResultBarGraph :barData="barData"/>-->
    <div class="radialArea">
      <div class="radialBar">
        <div class="radialLabel">진단 레벨</div>
        <RadialChart v-if="readyRemote" :radialData="levelRaidalData" />
      </div>
      <div class="radialBar">
        <div class="radialLabel">소요 시간</div>
        <RadialChart v-if="readyRemote" :radialData="timeRadialData" />
      </div>
    </div>
    <div class="normalDistributionArea" id="normalDistributionArea">
      <div class="reportRankingLabel">{{userName}}님의 랭킹</div>
      <div class="reportRanking">{{ rankLabel }}</div>
      <NormalDistributionChart class="NormalDistribution" :key="renderKeyNB" :chartData="radialDataNormalization"
       v-show="render" :graphType="'default'"/>
    </div>

    <!--<div class="predictionAreaB2B" v-if="showRecommendProductType === 'ybm_b2b'">
      <div class="predictLevelB2B">{{ levelArea }} 레벨</div>
      <div class="predictTextB2B">{{ levelComment }}</div>
    </div>-->

    <div class="predictionBtnB2B" v-if="showRecommendProductType === 'ybm_b2b'" @click="openGuideB2B">
      레벨 가이드 보기
    </div>

    <!--<div class="predictionArea">
      <div class="predictLevel">지금 이대로 토익을 본다면 아마도 <span class="predictLevelSpan">{{ predictText }}</span> 이실 것 같아요.</div>
      <div class="predictText">* 진단평가 응시생들의 평균 데이터를 활용하여 추측한 점수대 입니다. 참고용으로 활용해주세요.</div>
    </div>-->
  </div>
</template>

<script>
import RadialChart from '@/components/Result/RadialChart.vue';
//import ResultBarGraph from '@/components/Result/ResultBarGraph.vue';
import NormalDistributionChart from '@/components/Result/NormalDistributionChart.vue';

export default {
  name: 'TestResultOverallDiagnosis',
  components: {
    RadialChart,
    //ResultBarGraph,
    NormalDistributionChart,
  },
  props: {
    data:{
      type: Object,
      default: undefined,
    }
  },
  data() {
    return {
      level: this.data.level,
      //level: 10,

      readyRemote: true,

      render: true,

      renderKeyNB: 0,

      levelRaidalData: {avgLevel: this.data.avg_level, level: this.data.level, type:'level'},
      timeRadialData: {avgTime: this.data.avg_total_time, userTime: this.data.time, type:'time'},

      barData:{
        avgLevel: this.data.avg_level, level: this.data.level,
        avgTime: this.data.avg_total_time, userTime: this.data.time, 
      },

      radialDataNormalization: undefined,
      predictText: undefined,
    }
  },
  computed: {
    userName() {
      return this.$store.getters['testManage/name'].length === 0 ? '익명' : this.$store.getters['testManage/name']
    },
    ranking() {
      const ranking = this.data.rank / this.data.total_tester_count * 100
      return Math.round(ranking, 0)
    },
    rankLabel() {
      return this.ranking < 50 ? `상위 ${this.ranking}%` : `하위 ${100 - this.ranking}%`
    },
    showRecommendProductType(){
      return this.$store.getters['testManage/companyCode']
      //return 'ybm_b2b'
    },
    levelArea(){
      if(this.level >= 1 && this.level <= 3){
        return "1~3"
      } else if(this.level >= 4 && this.level <= 5){
        return "4~5"
      } else if(this.level >= 6 && this.level <= 7){
        return "6~7"
      } else if(this.level >= 8 && this.level <= 9){
        return "8~9"
      } else if(this.level === 10){
        return "10"
      } else {
        return ""
      }
    },
    levelComment(){
      if(this.level >= 1 && this.level <= 3){
        return "영어 입문자 수준의 실력을 가지고 있습니다. 일상생활에서 반복적으로 사용하는 친숙한 단어나 기초적인 표현 위주로 이해할 수 있습니다. 간단하게 자기소개를 할 수 있고, 어디에 사는지, 무엇을 알고 있는지, 어떤 것을 가지고 있는지 등 개인적인 것에 대해 간단하게 묻고 답할 수 있습니다."
      } else if(this.level >= 4 && this.level <= 5){
        return "영어 초급자 수준의 실력을 가지고 있습니다. 일상생활에서 규칙적으로 마주하는 상황에서 자주 사용되는 기본적인 표현이나 문장을 이해할 수 있고, 요점을 파악할 수 있습니다. 가족, 학교, 직장, 취미, 여가시간 등 본인과 가장 밀접하게 관련있는 영역에서 쓰이는 표현과 문장을 이해할 수 있습니다. 친숙한 분야, 개인적인 관심사와 관련한 간단한 지문을 만들고, 이해할 수 있습니다."
      } else if(this.level >= 6 && this.level <= 7){
        return "영어 중급자 수준의 실력을 가지고 있습니다. 자신의 경험과 희망 사항에 대한 것들을 묘사할 수 있고, 이유와 설명을 통해 자신의 의견과 계획을 말할 수 있습니다. 개인적인 관심사나 친숙한 주제와 연관된 문장을 자유롭게 만들 수 있습니다. 구체적인 주제와 관련한 다소 긴 지문의 논점을 이해할 수 있습니다. 원어민과 일상적이고 친숙한 주제에 대해서 말할 수 있습니다."
      } else if(this.level >= 8 && this.level <= 9){
        return "영어 중·고급자 수준의 실력을 가지고 있습니다. 세부적이고 추상적인 주제와 관련한 복잡한 지문의 논점을 이해할 수 있습니다. 원어민과 일상적이고 친숙한 주제에 대해서 꽤 유창하게 말할 수 있습니다. 다양한 주제로 명확하고 상세한 지문을 만들 수 있고, 찬·반 이슈에 대한 자신의 관점을 설명할 수 있습니다."
      } else if(this.level === 10){
        return "고급 수준의 영어 실력을 가지고 있습니다. 광범위한 주제의 어렵고, 긴 지문을 읽고 듣고 이해할 수 있으며, 그 안에 함축된 의미를 파악할 수 있습니다. 자발적으로 유창하게 의견을 표현할 수 있고, 영어를 사회적인 목적, 학구적인 목적, 기타 전문적인 목적으로 유연하게 사용할 수 있습니다. 복잡한 주제에 대하여 명확하고 잘 구조화된 상세한 지문을 만들 수 있습니다."
      } else {
        return ""
      }
    }
    
  },
  created(){
    this.setData();
  },
  mounted(){
    //console.log('TestResultOverallDiagnosis data : ', this.data, this.levelRaidalData, this.timeRadialData);
  },
  methods:{
    setData(){
      console.log('setraiderDataUnnormalization');

      if(this.level === 1){
        this.predictText = '500점 미만';
      } else if(this.level === 2 || this.level === 3){
        this.predictText = '500점대';
      } else if(this.level === 4 || this.level === 5){
        this.predictText = '600점대';
      } else if(this.level === 6 || this.level === 7){
        this.predictText = '700점대';
      } else if(this.level === 8 || this.level === 9){
        this.predictText = '800점대';
      } else if(this.level === 10){
        this.predictText = '900점대';
      }

      let tempArrCountByLevelDefault = {
        type: 'defaultLevel',
        mylevel: this.level,
        data: [],
      };

      for(let idx1 = 0; idx1 < this.data.case_level_info.length; idx1++){
        const arr1 = this.data.case_level_info[idx1];

        const temp1 = {
          level: arr1.level,
          count: arr1.count,
        }

        tempArrCountByLevelDefault.data.push(temp1);
      }

      this.radialDataNormalization = tempArrCountByLevelDefault;
    },
    openGuideB2B(){
      this.$emit("openGuideB2B")
    },
    forceRerender(){
      this.renderKeyNB += 1;
    }
  },
}
</script>


<style lang="scss" scoped>
.TestResultOverallDiagnosis{
  scroll-margin-top: 10rem;
  display: flex;
  flex-direction: column;

  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 8px;
  .radialArea{
    display: flex;
    width: 100%;

    //margin: 2.5rem 1.25rem 0 1.25rem;
    margin-top: 2.5rem;
    height: auto;
    
    .radialBar{
      display: flex;
      flex-direction: column;
      width: 50%;

      .radialLabel{
        display: flex;
        align-self: center;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.02em;

        /* Gray/900 */

        color: #222222;
      }
    }
  }

  .normalDistributionArea{
    display: flex;
    flex-direction: column;

    margin-top: 3rem;
    margin-bottom: 2rem;
    width: calc(100% - 2.5rem);
    align-self: center;

    .reportRankingLabel{
      display: flex;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      color: #222222;
    }

    .reportRanking{
      display: flex;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;
      color: #3E40F4;

      margin-bottom: 2rem;
    }

    .NormalDistribution{
      display: flex;
    }
  }

  .predictionArea{
    display: flex;
    flex-direction: column;

    width: calc(100% - 2.5rem);
    align-self: center;

    gap: 0.75rem;

    margin-bottom: 2rem;

    .predictLevel{
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;

      letter-spacing: -0.02em;

      color: #222222;

      word-break: keep-all;

      .predictLevelSpan{
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;
        color: #3E40F4;
      }
    }

    .predictText{
      display: flex;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      /* or 154% */

      letter-spacing: -0.02em;

      /* Gray/700 */

      color: #616161;

      word-break: keep-all;
    }
  }

  /*.predictionAreaB2B{
    display: flex;
    flex-direction: column;

    width: calc(100% - 2.5rem);
    align-self: center;

    gap: 0.75rem;

    margin-bottom: 2rem;

    .predictLevelB2B{
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 1.063rem;
      line-height: 1.5rem;

      letter-spacing: -0.02em;

      color: #222222;

      word-break: keep-all;
    }

    .predictTextB2B{
      display: flex;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 0.813rem;
      line-height: 1.25rem;

      letter-spacing: -0.02em;

      color: #616161;

      word-break: keep-all;
    }
  }*/

  .predictionBtnB2B{
    display: flex;

    width: calc(100% - 2rem);

    background: #EAE9FF;
    border-radius: 8px;

    align-items: center;
    display: flex;
    justify-content: center;

    margin: auto;
    margin-bottom: 1.5rem;

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16.71px;
    color: #3E40F4;

    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lineType{
    display: flex;
    flex-direction: row;

    width: calc(100% - 2.5rem);
    align-self: center;
    overflow: scroll;
    gap: 0.375rem;

    margin-top: 2rem;
    margin-bottom: 2.5rem;

    .type{
      display: flex;
      padding: 6px 12px;

      min-width: max-content;

      border-radius: 28px;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      /* identical to box height */

      letter-spacing: -0.01em;
    }

    .selected{
      background: #3E40F4;
      color: #FFFFFF;
    }

    .unSelected{
      background: #F5F6F7;
      color: #9E9E9E;
    }
  }
}
</style>