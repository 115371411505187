<template>
  <div id="main">
    <router-view ></router-view>
  </div>
</template>

<script>
// import 'css-init';

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
    }
  },
  async mounted() {
    document.title = "YBM AI 레벨테스트";
  },
  methods:{
  }
}
</script>
<style lang="scss" scoped>
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard.css");

#main {
  color: #2c3e50;
  
  width: 100%;
  height: 100%;
  //height: inherit;
  position: relative;
}
</style>
