<template>
  <div class="TestResultAnalysis">
    <div class="analysisText">
      <div class="analysisTextLabel">
        강점/약점 분석
        <div class="analysisTextTip">나와 비슷한 수준의 응시생들과 비교하여 영역별 강약점을 분석하였습니다.</div>
      </div>
    </div>
    <div class="RaiderChartDiv">
      <RaiderChart :chartData="selectedLevelData" :name="name" :key="renderKey"/>
    </div>
    <div class="lineLevel" id="lineLevel">
      <swiper class="lineArea" ref="mySwiper" 
      :options="swiperOption" :navigation="false" :modules="modules" :slidesPerView="slideNumByLine" :spaceBetween="30" :centeredSlides="true"
            @slideChange="onSlideChange(idx)" @swiper="onSwiper">
        <swiper-slide :class="'level ' + [(selectedLevel === 0) ? 'selected' : 'unSelected' ]" @click="changeLevel(0)">전체</swiper-slide>
        <swiper-slide v-for="index in 10" :key="index" :id="'levelUnit'+index" :class="'level ' + [(selectedLevel === index) ? 'selected' : 'unSelected' ]" @click="changeLevel(index)">{{ index }} Level</swiper-slide>
      </swiper>
    </div>
    <div class="dotType">
      <div class="type"><div class="dot blue"></div><div class="text">내 점수</div></div>
      <div class="type"><div class="dot red"></div><div class="text">레벨 평균 점수</div></div>
    </div>
    <div class="analysisAdvantageAndWeakness">
      <div class="analysisAdvantageArea">
        <div class="analysisAdvantageLabel">강점</div>
        <div v-if="advantage.length !== 0" class="analysisAdvantageText enough">{{advantage.join(", ")}}</div>
        <div v-if="advantage.length === 0" class="analysisAdvantageText empty">동일 레벨 응시자들과 다른 강점이 없습니다.</div>
      </div>
      <div class="analysisWeaknessArea">
        <div class="analysisWeaknessLabel">약점</div>
        <div v-if="weakness.length !== 0" class="analysisWeaknessText enough">{{weakness.join(", ")}}</div>
        <div v-if="weakness.length === 0" class="analysisWeaknessText empty">동일 레벨 응시자들과 다른 약점이 없습니다.</div>
      </div>
    </div>
    <div class="prescriptionArea" v-if="showRecommendProductType !== 'ybm_publishing' && showRecommendProductType !== 'ybm_b2b' && showRecommendProductType !== 'ybm_lemon'">
      <TestResultGuideForPublwizProduct :product="publwizProduct" :level="level" :userName="name" :weakness="weakness" v-if="showRecommendProductType === 'ybm_publwiz' && this.publwizOpen"/>
      <TestResultProductForEdu @eduSelectLocationFromWeak="eduSelectLocationFromWeak" :eduLocationIdx="eduLocationIdxTemp" :product="product" :level="level" :userName="name" :weakness="weakness" v-else-if="showRecommendProductType === 'ybm_edu'" />
      <TestResultProductForPrescription :product="product" :level="level" :userName="name" :weakness="weakness" v-else/>
    </div>
    <div class="analysisCompareArea">
      <div class="guideDistributionLabel"><span class="guideDistributionLabelSpan">{{ checkType(selectedDistribution, 'label') }}</span>&nbsp;영역 나의 위치</div>
      <div class="countDistribution">총&nbsp;<span class="rankAllSpan">{{ checkType(selectedDistribution, 'rank-all') }}</span>명 중&nbsp;<span class="rankCountSpan">{{ checkType(selectedDistribution, 'rank-count') }}</span>등에 위치하고 있습니다.</div>
      <NormalDistributionChart class="NormalDistribution" :key="renderKeyNB" v-show="render" :chartData="checkType(selectedDistribution, 'graph')" :graphType="'analysis'"/>
      <div class="distributionLine">
        <div v-for="(item, idx) in compareType" :key="idx" :data-index="idx" :data-item="item"
        :id="'distributionUnit'+idx" :class="'distribution ' + [(selectedDistribution === item) ? 'selected' : 'unSelected' ]" 
        @click="changeDistribution(item)">{{ checkType(item, 'label') }}</div>
      </div>
    </div>
  </div>
</template>


<script>
import RaiderChart from '@/components/Result/RaiderChart.vue';
import TestResultProductForPrescription from '@/components/Result/TestResultProductForPrescription.vue';
import TestResultGuideForPublwizProduct from '@/components/Result/TestResultGuideForPublwizProduct.vue';
import TestResultProductForEdu from '@/components/Result/TestResultProductForEdu.vue';
import NormalDistributionChart from '@/components/Result/NormalDistributionChart.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';

import { Navigation, Pagination } from 'swiper';
import "swiper/swiper-bundle.css";

import axios from 'axios';

import maskFirstSVG from "@/assets/img/block/maskFirst.svg";


export default{
  name: 'TestResultAnalysis',
  components: {
    RaiderChart,
    TestResultProductForPrescription,
    TestResultGuideForPublwizProduct,
    TestResultProductForEdu,
    NormalDistributionChart,

    Swiper,
    SwiperSlide,
  },
  props: {
    data:{
      type: Object,
      default: undefined,
    },
    eduLocationIdx:{
      type: Number,
      default: 1
    },
    userName: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    /*isShowRecommendPublwizProduct() {
      return this.$store.getters['testManage/companyCode'] === 'ybm_publwiz'
      //return true
    },
    isShowRecommendPublishingProduct(){
      return this.$store.getters['testManage/companyCode'] === 'ybm_publwiz'

    }*/
    showRecommendProductType(){
      return this.$store.getters['testManage/companyCode']
      //return true
    }
  },
  data(){
    return{
      name: this.userName,
      level: this.data.level,

      raiderDataUnnormalization: this.data.case_level_info,
      raiderDataNormalization: [],

      selectedLevelData: undefined,

      selectedLevel: this.data.level,

      weakness: [],
      advantage: [],

      modules: [Navigation, Pagination],
      swiperOption:{
        notNextTick: true,
        setWrapperSize :true,
        autoHeight: true,
      },
      swiper: undefined,
      slideNumByLine: 3,

      radialDataNormalization: {
        countByLevelWord: undefined,
        countByLevelLc: undefined,
        countByLevelRc: undefined,
        countByLevelSp: undefined,
        countByLevelWc: undefined,
      },

      product: [],
      publwizProduct: [],
      publwizOpen: false,

      compareType:['word', 'lc', 'rc', 'wc', 'sp'],

      selectedDistribution: 'word',

      analysisWordText: '일상생활에 친숙한 단어들이 어느 정도 습득된 상태. 어휘력이 주로 명사 동사에 편중되어 있어 기타 품사의 단어까지 골고루 학습할 필요가 있음',
      analysisListeningText: '영어의 소리, 강세, 리듬은 식별하지만, 대화를 듣고 세부 정보를 파악하는 능력이 한정적인 수준. 딕테이션 등을 통해 전체 흐름과 세부 내용을 파악하는 학습이 추천 됨',
      analysisReadingText: '일반적 주제에 관한 글을 읽고 세부 정보를 파악할 수 있음. 다만 내용의 논리적 관계를 파악하는 능력은 한정적인 편',
      analysisWritingText: '적절한 어휘를 선택하여 문장을 만들어 낼 수 있는 실력',
      analysisTalkingText: '일상 생활 친숙한 주제에 관해 쉽고 간단한 표현으로 말할 수 있음',

      renderKey:0,
      renderKeyNB: 0,
      
      maskFirst: maskFirstSVG,
      eduLocationIdxTemp: this.eduLocationIdx,
    }
  },
  created(){
    this.setRaiderChartData();
    this.setNormalDistributionData();
    this.setScoreColor();
    this.setProduct();
  },
  mounted(){
    this.setDeaultSwiper();
    this.setPublwizProductData();
  },
  watch:{
    eduLocationIdx: {
      handler: function (){
        console.log('changeLocation', this.eduLocationIdx);

        this.eduLocationIdxTemp = this.eduLocationIdx;
      }
    }
  },
  methods:{
    setDeaultSwiper(){
      this.swiper.slideTo(this.data.level, 0);
    }, 
    setPublwizProductData(){
      if(this.$store.getters['testManage/companyCode'] === 'ybm_publwiz'){
        const toUrl = `${this.$store.getters.apiUrl}/v1/recommend_study_list`;

        axios.post(toUrl, {
          "level": this.level,
          "company_code": 'ybm_publwiz',
        }).then((res) => {
          console.log("publwiz axios res : ", res.data);

          res.data.list.map((data) => {
            const item = {
              contentType: data.description,
              contentLabel: data.title,
              contentBtn: data.type_title,
              url_pc: data.url_pc,
              url_mobile: data.url_mobile,
            }

            this.publwizProduct.push(item);

          })
        }).finally(() => {
          this.publwizOpen = true;
        });
      }
      
    },
    eduSelectLocationFromWeak(idx){
      console.log("eduSelectLocationFromWeak", idx);
      this.$emit("eduSelectLocationFromWeak", idx);
    }, 
    setRaiderChartData(){
      console.log('setRaiderChartData');

      const maxArr = this.raiderDataUnnormalization[10];

      console.log('setRaiderChartData arr ', maxArr);

      const maxLcScore = maxArr.max_lc_score;
      const maxRcScore = maxArr.max_rc_score;
      const maxSpScore = maxArr.max_sp_score;
      const maxWcScore = maxArr.max_wc_score;
      const maxWordScore = maxArr.max_word_score;

      for(let i = 0; i<this.raiderDataUnnormalization.length; i++ ){
        const arr = this.raiderDataUnnormalization[i];

        const tempArrByLevel = {
          level: arr.level,
          count: arr.count,
          userLcPer: (maxLcScore !== 0) ? Math.floor((this.data.lc / maxLcScore) * 100) : 0,
          userRcPer: (maxRcScore !== 0) ? Math.floor((this.data.rc / maxRcScore) * 100) : 0,
          userSpPer: (maxSpScore !== 0) ? Math.floor((this.data.sp / maxSpScore) * 100) : 0,
          userWcPer: (maxWcScore !== 0) ? Math.floor((this.data.wc / maxWcScore) * 100) : 0,
          userWordPer: (maxWordScore !== 0) ? Math.floor((this.data.word / maxWordScore) * 100) : 0,

          avgLcPer: (maxLcScore !== 0) ? Math.floor((arr.avg_lc_score / maxLcScore) * 100) : 0,
          avgRcPer: (maxRcScore !== 0) ? Math.floor((arr.avg_rc_score / maxRcScore) * 100) : 0,
          avgSpPer: (maxSpScore !== 0) ? Math.floor((arr.avg_sp_score / maxSpScore) * 100) : 0,
          avgWcPer: (maxWcScore !== 0) ? Math.floor((arr.avg_wc_score / maxWcScore) * 100) : 0,
          avgWordPer: (maxWordScore !== 0) ? Math.floor((arr.avg_word_score / maxWordScore) * 100) : 0,
        }

        console.log(tempArrByLevel);
        this.raiderDataNormalization.push(tempArrByLevel);
      }

      this.selectedLevelData = this.raiderDataNormalization[0];
    },
    setScoreColor(){
      console.log('setScoreColor', this.data.rc, this.data.avg_rc_score, this.data.lc, this.data.avg_lc_score, this.data.sp, this.data.avg_sp_score, this.data.wc, this.data.avg_wc_score);

      if((this.data.word) < this.data.avg_word_score){
        this.weakness.push('어휘');
      } else if((this.data.avg_word_score) < this.data.word) {
        this.advantage.push('어휘');
      }

      if((this.data.rc) < this.data.avg_rc_score){
        this.weakness.push('읽기');
      } else if((this.data.avg_rc_score) < this.data.rc) {
        this.advantage.push('읽기');
      }

      if((this.data.lc) < this.data.avg_lc_score){
        this.weakness.push('듣기');
      } else if((this.data.avg_lc_score) < this.data.lc) {
        this.advantage.push('듣기');
      }

      if((this.data.sp) < this.data.avg_sp_score){
        this.weakness.push('말하기');
      } else if((this.data.avg_sp_score) < this.data.sp) {
        this.advantage.push('말하기');
      }

      if((this.data.wc) < this.data.avg_wc_score){
        this.weakness.push('쓰기');
      } else if((this.data.avg_wc_score) < this.data.wc) {
        this.advantage.push('쓰기');
      }
    },
    setProduct(){
      console.log('setProduct', this.data.recommend_study_list_by_level);

      this.data.recommend_study_list_by_level.map((data) => {
        const item = {
          contentType: data.description,
          contentLabel: data.title,
          contentBtn: data.type_title,
          url_pc: data.url_pc,
          url_mobile: data.url_mobile,
        }

        this.product.push(item)
      })

      console.log(this.product);
    },
    setNormalDistributionData(){
      console.log('setNormalDistributionData');

      let myWordLevel = 0;
      let myWorkRankCounter = 0;
      let wordFullCounter = 0;
      let tempArrCountByLevelWord = {
        type: 'wordLevel',
        mylevel: 0,
        data: [],
        text: this.analysisWordText,
        rankCounter: 0,
        fullCounter: 0,
      };

      for(let idx2 = 0; idx2 < this.data.word_score_level.length; idx2++){
        const arr2 = this.data.word_score_level[idx2];
        const temp2 = {
          level: (idx2 + 1),
          count: arr2.count,
        }

        if(arr2.max_score < this.data.word){
          myWordLevel++;
          myWorkRankCounter = myWorkRankCounter + arr2.count;
        }
        wordFullCounter = wordFullCounter + arr2.count;

        tempArrCountByLevelWord.data.push(temp2);
      }
      tempArrCountByLevelWord.mylevel = (myWordLevel === 0) ? 1 : myWordLevel;
      tempArrCountByLevelWord.rankCounter = (myWorkRankCounter === 0) ? wordFullCounter : (wordFullCounter-myWorkRankCounter);
      tempArrCountByLevelWord.fullCounter = (wordFullCounter === 0) ? 1 : wordFullCounter;

      /*console.log('정렬 로직 추가')
      tempArrCountByLevelWord.data.sort();
      console.log('정렬 로직 추가', tempArrCountByLevelWord.data)*/

      this.radialDataNormalization.countByLevelWord = tempArrCountByLevelWord;
    

    
      //LC data set
      let myLcLevel = 0;
      let myLcRankCounter = 0;
      let lcFullCounter = 0;
      let tempArrCountByLevelLc = {
        type: 'lcLevel',
        mylevel: 0,
        data: [],
        text: this.analysisListeningText,
        rankCounter: 0,
        fullCounter: 0,
      };

      for(let idx3 = 0; idx3 < this.data.lc_score_level.length; idx3++){
        const arr3 = this.data.lc_score_level[idx3];
        const temp3 = {
          level: (idx3 + 1),
          count: arr3.count,
        }

        if(arr3.max_score < this.data.lc){
          myLcLevel++;
          myLcRankCounter = myLcRankCounter + arr3.count;
        }
        lcFullCounter = lcFullCounter + arr3.count;

        tempArrCountByLevelLc.data.push(temp3);
      }
      tempArrCountByLevelLc.mylevel = (myLcLevel === 0) ? 1 : myLcLevel;
      tempArrCountByLevelLc.rankCounter = (myLcRankCounter === 0) ? lcFullCounter : (lcFullCounter-myLcRankCounter);
      tempArrCountByLevelLc.fullCounter = (lcFullCounter === 0) ? 1 : lcFullCounter;

      this.radialDataNormalization.countByLevelLc = tempArrCountByLevelLc;
    

    
      //RC data set
      let myRcLevel = 0;
      let myRcRankCounter = 0;
      let rcFullCounter = 0;
      let tempArrCountByLevelRc = {
        type: 'rcLevel',
        mylevel: 0,
        data: [],
        text: this.analysisReadingText,
        rankCounter: 0,
        fullCounter: 0,
      };

      for(let idx4 = 0; idx4 < this.data.rc_score_level.length; idx4++){
        const arr4 = this.data.rc_score_level[idx4];
        const temp4 = {
          level: (idx4 + 1),
          count: arr4.count,
        }

        if(arr4.max_score < this.data.rc){
          myRcLevel++;
          myRcRankCounter = myRcRankCounter + arr4.count;
        }
        rcFullCounter = rcFullCounter + arr4.count;

        tempArrCountByLevelRc.data.push(temp4);
      }
      tempArrCountByLevelRc.mylevel = (myRcLevel === 0) ? 1 : myRcLevel;
      tempArrCountByLevelRc.rankCounter = (myRcRankCounter === 0) ? rcFullCounter : (rcFullCounter-myRcRankCounter);
      tempArrCountByLevelRc.fullCounter = (rcFullCounter === 0) ? 1 : rcFullCounter;

      this.radialDataNormalization.countByLevelRc = tempArrCountByLevelRc;
    

    
      //SP data set
      let mySpLevel = 0;
      let mySpRankCounter = 0;
      let spFullCounter = 0;
      let tempArrCountByLevelSp = {
        type: 'spLevel',
        mylevel: 0,
        data: [],
        text: this.analysisTalkingText,
        rankCounter: 0,
        fullCounter: 0,
      };

      for(let idx5 = 0; idx5 < this.data.sp_score_level.length; idx5++){
        const arr5 = this.data.sp_score_level[idx5];
        const temp5 = {
          level: (idx5 + 1),
          count: arr5.count,
        }

        if(arr5.max_score < this.data.sp){
          mySpLevel++;
          mySpRankCounter = mySpRankCounter + arr5.count;
        }
        spFullCounter = spFullCounter + arr5.count;

        tempArrCountByLevelSp.data.push(temp5);
      }
      tempArrCountByLevelSp.mylevel = (mySpLevel === 0) ? 1 : mySpLevel;
      tempArrCountByLevelSp.rankCounter = (mySpRankCounter === 0) ? spFullCounter : (spFullCounter-mySpRankCounter);
      tempArrCountByLevelSp.fullCounter = (spFullCounter === 0) ? 1 : spFullCounter;

      this.radialDataNormalization.countByLevelSp = tempArrCountByLevelSp;
    

    
      //WC data set
      let myWcLevel = 0;
      let myWcRankCounter = 0;
      let wcFullCounter = 0;
      let tempArrCountByLevelWc = {
        type: 'wcLevel',
        mylevel: 0,
        data: [],
        text: this.analysisWritingText,
      };

      for(let idx6 = 0; idx6 < this.data.wc_score_level.length; idx6++){
        const arr6 = this.data.wc_score_level[idx6];
        const temp6 = {
          level: (idx6 + 1),
          count: arr6.count,
        }

        if(arr6.max_score < this.data.wc){
          myWcLevel++;
          myWcRankCounter = myWcRankCounter + arr6.count;
        }
        wcFullCounter = wcFullCounter + arr6.count;

        tempArrCountByLevelWc.data.push(temp6);
      }
      tempArrCountByLevelWc.mylevel = (myWcLevel === 0) ? 1 : myWcLevel;
      tempArrCountByLevelWc.rankCounter = (myWcRankCounter === 0) ? wcFullCounter : (wcFullCounter-myWcRankCounter);
      tempArrCountByLevelWc.fullCounter = (wcFullCounter === 0) ? 1 : wcFullCounter;

      this.radialDataNormalization.countByLevelWc = tempArrCountByLevelWc;
      console.log('radialDataNormalization', this.radialDataNormalization);

    },
    onSlideChange(){
      console.log(this.swiper.activeIndex);

      const num = this.swiper.activeIndex;
      this.changeLevel(num);
    },
    onSwiper(swiper){
      console.log('onSwiper', swiper);
      this.swiper = swiper;
    },
    changeLevel(num){
      this.selectedLevel = num;

      var letSwipe = num;

      this.swiper.slideTo(letSwipe, 0);

      this.setDataByLevel(num);
      //this.forceRerender();
    },
    setDataByLevel(level){
      console.log('changeLevel', level);
      this.raiderDataNormalization.forEach(element => {
        if(element.level === level){
          this.selectedLevelData = element;
        } 
      });

      if(level === 0){
        this.selectedLevelData = this.raiderDataNormalization[10];
      }
    },
    changeDistribution(item){
      console.log('changeDistribution', item);

      this.selectedDistribution = item;
      this.forceRerenderNB();
    }, 
    checkType(item, type){
      console.log('checkType', item, type );

      if(type === 'label'){
        if(item === 'word'){
          return '단어';
        } else if(item === 'lc'){
          return '듣기';
        } else if(item === 'rc'){
          return '읽기';
        } else if(item === 'wc'){
          return '쓰기';
        } else {
          return '말하기';
        }
      } else if(type === 'graph') {
        if(item === 'word'){
          return this.radialDataNormalization.countByLevelWord;
        } else if(item === 'lc'){
          return this.radialDataNormalization.countByLevelLc;
        } else if(item === 'rc'){
          return this.radialDataNormalization.countByLevelRc;
        } else if(item === 'wc'){
          return this.radialDataNormalization.countByLevelWc;
        } else {
          return this.radialDataNormalization.countByLevelSp;
        }
      } else if(type === 'rank-all') {
        if(item === 'word'){
          return this.radialDataNormalization.countByLevelWord.fullCounter;
        } else if(item === 'lc'){
          return this.radialDataNormalization.countByLevelLc.fullCounter;
        } else if(item === 'rc'){
          return this.radialDataNormalization.countByLevelRc.fullCounter;
        } else if(item === 'wc'){
          return this.radialDataNormalization.countByLevelWc.fullCounter;
        } else {
          return this.radialDataNormalization.countByLevelSp.fullCounter;
        }
      } else if(type === 'rank-count') {
        if(item === 'word'){
          return this.radialDataNormalization.countByLevelWord.rankCounter;
        } else if(item === 'lc'){
          return this.radialDataNormalization.countByLevelLc.rankCounter;
        } else if(item === 'rc'){
          return this.radialDataNormalization.countByLevelRc.rankCounter;
        } else if(item === 'wc'){
          return this.radialDataNormalization.countByLevelWc.rankCounter;
        } else {
          return this.radialDataNormalization.countByLevelSp.rankCounter;
        }
      } else {
        if(item === 'word'){
          return this.analysisWordText;
        } else if(item === 'lc'){
          return this.analysisListeningText;
        } else if(item === 'rc'){
          return this.analysisReadingText;
        } else if(item === 'wc'){
          return this.analysisWritingText;
        } else {
          return this.analysisTalkingText;
        }
      }
    },

    forceRerender(){
      this.renderKey += 1;
    },
    forceRerenderNB(){
      this.renderKeyNB += 1;
    }
  }
}
</script>


<style lang="scss" scoped>
.TestResultAnalysis{
  scroll-margin-top: 4.375rem;
  display: flex;

  flex-direction: column;

  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 8px;

  .analysisText{
    display: flex;
    flex-direction: column;
    width: 90%;

    margin-top: 1.5rem;
    margin-left: 1rem;
    margin-bottom: 1.5rem;

    .analysisTextLabel{
      display: flex;

      flex-direction: column;
      width: 100%;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 700;
      font-size: 1.375rem;
      line-height: 1.875rem;

      color: #222222;

      .analysisTextTip{
        display: flex;
        width: 100%;
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 0.813rem;
        line-height: 1rem;

        margin-top: 0.5rem;

        color: #222222;
      }
    }
  }

  .RaiderChartDiv{
    display: flex;
    width: min-content;
    height: 18rem;

    align-self: center;

    margin-top: 2.5rem;
  }

  .lineLevel{
    display: flex;

    //height: 3.5rem;
    height: auto;

    width: calc(100% - 2.5rem);
    align-self: center;
    align-items: center;
    overflow: scroll;

    margin-top: 2rem;



    .lineArea{
      display: flex;
      flex-direction: row;
      width: max-content;
      gap: 0.375rem;

      .level{
        display: flex;
        padding: 6px 12px;

        min-width: max-content;
        height: fit-content;

        border-radius: 28px;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;

        letter-spacing: -0.01em;

        justify-content: center;
      }

      .selected{
        background: rgba(223, 64, 61, 1);
        color: #FFFFFF;
      }

      .unSelected{
        background: #F5F6F7;
        color: #9E9E9E;
      }
    }
  }

  .dotType{
    display: flex;
  
    align-self: center;
    margin-top: 1rem;

    gap: 1rem;
    /* identical to box height */

    text-align: center;

    .type{
      display: flex;
      flex-direction: row;
      gap: 0.313rem;
      .dot{
        display: flex;
        align-self: center;
        width: 5px;
        height: 5px;

        border-radius: 50%;

      }

      .red{
        background-color: #FE5252;
      }

      .blue{
        background-color: #3E40F4;
      }

      .text{
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }

  .analysisAdvantageAndWeakness{
    display: flex;
    width: calc(100% - 2.5rem);
    align-self: center;
    height: auto;
    flex-direction: row;
    margin-top: 2rem;
    
    .analysisAdvantageArea{
      display: flex;
      flex-direction: column;
      width: calc(50% - 0.313rem);
      height: 4.5rem;

      background: #F5F6F7;
      border-radius: 8px;

      //align-items: center;
      justify-content: center;
      margin-right: 0.313rem;

      .analysisAdvantageLabel{
        display: flex;
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;

        color: #616161;

        margin-left: 1rem;
      }

      .analysisAdvantageText{
        display: flex;
        
        margin-left: 1rem;
        //margin-right: 1REM;
        margin-top: 0.25rem;
        min-height: 1.125rem;
      }

      .enough{
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;

        color: #3E40F4;
      }

      .empty{
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;

        color: #3E40F4;
      }

    }

    .analysisWeaknessArea{
      display: flex;
      flex-direction: column;
      width: calc(50% - 0.313rem);
      height: 4.5rem;

      background: #F5F6F7;
      border-radius: 8px;

      //align-items: center;
      justify-content: center;

      margin-left: 0.313rem;

      .analysisWeaknessLabel{
        display: flex;
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;

        color: #616161;

        margin-left: 1rem;
      }

      .analysisWeaknessText{
        display: flex;
  
        margin-left: 1rem;
        margin-top: 0.25rem;
        min-height: 1.125rem;
      }

      .enough{
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;

        color: #FE5252;
      }
      
      .empty{
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;

        color: #FE5252;
      }
    }
  }

  .prescriptionArea{
    display: flex;
    width: calc(100% - 2rem);
    align-self: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #DDDDDD;
    margin-top: 1rem;
  }

  .analysisCompareArea{
    display: flex;
    flex-direction: column;
    width: calc(100% - 2.5rem);
    align-self: center;
    height: auto;
    margin-top: 2.5rem;
    margin-bottom: 2.188rem;

    .guideDistributionLabel{
      display: flex;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 28px;

      color: #222222;

      .guideDistributionLabelSpan{
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;

        color: #3E40F4;
      }
    }

    .countDistribution{
      display: flex;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;

      color: #616161;

      margin-top: 1.5rem;
      margin-bottom: 1.5rem;

      .rankAllSpan{
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;

        color: #222222;
      }

      .rankCountSpan{
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 19px;

        color: #3E40F4;
      }
    }
    .distributionLine{
      display: flex;
      flex-direction: row;

      height: 3.5rem;
      min-width: 3.625rem;

      width: calc(100% - 2.5rem);
      align-self: center;
      align-items: center;
      overflow: scroll;

      gap: 0.25rem;


      .distribution{
        display: flex;
        padding: 6px 12px;

        min-width: max-content;
        width: -webkit-fill-available;
        height: fit-content;

        border-radius: 28px;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;

        letter-spacing: -0.01em;

        justify-content: center;
      }

      .selected{
        background: #3E40F4;
        color: #FFFFFF;
      }

      .unSelected{
        background: #F5F6F7;
        color: #9E9E9E;
      }
    }

    .NormalDistribution{
      display: flex;
      margin-top: 2rem;
    }

    .distributionRank{
      display: flex;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 19px;

      color: #616161;

      margin-left: 2rem;

      .rankGray{
        color: #616161;
        font-weight: 600;
      }

      .rankBlue{
        color: #3E40F4;
        font-weight: 600;
      }
    }

    .compareUnit{
      display: flex;
      flex-direction: column;
      width: 100%;

      .analysisUnitLabelArea{
        display: flex;
        flex-direction: row;
        width: 100%;

        .analysisUnitLabel{
          display: flex;
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;

          color: #222222;

          width: auto;
        }
      }

      .analysisUnitText{
        display: flex;
        width: 100%;
        margin-top: 0.75rem;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;

        color: #757575;
      }
    }
  }
}
</style>