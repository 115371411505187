<template>
  <div class="headerArea">
    <div class="header">
      <div class="headerLogoBox" @click="clickLogo()">
        <div class="headerLogo">
          <div class="headerLogoRight"></div>
          <div class="headerLogoIn"></div>
        </div>
      </div>
      <div class="headerText" @click="clickLogo()">
        <img class="svgSet" :src="$testControl.imgUrl(titleText)"/>
      </div>
      <div class="headerOut">
        <img :src="$testControl.imgUrl(XOutButton)"
             v-if="$testControl.isShowCloseButton()"
              @click="clickClosePage()"
        />
      </div>
    </div>
  </div>
  <div class="progressBarAreaBack">
    <div class="progressBarRunner" id="progressBarRunner">
      <img :src="$testControl.imgUrl(runner)" />
    </div>
    <div class="progressBarArea"> 
      <div class="progressBar"> 
        <div id="bar" class="innerbar"></div>
      </div>
    </div>
  </div>
  <confirmToLobbyModal v-if="toLobbyModalOpen" @uncheckLobby="uncheckLobby()" @checkLobby="checkLobby()"/>
  <CloseThePageModal v-if="openModalPageClose" @uncheckClose="closePageCloseModal()" @checkClose="checkClosePage()"/>
</template>

<script>

import Runner from "@/assets/img/block/runner.svg";

import runnerFisrtSVG from "@/assets/img/block/runnerFisrt.svg";
import runnerSecondSVG from "@/assets/img/block/runnerSecond.svg";
import runnerThirdSVG from "@/assets/img/block/runnerThird.svg";
import runnerFourthSVG from "@/assets/img/block/runnerFourth.svg";
import runnerFiveSVG from "@/assets/img/block/runnerFive.svg";

import titleTextSVG from "@/assets/img/block/titleText.svg";
import XOutButtonSVG from "@/assets/img/block/XOutButton.svg";

import confirmToLobbyModal from '@/components/confirmToLobbyModal.vue';
import CloseThePageModal from "@/components/closeThePageModal.vue";

import testControl from "@/function/testControl";

export default {
  name: 'Header',
  components: {
    confirmToLobbyModal,
    CloseThePageModal,
  },
  props: {
    num:{
      type: Number,
      default: 0,
    },
    /*fullCount:{
      type: Number,
      default: this.$store.getters['testManage/quizFullCount'],
    }*/
  },
  data(){
    return{
      progressStep: 0,
      runner: Runner,

      fullCount: this.$store.getters['testManage/quizFullCount'],

      runnerFisrt: runnerFisrtSVG,
      runnerSecond: runnerSecondSVG,
      runnerThird: runnerThirdSVG,
      runnerFourth: runnerFourthSVG,
      runnerFive: runnerFiveSVG,
      
      titleText: titleTextSVG,
      XOutButton: XOutButtonSVG,

      toLobbyModalOpen: false,
      openModalPageClose: false,

    }
  },
  computed: {
  },
  watch:{
    progressStep: {
      handler: function (count) {
        console.log('header progress counter : ', count);

        this.updateProgressbar(count);
      },
      deep: true
    },
    num: {
      handler: function (count) {
        console.log('header progress counter : ', count);

        this.updateProgressbar(count);
      },
      deep: true
    }
  },
  async mounted() {
    this.setProgress();
  },
  methods:{
    async setProgress(){
      console.log("setProgress");
      this.progressStep = this.num;
    },
    clickLogo(){
      this.toLobbyModalOpen = true;
    },
    uncheckLobby(){
      this.toLobbyModalOpen = false;
    },
    checkLobby(){
      this.toLobbyModalOpen = false;
      testControl.goRoute('lobby', false);
    },
    clickClosePage(){
      console.log('clickClosePage');

      this.openModalPageClose = true;
    },
    closePageCloseModal(){
      this.openModalPageClose = false;
    },
    checkClosePage(){
      console.log('checkClosePage');
      this.$testControl.closePage();
    },
    async updateProgressbar(num) {

      const count = num;
    
      const depth = (num / this.fullCount) * 100;
      let go = depth + "%";

      console.log("header updateProgressbar", go);
      document.getElementById("bar").style.width = go;
      document.getElementById("bar").style.height = "100%";

      const progressBarRunnerEl = document.getElementById("progressBarRunner");

      if(1 <= depth && depth < 25){
        this.runner = this.runnerFisrt;
      } else if(25 <= depth && depth < 50){
        this.runner = this.runnerSecond;
      } else if(50 <= depth && depth < 75){
        this.runner = this.runnerThird;
      } else if(75 <= depth && depth < 100){
        this.runner = this.runnerFourth;
      } else if(100 === depth){
        this.runner = this.runnerFive;
        go = '100%';
      }

      let runnerMargin = (count !== this.fullCount) ? `calc(${go} - 0.625rem)` : `calc(${go} - 1.25rem)`;

      progressBarRunnerEl.style.marginLeft = runnerMargin;
      progressBarRunnerEl.style.marginBottom = '0.327rem';
      //progressBarRunnerEl.style.width = '1.25rem';
      //progressBarRunnerEl.style.height = '1.25rem';
    },
  },
}
</script>

<style lang="scss" scoped>
.headerArea{
  //position: sticky;
  //top: 0;
  display: flex;
  flex-direction: column;

  height: auto;
  width: 100%;

  background: #FFFFFF;

  .header{
    display: flex;
    height: auto;
    width: auto;

    align-items: center;

    margin-left: 16px;
      margin-right: 16px;   
      margin-top: 16px;

    .headerLogoBox{
      display: flex;

      width: 1.875rem;
      height: 1.875rem;

      align-items: center;

      .headerLogo{
        display: flex;
        flex-direction: column;

        width: 1.529rem;
        height: 1.529rem;

        background: conic-gradient(#3E40F4, #FFFFFF);
        transform: rotate(180deg);
        border-radius: 50%;
      
        align-items: center;
        margin: auto;
        //vertical-align: middle;

        .headerLogoIn{
          width: 0.679rem;
          height: 0.679rem;
          background: #FFFFFF;
          //margin: auto auto 0 auto;
          margin: 0 0 auto 0;
          border-radius: 50%;
        }

        .headerLogoRight{
          width: 0.425rem;
          height: 0.425rem;
          background: #3E40F4;
          //margin: 0 auto 0 auto;
          margin: 0 auto 0 auto;
          border-radius: 50%;
        }
      }
    }

    .headerText{
      display: flex;
      align-items: center;
      width: 6.938rem;
      height: 1.313rem;

      .svgSet{
        width: inherit;
      }
    }

    .headerOut{
      display: flex;
      
      //margin-left: calc(100% - 12rem);
      margin-left: auto;
    }
    
  }
}
.progressBarAreaBack{
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;  
  background-color: #ffffff;
  width: 100%;
  justify-content: flex-end;
  margin: 10px 0;

  .progressBarArea{
    display: flex;
    position: sticky;
    top: 0;
    //width: calc(100% - 2.5rem);
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    //margin-left: 1.25rem;
    //margin-right: 1.25rem;

    align-items: center;

    .progressBar{
      display: flex;
      height: 0.313rem;
      width: 100%;
      //border: 1px solid black;
      background-color: #EAE9FF;

      .innerbar {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #3E40F4;
        transition: all .2s ease-in;
      }
    }

    .progressBarRunner{
      position: absolute;
      width: 1.25rem;
      height: 1.25rem;      
    }
  }
}
</style>