<template>
  <div class="labelUp">인원</div>
  <div id="NormalDistributionChart">
    <canvas :height="(type === 'default') ? '300' : '200'" ref="chart"></canvas>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';

Chart.register(annotationPlugin);

export default {
  name: 'NormalDistributionChart',
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    key:{
      type: Number,
      default: 0
    },
    graphType:{
      type: String,
      default: undefined
    }
  },
  data(){
    return{
      canvasCTX: undefined,
      canvasH: 0,
      canvasw: 0,
      chartWidth: '100',
      setData: this.chartData,
      type: this.graphType,
    }
  },
  async mounted() {
    this.canvasCTX = this.$refs.chart.getContext('2d');

    /*const el = document.getElementById('NormalDistributionChart');
    this.chartWidth = el.clientWidth;*/

    console.log('정렬 로직 추가 : ', this.setData.data);

    console.log('NormalDistributionChart', this.chartWidth)
    setTimeout(() => {
      new Chart(this.canvasCTX, {
        type: 'line',
        data: this.generateData(),
        options: this.generateConfig(),
      })
    }, 100)
  },
  methods: {
    generateData() {

      const canvasReadonly = this.$refs.chart;

      var gradient = this.canvasCTX.createLinearGradient(50, canvasReadonly.height, 50, 0);
      gradient.addColorStop(1, '#3354F4');
      gradient.addColorStop(0.5, 'rgba(51, 84, 244, 1)');
      gradient.addColorStop(0, 'rgba(51, 84, 244, 0.21)');

      const data = {
        labels: [0],
        datasets: [
          {
            label: '',
            borderColor: '#3354F4',
            backgroundColor: gradient,
            data: [0],
            fill: true,
          },
        ],
      }

      const temp = this.setData.data.sort((a, b) => a.level - b.level);
      console.log('정렬 로직 추가 : ', temp);

      if(this.setData.type === 'defaultLevel'){
        for (let i = 0; i < 10; i++) {
          const x = temp[i].level
          //const y = this.normalDistribution(x, this.mean, this.stdDev)
          const y = temp[i].count
          data.labels.push(x.toString())
          data.datasets[0].data.push(y)
        }
      } else {
        for (let i = 0; i < this.setData.data.length; i++) {
          const x = temp[i].level
          //const y = this.normalDistribution(x, this.mean, this.stdDev)
          const y = temp[i].count
          data.labels.push(x.toString())
          data.datasets[0].data.push(y)
        }
      }

      return data
    },
    generateConfig() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        borderWidth: 1,
        tension: 0.3,
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Level',
              align: 'end'
              
            },
            grid:{
              display: false
            }
          },
          
          y: {
            title: {
              display: false
            },
            grid:{
              display: false
            },
            min: 0,
          },
        },
        tooltips: {
          enabled: false
        },
        plugins: {
          legend: {
            display: false
          },
          annotation: {
            annotations: [
              {
                type: 'line',
                borderColor: 'black',
                scaleID: 'x',
                mode: "vertical",
                borderWidth: 1,
                label: {
                  display: true,
                  content: '나의 레벨',
                  backgroundColor: 'black',
                  position: 'start'
                },
                value: (`${this.chartData.mylevel}`)
              }
            ]
          },
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>

.labelUp{
  font-family: "Pretendard";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #616161;
}
.NormalDistributionChart{
  width: 100%;
  height: 100%;
}
</style>