<template>
  <div class="testContentButtonArea">
    <div class="footerContents">
      <!--<div v-if="readyToNext" class="testContentButtonNext" @click="nextStep(questionNum)">다음 문제</div>
      <div v-if="!readyToNext" class="testContentButtonNextUnselect" @click="nextStep(questionNum)">다음 문제</div>
      <div class="testContentUnder">
        <div class="testContentSkip" @click="openSkipModal">모르겠어요</div>
        <div class="testContentHelp" @click="openHelpModal"><img class="testContentHelpIcon" src="../../../assets/img/block/helpIcon.svg" />&nbsp;도움말</div>
      </div>-->
      <div class="testContentHelp" @click="openHelpModal"><img class="testContentHelpIcon" :src="$testControl.imgUrl(helpIcon)" />&nbsp;도움말</div>
      <div class="testBtnArea">
        <div class="testBtnSkip" @click="openSkipModal">모르겠어요</div>
        <div :class="[readyToNext ? 'testBtnNext' : 'testBtnNextUnselect']" @touchstart="touchStart" @touchend="touchEnd()" @click="nextStep()">{{ nextButtonLabel }}</div>
      </div>
    </div>
  </div>
  <Help v-if="helpModal" @closeModal="closeHelpModal" />
  <Skip v-if="skipModal" @closeModal="closeSkipModal" @doSkip="doSkip" @skipSet="skipSet"/>
</template>

<script>
import Help from '@/components/Test/Help.vue';
import Skip from '@/components/Test/Skip.vue';

import helpIconSVG from "@/assets/img/block/helpIcon.svg";

export default {
  name: 'Footer',
  components: {
    Help,
    Skip,
  },
  props: {
    readyToNext:{
      type: Boolean,
      default: false
    },
    skipDefaultSet:{
      type: Boolean,
    }
  },
  watch:{
    skipDefaultSet: {
      handler: function () {
        console.log('skipDefaultSet setting');
        this.defaultSet = this.skipDefaultSet;
      }
    }
  },
  data(){
    return{
      helpIcon: helpIconSVG,
      helpModal: false,
      skipModal: false,
      defaultSet: undefined,
      touchTime: 0,
    }
  },
  computed: {
    nextButtonLabel() {
      return this.$store.getters['testManage/nowQuizNum'] === this.$store.getters['testManage/quizFullCount'] ? '결과 보기' : '다음 문제'
    },
  },
  methods:{
    nextStep(){
      if(!this.isMobile()){
        this.$emit('nextStepOrder');
      }
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    touchStart(){
      console.log('touchStart', this.timer);

      this.timerOn();
    },
    touchEnd(){
      console.log('touchEnd');

      const timecheck = this.timerOff();
      if(timecheck < 300 && this.isMobile()){
        setTimeout(()=>{
          this.$emit('nextStepOrder');

        }, 50)
      }
      this.touchTime = 0;
    },
    timerOn(){
      this.timer = setInterval(() => {
        this.touchTime += 100;
      }, 100);
    },
    timerOff(){
      clearInterval(this.timer);
      return this.touchTime;
    },
    openHelpModal(){
      console.log('openHelpModal', this.helpModal);
      this.helpModal = true;
    },
    closeHelpModal(){
      console.log('closeModal');
      this.helpModal = false;
    },
    doSkip(){
      console.log('doSkip');
      this.$emit('doSkip');
    },
    skipSet(){
      console.log('skipSet');
      this.$emit('skipSet');
      this.doSkip();
    },
    openSkipModal(){
      console.log('openSkipModal', this.skipModal, this.skipDefaultSet);

      if(this.skipDefaultSet) {
        this.doSkip();
      } else {
        this.skipModal = true;
      } 
    },
    closeSkipModal(){
      console.log('closeModal');
      this.skipModal = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.testContentButtonArea{
  // position: sticky;
  position: absolute;
  bottom: 0;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 7.063rem;
  justify-content: center;

  background: #FFFFFF;
  border-top: 1px solid #e5e5e5;

  @import "@/css/footer/footerContents.scss";

  /*.footerContents{
    width: calc(100% - 2.5rem);
    margin-left: 1.25rem;
    margin-right: 1.25rem;
    margin-top: 0.5rem;

    .testContentButtonNext{
      display: flex;
      flex-direction: row;
      align-items: center;

      border-radius: 12px;
      background: #3E40F4;

      height: 3.5rem;
      
      justify-content: center;
      padding: 0px;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      text-align: center;

      color: #FFFFFF;
    }

    .testContentButtonNextUnselect{
      display: flex;
      flex-direction: row;
      align-items: center;

      border-radius: 12px;
      border: 1px solid #DDDDDD;
      background: #FFFFFF;

      height: 3.4rem;
      
      justify-content: center;
      padding: 0px;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      text-align: center;

      color: #DDDDDD;
    }

    .testContentUnder{
      display: flex;
      flex-direction: row;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;

      .testContentSkip{
        display: flex;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        text-align: center;
        text-decoration-line: underline;

        color: #9E9E9E;
      }

      .testContentHelp{
        display: flex;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        text-align: center;
        flex: none;
        order: 1;
        flex-grow: 0;

        color: #9E9E9E;
        align-items: center;

        margin-left: auto;

        .testContentHelpIcon{
          display: flex;

          height: 0.875rem;
          width: 0.875rem;

          flex: none;
          order: 0;
          flex-grow: 0;
        }
      }
    }
  }*/
}
</style>