export default {
  namespaced: true,
  state: () => ({
    challengeCount: undefined,
    tryCount: undefined,

    quizFullCount: null,

    nowQuizType: undefined,
    nowQuizNum: 1,

    userToken: undefined,
    quizSetToken: undefined,

    skipSet: false,

    resultObject: undefined,

    route: undefined,
    companyCode: undefined,
    landingPath: undefined,
    name: '',
    accessToken: undefined,
    ingangIdx: undefined,
  }),
  getters: {
    userToken(state){
      return state.userToken
    },
    quizSetToken(state){
      return state.quizSetToken
    },
    quizFullCount(state){
      return state.quizFullCount
    },
    nowQuizType(state){
      return state.nowQuizType
    },
    nowQuizNum(state){
      return state.nowQuizNum
    },
    challengeCount(state){
      return state.challengeCount
    },
    tryCount(state){
      return state.tryCount
    },
    skipSet(state){
      return state.skipSet
    },
    resultObject(state){
      return state.resultObject
    },
    route(state) {
      return state.route
    },
    companyCode(state) {
      return state.companyCode
    },
    landingPath(state) {
      return state.landingPath
    },
    name(state) {
      return state.name
    },
    accessToken(state) {
      return state.accessToken
    },
    ingangIdx(state) {
      return state.ingangIdx
    },
    isModal(state) {
      return state.landingPath === 'modal'
    },
  },
  mutations: {
    setIngangIdx: (state, value) => {
      state.ingangIdx = value
    },
    setQuizSetToken: (state, value) => {
      state.quizSetToken = value
    },
    setQuizFullCount: (state, value) => {
      state.quizFullCount = value
    },
    setNowQuizType: (state, value) => {
      state.nowQuizType = value
    },
    setNowQuizNum: (state, value) => {
      state.nowQuizNum = value
    },
    setChallengeCount: (state, value) => {
      state.challengeCount = value
    },
    setTryCount: (state, value) => {
      state.tryCount = value
    },
    setSkip(state, value){
      state.skipSet = value
    },
    setUserToken: (state, value) => {
      state.userToken = value
    },
    setResultObject: (state, value) => {
      state.resultObject = value
    },
    setRoute: (state, value) => {
      state.route = value
    },
    setCompanyCode: (state, value) => {
      state.companyCode = value
    },
    setLandingPath: (state, value) => {
      state.landingPath = value
    },
    setName: (state, value) => {
      state.name = value
    },
    setAccessToken: (state, value) => {
      state.accessToken = value
    },
    init(state) {
      state.challengeCount = undefined
      state.tryCount = undefined
      state.quizFullCount = undefined
      state.nowQuizType = undefined
      state.nowQuizNum = 1
      state.userToken = undefined
      state.quizSetToken = undefined
      state.skipSet = false
      state.resultObject = undefined
      state.route = undefined
      state.companyCode = undefined
      state.landingPath = undefined
      state.name = ''
      state.accessToken = undefined
      state.ingangIdx = undefined
    },
  },
  actions: {
  },
};