<template>
  <div class="skip">
    <div class="skipBack">
    </div>
    <div class="skipArea">
      <div class="skipVisual">
        <img :src="$testControl.imgUrl(skipVisual)" />
      </div>
      <div class="skipBold">
        다음 문제로 넘어가시겠습니까?
      </div>
      <div class="skipText">
        해당 문제는 오답 처리되며<br>다시 풀어보실 수 없습니다.
      </div>
      <div class="skipBtnArea">
        <div class="skipSelect" @click="doSkip()">
          <div class="skipSelectTxt">예</div>
        </div>
        <div class="skipCancel" @click="closeModel">
         <div class="skipCancelTxt">아니오</div>
        </div>
      </div>
    </div>
    <div class="skipSet" @click="skipSet">다시보지 않기</div>
  </div>
</template>

<script>
import skipVisual from "@/assets/img/block/skipVisual.svg"

export default {
  name: 'Help',
  data(){
    return{
      skipVisual: skipVisual,
    }
  }, 
  methods:{
    async closeModel(){
      console.log('closeModel on Skip');
      this.$emit('closeModal');
    },
    async doSkip(){
      console.log('doSkip on Skip');
      this.$emit('doSkip');
      this.closeModel();
    },
    async skipSet(){
      this.$emit('skipSet');
      this.closeModel();
    }
  }
}
</script>

<style lang="scss" scoped>
.skip{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;

  width: 100%;
  height: 100%;

  .skipBack{
    display: flex;
    position: absolute;  
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.8;

    z-index: 10;
  }

  .skipArea{
    display: flex;
    flex-direction: column;
    height: 16.375rem;
    //width: calc(100% - 3.75rem);
    width: 18.438rem;

    background: #ffffff;
    border-radius: 24px;

    opacity: 1;
    justify-content: center;
    align-items: center;
    z-index: 20;
    animation-name: fadeDown;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    visibility: visible;   
    @keyframes fadeDown {
      0% {
        opacity: 0;
        -webkit-transform: translateY(-2rem);
        -ms-transform: translateY(-2rem);
        transform: translateY(-2rem);
      }
      100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
      }
    }

    .skipVisual{
      align-self: center;
    }

    .skipBold{
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 700;
      font-size: 20px;     
      /* identical to box height, or 137% */

      text-align: center;

      /* Gray/900 */

      color: #222222;

      margin-top: 1rem;
      letter-spacing: -.4px;
    }

    .skipText{
      width: 12rem;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
      /* or 147% */

      text-align: center;

      /* Gray/700 */

      color: #616161;
      margin-top: 0.75rem;

      align-self: center;
    }

    .skipBtnArea{
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px;
      gap: 9px;

      width: 15.938rem;

      justify-self: center;

      margin-top: 1.5rem;

      .skipSelect{
        width: 7.688rem;
        height: 2.75rem;
        flex: none;
        order: 0;
        flex-grow: 0;

        background: #EAE9FF;
        border-radius: 8px;

        align-items: center;
        display: flex;
        justify-content: center;

        .skipSelectTxt{
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          /* identical to box height */

          text-align: center;

          /* Primary/P500 */

          color: #3E40F4;
          vertical-align: middle;
        }
      }

      .skipCancel{
        width: 7.688rem;
        height: 2.75rem;
        flex: none;
        order: 1;
        flex-grow: 0;

        background: #3E40F4;
        border-radius: 8px;

        align-items: center;
        display: flex;
        justify-content: center;

        .skipCancelTxt{
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          /* identical to box height */

          text-align: center;

          /* Primary/P500 */

          color: #FFFFFF;
          vertical-align: middle;
        }
      }
    }
  }

  .skipSet{
    //display: flex;
    margin-top: 1rem;

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    text-align: center;    

    /* Gray/White */

    color: #FFFFFF;
    height: 1rem;
    z-index: 20;
    animation-name: fadeDown;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    visibility: visible;   
    @keyframes fadeDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-2rem);
        -ms-transform: translateY(-2rem);
        transform: translateY(-2rem);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}
    
  }
}
</style>