<template>
  <div class="TestWritingContext">
    <Header :num="questionNum" :fullCount="quizFullCount" />
    <div class="box_content">
      <div class="questionContent" ref="questionContent">
        <div class="testContentTitle">
          Q{{questionNum}}. {{ direction }}
        </div>
        <div class="testQuestionTXT">
          {{questionText}}
        </div>
        <div class="testContentQuestion">
          
          <draggable class="questionAnswerPointLine" v-if="fisrtExamQuestionLenght >= 1"
          :group="{ name: 'people', put: questionExam1Dragable }"
          v-model="questionExam1" draggable="false" @click="clickCancelAnswer(0)" @touchstart="touchStartCancel(0)">
            <template #item="{ element }">
              <div class="questionAnswerPointLineDrag">{{ element.exam }}</div>
            </template>
          </draggable>
          <draggable class="questionAnswerPointLine" v-if="fisrtExamQuestionLenght >= 2"
          :group="{ name: 'people', put: questionExam2Dragable }"
          v-model="questionExam2" draggable="false" @click="clickCancelAnswer(1)" @touchstart="touchStartCancel(1)">
            <template #item="{ element }">
              <div class="questionAnswerPointLineDrag">{{ element.exam }}</div>
            </template>
          </draggable>
          <draggable class="questionAnswerPointLine" v-if="fisrtExamQuestionLenght >= 3"
          :group="{ name: 'people', put: questionExam3Dragable }"
          v-model="questionExam3" draggable="false" @click="clickCancelAnswer(2)" @touchstart="touchStartCancel(2)">
            <template #item="{ element }">
              <div class="questionAnswerPointLineDrag">{{ element.exam }}</div>
            </template>
          </draggable>

          <draggable class="questionAnswerPointLine" v-if="fisrtExamQuestionLenght >= 4"
          :group="{ name: 'people', put: questionExam4Dragable }"
          v-model="questionExam4" draggable="false" @click="clickCancelAnswer(3)" @touchstart="touchStartCancel(3)">
            <template #item="{ element }">
              <div class="questionAnswerPointLineDrag">{{ element.exam }}</div>
            </template>
          </draggable>
          <draggable class="questionAnswerPointLine" v-if="fisrtExamQuestionLenght >= 5"
          :group="{ name: 'people', put: questionExam5Dragable }"
          v-model="questionExam5" draggable="false" @click="clickCancelAnswer(4)" @touchstart="touchStartCancel(4)">
            <template #item="{ element }">
              <div class="questionAnswerPointLineDrag">{{ element.exam }}</div>
            </template>
          </draggable>
          <draggable class="questionAnswerPointLine" v-if="fisrtExamQuestionLenght >= 6"
          :group="{ name: 'people', put: questionExam6Dragable }"
          v-model="questionExam6" draggable="false" @click="clickCancelAnswer(5)" @touchstart="touchStartCancel(5)">
            <template #item="{ element }">
              <div class="questionAnswerPointLineDrag">{{ element.exam }}</div>
            </template>
          </draggable>

          <draggable class="questionAnswerPointLine" v-if="fisrtExamQuestionLenght >= 7"
          :group="{ name: 'people', put: questionExam7Dragable }"
          v-model="questionExam7" draggable="false" @click="clickCancelAnswer(6)" @touchstart="touchStartCancel(6)">
            <template #item="{ element }">
              <div class="questionAnswerPointLineDrag">{{ element.exam }}</div>
            </template>
          </draggable>
          <draggable class="questionAnswerPointLine" v-if="fisrtExamQuestionLenght >= 8"
          :group="{ name: 'people', put: questionExam8Dragable }"
          v-model="questionExam8" draggable="false" @click="clickCancelAnswer(7)" @touchstart="touchStartCancel(7)">
            <template #item="{ element }">
              <div class="questionAnswerPointLineDrag">{{ element.exam }}</div>
            </template>
          </draggable>
          <draggable class="questionAnswerPointLine" v-if="fisrtExamQuestionLenght >= 9"
          :group="{ name: 'people', put: questionExam9Dragable }"
          v-model="questionExam9" draggable="false" @click="clickCancelAnswer(8)" @touchstart="touchStartCancel(8)">
            <template #item="{ element }">
              <div class="questionAnswerPointLineDrag">{{ element.exam }}</div>
            </template>
          </draggable>
          .
          
        </div>

        <div class="testContentAnswerBox">
          <draggable class="testContentAnswerBoxDraggable"
          v-model="examList"
          itemKey="index"
          :group="{name:'people', pull: 'clone', put:false}"
          draggable=".testContentAnswerArea" @change="log">
            <template #item="{ element, index }" >
              <div class="testContentAnswerArea" 
              @click="checkAnswerII(element, index)" @touchstart="touchStart" @touchend="touchEnd(element, index)">
                <button v-if="!element.check && touchAnswerNum !== index" class="testContentAnswer">{{ element.exam }}</button>
                <button v-if="!element.check && touchAnswerNum === index" class="testContentAnswerTouch">{{element.exam}}</button>
                <button v-if="element.check" class="testContentAnswerCheck">{{element.exam}}<!--<img v-if="element.check" class="uncheckSVG" :src="uncheck" />--></button>
                
              </div>
            </template>
          </draggable>
        </div>
        
      </div>
    </div>
    <Footer :readyToNext="readyToNext" @nextStepOrder="nextStep(false)" @doSkip="doSkip" @skipSet="skipSet" :skipDefaultSet="skipDefaultSet"/>
  </div>
</template>

<script>
import Header from '@/components/Test/Header.vue';
import Footer from '@/components/Test/Footer.vue';
import uncheckSVG from "@/assets/img/block/uncorrect.svg";
//import axios from 'axios';

import draggable from 'vuedraggable';

export default {
  name: 'TestWritingContext',
  components: {
    draggable,
    Header,
    Footer
  },
  props: {
  },
  data() {
    return {
      quizData: undefined,
      selectedTempArr: [undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined],
      selectedViewTest: undefined,
      dragFlag: true,
      dragableFlag: false,
      firstExamLenght: 0,
      fisrtExamQuestionLenght: 0,

      touchAnswer: '',
      touchAnswerNum: -1,
      touchAnswerFlag: false,

      questionExam1: [],
      questionExam1Dragable: true,
      questionExam2: [],
      questionExam2Dragable: true,
      questionExam3: [],
      questionExam3Dragable: true,
      questionExam4: [],
      questionExam4Dragable: true,
      questionExam5: [],
      questionExam5Dragable: true,
      questionExam6: [],
      questionExam6Dragable: true,
      questionExam7: [],
      questionExam7Dragable: true,
      questionExam8: [],
      questionExam8Dragable: true,
      questionExam9: [],
      questionExam9Dragable: true,

      justCheck:0,
      readyToNext: false,

      timer: undefined,
      touchTime: 0,

      uncheck: uncheckSVG,
    }
  },
  computed: {
    readyToNextCheck: function(){
      console.log('computed readyToNextCheck',this.justCheck, this.fisrtExamQuestionLenght);
      return !(this.justCheck < this.fisrtExamQuestionLenght)
    },
    direction() {
      return this.quizData ? this.quizData.direction : ''
    },
    questionNum() {
      return this.$store.getters['testManage/nowQuizNum']
    },
    questionId() {
      return this.quizData ? this.quizData.questionId : 0
    },
    quizFullCount() {
      return this.quizData ? this.quizData.quizFinishNumber : 0
    },
    quizType() {
      return this.quizData ? this.quizData.quizType : 0
    },
    questionText() {
      return this.quizData ? this.quizData.questionText : ''
    },
    examDefault() {
      return this.quizData ? this.quizData.examDefault : ''
    },
    examList() {
      return this.quizData ? this.quizData.examList : []
    },
    endCharacter() {
      return this.quizData ? this.quizData.endCharacter : ''
    },
    skipDefaultSet() {
      return this.$store.getters['testManage/skipSet']
    },
  },
  watch: {
    readyToNextCheck: {
      handler: function (check){
        console.log('readyToNextCheck', check);

        this.readyToNext = check;
      }
    },
    selectedTempArr:{
      handler: function (check){
        console.log('selectedTempArr', check);
      }
    },
    questionExam1: {
      handler: function (arr) {
        console.log('questionExam1 changed', arr);

        if(arr.length === 1){
          if(arr[0].check === true){
            //이미 사용된 선택지여부 체크
            this.questionExam1 = [];
          } else {
            console.log('questionExam1 length 1');
            this.justCheck++;
            this.questionExam1Dragable = false;
            this.selectedTempArr[0] = arr[0];
            this.setChecked(arr[0]);

            console.log('questionExam1 : ', this.questionExam1, this.selectedTempArr);
          }
        } else if(arr.length < 1){
          console.log('questionExam1 length 0');
          this.questionExam1Dragable = true;
          
        }
      },
      deep: false
    },
    questionExam2: {
      handler: function (arr) {
        console.log('questionExam2 changed', arr);

        if(arr.length === 1){
          if(arr[0].check === true){
             //이미 사용된 선택지여부 체크
            this.questionExam2 = [];
          } else {
            console.log('questionExam2 length 1');
            this.justCheck++;
            this.questionExam2Dragable = false;
            this.selectedTempArr[0] = arr[0];
            this.setChecked(arr[0]);

            console.log('questionExam2 : ', this.questionExam2, this.selectedTempArr);
          }
        } else if(arr.length < 1){
          console.log('questionExam2 length 0');
          this.questionExam2Dragable = true;
          
        }
      },
      deep: false
    },
    questionExam3: {
      handler: function (arr) {
        console.log('questionExam3 changed', arr);

        if(arr.length === 1){
          if(arr[0].check === true){
             //이미 사용된 선택지여부 체크
            this.questionExam3 = [];
          } else {
            console.log('questionExam3 length 1');
            this.justCheck++;
            this.questionExam3Dragable = false;
            this.selectedTempArr[0] = arr[0];
            this.setChecked(arr[0]);

            console.log('questionExam3 : ', this.questionExam3, this.selectedTempArr);
          }
        } else if(arr.length < 1){
          console.log('questionExam3 length 0');
          this.questionExam3Dragable = true;
          
        }
      },
      deep: false
    },
    questionExam4: {
      handler: function (arr) {
        console.log('questionExam4 changed', arr);

        if(arr.length === 1){
          if(arr[0].check === true){
             //이미 사용된 선택지여부 체크
            this.questionExam4 = [];
          } else {
            console.log('questionExam4 length 1');
            this.justCheck++;
            this.questionExam4Dragable = false;
            this.selectedTempArr[0] = arr[0];
            this.setChecked(arr[0]);

            console.log('questionExam4 : ', this.questionExam4, this.selectedTempArr);
          }
        } else if(arr.length < 1){
          console.log('questionExam4 length 0');
          this.questionExam4Dragable = true;
          
        }
      },
      deep: false
    },
    questionExam5: {
      handler: function (arr) {
        console.log('questionExam5 changed', arr);

        if(arr.length === 1){
          if(arr[0].check === true){
             //이미 사용된 선택지여부 체크
            this.questionExam5 = [];
          } else {
            console.log('questionExam5 length 1');
            this.justCheck++;
            this.questionExam5Dragable = false;
            this.selectedTempArr[0] = arr[0];
            this.setChecked(arr[0]);

            console.log('questionExam5 : ', this.questionExam5, this.selectedTempArr);
          }
        } else if(arr.length < 1){
          console.log('questionExam5 length 0');
          this.questionExam5Dragable = true;
          
        }
      },
      deep: false
    },
    questionExam6: {
      handler: function (arr) {
        console.log('questionExam6 changed', arr);

        if(arr.length === 1){
          if(arr[0].check === true){
             //이미 사용된 선택지여부 체크
            this.questionExam6 = [];
          } else {
            console.log('questionExam6 length 1');
            this.justCheck++;
            this.questionExam6Dragable = false;
            this.selectedTempArr[0] = arr[0];
            this.setChecked(arr[0]);

            console.log('questionExam6 : ', this.questionExam6, this.selectedTempArr);
          }
        } else if(arr.length < 1){
          console.log('questionExam6 length 0');
          this.questionExam6Dragable = true;
          
        }
      },
      deep: false
    },
    questionExam7: {
      handler: function (arr) {
        console.log('questionExam7 changed', arr);

        if(arr.length === 1){
          if(arr[0].check === true){
             //이미 사용된 선택지여부 체크
            this.questionExam7 = [];
          } else {
            console.log('questionExam7 length 1');
            this.justCheck++;
            this.questionExam7Dragable = false;
            this.selectedTempArr[0] = arr[0];
            this.setChecked(arr[0]);

            console.log('questionExam7 : ', this.questionExam7, this.selectedTempArr);
          }
        } else if(arr.length < 1){
          console.log('questionExam7 length 0');
          this.questionExam7Dragable = true;
          
        }
      },
      deep: false
    },
    questionExam8: {
      handler: function (arr) {
        console.log('questionExam8 changed', arr);

        if(arr.length === 1){
          if(arr[0].check === true){
             //이미 사용된 선택지여부 체크
            this.questionExam8 = [];
          } else {
            console.log('questionExam8 length 1');
            this.justCheck++;
            this.questionExam8Dragable = false;
            this.selectedTempArr[0] = arr[0];
            this.setChecked(arr[0]);

            console.log('questionExam8 : ', this.questionExam8, this.selectedTempArr);
          }
        } else if(arr.length < 1){
          console.log('questionExam8 length 0');
          this.questionExam8Dragable = true;
          
        }
      },
      deep: false
    },
    questionExam9: {
      handler: function (arr) {
        console.log('questionExam9 changed', arr);

        if(arr.length === 1){
          if(arr[0].check === true){
             //이미 사용된 선택지여부 체크
            this.questionExam9 = [];
          } else {
            console.log('questionExam9 length 1');
            this.justCheck++;
            this.questionExam9Dragable = false;
            this.selectedTempArr[0] = arr[0];
            this.setChecked(arr[0]);

            console.log('questionExam9 : ', this.questionExam9, this.selectedTempArr);
          }
        } else if(arr.length < 1){
          console.log('questionExam9 length 0');
          this.questionExam9Dragable = true;
          
        }
      },
      deep: false
    },
    justCheck: {
      handler: function (num) {
        console.log('justCheck ', num, this.fisrtExamQuestionLenght);
        
      }
    }
  },
  async mounted() {
    await this.setVal();
  },
  methods: {
  
    async setVal(){
      console.log("TestReadingWord setVal");
      // window.scrollTo({ top: 0});
      this.$refs.questionContent.scrollIntoView({block: "start"})

      this.questionExam1 = [];
      this.questionExam2 = [];
      this.questionExam3 = [];
      this.questionExam4 = [];
      this.questionExam5 = [];
      this.questionExam6 = [];
      this.questionExam7 = [];
      this.questionExam8 = [];
      this.questionExam9 = [];

      this.quizData = await this.$testControl.fnTestCall();
      console.log('getData : ', this.quizData);

      this.firstExamLenght = this.examDefault.length;
      this.fisrtExamQuestionLenght = this.firstExamLenght - 3;
      console.log('setVal', this.fisrtExamQuestionLenght)
      this.justCheck = 0;
    },

    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    timerOn(){
      this.timer = setInterval(() => {
        this.touchTime += 100;
      }, 100);
    },
    timerOff(){
      
      clearInterval(this.timer);
      return this.touchTime;
    },

    touchStart(){
      if(this.isMobile()){
        console.log('touchStart', this.touchTime);
        this.timerOn();
      }
    },

    touchEnd(item, idx){
      if(this.isMobile()){
        console.log('touchEnd', item, idx,  this.touchTime);
        const timecheck = this.timerOff();
        //터치와 드레그를 구분하는 터치시간 계산
        if(timecheck < 100){
          setTimeout(()=>{
            this.checkAnswer(item, idx);
          }, 50)
        } 
        this.touchTime = 0;
      }
    },

    touchStartCancel(num){
      if(this.isMobile()){
        console.log('touchStartCancel', this.timer);
        this.touchEndCancel(num);
      }
    },
    touchEndCancel(num){
      
      if(this.isMobile()){
        console.log('touchEndCancel');
        setTimeout(()=>{
          this.cancelAnser(num);
          
        }, 50)
      }
      this.touchTime = 0;
    },

    checkAnswerII(item, idx){
      console.log('mobile check', this.isMobile())

      if(!this.isMobile()){
        this.checkAnswer(item, idx);
        //this.setAnswer(item, idx, 'before');
      }
    },
    
    async checkAnswer(item, idx){
      console.log("checkAnswer", item, idx);

      if(item.check === false && this.fisrtExamQuestionLenght > this.justCheck){

        this.selectedTempArr[idx] = item;
        this.examList[idx].check = true;
        this.justCheck++;

        if(this.questionExam1.length === 0){
          this.questionExam1.push(item);
          this.questionExam1Dragable = false;
        } else if(this.questionExam2.length === 0){
          this.questionExam2.push(item);
          this.questionExam2Dragable = false;
        } else if(this.questionExam3.length === 0){
          this.questionExam3.push(item);
          this.questionExam3Dragable = false;
        } else if(this.questionExam4.length === 0){
          this.questionExam4.push(item);
          this.questionExam4Dragable = false;
        } else if(this.questionExam5.length === 0){
          this.questionExam5.push(item);
          this.questionExam5Dragable = false;
        } else if(this.questionExam6.length === 0){
          this.questionExam6.push(item);
          this.questionExam6Dragable = false;
        } else if(this.questionExam7.length === 0){
          this.questionExam7.push(item);
          this.questionExam7Dragable = false;
        } else if(this.questionExam8.length === 0){
          this.questionExam8.push(item);
          this.questionExam8Dragable = false;
        } else if(this.questionExam9.length === 0){
          this.questionExam9.push(item);
          this.questionExam9Dragable = false;
        }

      } else if(item.check !== false){
        console.log('이미 사용한 선택지입니다.');

        if(this.questionExam1.length !== 0 && this.questionExam1[0].exam === item.exam){
          console.log('questionExam1 취소');
          this.cancelAnser(0);
        } else if(this.questionExam2.length !== 0 && this.questionExam2[0].exam === item.exam){
          console.log('questionExam2 취소');
          this.cancelAnser(1);
        } else if(this.questionExam3.length !== 0 && this.questionExam3[0].exam === item.exam){
          console.log('questionExam3 취소');
          this.cancelAnser(2);
        } else if(this.questionExam4.length !== 0 && this.questionExam4[0].exam === item.exam){
          console.log('questionExam4 취소');
          this.cancelAnser(3);
        } else if(this.questionExam5.length !== 0 && this.questionExam5[0].exam === item.exam){
          console.log('questionExam5 취소');
          this.cancelAnser(4);
        } else if(this.questionExam6.length !== 0 && this.questionExam6[0].exam === item.exam){
          console.log('questionExam6 취소');
          this.cancelAnser(5);
        } else if(this.questionExam7.length !== 0 && this.questionExam7[0].exam === item.exam){
          console.log('questionExam7 취소');
          this.cancelAnser(6);
        } else if(this.questionExam8.length !== 0 && this.questionExam8[0].exam === item.exam){
          console.log('questionExam8 취소');
          this.cancelAnser(7);
        } else if(this.questionExam9.length !== 0 && this.questionExam9[0].exam === item.exam){
          console.log('questionExam9 취소');
          this.cancelAnser(8);
        }

      } else if(this.fisrtExamQuestionLenght <= this.justCheck){
        console.log('체크 가능한 횟수를 넘었습니다.');
      }

    },

    clickCancelAnswer(num){
      if(!this.isMobile()){
        console.log('clickCancelAnswer', num);
        this.cancelAnser(num);
      }
    },

    async cancelAnser(num){
      console.log("cancelAnser", num);

      let tempItem = undefined;
      this.selectedTempArr[num] = undefined;

      if(num === 0){
        if(this.questionExam1.length > 0){
          tempItem = this.questionExam1[0];
          this.questionExam1 = [];
        }
      } else if(num === 1){
        if(this.questionExam2.length > 0){
          tempItem = this.questionExam2[0];
          this.questionExam2 = [];
        }
      } else if(num === 2){
        if(this.questionExam3.length > 0){
          tempItem = this.questionExam3[0];
          this.questionExam3 = [];
        }
      } else if(num === 3){
        if(this.questionExam4.length > 0){
          tempItem = this.questionExam4[0];
          this.questionExam4 = [];
        }
      } else if(num === 4){
        if(this.questionExam5.length > 0){
          tempItem = this.questionExam5[0];
          this.questionExam5 = [];
        }
      } else if(num === 5){
        if(this.questionExam6.length > 0){
          tempItem = this.questionExam6[0];
          this.questionExam6 = [];
        }
      } else if(num === 6){
        if(this.questionExam7.length > 0){
          tempItem = this.questionExam7[0];
          this.questionExam7 = [];
        }
      } else if(num === 7){
        if(this.questionExam8.length > 0){
          tempItem = this.questionExam8[0];
          this.questionExam8 = [];
        }
      } else if(num === 8){
        if(this.questionExam9.length > 0){
          tempItem = this.questionExam9[0];
          this.questionExam9 = [];
        }
      }

      if(tempItem !== undefined){
        this.justCheck--;
        for(let i = 0; i < this.examList.length; i++){
          if(tempItem.exam === this.examList[i].exam){
            this.examList[i].check = false;
          }
        }
      }
    },

    async setChecked(item){
      //답을 입력했을 때, 이미 입력된 답안이 두 번 이용되지 않도록 하는 장치.

      for(let i = 0; i < this.examList.length; i++){
        if(item.exam === this.examList[i].exam){
          this.examList[i].check = true;
        }
      }
    },

    async nextStep(skipBool){
      console.log('step : ', this.questionNum, this.justCheck, this.fisrtExamQuestionLenght);

      if(!this.readyToNext && !skipBool){
        console.log("답을 입력해주세요.");
        return;
      } else {
        const answerTemp = [this.questionExam1, this.questionExam2, this.questionExam3, this.questionExam4, this.questionExam5, this.questionExam6, this.questionExam7, this.questionExam8, this.questionExam9]
        const answer = answerTemp.filter(answer => answer.length > 0).reduce((answer, word) => answer + `${word[0].exam} `, '').trim()

        const nextType = await this.$testControl.saveAnswer((!skipBool ? answer : 'SKIP_ANSWER'));

        this.touchAnswerNum = -1;

        if(nextType === this.quizType){
          this.selectedTempArr= [undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined];
          await this.setVal();
        } else {
          await this.$testControl.toNextType(nextType);
        }
      }
    },
    async doSkip(){
      this.nextStep(true);
      
    },
    async skipSet(){
      this.skipDefaultSet = true;

      this.$store.commit('testManage/setSkip', true);
      console.log('skipSet', this.$store.getters['testManage/skipSet']);
    }
  }
}
</script>


<style lang="scss" scoped>
.TestWritingContext{
  display: flex;
  flex-direction: column;

  width: 100%;
  height: inherit;

  .box_content {
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;
    overflow-y: scroll;
    .questionContent{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      width: auto;
      margin-left: 1.25rem;
      margin-right: 1.25rem;

      .testContentTitle{
        display: flex;
        width: 100%;

        margin-top: 0.625rem;
        margin-bottom: 1rem;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: .9375rem;
        line-height: 1.5;

        color: #222222;
      }

      .testQuestionTXT{
        display: flex;
        width: 100%;

        margin-top: 0.4rem;
        margin-bottom: 0.4rem;
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 1.75rem;
        justify-content: center;
        /* or 140% */

        text-align: center;

        /* Gray/900 */

        color: #222222;
      }
    
      .testContentQuestion {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 0;
        gap: .5rem;

        width: 100%;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 1.375rem;
        line-height: 1.625rem;

        color: #222222;

        margin-top: 1.5rem;
        margin-bottom: 2rem;
        justify-content: center;

        .questionAnswerPointLine{
          display: flex;
          padding: 0;
          gap: .5rem;

          min-width: 3rem;
          height: 1.6rem;
          border-bottom: .0625rem solid #222222;

          /*flex: none;
          order: 0;
          flex-grow: 0;*/

          .questionAnswerPointLineDrag{
            height: 100%;
            width: 100%;

            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 1.5;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
          }
        }

        .tempDiv{
          display: flex;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 500;
          font-size: 1.1875rem;
          line-height: 1.625rem;
          vertical-align: middle;

          color: #222222;
        }
      }

      .testContentAnswerBox{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
        margin-bottom: 8rem;

        .testContentAnswerBoxDraggable{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          height: auto;

          .testContentAnswerArea{
            display: flex;
            flex-direction: row;
            //align-items: flex-start;

            margin-left: 0.5rem;
            margin-top: 0.5rem;

            align-items: center;

            .testContentAnswer{
              
              padding: .5625rem 1.25rem;
              background: #F5F6F7;
              border-radius: 2.25rem;

              font-family: 'Pretendard';
              font-style: normal;
              font-weight: 400;
              font-size: 1rem;
              line-height: 1.1875rem;
              display: flex;
              align-items: center;

              color: #222222;

            }

            .testContentAnswerTouch{
              padding: .5625rem 1.25rem;
              background: #F5F6F7;
              border-radius: 2.25rem;
              //border: 2px solid #3E40F4;

              font-family: 'Pretendard';
              font-style: normal;
              font-weight: 400;
              font-size: 1rem;
              line-height: 1.1875rem;
              display: flex;
              align-items: center;
              color: #3E40F4;
            }

            .testContentAnswerCheck{
              padding: .5625rem 1.25rem;
              background: rgba(245, 246, 247, 0.5);
              border-radius: 2.25rem;

              font-family: 'Pretendard';
              font-style: normal;
              font-weight: 400;
              font-size: 1rem;
              line-height: 1.1875rem;
              display: flex;
              align-items: center;
              justify-content: center;

              color: rgba(34, 34, 34, 0.5);

              .uncheckSVG{
                display: flex;
                position: absolute;
              }
            }
          }

          .check{
            background-color: rgba(245, 246, 247, 0.5);
            justify-content: center;
          }
        }

        
      }
    }
  }
}
</style>