<template>
  <div class="InfoSelectFirst" ref="InfoSelectFirst">
    <div class="headerArea">
      <div class="header">
        <div class="headerLogoBox">
          <div class="headerLogo">
            <div class="headerLogoRight"></div>
            <div class="headerLogoIn"></div>
          </div>
        </div>
        <div class="headerText">
          <img class="svgSet" :src="$testControl.imgUrl(titleText)"/>
        </div>
        <div class="headerRight">
          <div class="headerOut">
            <img :src="$testControl.imgUrl(XOutButton)"
                 v-if="$testControl.isShowCloseButton()"
                 @click="$testControl.closePage()"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="infoGuide">
      <div class="infoGuideLabel">
        영어 실력이<br/> 어느 정도이신가요?
      </div>
      <div class="infoGuideTip">
        홍길동님에게 맞는 문제를 출제해 드립니다.
      </div>
    </div>
    <div class="checkLevelArea">
      <div class="checkLevelStep" id="checkLevelStepFisrt" @click="checkStep(1)">
        <div class="checkLevelStepLeft">
          초급
        </div>
        <div class="checkLevelStepRight">
          <div class="checkLevelStepRightLabel">간단한 대화도 쉽지 않아요.</div>
          <div class="checkLevelStepRightTip">(토익 500점 미만, 토스 novice)</div>
        </div>
      </div>
      <div class="checkLevelStep" id="checkLevelStepSecond" @click="checkStep(2)">
        <div class="checkLevelStepLeft">
          중급
        </div>
        <div class="checkLevelStepRight">
          <div class="checkLevelStepRightLabel">짧고 간단한 대화를 할 수 있어요.</div>
          <div class="checkLevelStepRightTip">(토익 500점~800점 , 토스 intermediate)</div>
        </div>
      </div>
      <div class="checkLevelStep" id="checkLevelStepThird" @click="checkStep(3)">
        <div class="checkLevelStepLeft">
          고급
        </div>
        <div class="checkLevelStepRight">
          <div class="checkLevelStepRightLabel">영어로 대화를 이어갈 수 있어요!</div>
          <div class="checkLevelStepRightTip">(토익 800점 이상, 토스 Advanced 이상)</div>
        </div>
      </div>
    </div>
    <div class="pageSet">
      1<div class="pageSetRight">/2</div>
    </div>
    <div :class="'testStartBtnArea' + ((this.selectStep === 0) ? ' unselected' : ' selected')" @click="toNextInfo()">
      다음
    </div>
    <div class="skip" @click="opneModel">
      건너뛰기
    </div>
    <InfoSkip v-if="skipModal" @skipInfo="skipInfo()" @cancelSkip="cancelSkip()"/>
  </div>
</template>

<script>
import titleTextSVG from "@/assets/img/block/titleText.svg";
import XOutButtonSVG from "@/assets/img/block/XOutButton.svg";
import InfoSkip from "@/components/InfoSkip.vue";
import testControl from "@/function/testControl";

export default {
  name:"InfoSelectFirst",
  components:{
    InfoSkip,
  },
  data() {
    return {
      titleText: titleTextSVG,
      XOutButton: XOutButtonSVG,
      level: '',
      selectStep: 0,
      skipModal: false,
    }
  },
  async mounted() {
    this.$refs.InfoSelectFirst.scrollIntoView({block: "start"})
    // window.scrollTo({ top: 0});
  },
  methods:{
    async toNextInfo(){
      console.log('toNextInfo');

      if(this.selectStep !== 0){

        if(this.selectStep === 1){
          this.level = '초급'
        } else if(this.selectStep === 2){
          this.level = '중급'
        } else if(this.selectStep === 3){
          this.level = '고급'
        }

        this.$store.commit('setUserSelectedSkillInfo', this.level);
        testControl.goRoute('infoSecond')
      } else {
        console.log('not select');
      }
    },
    async skipInfo(){
      console.log('skipInfo');

      this.$store.commit('setUserSelectedSkillInfo', 'SKIP');
      testControl.goRoute('infoSecond')
    },
    async opneModel(){
      console.log('skipModal', this.skipModal);
      this.skipModal = !this.skipModal;
    },
    async cancelSkip(){
      console.log('cancelSkip');
      this.skipModal = false;
    },
    async checkStep(num){
      console.log('checkStep', num);
      
      let elNum = undefined;
      let elStep = undefined;

      if(num === 1){
        elNum = document.getElementById('checkLevelStepFisrt');
      } else if(num === 2){
        elNum = document.getElementById('checkLevelStepSecond');
      } else if(num === 3){
        elNum = document.getElementById('checkLevelStepThird');
      }

      if(this.selectStep === 1){
        elStep = document.getElementById('checkLevelStepFisrt');
      } else if(this.selectStep === 2){
        elStep = document.getElementById('checkLevelStepSecond');
      } else if(this.selectStep === 3){
        elStep = document.getElementById('checkLevelStepThird');
      }


      if(this.selectStep === 0){

        elNum.style.border = "solid 2px #3E40F4";
        elNum.style.background = "#EAE9FF";
        
        
        this.selectStep = num;

      } else if(this.selectStep !== 0 && this.selectStep === num){
        
        elNum.style.border = "solid 2px #F5F6F7";
        elNum.style.background = "#F5F6F7";
        this.selectStep = 0;

      } else if(this.selectStep !== 0 && this.selectStep !== num){

        elStep.style.border = "solid 2px #F5F6F7";
        elStep.style.background = "#F5F6F7";
        elNum.style.border = "solid 2px #3E40F4";
        elNum.style.background = "#EAE9FF";
        this.selectStep = num;
      }

      console.log('now select : ', this.selectStep);
    }
  }
}
</script>

<style lang="scss" scoped>
.InfoSelectFirst{
  display: flex;
  flex-direction: column;

  width: 100%;
  height: auto;
  
  .headerArea{
    display: flex;
    flex-direction: column;

    height: auto;
    width: 100%;

    background: #FFFFFF;

    .header{
      display: flex;
      height: auto;
      width: auto;

      align-items: center;

      margin-left: 16px;
      margin-right: 16px;   
      margin-top: 16px;

      .headerLogoBox{
        display: flex;

        width: 1.875rem;
        height: 1.875rem;

        align-items: center;

        .headerLogo{
          display: flex;
          flex-direction: column;

          width: 1.529rem;
          height: 1.529rem;

          background: conic-gradient(#3E40F4, #FFFFFF);
          transform: rotate(180deg);
          border-radius: 50%;
        
          align-items: center;
          margin: auto;
          //vertical-align: middle;

          .headerLogoIn{
            width: 0.679rem;
            height: 0.679rem;
            background: #FFFFFF;
            //margin: auto auto 0 auto;
            margin: 0 0 auto 0;
            border-radius: 50%;
          }

          .headerLogoRight{
            width: 0.425rem;
            height: 0.425rem;
            background: #3E40F4;
            //margin: 0 auto 0 auto;
            margin: 0 auto 0 auto;
            border-radius: 50%;
          }
        }
      }

      .headerText{
        display: flex;
        align-items: center;
        width: 6.938rem;
        height: 1.313rem;

        .svgSet{
          width: inherit;
        }
      }

      .headerRight{
        display: flex;
        margin-left: auto;

        .headerOut{
          display: flex;
         
          //margin-left: calc(100% - 12rem);
          margin-left: 0.5rem;
        }
      }
      
    }

  }

  .infoGuide{
    display: flex;
    width: calc(100% - 2.5rem);
    height: 6.5rem;
    margin-top: 48px;
    flex-direction: column;
    align-self: center;

    .infoGuideLabel{
      display: flex;
      //width: 13.5rem;
      width: 100%;
      //height: 4.5rem;

      word-break: keep-all;

      font-family: 'Pretendard';      
      font-weight: 800;
      font-size: 28px;
      line-height: 36px;
      letter-spacing: -0.5px;

      color: #222222;
    }

    .infoGuideTip{
      display: flex;
      margin-top: 0.875rem;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;

      color: #757575;
    }

  }

  .checkLevelArea{
    display: flex;
    flex-direction: column;
    width: calc(100% - 2.5rem);
    align-self: center;

    margin-top: 2.5rem;

    .checkLevelStep:nth-child(1) .checkLevelStepLeft{
      background: #FFC635;
    }

    .checkLevelStep:nth-child(2) {
      margin-top: 0.75rem;
      .checkLevelStepLeft{
        background: #FF8B38;
      }
    }

    .checkLevelStep:nth-child(3) {
      margin-top: 0.75rem;
      .checkLevelStepLeft{
        background: #FD2448;
      }
    }

    .checkLevelStep{
      display: flex;
      height: 5.75rem;
      background: #F5F6F7;
      border-radius: 12px;
      align-items: center;
      border: solid 2px #F5F6F7;
      transition: all .15s ease-in;

      .checkLevelStepLeft{
        display: flex;
        width: 2.375rem;
        height: 2.375rem;
        //background: #FFC635;
        border-radius: 50%;
        margin-left: 1.25rem;
        align-items: center;
        justify-content: center;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        text-align: center;

        /* Gray/White */

        color: #FFFFFF;
      }
      

      .checkLevelStepRight{
        display: flex;
        flex-direction: column;
        margin-left: 0.75rem;

        .checkLevelStepRightLabel{
          display: flex;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 700;
          font-size: 17px;
          line-height: 20px;
          letter-spacing: -0.01em;

          /* Gray/900 */

          color: #222222;
        }

        .checkLevelStepRightTip{
          display: flex;
          
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          /* identical to box height */


          /* Gray/600 */

          color: #757575;
          margin-top: 0.375rem;
        }
      }
    }
  }

  .pageSet{
    display: flex;
    margin-top: 24px;
    align-self: center;

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 0.2em;

    /* Gray/900 */

    color: #222222;

    .pageSetRight{
      color: #C2C2C2;
    }
  }

  .testStartBtnArea{
    display: flex;
    width: calc(100% - 40px);
    height: 60px;

    border-radius: 12px;

    align-self: center;

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;

    align-items: center;
    justify-content: center;
    margin: 40px 0 20px;
  }

  .unselected{
    background: #EAE9FF;
    color: #A4A5FC;
  }

  .selected{
    background: #3E40F4;
    color: #FFFFFF;
  }

  .skip{
    display: flex;
    align-self: center;
    margin-bottom: 3rem;

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    text-align: center;   

    /* Gray/500 */

    color: #9E9E9E
  }

}
</style>