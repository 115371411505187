<template>
  <div class="TestResult" ref="TestResult" id="TestResult">

    <div class="headerArea">
      <div class="header">
        <div class="headerLogoBox">
          <div class="headerLogo">
            <div class="headerLogoRight"></div>
            <div class="headerLogoIn"></div>
          </div>
        </div>
        <div class="headerText">
          <img class="svgSet" :src="$testControl.imgUrl(titleText)"/>
        </div>
        <div class="headerRight">
          <div class="personIcon" @click="toMyPage" v-if="false">
            <img :src="$testControl.imgUrl(personIcon)"/>
          </div>
          <div class="headerOut">
            <img :src="$testControl.imgUrl(XOutButton)"
                 v-if="$testControl.isShowCloseButton()"
                 @click="$testControl.closePage()"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="resultArea" v-if="showResult">
      <div class="tabResultTypeSelect" id="tabResultTypeSelect">
        <div :class="'resultType ' + [(tabSelect === 1) ? 'selected' : 'unselected']" @click="selectTab(1)">종합진단</div>
        <div :class="'resultType ' + [(tabSelect === 2) ? 'selected' : 'unselected']" @click="selectTab(2)">강약분석</div>
        <div :class="'resultType ' + [(tabSelect === 3) ? 'selected' : 'unselected']" @click="selectTab(3)">정답확인</div>
        <div :class="'resultType ' + [(tabSelect === 4) ? 'selected' : 'unselected']" @click="selectTab(4)" v-if="showRecommendProductType!=='ybm_b2b'">추천콘텐츠</div>
        <!--<div class="survey-tab" :class="'resultType ' + [(tabSelect === 5) ? 'selected' : 'unselected']" @click="selectTab(5)" v-if="isCompanyCodeYbmClass">
          <img :src="$testControl.imgUrl(tabEventImg)" class="survey-tab-event-image"/>
          설문조사
        </div>-->
      </div>

      <div class="resultPart" id="TestResultOverallDiagnosis" ref="TestResultOverallDiagnosis">
        <TestResultOverallDiagnosis :data="resultData" @openGuideB2B="openGuideB2B()" v-if="!isLoading"/>
      </div>

      <div class="separation"></div>

      <div class="resultPart" id="TestResultAnalysis" ref="TestResultAnalysis">
        <TestResultAnalysis @eduSelectLocationFromWeak="eduSelectLocationFromWeak" :userName="userName" :eduLocationIdx="eduLocationIdx" :data="resultData" v-if="!isLoading"/>
      </div>

      <div class="separation"></div>

      <div class="checkAnswerArea" id="TestResultCheckAnswer" ref="TestResultCheckAnswer">
        <TestResultCheckAnswer @openAnswer="openAnswerCheck()" :testRecord="testRecordArr" v-if="!isLoading"/>
      </div>

      <div class="separation"></div>

      <div class="resultPart" id="TestResultGuideForProduct" ref="TestResultGuideForProduct">
        <template v-if="!isLoading">
          <TestResultGuideForToeicProduct :level="level" :userName="userName" v-if="showRecommendProductType==='ybm_class'"/>
          <TestResultGuideForBookProduct :level="level" :userName="userName" v-else-if="showRecommendProductType==='ybm_publishing'"/>
          <TestResultGuideForLemonProduct :level="level" :userName="userName" v-else-if="showRecommendProductType==='ybm_lemon'"/>
          <!--<TestResultGuideForProduct :product="product" :level="level" :userName="userName" v-else-if="showRecommendProductType==='ybm_school'"/>-->
          <TestResultGuideForEduProduct @eduSelectLocationFromProduct="eduSelectLocationFromProduct" :eduLocationIdx="eduLocationIdx" :level="level" :userName="userName" v-if="showRecommendProductType==='ybm_edu'"/>
        </template>
      </div>

      <div class="separation"></div>

      <div class="suggestionPart" v-if="!isLoading" ref="TestResultSurvey" id="TestResultSurvey">
        <!--<TestResultSurvey v-if="isCompanyCodeYbmClass" />-->
        <TestResultSuggestion/>
      </div>
      
    </div>
    <div class="noShowResultArea" v-else>
      <div class="noShowCenter" v-html="noShowMessage">
      </div>
    </div>
    <div class="spinner" v-if="isLoading">
      <img class="spinnerImg" :src="$testControl.imgUrl(SpinnerImg)"/>
    </div>
    <div class="dimm" v-if="isLoading"/>
    <div class="resultFooter">
      <div class="companyInfo">서울 종로구 종로 98, YBM별관<div class="companyUserInfoGuide" @click="showUserInfoGuide()">개인정보처리방침</div></div>
      <div class="companyInfoUnder">Copyright ⓒ 2023 와이비엠넷 Rights Reserved</div>
    </div>
  </div>
  <B2BResultGuideModal class="b2bModal" @outB2BModal="outB2BModal()" v-if="b2bGuideModal"/>
</template>

<script>
import titleTextSVG from "@/assets/img/block/titleText.svg";
import XOutButtonSVG from "@/assets/img/block/XOutButton.svg";
import personIconSVG from "@/assets/img/block/personIcon.svg";
import Spinner from '@/assets/img/block/spinner.png'
import tabEventSVG from '@/assets/img/event_tab.svg';

import TestResultOverallDiagnosis from '@/components/Result/TestResultOverallDiagnosis.vue';
import TestResultAnalysis from '@/components/Result/TestResultAnalysis.vue';
import TestResultCheckAnswer from '@/components/Result/TestResultCheckAnswer.vue';
//import TestResultGuideForProduct from '@/components/Result/TestResultGuideForProduct.vue';
import TestResultGuideForToeicProduct from '@/components/Result/TestResultGuideForToeicProduct.vue';
import TestResultGuideForBookProduct from '@/components/Result/TestResultGuideForBookProduct.vue';
import TestResultGuideForEduProduct from '@/components/Result/TestResultGuideForEduProduct';
import TestResultGuideForLemonProduct from "@/components/Result/TestResultGuideForLemonProduct.vue";
import TestResultSuggestion from '@/components/Result/TestResultSuggestion.vue';
//import TestResultSurvey from '@/components/Result/TestResultSurvey.vue';

import B2BResultGuideModal from '@/components/Result/B2BResultGuideModal.vue';

import axios from 'axios';

export default {
  components: {
    TestResultGuideForLemonProduct,
    TestResultOverallDiagnosis,
    TestResultAnalysis,
    TestResultCheckAnswer,
    //TestResultGuideForProduct,
    TestResultGuideForToeicProduct,
    TestResultGuideForBookProduct,
    TestResultGuideForEduProduct,
    TestResultSuggestion,
    //TestResultSurvey,
    B2BResultGuideModal,
  },
  data() {
    return {
      titleText: titleTextSVG,
      XOutButton: XOutButtonSVG,
      personIcon: personIconSVG,

      tabSelect: 1,

      isLoading: true,
      resultData: undefined,

      testRecordArr: undefined,

      level: 0,
      product: [],

      TestResultOverallDiagnosisEL: undefined,
      TestResultAnalysisEL: undefined,
      TestResultCheckAnswerEL: undefined,
      TestResultGuideForProductEL: undefined,
      arrEl: [],

      eduLocationIdx: -1,
      b2bGuideModal: false,
      prevScrollY:undefined,
      showResult: false,
      noShowMessage: "",
    }
  },
  watch: {
    isLoading() {
      console.log('loading check', this.isLoading);
      if(!this.isLoading){
        setTimeout(() => {
          //this.setInerective();
        }, 1000)
        
      }
    }
  },
  computed: {
    SpinnerImg: () => Spinner,
    tabEventImg: () => tabEventSVG,
    isShowRecommendToeicProduct() {
      return true
      // return this.$store.getters['testManage/companyCode'] === 'ybm_class'
    },
    showRecommendProductType(){
      return this.$store.getters['testManage/companyCode']
      //return true
    },
    companyCode(){
      return this.$store.getters['testManage/companyCode']
    },
    isCompanyCodeYbmClass() {
      return this.companyCode === 'ybm_class'
    },
    userName() {
      return this.$store.getters['testManage/name'].length === 0 ? '익명' : this.$store.getters['testManage/name']
    },
  },
  created() {
    this.fetchData();
  },
  mounted(){
    this.$testControl.setDomValueResultPage()

    setTimeout(() => {
      this.setVal();
      //this.setInerective();
    }, 1000);
  },
  methods: {
    fetchData(){
      this.isLoading = true
      const toUrl = `${this.$store.getters.apiUrl}/v1/result`;
      axios.post(toUrl, {
        "quiz_set_uuid" : this.$store.getters['testManage/quizSetToken'],
        "user_uuid": this.$store.getters['testManage/userToken'],
        "company_code": this.$store.getters['testManage/companyCode'],
      }).then((res) => {
        console.log("axios res : ", res.data);

        if (res.data.error_code) {
          alert(`결과값 가져오기 실패 (${res.data.error_code})`)
          return
        }

        this.resultData = res.data;
        this.testRecordArr = res.data.quiz_list;
        res.data.recommend_study_list_by_level.map((data) => {
          const item = {
            contentType: '뭐를 넣지?',
            contentLabel: data.title,
            contentBtn: data.type_title,
            url_pc: data.url_pc,
            url_mobile: data.url_mobile,
          }
          this.product.push(item)
        });
        this.level = res.data.level;
        console.log('product', this.product);

        this.showResult = this.resultData.show_result === 'True'
        this.noShowMessage = this.resultData.show_msg
      }).finally(() => {
        this.isLoading = false;
      });
    },
    setVal(){
      console.log("TestResult setVal");
      this.$refs.TestResult.scrollIntoView({block: "start"})
      const resultPageEl = (this.$store.getters['testManage/landingPath'] === 'url' || this.$store.getters['testManage/landingPath'] === 'qr' || this.$store.getters['testManage/landingPath'] === undefined) ? document : document.getElementById('ybm-leveltest-app');

      resultPageEl.addEventListener('scroll', ()=>{

        const tapEl = document.getElementById('tabResultTypeSelect');
        const tapElRect = tapEl.getBoundingClientRect();
        
        const tapELTop = Math.floor(tapElRect.top + 5);

        //const overallEL = this.$refs.TestResultOverallDiagnosis;
        const overallEL = document.getElementById('TestResultOverallDiagnosis');
        const overallRect = overallEL.getBoundingClientRect();
        const overallTop = Math.floor(overallRect.top + 5) - 80;

        //const analysisEL = this.$refs.TestResultAnalysis;
        const analysisEL = document.getElementById('TestResultAnalysis');
        const analysisRect = analysisEL.getBoundingClientRect();
        const analysisTop = Math.floor(analysisRect.top + 5) - 80;

        //const checkAnswerEL = this.$refs.TestResultCheckAnswer;
        const checkAnswerEL = document.getElementById('TestResultCheckAnswer');
        const checkAnswerRect = checkAnswerEL.getBoundingClientRect();
        const checkAnswerTop = Math.floor(checkAnswerRect.top + 5) - 80;

        //const productEL = this.$refs.TestResultGuideForProduct;
        const productEL = document.getElementById('TestResultGuideForProduct');
        const productRect = productEL.getBoundingClientRect();
        const productTop = Math.floor(productRect.top + 5) - 200;

        const surveyEL = document.getElementById('TestResultSurvey');
        const surveyRect = surveyEL.getBoundingClientRect();
        const surveyTop = Math.floor(surveyRect.top + 5) - 200;

        //console.log(tapELTop, overallTop,analysisTop,checkAnswerTop,productTop )

        if(tapELTop >= overallTop && tapELTop < analysisTop){
          this.tabSelect = 1;
        } else if(tapELTop >= analysisTop && tapELTop < checkAnswerTop){
          this.tabSelect = 2;
        } else if(tapELTop >= checkAnswerTop && tapELTop < productTop){
          this.tabSelect = 3;
        } else if(tapELTop >= productTop && tapELTop < surveyTop){
          this.tabSelect = 4;
        } else if(tapELTop >= surveyTop){
          this.tabSelect = 5;
        }
      })
    },
    /*setInerective(){

      const root = document.getElementById('app_top');

      const option = {
        root: root
      }

      const TestResultOverallDiagnosis = document.getElementById('TestResultOverallDiagnosis');
      const TestResultAnalysis = document.getElementById('TestResultAnalysis');
      const TestResultCheckAnswer = document.getElementById('TestResultCheckAnswer');
      const TestResultGuideForProduct = document.getElementById('TestResultGuideForProduct');

      const arrEl = [TestResultOverallDiagnosis, TestResultAnalysis, TestResultCheckAnswer, TestResultGuideForProduct];

      const io = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {

            console.log('intersectionRatio : ', entry.intersectionRatio)
            
            if(entry.target.id === 'TestResultOverallDiagnosis'){
              console.log('TestResultOverallDiagnosis observe');
              this.tabSelect = 1;
            } else if(entry.target.id === 'TestResultAnalysis'){
              console.log('TestResultAnalysis observe');
              this.tabSelect = 2;
            } else if(entry.target.id === 'TestResultCheckAnswer'){
              console.log('TestResultCheckAnswer observe');
              this.tabSelect = 3;
            } else if(entry.target.id === 'TestResultGuideForProduct'){
              console.log('TestResultGuideForProduct observe');
              this.tabSelect = 4;
            } 
          }
        })
      }, option);

      arrEl.forEach(element => {
        io.observe(element)
      });
    },*/
    openGuideB2B(){
      console.log("openGuideB2B")
      this.prevScrollY = this.preventScroll();

      this.b2bGuideModal = true
    },
    outB2BModal(){
      this.b2bGuideModal = false
      this.allowScroll(this.prevScrollY)
    },
    preventScroll() {
      const currentScrollY = window.scrollY;
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
      document.body.style.top = `-${currentScrollY}px`; // 현재 스크롤 위치
      document.body.style.overflowY = 'scroll';
      return currentScrollY;
    },
    allowScroll(prevScrollY) {
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.top = '';
      document.body.style.overflowY = '';
      window.scrollTo(0, prevScrollY);
    },
    showUserInfoGuide(){
      window.open("https://member.ybmnet.co.kr/join/terms/ybmnet_indiv_new.html", "_blank");
    },
    async selectTab(num){
      console.log("selectTab", this.tabSelect, num);

      this.tabSelect = num;
      let contentEL = undefined;

      if(num === 1){
        contentEL = this.$refs.TestResultOverallDiagnosis;
      } else if(num === 2){
        contentEL = this.$refs.TestResultAnalysis
      } else if(num === 3){
        contentEL = this.$refs.TestResultCheckAnswer
      } else if(num === 4){
        contentEL = this.$refs.TestResultGuideForProduct
      } else if(num == 5){
        contentEL = this.$refs.TestResultSurvey
      }

      if (contentEL) {
        await contentEL.scrollIntoView({block: "start", behavior: "smooth"});

        /*if(num === 1){
          contentEL.scrollIntoView({block: "start", behavior: "smooth"});
        }
        if(num === 2 || num === 3 || num === 4){
          const contentELRect = contentEL.getBoundingClientRect();
          const contentELTop = contentELRect.top + 5;

          window.scrollBy({top: contentELTop, left: 0, behavior: 'smooth'});
          //스크롤 이슈에 따른 수정사항
        }*/
      }
    },
    openAnswerCheck(){
      console.log('openAnswerCheck');
      /*setTimeout(() => {
        
      }, 1000);*/
    },
    eduSelectLocationFromWeak(idx){
      console.log('eduSelectLocationFromWeak', idx);
      this.eduLocationIdx = idx;
    },
    eduSelectLocationFromProduct(idx){
      console.log('eduSelectLocationFromProduct', idx);
      this.eduLocationIdx = idx;
    }
  }
}
</script>


<style lang="scss" scoped>
.separation{
  display: flex;
  width: 100%;
  height: 1rem;
  background: #F5F6F7;
  margin-top: 3rem; 
}

.TestResult{
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  .headerArea{
    display: flex;
    flex-direction: column;

    height: auto;
    width: 100%;

    background: #FFFFFF;

    .header{
      display: flex;
      height: auto;
      width: auto;

      align-items: center;

      margin-left: 1.25rem;
      margin-right: 1.25rem;
      margin-bottom: 1.5rem;
      margin-top: 1.5rem;

      .headerLogoBox{
        display: flex;

        width: 1.875rem;
        height: 1.875rem;

        align-items: center;

        .headerLogo{
          display: flex;
          flex-direction: column;

          width: 1.529rem;
          height: 1.529rem;

          background: conic-gradient(#3E40F4, #FFFFFF);
          transform: rotate(180deg);
          border-radius: 50%;
        
          align-items: center;
          margin: auto;

          .headerLogoIn{
            width: 0.679rem;
            height: 0.679rem;
            background: #FFFFFF;
            margin: 0 0 auto 0;
            border-radius: 50%;
          }

          .headerLogoRight{
            width: 0.425rem;
            height: 0.425rem;
            background: #3E40F4;
            margin: 0 auto 0 auto;
            border-radius: 50%;
          }
        }
      }

      .headerText{
        display: flex;
        align-items: center;
        width: 6.938rem;
        height: 1.313rem;

        .svgSet{
          width: inherit;
        }
      }

      .headerRight{
        display: flex;
        margin-left: auto;
      
        .personIcon{
          display: flex;
          height: 1.5rem;
          width: auto;
        }

        .headerOut{
          display: flex;
          height: 1.5rem;
          width: auto;
          margin-left: 0.5rem;
        }
      } 
    }
  }

  .noShowResultArea {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;

    background: #FFFFFF;
    align-items: center;

    .noShowCenter {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .resultArea{
    display: flex;
    flex-direction: column;

    width: 100%;

    background: #F5F6F7;
    align-items: center;

    .survey-tab {
      position: relative;

      .survey-tab-event-image {
        position: absolute;
        top: .5rem;
        left: .2rem;
        width: 2.8rem;
        height: 1rem;
      }
    }

    .tabResultTypeSelect{
      display: flex;
      flex-direction: row;

      width: calc(100% - 2.5rem);
      align-items: center;
      justify-content: center;
      gap: 1rem;

      position: sticky;
      top: 0;
      background: #F5F6F7;
      z-index: 2;

      .resultType{
        display: flex;
        height: 4.5rem;
        align-items: center;

        display: flex;
        
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: -0.01em;
      }
      .selected{
        text-decoration : underline solid 2px;
        text-underline-position: under;

        color: #222222;
      }

      .unselected{
        color: #C2C2C2;
      }
    }

    .resultPart{
      display: flex;
      width: 100%;
      align-self: center;
      justify-content: center;
      border-radius: 8px;
    }

    .suggestionPart{
      display: flex;
      width: 100%;
      align-self: center;
      justify-content: center;
      border-radius: 8px;
    }

    .checkAnswerArea{
      display: flex;
      width: calc(100% - 2.5rem);
      align-self: center;
      justify-content: center;
      border-radius: 8px;

      background: #fff;
    }
  }

  #TestResultOverallDiagnosis {
    scroll-margin-top: 10rem;
  }

  #TestResultAnalysis, #TestResultCheckAnswer, #TestResultGuideForProduct {
    scroll-margin-top: 4.375rem;
  }

  .dimm {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.8;
    z-index: 50;
  }

  .description {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 51;

    .thanks {
      display: flex;
      justify-content: center;
      color: #fff;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      margin-bottom: 1.25rem;
    }

    .info {
      div {
        display: flex;
        justify-content: center;
        color: #fff;
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.25rem;
      }
    }
  }

  @keyframes rotate {
    from {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    to {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }

  .spinner {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 4.5rem;
    height: 4.5rem;

    .spinnerImg {
      animation: rotate 2s infinite linear;
    }
  }

  .resultFooter{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 5.25rem;
    background: #F5F6F7;

    align-items: center;

    .companyInfo{
      display: flex;

      margin-top: 1.25rem;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 8px;
      /* identical to box height, or 67% */

      letter-spacing: -0.02em;

      /* Gray/700 */

      color: #616161;

      .companyUserInfoGuide{
        display: flex;

        margin-left: 0.563rem;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 8px;
        /* identical to box height, or 67% */

        letter-spacing: -0.02em;
        text-decoration-line: underline;

        /* Gray/900 */

        color: #222222;
      }
    }

    .companyInfoUnder{
      display: flex;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 8px;
      /* identical to box height, or 67% */

      letter-spacing: -0.02em;

      /* Gray/700 */

      color: #616161;

      margin-top: 1.125rem;
      margin-bottom: 1.875rem;
    }
  }

  .b2bModal{
    display: flex;
    position: absolute;

  }
}

</style>