<template>
  <div class="lobby">
    <div class="headerArea">
      <div class="header">
        <div class="headerLogoBox">
          <div class="headerLogo">
            <div class="headerLogoRight"></div>
            <div class="headerLogoIn"></div>
          </div>
        </div>
        <div class="headerText">
          <img class="svgSet" :src="$testControl.imgUrl(titleText)"/>
        </div>
        <div class="headerRight">
          <div class="personIcon" @click="toMyPage" v-if="false">
            <img :src="$testControl.imgUrl(personIcon)"/>
          </div>
          <div class="headerOut">
            <img :src="$testControl.imgUrl(XOutButton)"
                 v-if="$testControl.isShowCloseButton()"
                 @click="$testControl.closePage()"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="boxContent">
      <div class="userCounter" v-if="userCounter > 10000">
        <div class="userCounterTxt">레벨테스트 총 참여인원</div>
        <div class="userCounterNum">
          <div class="userCounterStart">{{ zerofill_3(userCounter, 5) }}</div>
          <div class="userCounterEnd">명</div>
        </div>
      </div>
      <div class="timeAndLevelArea">
        <div class="timeAndLevelBack">
          <div class="timeAndLevelBackGuide">본 내용은 레벨테스트 총 참여인원의 평균 진단결과와 소요시간입니다.</div>
        </div>
        <div class="levelTalk">
          <div class="levelTalkUp">
            <div class="levelTalkUpTXT">
              진단 결과 평균
            </div>
          </div>
          <div class="levelTalkDown">
          </div>
        </div>
        <div class="levelVisaul">
          <div class="levelVisaulBack" id="levelVisaulBack">
            <div class="levelVisaulBarCircle1" id="levelVisaulBarCircle1"></div>
            <div class="levelVisaulBarIncircle">
              <div class="levelVisaulBarIncircleMessage">
                <div class="levelVisaulBarIncircleMessageLevel">Level {{avgLevel}}</div>
                <div class="levelVisaulBarIncircleMessageLimit">/ Level 10</div>
              </div>
            </div>
            <div class="levelVisaulBarCircle2" id="levelVisaulBarCircle2"></div>
          </div>
        </div>
        <div class="timeTalk">
          <div class="timeTalkUp">
            <div class="timeTalkUpTXT">
              평균 소요시간
            </div>
          </div>
          <div class="timeTalkDown">
          </div>
        </div>
        <div class="timeVisaul">
          <div class="timeVisaulBack" id="timeVisaulBack">
            <div class="timeVisaulBarCircle1" id="timeVisaulBarCircle1"></div>
            <div class="timeVisaulBarIncircle">
              <div class="timeVisaulBarIncircleMessage">
                <div class="timeVisaulBarIncircleMessageTime">{{avgTimeM}}분 {{avgTimeS}}초</div>
                <div class="timeVisaulBarIncircleMessageLimit">/ 30분</div>
              </div>
            </div>
            <div class="timeVisaulBarCircle2" id="timeVisaulBarCircle2"></div>
          </div>
        </div>
      </div>
      <div ref="maskGroup" id="maskGroup" class="aos-init aos-animate maskGroup" data-aos="fade-up" data-aos-offset="0" data-aos-duration="450"  data-aos-easing="linear">
        <div class="maskGroupLine" ref="maskGroupLineTop" id="maskGroupLineTop">
          <div class="maskGroupTag" v-for="(item, idx) in tagArrLine1" :key="idx">
            <img class="maskGroupTagImg" :src="$testControl.imgUrl(item)" :draggable="false"/>
          </div>
        </div>
        <div class="maskGroupLine" ref="maskGroupLineMiddle">
          <div class="maskGroupTag" v-for="(item, idx) in tagArrLine2" :key="idx">
            <img class="maskGroupTagImg" :src="$testControl.imgUrl(item)" :draggable="false"/>
          </div>
        </div>
        <div class="maskGroupLine" ref="maskGroupLineBot">
          <div class="maskGroupTag" v-for="(item, idx) in tagArrLine3" :key="idx">
            <img class="maskGroupTagImg" :src="$testControl.imgUrl(item)" :draggable="false"/>
          </div>
        </div>

      </div>
      <div class="aos-init aos-animate testGuideArea" data-aos="fade-up" data-aos-offset="0" data-aos-duration="450"  data-aos-easing="linear">
        <div class="testGuideText">
          <div class="testGuideLabel">
            문항구성
          </div>
          <div class="testGuideTip">
            총 23개의 문항이 출제되며 영역별 실력을<br>진단해 드립니다.
          </div>
        </div>
        <div class="testGuideBase" data-aos="fade-up" data-aos-offset="0" data-aos-duration="450"  data-aos-easing="linear">
          <!--<div class="testGuideUnit" @click="changeGuideUnit">-->
          <div class="testGuideUnit">
            <swiper class="mySwiper" ref="mySwiper" :options="swiperOption" :navigation="false" :modules="modules"
            @slideChange="onSlideChange(idx)" @swiper="onSwiper">
              <swiper-slide>
                <div class="testGuideUnitBox box1">
                  <div class="testGuideLogo" ref="testGuideLogo"></div>
                  <div class="testGuideUnitNum">
                    영역 {{testGuideNum}}
                  </div>
                  <div class="testGuideUnitText">
                    <div class="testGuideUnitLabel">Vocabulary<img class="testGuideUnitLabelImg" :src="$testControl.imgUrl(letter)" /></div>
                    <div class="testGuideUnitTip">어휘숙지여부를 통해 평가자의 레벨 진단</div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide class="swipeUnit">
                <div class="testGuideUnitBox box2">
                  <div class="testGuideLogo" ref="testGuideLogo2"></div>
                  <div class="testGuideUnitNum">
                    영역 {{testGuideNum}}
                  </div>
                  <div class="testGuideUnitText">
                    <div class="testGuideUnitLabel">Listening<img class="testGuideUnitLabelImg" :src="$testControl.imgUrl(headphone)" /></div>
                    <div class="testGuideUnitTip">소리식별, 주제 및 요지파악 등 진단</div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="testGuideUnitBox box3">
                  <div class="testGuideLogo" ref="testGuideLogo2"></div>
                  <div class="testGuideUnitNum">
                    영역 {{testGuideNum}}
                  </div>
                  <div class="testGuideUnitText">
                    <div class="testGuideUnitLabel">Reading<img class="testGuideUnitLabelImg" :src="$testControl.imgUrl(openBook)" /></div>
                    <div class="testGuideUnitTip">세부정보파악, 내용추론 등 진단</div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="testGuideUnitBox box4">
                  <div class="testGuideLogo" ref="testGuideLogo3"></div>
                  <div class="testGuideUnitNum">
                    영역 {{testGuideNum}}
                  </div>
                  <div class="testGuideUnitText">
                    <div class="testGuideUnitLabel">Writing<img class="testGuideUnitLabelImg" :src="$testControl.imgUrl(writingHands)" /></div>
                    <div class="testGuideUnitTip">단문 및 중문 쓰기, 논리전개파악 등 진단</div>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="testGuideUnitBox box5">
                  <div class="testGuideLogo" ref="testGuideLogo4"></div>
                  <div class="testGuideUnitNum">
                    영역 {{testGuideNum}}
                  </div>
                  <div class="testGuideUnitText">
                    <div class="testGuideUnitLabel">Speaking<img class="testGuideUnitLabelImg" :src="$testControl.imgUrl(microphone)" /></div>
                    <div class="testGuideUnitTip">발음, 억양, 문법, 답변 관련성 진단</div>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
        <div class="testGuideDotArea">
          <div :class="[(testGuideNum === 1) ? 'testGuideDotPoint': 'testGuideDotPointUnselect']" @click="changeDot(1)"></div>
          <div :class="[(testGuideNum === 2) ? 'testGuideDotPoint': 'testGuideDotPointUnselect']" @click="changeDot(2)"></div>
          <div :class="[(testGuideNum === 3) ? 'testGuideDotPoint': 'testGuideDotPointUnselect']" @click="changeDot(3)"></div>
          <div :class="[(testGuideNum === 4) ? 'testGuideDotPoint': 'testGuideDotPointUnselect']" @click="changeDot(4)"></div>
          <div :class="[(testGuideNum === 5) ? 'testGuideDotPoint': 'testGuideDotPointUnselect']" @click="changeDot(5)"></div>
        </div>
      </div>
      <div class="lobbyBottumGuide" data-aos="fade-up" data-aos-offset="0" data-aos-duration="450" data-aos-easing="linear">
        <div class="lobbyBottumGuideLabel">
          유의사항
        </div>
        <div class="lobbyBottumGuideList">
          <div class="lobbyBottumGuideListUnit"><div class="miniDotArea"><div class="miniDot"/></div>- 모바일에 최적화 되어있습니다.</div>
          <div class="lobbyBottumGuideListUnit"><div class="miniDotArea"><div class="miniDot"/></div>- 모바일로 더욱 편하게 응시하시는 것을 추천드립니다.</div>
          <div class="lobbyBottumGuideListUnit"><div class="miniDotArea"><div class="miniDot"/></div>- PC로 응시하실 경우 이어폰(스피커)과 마이크 연결이 필요합니다.</div>
          <div class="lobbyBottumGuideListUnit"><div class="miniDotArea"><div class="miniDot"/></div>- 익스플로러에서는 레벨테스트를 진행하실 수 없습니다.</div>
          <div class="lobbyBottumGuideListUnit"><div class="miniDotArea"><div class="miniDot"/></div>- 테스트 시작 클릭 시 마이크 테스트가 진행됩니다.</div>
          <div class="lobbyBottumGuideListUnit"><div class="miniDotArea"><div class="miniDot"/></div>- 조용한 장소에서 미리 준비해주세요.</div>
        </div>
      </div>
      <div class="lobbyUserInfoGuide">
        <div class="lobbyUserInfoGuideLabel" @click="changeOpenGuide()">개인정보 수집 및 이용 동의 {{ (userInfoGuideOpen ? '[닫기]' : '[자세히 보기]') }}</div>

        <div class="lobbyUserInfoGuideUnit" v-if="userInfoGuideOpen">1.개인정보 수집 항목 응시자 말하기 테스트 답변 음성 데이터</div>
        <div class="lobbyUserInfoGuideUnit" v-if="userInfoGuideOpen">2.수집 및 이용 목적 다시듣기에 활용 / 테스트 진단 / 기술 개발에 활용</div>
        <div class="lobbyUserInfoGuideUnit" v-if="userInfoGuideOpen">3.개인정보 이용기간 수집 후 2년 또는 응시자의 삭제 요청 시</div>
        <div class="lobbyUserInfoGuideUnit" v-if="userInfoGuideOpen">4.응시자는 개인정보 수집 및 이용에 동의하지 않으실 수 있습니다. 단, 동의 거부 시 서비스 사용이 제한됩니다.</div>

        <div class="lobbyUserInfoGuideCheck" @click="userInfoClick()"><input type="checkbox" class="userInfoCheckbox" v-model="userInfoCheck">개인정보 수집 및 이용에 동의합니다.</div>
      </div>
      <!--<div class="userNumArea" data-aos="fade-up" data-aos-offset="0" data-aos-duration="450"  data-aos-easing="linear" v-if="isCompanyCodeYbmClass">
        <div class="userNumAreaUp">
          <div class="userNumAreaTxt">테스트 후 결과페이지 하단 설문조사 참여하시면</div>
          <div class="userNumAreaTxt Blue">YBM머니 1,000원을 드립니다.</div>
        </div>
        <div class="userNumAreadown"></div>
      </div>-->
      <!--<div class="testStartBtnArea" @click="goToTest">
        테스트 시작
      </div>-->
      <div @click="goToTest()" :class="[((userInfoCheck) ? 'testStartBtnArea selected' : 'testStartBtnArea unselected'), 'margin-top']">테스트 시작</div>

    </div>
    <div class="lobbyFooter">
      <div class="companyInfo">서울 종로구 종로 98, YBM별관<div class="companyUserInfoGuide" @click="showUserInfoGuide()">개인정보처리방침</div></div>
      <div class="companyInfoUnder">Copyright ⓒ 2023 와이비엠넷 Rights Reserved</div>
    </div>
    <UserInfoModal v-if="openModalUserInfo" @closeModal="closeUserInfoModal()" />
  </div>
</template>

<script>
import titleTextSVG from "@/assets/img/block/titleText.svg";
import XOutButtonSVG from "@/assets/img/block/XOutButton.svg";
import personIconSVG from "@/assets/img/block/personIcon.svg";

import line1Tag1SVG from "@/assets/img/lobbyTagSvg/Line1/Frame 9.png";
import line1Tag2SVG from "@/assets/img/lobbyTagSvg/Line1/Frame 10.png";
import line1Tag3SVG from "@/assets/img/lobbyTagSvg/Line1/Frame 11.png";
import line1Tag4SVG from "@/assets/img/lobbyTagSvg/Line1/Frame 12.png";
import line1Tag5SVG from "@/assets/img/lobbyTagSvg/Line1/Frame 3.png";
import line1Tag6SVG from "@/assets/img/lobbyTagSvg/Line1/Frame 16.png";
import line1Tag7SVG from "@/assets/img/lobbyTagSvg/Line1/Frame 13.png";
import line1Tag8SVG from "@/assets/img/lobbyTagSvg/Line1/Frame 14.png";
import line1Tag9SVG from "@/assets/img/lobbyTagSvg/Line1/Frame 15.png";
import line1Tag10SVG from "@/assets/img/lobbyTagSvg/Line1/Frame 17.png";

import headphoneSVG from "@/assets/img/block/headphone.png";
import openBookSVG from "@/assets/img/block/open-book.png";
import writingHandsSVG from "@/assets/img/block/writing-hand_light-skin-tone.png";
import letterSVG from "@/assets/img/block/input-latin-letters.png";
import maskOneImgPng from '@/assets/img/block/maskOneImg.png'
import microphoneSVG from "@/assets/img/block/microphone.png";

import UserInfoModal from "@/components/UserInfoModal.vue";

import axios from "axios";

import "aos/dist/aos.css";
import AOS from 'aos';

import { Swiper, SwiperSlide } from 'swiper/vue';

import { Navigation, Pagination } from 'swiper';
import "swiper/swiper-bundle.css";

import { dragscroll } from 'vue-dragscroll';
import testControl from "@/function/testControl";

export default {
  name: "Lobby",
  components:{
    Swiper,
    SwiperSlide,
    UserInfoModal,
  },
  directives: {
    dragscroll
  },
  data() {
    return {
      modules: [Navigation, Pagination],

      userCounter: 0,

      titleText: titleTextSVG,
      XOutButton: XOutButtonSVG,
      personIcon: personIconSVG,
      maskOneImg: maskOneImgPng,

      avgTime: 13,
      avgTimeM: 0,
      avgTimeS: 0,
      avgTimeSecond: 0,
      avgLevel: 0,
      userNum: 0,
      userNumText: '',

      tagArrLine1: [line1Tag1SVG,line1Tag2SVG,line1Tag3SVG,line1Tag4SVG,line1Tag5SVG,line1Tag6SVG,line1Tag7SVG,line1Tag8SVG,line1Tag9SVG,line1Tag10SVG],
      tagArrLine2: [line1Tag10SVG,line1Tag7SVG,line1Tag8SVG,line1Tag3SVG,line1Tag2SVG,line1Tag9SVG,line1Tag4SVG,line1Tag1SVG,line1Tag5SVG,line1Tag6SVG],
      tagArrLine3: [line1Tag4SVG,line1Tag7SVG,line1Tag9SVG,line1Tag10SVG,line1Tag1SVG,line1Tag2SVG,line1Tag5SVG,line1Tag6SVG,line1Tag3SVG,line1Tag8SVG],
      //tagArrLine1: [line1Tag1SVG, line1Tag2SVG, line1Tag10SVG],
      //tagArrLine2: [line1Tag4SVG, line1Tag5SVG, line1Tag9SVG],
      //tagArrLine3: [line1Tag7SVG, line1Tag8SVG, line1Tag6SVG, line1Tag3SVG],
      tagArrLine: [line1Tag1SVG,line1Tag2SVG,line1Tag3SVG,line1Tag4SVG,line1Tag5SVG,line1Tag6SVG,line1Tag7SVG,line1Tag8SVG,line1Tag9SVG,line1Tag10SVG],

      headphone: headphoneSVG,
      openBook: openBookSVG,
      writingHands: writingHandsSVG,
      microphone: microphoneSVG,
      letter: letterSVG,

      testGuideNum:1,
      swiperOption:{
        notNextTick: true,
        setWrapperSize :true,
        autoHeight: true,
      },
      swiper: undefined,

      isDown: false,
      startX: undefined,
      scrollLeft: undefined,

      userInfoGuideOpen: false,
      userInfoCheck: false,
      openModalUserInfo: false,
    }
  },
  computed: {
    companyCode(){
      return this.$store.getters['testManage/companyCode']
    },
    isCompanyCodeYbmClass() {
      return this.companyCode === 'ybm_class'
    }
  },
  watch: {
    userCounter:{

    }
  },
  mounted() {
    this.setVal();
    this.initAOS()
    //this.setScrollCenter();
  },
  methods: {
    initAOS() {
      AOS.init({
        disable: this.$store.getters['testManage/isModal'],
      })
    },
    pagination: {
      clickable: true,
      renderBullet: function (index, className) {
        //return '<span class="' + className + '">' + (index + 1) + '</span>';
        console.log(index, className);
      },
    },
    /*async setScrollCenter(){
      console.log('setScrollCenter');

      const el = document.getElementById("maskGroupLineTop");
      el.scroll({
        left: 100,
        behavior: "smooth",
      })

      console.log('el : ' , el)

    },*/
    zerofill_3(value){
      var result = value.toLocaleString();
      // value가 number 타입일 경우 string으로 변경 후 작업
      if(typeof value === "number") result = value.toString()
      var reVal = result.padStart(5, 0);

      if(reVal.length > 3){
        reVal = reVal.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
      }

      return reVal;

      //var fillText = Array(fillCount+1 - result.length).join('0')
      //return result.toString().length < fillCount ?  (fillText + result) : result;
      //return returnVal.toLocaleString();

      /*if(result.toString().length < fillCount){
        return (fillText + result).toLocaleString();
      } else {
        return result;
      }*/
    },
    async setVal(){
      console.log('Lobby setVal');

      await axios.get(`${this.$store.getters.apiUrl}/v1/info`).then((res) => {
        console.log('data', res.data)
        this.avgTimeSecond = res.data.avg_total_time_second;
        this.avgTime = res.data.avg_total_time;
        this.avgLevel = res.data.avg_level;
        this.userNum = res.data.total_tester_count;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.setTimeAvgVisual();
        this.setLevelAvgVisual();
      });


      this.userNumText = this.userNum.toLocaleString('ko-KR');

      for(var i = 0; i < this.userNum; i++){
        setTimeout(()=>{
          this.userCounter++;
          //this.userCounter = `${i.toLocaleString()}`;
          //this.userCounter = i;
        }, 10)
      }

      const testGuideLogos = document.getElementsByClassName('testGuideLogo');
      Array.from(testGuideLogos).map(ele => ele.style.background = `url(${this.$testControl.imgUrl(this.maskOneImg)})`)

      /*const maskGroup = document.getElementById('maskGroup');

      maskGroup.addEventListener('mousedown', (e) => {
        this.isDown = true;
        maskGroup.classList.add('active');
        this.startX = e.pageX - maskGroup.offsetLeft;
        this.scrollLeft = maskGroup.scrollLeft;
      });

      maskGroup.addEventListener('mouseleave', () => {
        this.isDown = false;
        maskGroup.classList.remove('active');
      });

      maskGroup.addEventListener('mouseup', () => {
        this.isDown = false;
        maskGroup.classList.remove('active');
      });

      maskGroup.addEventListener('mousemove', (e) => {
        if(!this.isDown) return;
        e.preventDefault();
        const x = e.pageX - maskGroup.offsetLeft;
        const walk = (x - this.startX) * 3; //scroll-fast
        maskGroup.scrollLeft = this.scrollLeft - walk;
        console.log(walk);
      });*/
    },
    showUserInfoGuide(){
      window.open("https://member.ybmnet.co.kr/join/terms/ybmnet_indiv_new.html", "_blank");
    },
    changeOpenGuide(){
      this.userInfoGuideOpen = !this.userInfoGuideOpen;
    },
    userInfoClick(){
      if(this.userInfoCheck){
        this.userInfoCheck = false;
      } else {
        this.userInfoCheck = true;
      }
    },
    closeUserInfoModal(){
      console.log('closeUserInfoModal', this.openModalUserInfo)
      this.openModalUserInfo = false;
    },
    changeGuideUnit(){
      console.log('changeGuideUnit', this.testGuideNum);

      if(this.testGuideNum !== 5){
        this.testGuideNum++;
      } else {
        this.testGuideNum = 1;
      }

      const el = document.getElementsByClassName("testGuideUnit");
      //console.log(el[0]);

      if(this.testGuideNum === 1){
        el[0].style.backgroundColor = '#3E40F4';
      } else if(this.testGuideNum === 2) {
        el[0].style.backgroundColor = '#246BFD';
      } else if(this.testGuideNum === 3) {
        el[0].style.backgroundColor = '#1FB58F';
      } else if(this.testGuideNum === 4) {
        el[0].style.backgroundColor = '#0AA1D1';
      } else if(this.testGuideNum === 5) {
        el[0].style.backgroundColor = '#F4A023';
      }

    },
    /*setTagLine(){
      console.log('setTagLine setVal');

      for(let i = 0; i < 10; i++){
        this.tagArrLine1.push
      }
    },*/
    setTimeAvgVisual(){
      console.log('setTimeAvgVisual setVal');

      const timeVisaulBack = document.getElementById("timeVisaulBack");
      const timeVisaulBarCircle1 = document.getElementById("timeVisaulBarCircle1");
      const timeVisaulBarCircle2 = document.getElementById("timeVisaulBarCircle2");

      const timeCheck = (this.avgTimeSecond / 60).toFixed(2);
      console.log('timeCheck', timeCheck);

      /*this.avgTimeM = Math.floor(timeCheck);
      this.avgTimeS = timeCheck.split('.')[1];*/

      this.avgTimeM = this.avgTime.split(':')[0];
      this.avgTimeS = this.avgTime.split(':')[1];

      const perTime = Math.round((timeCheck / 30) * 100) / 100;
      const degNum = (360 * perTime);
      const deg = degNum+'deg';

      const circle2DegNum = degNum - 180;
      const circle2Deg = circle2DegNum +'deg';

      console.log('perTime & deg', perTime, deg);

      console.log('degNum & circle2DegNum', degNum, circle2DegNum);

      timeVisaulBack.style.backgroundImage  = `conic-gradient(#FFFFFF 0deg ${deg}, rgba(255, 255, 255, 0.2) ${deg} 360deg)`;
      timeVisaulBarCircle1.style.transform = `rotate(180deg) translateY(6.1rem)`;
      timeVisaulBarCircle2.style.transform = `rotate(${circle2Deg}) translateY(6.125rem)`;
    },
    setLevelAvgVisual(){
      console.log('setTimeAvgVisual setVal');

      const levelVisaulBack = document.getElementById("levelVisaulBack");
      const levelVisaulBarCircle1 = document.getElementById("levelVisaulBarCircle1");
      const levelVisaulBarCircle2 = document.getElementById("levelVisaulBarCircle2");

      const perTime = Math.round((this.avgLevel / 10) * 100) / 100;
      const degNum = (360 * perTime);
      const deg = degNum+'deg';

      const circle2DegNum = degNum - 180;
      const circle2Deg = circle2DegNum +'deg';

      console.log('perTime & deg', perTime, deg);

      console.log('degNum & circle2DegNum', degNum, circle2DegNum);

      levelVisaulBack.style.backgroundImage  = `conic-gradient(#FFFFFF 0deg ${deg}, rgba(255, 255, 255, 0.2) ${deg} 360deg)`;
      levelVisaulBarCircle1.style.transform = `rotate(180deg) translateY(6.12rem)`;
      levelVisaulBarCircle2.style.transform = `rotate(${circle2Deg}) translateY(6.125rem)`;
    },
    async goToTest() {

      console.log(this.userInfoCheck, this.openModalUserInfo);

      if(this.userInfoCheck){
        /*const to = this.$store.getters['testManage/userToken'] ? 'microphone' : routeName
        console.log("enterInfo in lobby");
        testControl.goRoute(to, false)*/

        if(this.$store.getters['testManage/userToken']){
          if (this.$store.getters['testManage/companyCode'] === 'special') {
            // token 을 URL 로 바로 받는 ControllerForce 를 거치는 경우 companyCode 가 special 로 넘어온다
            // 이 경우는 open 형태로 진행 된다
            testControl.goRoute('infoFirst', false)
          } else {
            // token 이 있고, companyCode 가 special 이 아닌 경우 바로 close 형태로 진행. (아직 까지는 인강만 해당)
            testControl.goRoute('microphone', false);
          }
        } else {
          //그 외에 경우 
          return await axios.post(`${this.$store.getters.apiUrl}/create_member`, {
            "score" : 0,
            "info": "1",
            "toeic_score":'temp',
            "toeic_sw_score":'temp',
            "opic_score":'temp',

          }).then((res) => {
            console.log("axios res : ", res);

            this.$store.commit('testManage/setUserToken', res.data.token);
            this.$store.commit('testManage/setCompanyCode', '');

          }).finally(() => {
            testControl.goRoute('microphone', false);
          });
        }

      } else {
        console.log('uncheck openModalUserInfo')
        this.openModalUserInfo = true;
      }
    },
    toMyPage(){
      console.log('toMyPage');
      this.$emit("toMyPage");
    },
    onSlideChange(idx){
      console.log('onSlideChange', idx);
      //console.log(this.$refs.mySwiper.swiper.activeIndex);

      this.testGuideNum = (this.swiper.activeIndex + 1);
      console.log(this.testGuideNum);
    },
    changeDot(toIdx){
      console.log('changeDot');
      const toIdxTemp = toIdx - 1;
      this.swiper.slideTo(toIdxTemp, 0);
    },
    onSwiper(swiper){
      console.log('onSwiper', swiper);
      this.swiper = swiper;
    },
  },
};
</script>

<style lang="scss" scoped>
//@import "@/lobbyObj/aos.css";

.lobby {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: auto;

  .headerArea{
    display: flex;
    flex-direction: column;

    height: auto;
    width: 100%;

    background: #FFFFFF;

    .header{
      display: flex;
      height: auto;
      width: auto;

      align-items: center;

      margin-left: 16px;
      margin-right: 16px;
      margin-top: 16px;

      .headerLogoBox{
        display: flex;

        width: 1.875rem;
        height: 1.875rem;

        align-items: center;

        .headerLogo{
          display: flex;
          flex-direction: column;

          width: 1.529rem;
          height: 1.529rem;

          background: conic-gradient(#3E40F4, #FFFFFF);
          transform: rotate(180deg);
          border-radius: 50%;

          align-items: center;
          margin: auto;
          //vertical-align: middle;

          .headerLogoIn{
            width: 0.679rem;
            height: 0.679rem;
            background: #FFFFFF;
            //margin: auto auto 0 auto;
            margin: 0 0 auto 0;
            border-radius: 50%;
          }

          .headerLogoRight{
            width: 0.425rem;
            height: 0.425rem;
            background: #3E40F4;
            //margin: 0 auto 0 auto;
            margin: 0 auto 0 auto;
            border-radius: 50%;
          }
        }
      }

      .headerText{
        display: flex;
        align-items: center;
        width: 6.938rem;
        height: 1.313rem;

        .svgSet{
          width: inherit;
        }
      }

      .headerRight{
        display: flex;
        margin-left: auto;

        .personIcon{
          display: flex;
          height: 2rem;
          //margin-left: 7.938rem;
          //margin-left: auto;
        }

        .headerOut{
          display: flex;
          height: 2rem;
          //margin-left: calc(100% - 12rem);
          margin-left: 0.5rem;
        }
      }

    }

  }

  .boxContent{
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;

    margin-top: 48px;

    .userCounter{
      display: flex;
      flex-direction: column;
      height: 9.438rem;
      width: calc(100% - 4rem);
      margin-left: 32px;

      .userCounterTxt{
        display: flex;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 700;
        //font-size: 2.25rem;
        font-size: 28px;


        color: #222222;

        word-break: keep-all;
        letter-spacing: -0.5px;
      }

      .userCounterNum{
        display: flex;

        width: 16rem;

        .userCounterStart{
          display: flex;
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 800;
          font-size: 3.5rem;
          line-height: 4.188rem;

          /* Primary/P500 */

          color: #3E40F4;
          letter-spacing: -0.5px;
        }

        .userCounterEnd{
          display: flex;
          width: 3rem;

          align-items: center;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 800;
          font-size: 2.5rem;
          line-height: 3rem;
          /* identical to box height */

          letter-spacing: -0.02em;

          /* Primary/P500 */

          color: #3E40F4;
        }
      }
    }

    .timeAndLevelArea{
      position: relative;
      display: flex;
      //width: 20.938rem;
      width: calc(100% - 2.5rem);
      min-height: 42.125rem;
      //height: 10.625rem;
      align-self: center;
      margin-top: 1.25rem;
      //margin-top: 9.438rem; 
      justify-content: center;

      

      .timeAndLevelBack{
        display: flex;
        align-items: flex-end;
        width: 100%;
        //height: ;
        //height: calc(100% - 0.5rem);
        background-color: #3E40F4;
        margin-top: 1rem;
        border-radius: 32px;
        justify-content: center;

        .timeAndLevelBackGuide{
          display: flex;
          margin-bottom: 1.5rem;
          width: calc(100% - 2.5rem);

          font-family: "Pretendard";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;

          color: #FFFFFF;

          word-break: keep-all;
        }
      }

      .levelTalk{
        //width: 7.813rem;
        position: absolute;
        width: fit-content;
        height: 3.75rem;

        margin-bottom: 1rem;
        margin-right: 8rem;
        //margin-top: 19.25rem;
        //margin-left: 2.75rem;
        display: flex;
        flex-direction: column;

        .levelTalkUp{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          padding: 12px 16px;

          width: fit-content;
          height: 2.75rem;

          background: #00A3FF;
          border-radius: 8px;

          .levelTalkUpTXT{
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 700;
            font-size: 1.063rem;
            line-height: 1.25rem;
            /* identical to box height */

            text-align: center;

            /* Gray/White */

            color: #FFFFFF;
          }
        }

        .levelTalkDown{
          //justify-content: center;
          display: flex;
          width: 0px;
          height: 0px;
          border-radius: 1px;
          transform: matrix(1, 0, 0, -1.1, 0, 0);
          //margin: auto;
          margin-left: 4.75rem;

          border-bottom: 0.45rem solid #00A3FF;
          border-top: 0.5rem solid transparent;
          border-left: 0.5rem solid transparent;
          border-right: 0.5rem solid transparent;
        }
      }

      .levelVisaul{
        display: flex;
        position: absolute;

        width: 13.25rem;
        //width: calc(100% - 7.688rem);
        height: 13.25rem;
        margin-left: 3.875rem;
        margin-right: 3.813rem;
        margin-top: 4.188rem;

        /*margin-top: 23.438rem;
        margin-bottom: 1.938rem;*/

        .levelVisaulBack{
          display: flex;
          //justify-content: center;
          align-items: center;
          justify-content: center;
          //background: rgba(255, 255, 255, 0.2);
          //background: conic-gradient(#FFFFFF 210deg, rgba(255, 255, 255, 0.2) 210deg);
          mix-blend-mode: normal;
          border-radius: 50%;
          //transform: rotate(180deg);

          height: 100%;
          width: 100%;

          .levelVisaulBarCircle1{
            position: absolute;
            background: #ffffff;
            border-radius: 50%;
            width: 1rem;
            height: 1rem;
            //transform: rotate(180deg) translateY(6.1rem);
          }

          .levelVisaulBarIncircle{
            display: flex;
            background:#3E40F4;
            border-radius: 50%;
            width: calc(100% - 2rem);
            height: calc(100% - 2rem);

            align-items: center;
            justify-content: center;

            .levelVisaulBarIncircleMessage{
              display: flex;
              width: fit-content;
              height: 3.875rem;
              flex-direction: column;

              .levelVisaulBarIncircleMessageLevel{
                font-family: 'Pretendard';
                font-style: normal;
                font-weight: 700;
                font-size: 28px;
                line-height: 34px;
                /* identical to box height */

                text-align: center;

                /* Gray/White */

                color: #FFFFFF;


                /* Inside auto layout */

                flex: none;
                order: 0;
                flex-grow: 0;
              }

              .levelVisaulBarIncircleMessageLimit{
                font-family: 'Pretendard';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                /* identical to box height */

                text-align: center;

                /* Gray/White */

                color: #FFFFFF;

                opacity: 0.4;

                /* Inside auto layout */

                flex: none;
                order: 1;
                flex-grow: 0;

              }
            }
          }

          .levelVisaulBarCircle2{
            position: absolute;
            background: #ffffff;
            border-radius: 50%;
            width: 1rem;
            height: 0.98rem;
            //transform: rotate(30deg) translateY(6.125rem);
          }
        }
      }

      .timeTalk{
        //width: 7.813rem;
        position: absolute;
        width: fit-content;
        height: 3.75rem;

        margin-bottom: 1rem;
        margin-top: 19.25rem;
        margin-right: 7.813rem;
        //margin-left: 2.75rem;
        display: flex;
        flex-direction: column;

        .timeTalkUp{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          padding: 12px 16px;

          width: fit-content;
          height: 2.75rem;

          background: #271AD0;
          border-radius: 8px;

          .timeTalkUpTXT{
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 700;
            font-size: 1.063rem;
            line-height: 1.25rem;
            /* identical to box height */

            text-align: center;

            /* Gray/White */

            color: #FFFFFF;
          }
        }

        .timeTalkDown{
          //justify-content: center;
          display: flex;
          width: 0px;
          height: 0px;
          border-radius: 1px;
          transform: matrix(1, 0, 0, -1.1, 0, 0);
          //margin: auto;
          margin-left: 4.75rem;

          border-bottom: 0.45rem solid #271AD0;
          border-top: 0.5rem solid transparent;
          border-left: 0.5rem solid transparent;
          border-right: 0.5rem solid transparent;
        }
      }

      .timeVisaul{
        display: flex;
        position: absolute;

        width: 13.25rem;
        //width: calc(100% - 7.688rem);
        height: 13.25rem;
        margin-left: 3.875rem;
        margin-right: 3.813rem;
        margin-top: 23.438rem;
        margin-bottom: 1.938rem;
        //margin-top: 4.188rem;


        .timeVisaulBack{
          display: flex;
          //justify-content: center;
          align-items: center;
          justify-content: center;
          //background: rgba(255, 255, 255, 0.2);
          //background: conic-gradient(#FFFFFF 210deg, rgba(255, 255, 255, 0.2) 210deg);
          mix-blend-mode: normal;
          border-radius: 50%;
          //transform: rotate(180deg);

          height: 100%;
          width: 100%;

          .timeVisaulBarCircle1{
            position: absolute;
            background: #ffffff;
            border-radius: 50%;
            width: 1rem;
            height: 1rem;
            //transform: rotate(180deg) translateY(6.1rem);
          }

          .timeVisaulBarIncircle{
            display: flex;
            background:#3E40F4;
            border-radius: 50%;
            width: calc(100% - 2rem);
            height: calc(100% - 2rem);

            align-items: center;
            justify-content: center;

            .timeVisaulBarIncircleMessage{
              display: flex;
              width: fit-content;
              height: 3.875rem;
              flex-direction: column;

              .timeVisaulBarIncircleMessageTime{
                font-family: 'Pretendard';
                font-style: normal;
                font-weight: 700;
                font-size: 28px;
                line-height: 34px;
                /* identical to box height */

                text-align: center;

                /* Gray/White */

                color: #FFFFFF;


                /* Inside auto layout */

                flex: none;
                order: 0;
                flex-grow: 0;
              }

              .timeVisaulBarIncircleMessageLimit{
                font-family: 'Pretendard';
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                /* identical to box height */

                text-align: center;

                /* Gray/White */

                color: #FFFFFF;

                opacity: 0.4;

                /* Inside auto layout */

                flex: none;
                order: 1;
                flex-grow: 0;

              }
            }
          }

          .timeVisaulBarCircle2{
            position: absolute;
            background: #ffffff;
            border-radius: 50%;
            width: 1rem;
            height: 1rem;
            //transform: rotate(30deg) translateY(6.125rem);
          }
        }
      }
    }

    .maskGroup{
      //display: flex;
      //flex-direction: column;
      //width: calc(100% - 2.5rem);
      //width: 100%;
      width: 100vw;
      //height: 12.25rem;
      height: auto;

      margin-top: 72px;
      //align-self: center;

      overflow: scroll;
      //justify-content: center;
      -ms-overflow-style: none;

      //align-items: center;



      .maskGroupLine{
        display: flex;
        flex-direction: row;

        width: max-content;
        margin-bottom: 16px;
        margin-left: 25px;


        //margin-inline-start: -50px;
        //scroll-margin-inline-start: -50px;

        //animation: slidein 1s linear normal;

        //scroll-behavior: smooth;
        //scroll-padding-left: 100px;
        //scroll-margin-left: 100px;
        //left: -50px;
        //translate: -50vw;

        //align-self: center;

        .maskGroupTag{
          width: fit-content;
          display: flex;
          //animation: slidein 5s linear infinite normal;
        }
        .maskGroupTagImg{
          height: 56px;
          margin-right: 10px;
        }
      }

      .maskGroupLine:nth-child(1){
        translate: -50%;
        //margin-left: 10rem;
        animation: slidein 40s linear infinite reverse;
      }
      .maskGroupLine:nth-child(2){
        translate: -50%;
        //margin-left: 5rem;
        animation: slidein 40s linear infinite reverse;
      }
      .maskGroupLine:nth-child(3){
        translate: -50%;
        //margin-left: 10rem;
        animation: slidein 40s linear infinite reverse;
      }
    }

    .maskGroup::-webkit-scrollbar{
      display: none;
    }

    /*.maskGroup{
      display: flex;
      flex-direction: row;
      width: calc(100% - 2.5rem);
      align-self: center;
      flex-wrap: wrap;
      margin-top: 6rem;

      .maskGroupTag{
        width: fit-content;
        display: flex;
        widows: 50%;
        margin-left: 0.5rem;
        margin-top: 0.5rem;
      }
    }*/

    .testGuideArea{
      display: flex;
      width: 100%;
      height: 37.5rem;

      flex-direction: column;

      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #3A37E8;

      margin-top: 72px;
      //justify-content: center;

      .testGuideText{
        display: flex;
        flex-direction: column;
        margin-top: 2.5rem;
        margin-left: 25px;

        .testGuideLabel{
          display: flex;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 700;
          font-size: 28px;
          line-height: 38px;

          /* Gray/White */

          color: #FFFFFF;
        }

        .testGuideTip{
          display: flex;



          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;

          margin-top: 4px;

          color: #FFFFFF;

          opacity: 0.7;

          word-break: break-word;
        }
      }

      .testGuideBase{
        display: flex;
        flex-direction: column;
        width: 390px;
        height: 22.625rem;
        align-self: center;
        margin-top: 2.5rem;
        justify-content: center;
        align-items: center;

        .testGuideUnit{
          display: flex;
          flex-direction: row;
          overflow: hidden;

          width: 100%;
          height: 22.625rem;

          /*background-color: #3E40F4;
          border-radius: 24px;*/

          scroll-snap-type: x mandatory;

          .mySwiper{
            width: 100%;
            height: 100%;

            .testGuideUnitBox{
              display: flex;
              flex-direction: column;
              position: relative;
              width: 335px;
              height: 362px;
              margin: 0 29px;

              /*background-color: #3E40F4;
              border-radius: 24px;*/

              .testGuideUnitNum {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8px 16px;

                margin-left: 1.5rem;
                margin-top: 2rem;

                background: #FFFFFF;
                mix-blend-mode: normal;
                border-radius: 50px;

                //width: 4.25rem;
                height: fit-content;
                width: fit-content;

                font-family: 'Pretendard';
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 18px;

                text-align: center;
                letter-spacing: -0.02em;

                color: #222222;

                height: 2.125rem;
                //width: 4.25rem;
              }

              .testGuideUnitText{
                display: flex;
                flex-direction: column;
                //margin-left: 1.5rem;
                margin-top: 8px;
                z-index: 1;

                width: 20.938rem;
                padding-left: 1.5rem;

                .testGuideUnitLabel{
                  display: flex;

                  font-family: 'Pretendard';
                  font-style: normal;
                  font-weight: 700;
                  font-size: 32px;
                  line-height: 38px;

                  color: #FFFFFF;

                  flex: none;
                  order: 0;
                  flex-grow: 0;
                  align-items: center;

                  .testGuideUnitLabelImg{
                    height: 48px;
                    width: 48px;
                    margin-left: 6px;
                  }

                }

                .testGuideUnitTip{
                  display: flex;

                  font-family: 'Pretendard';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 18px;

                  margin-top: 6px;

                  color: #FFFFFF;
                }
              }
            }

            .box1{
              background-color: #3E40F4;
              border-radius: 24px;
            }
            .box2{
              background-color: #246BFD;
              border-radius: 24px;
            }
            .box3{
              background-color: #1FB58F;
              border-radius: 24px;
            }
            .box4{
              background-color: #0AA1D1;
              border-radius: 24px;
            }
            .box5{
              background-color: #F4A023;
              border-radius: 24px;
            }
          }

          .testGuideLogo{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            // background: url("../assets/img/block/maskOneImg.png") center center;
            opacity: 0.5;
            width: 100%;
            height: 100%;
          }
        }
      }

      .testGuideDotArea{
        display: flex;
        flex-direction: row;
        height: 0.375rem;
        margin-top: 1.5rem;
        align-self: center;

        .testGuideDotPoint{
          height: 7px;
          width: 7px;
          border-radius: 50%;
          background: #ffffff;

          margin: 0 0.219rem 0 0.219rem;
        }

        .testGuideDotPointUnselect{
          height: 7px;
          width: 7px;
          border-radius: 50%;
          background: #ffffff;

          margin: 0 0.219rem 0 0.219rem;
          opacity: 0.3;
        }
      }
    }

    .lobbyBottumGuide{
      display: flex;
      flex-direction: column;

      width: calc(100% - 2.5rem);
      height: auto;
      margin: 40px 0 0 20px;



      .lobbyBottumGuideLabel{
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;

        color: #222222;
      }

      .lobbyBottumGuideList{
        display: flex;
        flex-direction: column;
        margin-top: 12px;
        //font-family: 'Pretendard';
        line-height: 1.6;
        font-size: 14px;
        color:#666;

        .lobbyBottumGuideListUnit{
          display: flex;
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 24px;

          color: #424242;


          .miniDotArea{
            .miniDot{
            }
            //border-radius: 50%;
            //color: #424242;
          }
        }

      }
    }

    .lobbyUserInfoGuide{
      display: flex;
      width: calc(100% - 2.5rem);
      align-self: center;
      flex-direction: column;
      margin-top: 2rem;

      .lobbyUserInfoGuideLabel{
        display: flex;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.2rem;

        margin-bottom: 0.75rem;

        color: #222222;
      }

      .lobbyUserInfoGuideUnit{
        display: flex;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 24px;
        

        color: #424242;
      }

      .lobbyUserInfoGuideCheck{
        display: flex;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 700;
        font-size: 0.9rem;
        line-height: 1.1rem;

        margin-top: 0.75rem;

        color: #424242;

        .userInfoCheckbox{
          margin-right: 0.5rem;
        }
      }
    }

    .userNumArea{
      display: flex;
      flex-direction: column;
      width: fit-content;
      height: 3.75rem;
      align-self: center;
      margin:40px 0 8px;
      animation: usernum .35s ease-in alternate infinite;
      @keyframes usernum {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(8px);
        }
      }


      .userNumAreaUp{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 8px 16px;
        width: fit-content;
        height: 3.25rem;
        background: #EAE9FF;
        border-radius: 8px;
        gap: 0.5rem;

        .userNumAreaTxt{
          display: flex;
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 16px;
          /* identical to box height */

          text-align: center;

          /* Gray/900 */

          color: #222222;

          .userNumAreaTxtInner{
            display: flex;
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 16px;
            /* identical to box height */

            text-align: center;

            /* Gray/900 */

            color: #3E40F4;
          }
        }

        .Blue {
          color: #3E40F4;
        }
      }
      .userNumAreadown{
        display: flex;
        width: 0px;
        height: 0px;
        border-radius: 1px;
        transform: matrix(1, 0, 0, -2.1, 0, 5);
        align-self: center;
        border-bottom: 0.35rem solid #EAE9FF;
        border-top: 0.5rem solid transparent;
        border-left: 0.5rem solid transparent;
        border-right: 0.5rem solid transparent;
      }
    }

    .testStartBtnArea{
      display: flex;
      width: calc(100% - 2.5rem);
      height: 60px;

      border-radius: .75rem;

      align-self: center;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 1.0625rem;
      line-height: 1.25rem;
      text-align: center;

      align-items: center;
      justify-content: center;

      margin-bottom: 24px;

      &:hover {
        cursor: pointer;
      }
    }

    .margin-top {
      margin-top: 3.75rem;
    }

    .selected{
      background: #3E40F4;
      color: #FFFFFF;
    }

    .unselected{
      background: #EAE9FF;
      color: #A4A5FC;
    }

  }

  .lobbyFooter{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 5.25rem;
    background: #F5F6F7;

    align-items: center;

    .companyInfo{
      display: flex;

      margin-top: 1.25rem;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 8px;
      /* identical to box height, or 67% */

      letter-spacing: -0.02em;

      /* Gray/700 */

      color: #616161;

      .companyUserInfoGuide{
        display: flex;

        margin-left: 0.563rem;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 8px;
        /* identical to box height, or 67% */

        letter-spacing: -0.02em;
        text-decoration-line: underline;

        /* Gray/900 */

        color: #222222;
      }
    }

    .companyInfoUnder{
      display: flex;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 8px;
      /* identical to box height, or 67% */

      letter-spacing: -0.02em;

      /* Gray/700 */

      color: #616161;

      margin-top: 1.125rem;
      margin-bottom: 1.875rem;
    }
  }

}

@keyframes slidein {
  from{
    //translate: -134.5rem;
    //translate: calc(100% - 134.5rem);
    translate: -50%;
  }
  to{
    //translate: calc(134.5rem + 100vw);
    translate: 0%;
  }
}
</style>
