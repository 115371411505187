<template>
  <div class="controllerForce">
    <Enter v-if="isRoute('enter')" />
    <Lobby v-if="isRoute('lobby')" />
    <InfoSelectFirst v-if="isRoute('infoFirst')" />
    <InfoSelectSecond v-if="isRoute('infoSecond')" />
    <MicrophoneTest v-if="isRoute('microphone')" />
    <TestReadingWord v-if="isRoute('TestReadingWord')" />
    <TestReadingContext v-if="isRoute('TestReadingContext')" />
    <TestListeningNormal v-if="isRoute('TestListeningNormal')" />
    <TestReadingNormal v-if="isRoute('TestReadingNormal')" />
    <TestWritingContext v-if="isRoute('TestWritingContext')" />
    <TestListeningHard v-if="isRoute('TestListeningHard')" />
    <TestListeningContext v-if="isRoute('TestListeningContext')" />
    <TestTalkingText v-if="isRoute('TestTalkingText')" />
    <TestTalkingContext v-if="isRoute('TestTalkingContext')" />
    <TestResult v-if="isRoute('TestResult')" />
  </div>
</template>

<script>
import Lobby from '@/components/Lobby.vue';

import InfoSelectFirst from '@/components/InfoSelectFirst.vue';
import InfoSelectSecond from '@/components/InfoSelectSecond.vue';
import MicrophoneTest from '@/components/MicrophoneTest.vue';

import TestReadingWord from '@/components/Test/TestReadingWord.vue';
import TestReadingContext from '@/components/Test/TestReadingContext.vue';
import TestListeningNormal from '@/components/Test/TestListeningNormal.vue';
import TestReadingNormal from '@/components/Test/TestReadingNormal.vue';
import TestWritingContext from '@/components/Test/TestWritingContext.vue';
import TestListeningHard from '@/components/Test/TestListeningHard.vue';
import TestListeningContext from '@/components/Test/TestListeningContext.vue';
import TestTalkingText from '@/components/Test/TestTalkingText.vue';
import TestTalkingContext from '@/components/Test/TestTalkingContext.vue';
import TestResult from '@/components/Result/TestResult.vue';
import Enter from "@/components/Enter.vue";
import axios from "axios";
import testControl from "@/function/testControl";
import store from "@/store/store";

export default {
  name: 'ControllerForce',
  components: {
    Lobby,
    InfoSelectFirst,
    InfoSelectSecond,
    MicrophoneTest,
    TestReadingWord,
    TestReadingContext,
    TestListeningNormal,
    TestReadingNormal,
    TestWritingContext,
    TestListeningHard,
    TestListeningContext,
    TestTalkingText,
    TestTalkingContext,
    TestResult,
    Enter,
  },
  data() {
    return {
    }
  },
  computed: {
    route() {
      return this.$store.getters['testManage/route'] ? this.$store.getters['testManage/route'] : 'lobby'
    }
  },
  created() {
    console.log(this.$route.params.token)
    this.$store.commit('testManage/init')
    this.$store.commit('testManage/setUserToken', this.$route.params.token)
    this.$store.commit('testManage/setCompanyCode', 'special')
    testControl.toNextType('lobby')
  },
  methods: {
    isRoute(routeName) {
      console.log(`isRoute: ${routeName}, ${this.route}`)
      return routeName === this.route
    },
  }
}
</script>

<style scoped lang="scss">
.controllerForce {
  width: 100%;
  height: 100%;
}
</style>