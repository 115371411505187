<template>
    <div class="TestResultProductForEdu">
        <div class="guideForProductLabel" @click="openArea()">
            <div class="labelLeft"><img :src="$testControl.imgUrl(tablet)" class="labelLeftImg" />
                <div class="labelLeftText">LV{{ level }}. {{ userName }}님을 위한 맞춤 콘텐츠</div>
            </div>
            <div v-if="eduProductStep === 2" class="labelRight" @click="resetData()"><img class="guideLabelResetSVG" id="guideLabelResetSVG" :src="$testControl.imgUrl(cached)"/></div>
        </div>

        <div v-if="eduProductStep === 1" class="stepEduFirst">
            <div class="guideEduProduct">선택하신 학원의 추천강의가 표시 됩니다.</div>
            <div class="locationEduProduct">
                <div v-for="(item, index) in locationList" :key="index" @click="selectLocation(index)"
                :class="'locationTap ' + [(locationTap === index) ? 'select': 'unselect']">{{ item }}</div>
            </div>
            <div :class="'applyBtn' + [(locationTap !== -1) ? ' check' : ' uncheck']" @click="applyLocation(1)">적용하기</div>
        </div>

        <div v-if="eduProductStep === 2" class="stepEduSecond">
            <div class="selectedLocation" @click="openList()">
                <div class="locationChangeList">
                    <div class="locationChangeListTxt">{{ locationList[locationTap] }}</div>
                    &nbsp;<img class="locationChangeListSVG" :src="$testControl.imgUrl(vectorUnder)"/>
                </div>
                <div class="text">학원의 추천강의</div>
            </div>
            <div class="productList">
                <div v-for="(item, index) in product" class="productUnit" :class="`unit${(index%3) + 1}`" :key="index" @click="$testControl.newLinkPage(item.url_pc, item.url_mobile)">
                    <div class="unitLeftSide">
                        <div class="unitLeftSideBtn">[{{item.contentBtn}}]</div>
                        <div class="unitLeftSideLabel">{{item.contentLabel}}</div>
                        <div v-if="false" class="unitLeftSideType">{{item.contentType}}</div>
                    </div>
                    <div class="unitRightSide">
                        <img :src="$testControl.imgUrl(toSiteArrow)"/>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
    <div v-if="openLocationList" class="locationSelectList">
        <div class="listLocation">
            <div class="locationEduProduct">
                <div v-for="(item, index) in locationList" :key="index" @click="selectLocation(index)"
                :class="'locationTap ' + [(locationTap === index) ? 'select': 'unselect']">{{ item }}</div>
            </div>
            <div class="applyBtn" @click="applyLocation(2)">적용하기</div>
        </div>
    </div>
</template>

<script>
import toSiteArrowSVG from '@/assets/img/block/toSiteArrowBlack.svg';
import tabletSVG from '@/assets/img/block/tablet.svg';
import productLabelSVG from '@/assets/img/block/productLabel.svg';
import vectorUnderSVG from '@/assets/img/block/vectorUnder.svg';
import vectorUpSVG from '@/assets/img/block/vectorUp.svg';

import axios from "axios";

import cachedSVG from '@/assets/img/block/cached.svg';

export default {
    name: 'TestResultProductForEdu',
    props: {
        /*product: {
            type: Object,
            default: null,
        },*/
        level: {
            type: String,
            default: undefined,
        },
        userName: {
            type: String,
            default: undefined,
        },
        weakness: {
            type: Array,
            default: undefined,
        },
        eduLocationIdx:{
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            toSiteArrow: toSiteArrowSVG,
            cached: cachedSVG,

            show: true,

            selectedProductTab: 1,
            selectedClassTab: 1,

            tablet: tabletSVG,
            vectorUnder: vectorUnderSVG,
            vectorUp: vectorUpSVG,
            productLabel: productLabelSVG,

            eduProductStep: 1,
            depthByLocation: 1,

            locationList: ['종로e4u', '종로', '신촌', '강남', '건대', '인천', '대구 동성로', '부산 광복', '부산 서면', '불라방'],
            locationTap: -1,

            product: [],
            openLocationList: false,
        }
    },
    watch:{
        eduLocationIdx: {
            handler: function (){
                console.log('changeLocation', this.eduLocationIdx);

                this.locationTap = this.eduLocationIdx;
                this.applyLocation(1);
            }
        }
    },
    methods: {
        openArea() {
            console.log('openArea');
            this.show = !this.show;
        },
        selectProductTab(num) {
            this.selectedProductTab = num;
        },
        selectClassTab(num) {
            this.selectedClassTab = num;
        },

        selectLocation(idx){
            if(this.locationTap !== idx){
                this.locationTap = idx;
            }
        },
        async callDataByLocation(){
            console.log('callDataByLocation');

            const toUrl = `${this.$store.getters.apiUrl}/v1/recommend_study_list`;
            
            let result = [];

            await axios.post(toUrl, {
                "level": 1,
                "div": `${this.depthByLocation}`,
                "company_code": "ybm_edu",
                "div1": `${this.locationList[this.locationTap]}`,
            }).then((res) =>  {
                console.log("edu axios res : ", res);

                res.data.list.map((data) => {

                    if(this.weakness.includes(data.type_title))
                    {

                        const item = {
                            contentType: data.description,
                            contentLabel: data.title,
                            contentBtn: data.type_title,
                            url_pc: data.url_pc,
                            url_mobile: data.url_mobile,
                        }

                        result.push(item)
                    }
                })
            }).finally(() => {
                console.log('result', result)
            });
            return result;
        },
        async applyLocation(num){
            console.log('applyLocation', this.locationTap);

            if(this.locationTap !== -1){
                this.depthByLocation = 1;

                const data = await this.callDataByLocation();
                this.product = data;

                if(num === 1){
                    this.eduProductStep = 2;

                } else if(num === 2){
                    this.openLocationList = false;
                }
                this.$emit("eduSelectLocationFromWeak", this.locationTap);
            } else {
                console.log('unselected location');
            }
        },
        openList(){
            console.log('openLocationList');
            this.openLocationList = !this.openLocationList;
        },
        async resetData(){
            console.log('resetData');

            this.depthByLocation++;
            const result = await this.callDataByLocation();

            if(result.length !== 0){
                console.log('take new list this location');
                this.product = result;
            } else {
                console.log('end of list');
                this.depthByLocation = 1;
                const resultII = await this.callDataByLocation();
                this.product = resultII;
            }
        }
    }
}
</script>


<style lang="scss" scoped>
.TestResultProductForEdu {
    display: flex;
    width: 100%;
    flex-direction: column;

    align-self: center;
    justify-content: center;
    background: #fff;
    border-radius: 8px;

    .guideForProductLabel{
        display: flex;
        flex-direction: row;
        width: 100%;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 30px;

        color: #222222;
        padding: 1rem;

        .labelLeft{
            display: flex;
            gap: 0.5rem;

            .labelLeftText{
                display: flex;

                font-family: "Pretendard";
                font-size: 15px;
                font-weight: 600;
                line-height: 18px;
                letter-spacing: 0em;
                text-align: left;

                align-items: center;
            }

            .labelLeftImg {
                width: 1.25rem;
                height: 1.25rem;
                display: flex;
                align-self: center;
            }
        }

        .labelRight{
            display: flex;
            margin-left: auto;

            width: 2rem;
            height: 2rem;
            .guideLabelResetSVG{
                display: flex;
          
                width: 100%;
                height: 100%;

                &:hover {
                    animation: rotate 2s linear;
                    cursor: pointer;
                }
            }
        }
    }

    .stepEduFirst{
        display: flex;
        flex-direction: column;

        width: calc(100% - 2rem);
        align-self: center;

        .guideEduProduct{
            display: flex;

            width: 100%;

            font-family: "Pretendard";
            font-size: 16px;
            font-weight: 600;
            line-height: 19px;
            letter-spacing: 0em;
            text-align: center;
        }

        .locationEduProduct{
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            margin-top: 1.5rem;
            gap: 0.688rem;
            align-self: center;            

            .locationTap{
                display: flex;
                width: calc(50% - 0.344rem);
                align-self: center;
                justify-content: center;

                padding: 1rem;
                border-radius: 0.5rem;
                //color: blue;

                background: #F5F6F7;

                font-family: "Pretendard";
                font-size: 13px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: center;
            }

            .unselect{
                border: 2px solid #F5F6F7;
                //color: #F5F6F7;
            }

            .select{
                border: 2px solid #3E40F4;
                color: #3E40F4;
            }
        }

        .applyBtn{
            display: flex;

            width: 100%;
            padding: 1.125rem;
            justify-content: center;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            background: #3E40F4;
            border-radius: 0.75rem;

            font-family: "Pretendard";
            font-size: 17px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;

            color: #FFFFFF;
        }

        .check{
            background: #3E40F4;
            color: #FFFFFF;
        }

        .uncheck{
            background: #EAE9FF;
            color: #A4A5FC;
        }
    }

    .stepEduSecond{
        display: flex;
        flex-direction: column;

        width: calc(100% - 2rem);
        align-self: center;

        .selectedLocation{
            display: flex;
            flex-direction: row;
            width: 100%;

            gap: 0.5rem;

            .locationChangeList{
                display: flex;
                padding: 0.625rem;
                border-radius: 0.25rem;

                width: max-content;

                border: 1px solid #DDDDDD;

                .locationChangeListTxt{
                    display: flex;

                    font-family: "Pretendard";
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 19px;
                    letter-spacing: 0em;
                    text-align: left;

                    color: #3E40F4;
                }

                .locationChangeListSVG{
                    display: flex;
                    width: 0.408rem;
                    color: #3E40F4;

                    //margin-left: 0.5rem;
                }

            }

            .text{
                display: flex;
                align-items: center;

                font-family: "Pretendard";
                font-size: 16px;
                font-weight: 600;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: left;

            }
        }

        .productList{
            display: flex;
            flex-direction: column;
            margin-top: 2rem;
            width: 100%;
            gap: 1rem;
            margin-bottom: 1.5rem;

            .productUnit{
                display: flex;
                width: 100%;
                height: 3.25rem;
                border-radius: 12px;

                align-items: center;

                .unitLeftSide{
                    display: flex;
                    flex-direction: row;
                    position: absolute;
                    //width: auto;
                    width: calc(100% - 10.5rem);
                    height: auto;

                    //margin-top: 1.375rem;
                    margin-left: 1.25rem;
                    align-items: center;

                    //gap: 0.375rem;

                    .unitLeftSideType{
                        font-family: 'Pretendard';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 16px;

                        color: #FFFFFF;

                        margin-top: 0.375rem;
                    }

                    .unitLeftSideLabel{
                        display: -webkit-box;
                        display: block;
                        -webkit-line-clamp: 2; // 원하는 라인수
                        -webkit-box-orient: vertical;
                        font-family: 'Pretendard';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 15px;
                        line-height: 22px;

                        height: 1.25rem;
                        width: calc(100% - 3.35rem);

                        color: #222222;

                        //margin-top: 0.375rem;

                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap; 	
                        word-break: break-all;
                    }

                    .unitLeftSideBtn{
                        //border: 1px solid rgba(255, 255, 255, 0.5);
                        //border-radius: 5px;
                        //padding: 6px 10px;
                        width: 3rem;
                        height: auto;

                        font-family: 'Pretendard';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 15.6px;

                        color: #222222;

                        //margin-top: 0.813rem;
                    }
                }

                .unitRightSide{
                    display: flex;
                    position: absolute;
                    width: calc(100% - 7.5rem);
                    
                    height: 3.25rem;
                    align-items: center;
                    justify-content: flex-end;
                    //padding-right: 1.356rem;
                }
            }

            .unit1{
                background: #F5F6F7;
                overflow: hidden;

                .productListBack{
                    display: flex;
                    width: 26rem;
                    height: 26rem;

                    .productListLogo{
                        display: flex;
                        width: 26rem;
                        height: 26rem;
                        border-radius: 50%;
                        background: conic-gradient(rgba(255, 255, 255, 0.4) 0deg 290deg, rgba(255, 255, 255, 0) 290deg 360deg);
                        opacity: 0.4;
                        right: 50px;

                        translate: 6rem -1rem;
                        transform: rotate(180deg);

                        align-items: center;
                        justify-content: center;
                        
                        .Incircle{
                        display: flex;
                        height: 10.78rem;
                        width: 10.78rem;
                        border-radius: 50%;
                        background: #F5F6F7;
                        }
                    }

                }
            }
            .unit2{
                background: #F5F6F7;
                overflow: hidden;

                .productListBack{
                    display: flex;
                    width: 26rem;
                    height: 26rem;

                    .productListLogo{
                        display: flex;
                        width: 26rem;
                        height: 26rem;
                        border-radius: 50%;
                        background: conic-gradient(rgba(255, 255, 255, 0.4) 0deg 180deg, rgba(255, 255, 255, 0));
                        opacity: 0.4;
                        right: 50px;

                        translate: 6rem -10rem;
                        transform: rotate(180deg);

                        align-items: center;
                        justify-content: center;
                        
                        .Incircle{
                        display: flex;
                        height: 10.78rem;
                        width: 10.78rem;
                        border-radius: 50%;
                        background: #F5F6F7;
                        }
                    }

                }
            }
            .unit3{
                background: #F5F6F7;
                overflow: hidden;

                .productListBack{
                    display: flex;
                    width: 26rem;
                    height: 26rem;

                    .productListLogo{
                        display: flex;
                        width: 26rem;
                        height: 26rem;
                        border-radius: 50%;
                        background: conic-gradient(rgba(255, 255, 255, 0.4) 0deg 290deg, rgba(255, 255, 255, 0) 290deg 360deg);
                        opacity: 0.4;
                        right: 50px;

                        translate: 6rem -19rem;
                        transform: rotate(180deg);

                        align-items: center;
                        justify-content: center;
                        
                        .Incircle{
                        display: flex;
                        height: 10.78rem;
                        width: 10.78rem;
                        border-radius: 50%;
                        background: #F5F6F7;
                        }

                        .underCircle{
                        display: flex;
                        position: absolute;
                        border-radius: 50%;
                        background: linear-gradient(rgba(255, 255, 255, 0) 0 50%, rgba(255, 255, 255, 0.4) 50% 100%);
                        transform: rotate(90deg);
                        translate: 0rem -9.51rem;
                        width: 7rem;
                        height: 7rem;
                        }
                    }
                }
            }

        }
    }
}
.locationSelectList{
    display: flex;
    width: calc(100% - 6.5rem);
    //height: 12rem;
    position: absolute;
    border: 1px solid #dddddd;
    border-radius: 0.5rem;
    margin-top: 6.938rem;
    justify-content: center;
    background: #ffffff;

    .listLocation{
        display: flex;
        flex-direction: column;
        width: calc(100% - 2rem);
        align-self: center;
        
        .locationEduProduct{
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            margin-top: 1.5rem;
            gap: 0.688rem;
            align-self: center;            

            .locationTap{
                display: flex;
                width: calc(50% - 0.344rem);
                align-self: center;
                justify-content: center;

                padding: 1rem;
                border-radius: 0.5rem;
                //color: blue;

                background: #F5F6F7;

                font-family: "Pretendard";
                font-size: 13px;
                font-weight: 500;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: center;
            }

            .unselect{
                border: 2px solid #F5F6F7;
                //color: #F5F6F7;
            }

            .select{
                border: 2px solid #3E40F4;
                color: #3E40F4;
            }
        }

        .applyBtn{
            display: flex;

            width: 100%;
            padding: 1.125rem;
            justify-content: center;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            background: #3E40F4;
            border-radius: 0.75rem;

            font-family: "Pretendard";
            font-size: 17px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            text-align: center;

            color: #FFFFFF;
        }
    }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
</style>