<template>
  <div class="TestResultGuideForBookProduct">
    <div class="guideForProduct">
      <div class="predictionArea">
        <div class="predictLevel">지금 토익을 본다면 <span class="predictLevelSpan">{{ predictText }}</span> 예상 됩니다.</div>
        <div class="predictText">* 진단평가 응시생들의 평균 데이터를 활용하여 추측한 점수대 입니다. 참고용으로 활용해주세요.</div>
      </div>
      <div class="guideLabel">
        <div class="guideLabelText">{{userName}}회원님을 위한 맞춤 콘텐츠</div>
        <div class="guideLabelReset" @click="resetProduct"><img class="guideLabelResetSVG" id="guideLabelResetSVG" :src="$testControl.imgUrl(cached)" /></div>
      </div>

      <div class="guideForProduct" v-if="show">
        <div class="productList">
          <div v-for="(item, index) in product" class="productUnit" :class="`unit${(index%3) + 1}`" :key="index" @click="$testControl.newLinkPage(item.url_pc, item.url_mobile)">
            <div class="unitLeftSide">
              <div class="unitLeftSideBtn">[{{item.contentBtn}}]</div>
              <div class="unitLeftSideLabel">{{item.contentLabel}}</div>
              <div v-if="false" class="unitLeftSideType">{{item.contentType}}</div>
            </div>
            <div class="unitRightSide">
              <img :src="$testControl.imgUrl(toSiteArrow)"/>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import toSiteArrowSVG from '@/assets/img/block/toSiteArrowBlack.svg';
import cachedSVG from '@/assets/img/block/cached.svg';
import axios from "axios";

export default{
  name: 'TestResultGuideForBookProduct',
  props:{
    level: {
      type: String,
      default: undefined,
    },
  },
  data(){
    return{
      toSiteArrow: toSiteArrowSVG,
      cached: cachedSVG,
      
      show: true,

      selectedProductTab: 1,
      selectedClassTab: 1,
      guideType: this.type,
      product: [],

      divRecommend: 0,

      predictText: undefined,
    }
  },
  computed: {
    userName() {
      return this.$store.getters['testManage/name'].length === 0 ? '익명' : this.$store.getters['testManage/name']
    },
    /*isShowRecommendPublwizProduct() {
      return this.$store.getters['testManage/companyCode'] === 'ybm_publwiz'
    }*/
  },
  async mounted() {
    await this.fetchData()
  },
  methods:{
    async fetchData() {

      this.resetProduct();

      if(this.level === 1){
        this.predictText = '500점 미만이';
      } else if(this.level === 2 || this.level === 3){
        this.predictText = '500점대가';
      } else if(this.level === 4 || this.level === 5){
        this.predictText = '600점대가';
      } else if(this.level === 6 || this.level === 7){
        this.predictText = '700점대가';
      } else if(this.level === 8 || this.level === 9){
        this.predictText = '800점대가';
      } else if(this.level === 10){
        this.predictText = '900점대가';
      }
    },
    selectProductTab(num){
      this.selectedProductTab = num;
    },
    selectClassTab(num){
      this.selectedClassTab = num;
    },
    async callPublishData(recommend){
      console.log('callPublishData', recommend);

      const toUrl = `${this.$store.getters.apiUrl}/v1/recommend_study_list`;

      let result = [];

      await axios.post(toUrl, {
        "level": this.level,
        "div": `${recommend}`,
        "company_code": "ybm_publishing",
      }).then((res) => {
        console.log("publishing axios res : ", res);

        res.data.list.map((data) => {
          const item = {
            contentType: data.description,
            contentLabel: data.title,
            contentBtn: data.type_title,
            url_pc: data.url_pc,
            url_mobile: data.url_mobile,
          }

          result.push(item)
          //this.product.push(item);
        })
        
      }).finally(() => {
        this.show = true;        
      });

      console.log('get result?', result);
      return result;

    },
    async resetProduct(){
      console.log('resetProduct');

      if(this.divRecommend === 4){
        this.divRecommend = 1;
      } else {
        this.divRecommend++;
      }

      const data = await this.callPublishData(this.divRecommend);
      console.log('get result?', data);
      
      setTimeout(async() => {
        if(data.length !== 0){
          this.product = data;
        } else {
          this.divRecommend = 1;
          const reData = await this.callPublishData(this.divRecommend);
          this.product = reData;
        }
      }, 100)
      //this.show = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.TestResultGuideForBookProduct{
  scroll-margin-top: 4.375rem;
  display: flex;
  width: calc(100% - 2.5rem);
  align-self: center;
  justify-content: center;

  background: #fff;
  border-radius: 8px;
  .guideForProduct{
    display: flex;
    width: calc(100% - 1rem);
    //width: 100%;
    align-self: center;
    flex-direction: column;
    margin-bottom: 2.5rem;

    .predictionArea{
      display: flex;
      flex-direction: column;

      width: calc(100% - 1rem);
      //width: 100%;
      align-self: center;

      gap: 0.75rem;

      margin-top: 2rem;

      .predictLevel{
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;

        letter-spacing: -0.02em;

        color: #222222;

        word-break: keep-all;

        .predictLevelSpan{
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 24px;
          color: #3E40F4;
        }
      }

      .predictText{
        display: flex;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;

        letter-spacing: -0.02em;
        //align-self: flex-end;

        /* Gray/700 */

        color: #222222;

        word-break: keep-all;
      }
    }

    .guideLabel{
      display: flex;
      width: calc(100% - 1rem);
      align-self: center;
      margin-top: 2rem;

      .guideLabelText{
        display: flex;
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 700;
        font-size: 19px;
        line-height: 30px;

        align-self: center;

        color: #222222;

        word-break: keep-all;
      }

      .guideLabelReset{
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
        background: #F5F6F7;
        /* justify-self: flex-end; */
        margin-left: auto;
        border-radius: 10px;
        align-items: center;
        justify-content: center;

        .guideLabelResetSVG{
          display: flex;
          
          width: 100%;
          height: 100%;

          &:hover {
            animation: rotate 2s linear;
            cursor: pointer;
          }
        }
      }      
    }

    

    .productList{
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      width: 100%;
      gap: 1rem;

      .productUnit{
        display: flex;
        width: 100%;
        height: 3.25rem;
        border-radius: 12px;

        align-items: center;

        .unitLeftSide{
          display: flex;
          flex-direction: row;
          position: absolute;
          //width: auto;
          width: calc(100% - 10.5rem);
          height: auto;

          //margin-top: 1.375rem;
          margin-left: 1.25rem;
          align-items: center;

          //gap: 0.375rem;

          .unitLeftSideType{
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;

            color: #FFFFFF;

            margin-top: 0.375rem;
          }

          .unitLeftSideLabel{
            display: -webkit-box;
            display: block;
            -webkit-line-clamp: 2; // 원하는 라인수
            -webkit-box-orient: vertical;
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 22px;

            height: 1.25rem;
            width: calc(100% - 3.35rem);

            color: #222222;

            //margin-top: 0.375rem;

            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap; 	
            word-break: break-all;
          }

          .unitLeftSideBtn{
            //border: 1px solid rgba(255, 255, 255, 0.5);
            //border-radius: 5px;
            //padding: 6px 10px;
            width: 3rem;
            height: auto;

            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 15.6px;

            color: #222222;

            //margin-top: 0.813rem;
          }
        }

        .unitRightSide{
          display: flex;
          position: absolute;
          width: calc(100% - 5.5rem);
          
          height: 3.25rem;
          align-items: center;
          justify-content: flex-end;
          //padding-right: 1.356rem;
        }
      }

      .unit1{
        background: #F5F6F7;
        overflow: hidden;

        .productListBack{
          display: flex;
          width: 26rem;
          height: 26rem;

          .productListLogo{
            display: flex;
            width: 26rem;
            height: 26rem;
            border-radius: 50%;
            background: conic-gradient(rgba(255, 255, 255, 0.4) 0deg 290deg, rgba(255, 255, 255, 0) 290deg 360deg);
            opacity: 0.4;
            right: 50px;

            translate: 6rem -1rem;
            transform: rotate(180deg);

            align-items: center;
            justify-content: center;
            
            .Incircle{
              display: flex;
              height: 10.78rem;
              width: 10.78rem;
              border-radius: 50%;
              background: #F5F6F7;
            }
          }

        }
      }
      .unit2{
        background: #F5F6F7;
        overflow: hidden;

        .productListBack{
          display: flex;
          width: 26rem;
          height: 26rem;

          .productListLogo{
            display: flex;
            width: 26rem;
            height: 26rem;
            border-radius: 50%;
            background: conic-gradient(rgba(255, 255, 255, 0.4) 0deg 180deg, rgba(255, 255, 255, 0));
            opacity: 0.4;
            right: 50px;

            translate: 6rem -10rem;
            transform: rotate(180deg);

            align-items: center;
            justify-content: center;
            
            .Incircle{
              display: flex;
              height: 10.78rem;
              width: 10.78rem;
              border-radius: 50%;
              background: #F5F6F7;
            }
          }

        }
      }
      .unit3{
        background: #F5F6F7;
        overflow: hidden;

        .productListBack{
          display: flex;
          width: 26rem;
          height: 26rem;

          .productListLogo{
            display: flex;
            width: 26rem;
            height: 26rem;
            border-radius: 50%;
            background: conic-gradient(rgba(255, 255, 255, 0.4) 0deg 290deg, rgba(255, 255, 255, 0) 290deg 360deg);
            opacity: 0.4;
            right: 50px;

            translate: 6rem -19rem;
            transform: rotate(180deg);

            align-items: center;
            justify-content: center;
            
            .Incircle{
              display: flex;
              height: 10.78rem;
              width: 10.78rem;
              border-radius: 50%;
              background: #F5F6F7;
            }

            .underCircle{
              display: flex;
              position: absolute;
              border-radius: 50%;
              background: linear-gradient(rgba(255, 255, 255, 0) 0 50%, rgba(255, 255, 255, 0.4) 50% 100%);
              transform: rotate(90deg);
              translate: 0rem -9.51rem;
              width: 7rem;
              height: 7rem;
            }
          }

        }
      }

    }
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

</style>