<template>
  <div class="TestReadingWord">
    <Header :num="questionNum" :fullCount="quizFullCount" />
    <div class="box_content">
      <div class="questionContent" ref="questionContent">
        <div class="testContentTitle">
          Q{{questionNum}}. {{ direction }}
        </div>
        <div class="testContentQuestion">
          {{ questionText }}
        </div>
        <div class="testContentAnswer">
          <div v-if="tempSelect==='A'" class="testContentAnswerArea" @click="checkAnswer('A', answer1)">
            <div class="testContentAnswerBtnSelected">A</div>
            <div class="testContentAnswerTxt">{{ answer1 }}</div>
          </div>
          <div v-if="tempSelect!=='A'" class="testContentAnswerAreaUnselect" @click="checkAnswer('A', answer1)">
            <div class="testContentAnswerBtnUnselect">A</div>
            <div class="testContentAnswerTxt">{{ answer1 }}</div>
          </div>

          <div v-if="tempSelect==='B'" class="testContentAnswerArea" @click="checkAnswer('B', answer2)">
            <div class="testContentAnswerBtnSelected">B</div>
            <div class="testContentAnswerTxt">{{ answer2 }}</div>
          </div>
          <div v-if="tempSelect!=='B'" class="testContentAnswerAreaUnselect" @click="checkAnswer('B', answer2)">
            <div class="testContentAnswerBtnUnselect">B</div>
            <div class="testContentAnswerTxt">{{ answer2 }}</div>
          </div>

          <div v-if="tempSelect==='C'" class="testContentAnswerArea" @click="checkAnswer('C', answer3)">
            <div class="testContentAnswerBtnSelected">C</div>
            <div class="testContentAnswerTxt">{{ answer3 }}</div>
          </div>
          <div v-if="tempSelect!=='C'" class="testContentAnswerAreaUnselect" @click="checkAnswer('C', answer3)">
            <div class="testContentAnswerBtnUnselect">C</div>
            <div class="testContentAnswerTxt">{{ answer3 }}</div>
          </div>

          <div v-if="tempSelect==='D'" class="testContentAnswerArea" @click="checkAnswer('D', answer4)">
            <div class="testContentAnswerBtnSelected">D</div>
            <div class="testContentAnswerTxt">{{ answer4 }}</div>
          </div>
          <div v-if="tempSelect!=='D'" class="testContentAnswerAreaUnselect" @click="checkAnswer('D', answer4)">
            <div class="testContentAnswerBtnUnselect">D</div>
            <div class="testContentAnswerTxt">{{ answer4 }}</div>
          </div>
        </div>
      </div>
    </div>
    <Footer :readyToNext="readyToNext" @nextStepOrder="nextStep(false)" @doSkip="doSkip" @skipSet="skipSet" :skipDefaultSet="skipDefaultSet"/>
  </div>
</template>

<script>
import Header from '@/components/Test/Header.vue';
import Footer from '@/components/Test/Footer.vue';
//import axios from 'axios';

export default {
  components: { 
    Header, 
    Footer,
  },
  name: 'TestReadingWord',
  props: {
  },
  data() {
    return {
      quizData: undefined,
      tempSelect: undefined,
      selectAnswer: undefined,
      readyToNext: false,
    }
  },
  computed: {
    readyToNextCheck() {
      return this.tempSelect !== undefined
    },
    direction() {
      return this.quizData ? this.quizData.direction : ''
    },
    questionNum() {
      return this.$store.getters['testManage/nowQuizNum']
    },
    questionId() {
      return this.quizData ? this.quizData.questionId : 0
    },
    quizFullCount() {
      return this.quizData ? this.quizData.quizFinishNumber : 0
    },
    quizType() {
      return this.quizData ? this.quizData.quizType : 0
    },
    questionText() {
      return this.quizData ? this.quizData.questionText : ''
    },
    answer1() {
      return this.quizData ? this.quizData.answerArr[0] : ''
    },
    answer2() {
      return this.quizData ? this.quizData.answerArr[1] : ''
    },
    answer3() {
      return this.quizData ? this.quizData.answerArr[2] : ''
    },
    answer4() {
      return this.quizData ? this.quizData.answerArr[3] : ''
    },
    skipDefaultSet() {
      return this.$store.getters['testManage/skipSet']
    },
  },
  watch: {
    readyToNextCheck: {
      handler: function (check){
        console.log('readyToNextCheck', check);

        this.readyToNext = check;
      }
    }
  },
  async mounted() {
    await this.setVal();
  },
  methods: {
    async setVal(){
      console.log("TestReadingWord setVal");
      this.$refs.questionContent.scrollIntoView({block: "start"})

      this.quizData = await this.$testControl.fnTestCall();
      //console.log('getData : ', this.quizData, this.$store.getters['testManage/nowQuizNum'], this.$store.getters['testManage/nowQuizType']);
    },

    async checkAnswer(select, answer){
      console.log("checkAnswer testContentAnswer" + select, answer);

      if(this.tempSelect !== select){
        this.selectAnswer = answer;
        this.tempSelect = select;
      } else if(this.tempSelect === select){
        this.selectAnswer = undefined;
        this.tempSelect = undefined;
      }
      
      console.log("checkAnswer answer : " + this.answer);
    },

    async nextStep(skipBool){
      console.log('step : ', this.questionNum, 'skip : ', skipBool);

      if(!this.readyToNext && !skipBool){
        console.log('answer is undefined');
      } else {
        const nextType = await this.$testControl.saveAnswer((!skipBool ? this.selectAnswer : 'SKIP_ANSWER'));

        if(nextType === this.quizType) {
          this.correctAnswer = undefined;
          this.tempSelect = undefined;
          await this.setVal();
        } else {
          await this.$testControl.toNextType(nextType);
        }
      }
    },
    async doSkip(){
      await this.nextStep(true);
    },
    async skipSet(){
      
      this.skipDefaultSet = true;

      this.$store.commit('testManage/setSkip', true);
      console.log('skipSet', this.$store.getters['testManage/skipSet']);
    }
  }
}
</script>


<style lang="scss" scoped>
@import "scssCode.scss";

.TestReadingWord {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: inherit;

  .box_content{
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;
    overflow-y: scroll;

    .questionContent{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: auto;

      .testContentTitle{
        display: flex;
        width: calc(100% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-top: 0.625rem;
        margin-bottom: 1rem;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 1.5;

        color: #222222;
      }
    }

    .testContentQuestion{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;

      height: 5rem;
      width: calc(100% - 2.5rem);
      margin-left: 1.25rem;
      margin-right: 1.25rem;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 2.375rem;

      color: #222222;

      background-color: #F5F6F7;
      border-radius: 12px;
      margin-bottom: 1rem;

      padding: 1.25rem 0;

      .selectedAnswerPoint{
        display: inline-block;
        background: #FFFFFF;

        height: 2.25rem;
        width: 6.5rem;

        border: 1px solid #DDDDDD;
        border-radius: 8px;

        align-items: center;
        justify-content: center;
        align-content: center;
        text-align: center;

        margin-left: 0.5rem;
        margin-right: 0.5rem;
        
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;

        color: #3E40F4;

        .selectedAnswerPointTXT{
          display: inline-flex;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 500;
          font-size: 19px;
          line-height: 1.625rem;
          vertical-align: middle;

          text-align: center;
          
        }
      }
    }

    .testContentAnswer{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      width: 100%;
      margin-bottom: 8rem;

      //margin-top: 2rem;

      .testContentAnswerAreaUnselect{
        @include defaultTestContentAnswerArea;
      }

      .testContentAnswerArea{
        
        @include defaultTestContentAnswerArea;
        background: #F5F6F7;
        //background-size: 100vw;
        
      }
    }

  }
}
</style>