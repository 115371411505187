<template>
  <div class="TestResultGuideForToeicProduct">
    <div class="guideForProduct">
      <div class="predictionArea">
        <div class="predictLevel">지금 토익을 본다면 <span class="predictLevelSpan">{{ predictText }}</span> 예상 됩니다.</div>
        <div class="predictText">* 진단평가 응시생들의 평균 데이터를 활용하여 추측한 점수대 입니다. 참고용으로 활용해주세요.</div>
      </div>
      <div class="guideLabel" v-if="!isShowRecommendPublwizProduct">
        {{userName}}님을 위한 토익 강의
      </div>
      <div class="productTab" v-if="!isShowRecommendPublwizProduct">
        <div class="tabProductType">
          <div :class="'productUnit ' + [(selectedProductTab === 1) ? 'selected' : 'unselected']" @click="selectProductTab(1)">인강</div>
          <div v-if="false" :class="'productUnit ' + [(selectedProductTab === 2) ? 'selected' : 'unselected']" @click="selectProductTab(2)">학원</div>
          <div v-if="false" :class="'productUnit ' + [(selectedProductTab === 3) ? 'selected' : 'unselected']" @click="selectProductTab(3)">교재</div>
        </div>
      </div>
      <div class="productList" v-if="!isShowRecommendPublwizProduct">
        <div v-for="(item, index) in product" class="productUnit" :class="`unit${index < 3 ? index+1 : 1}`" :key="index" @click="$testControl.newLinkPage(item.url_pc, item.url_mobile)">
          <div class="productListBack">
            <div class="productListLogo">
              <div class="Incircle"></div>
            </div>
          </div>
          <div class="unitLeftSide">
            <div class="unitLeftSideLabel">{{item.title}}</div>
          </div>
          <div class="unitRightSide">
            <img :src="$testControl.imgUrl(toSiteArrow)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toSiteArrowSVG from '@/assets/img/block/toSiteArrow.svg';
import axios from "axios";

export default{
  name: 'TestResultGuideForToeicProduct',
  props:{
    level: {
      type: String,
      default: undefined,
    },
    type:{
      type: String,
      default: undefined,
    }
  },
  data(){
    return{
      toSiteArrow: toSiteArrowSVG,

      selectedProductTab: 1,
      selectedClassTab: 1,
      guideType: this.type,
      product: [],

      predictText: undefined,
    }
  },
  computed: {
    userName() {
      return this.$store.getters['testManage/name'].length === 0 ? '익명' : this.$store.getters['testManage/name']
    },
    isShowRecommendPublwizProduct() {
      return this.$store.getters['testManage/companyCode'] === 'ybm_publwiz'
      //return true
    }
  },
  async mounted() {
    await this.fetchData()
  },
  methods:{
    async fetchData() {
      const result = await axios.post(
          `${this.$store.getters.apiUrl}/v1/recommend_study_list`,
          {
            level: this.level,
            div: 'toeic',
          }
      )
      console.log('product_list : ', result.data.list);

      this.product = result.data.list;

      if(this.level === 1){
        this.predictText = '500점 미만이';
      } else if(this.level === 2 || this.level === 3){
        this.predictText = '500점대가';
      } else if(this.level === 4 || this.level === 5){
        this.predictText = '600점대가';
      } else if(this.level === 6 || this.level === 7){
        this.predictText = '700점대가';
      } else if(this.level === 8 || this.level === 9){
        this.predictText = '800점대가';
      } else if(this.level === 10){
        this.predictText = '900점대가';
      }
    },
    selectProductTab(num){
      this.selectedProductTab = num;
    },
    selectClassTab(num){
      this.selectedClassTab = num;
    },
  }
}
</script>

<style lang="scss" scoped>
.TestResultGuideForToeicProduct{
  scroll-margin-top: 4.375rem;
  display: flex;
  width: calc(100% - 2.5rem);
  align-self: center;
  justify-content: center;

  background: #fff;
  border-radius: 8px;
  .guideForProduct{
    display: flex;
    width: calc(100% - 2.5rem);
    //width: 100%;
    align-self: center;
    flex-direction: column;
    margin-bottom: 2.5rem;

    .predictionArea{
      display: flex;
      flex-direction: column;

      //width: calc(100% - 2.5rem);
      width: 100%;
      //align-self: center;

      gap: 0.75rem;

      margin-top: 2rem;

      .predictLevel{
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;

        letter-spacing: -0.02em;

        color: #222222;

        word-break: keep-all;

        .predictLevelSpan{
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 24px;
          color: #3E40F4;
        }
      }

      .predictText{
        display: flex;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 24px;

        letter-spacing: -0.02em;
        //align-self: flex-end;

        /* Gray/700 */

        color: #222222;

        word-break: keep-all;
      }
    }

    .guideLabel{
      display: flex;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;

      color: #222222;

      margin-top: 2rem;
    }

    .productTab{
      display: flex;
      width: 100%;
      flex-direction: row;

      .tabProductType{
        display: flex;
        flex-direction: row;

        gap: 0.25rem;
        margin-top: 1.5rem;
        .productUnit{
          display: flex;
          padding: 7px 16px;

          border-radius: 39px;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          /* identical to box height */

          letter-spacing: -0.01em;
        }

        .selected{
          background: #3E40F4;
          color: #FFFFFF;
        }

        .unselected{
          background: #F5F6F7;
          color: #9E9E9E;
        }
      }

      .tabClassType{
        display: flex;
        flex-direction: row;

        margin-left: auto;

        gap: 0.75rem;
        margin-top: 1.5rem;
        align-items: flex-end;

        .classUnit{
          display: flex;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.01em;
        }

        .selected{
          color: #222222;

          text-decoration: underline;
          text-underline-position:under;
        }

        .unselected{
          color: #C2C2C2;

        }
      }
    }

    .productList{
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      width: 100%;
      gap: 1rem;

      .productUnit{
        display: flex;
        width: 100%;
        height: 8rem;
        border-radius: 12px;

        .unitLeftSide{
          display: flex;
          flex-direction: column;
          position: absolute;
          width: calc(100% - 10.5rem);
          height: auto;
          margin-left: 1.25rem;

          .unitLeftSideType{
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;

            color: #FFFFFF;
          }

          .unitLeftSideLabel{
            display: -webkit-box;
            -webkit-line-clamp: 2; // 원하는 라인수
            -webkit-box-orient: vertical;
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 22px;

            letter-spacing: -0.02em;

            color: #FFFFFF;

            //margin-top: 1.438rem;

            word-break: break-word;

            text-overflow: ellipsis;
            overflow: hidden;
            margin-top: 2.813rem;
          }

        }

        .unitRightSide{
          display: flex;
          position: absolute;
          width: calc(100% - 4.5rem);
          
          height: 8rem;
          align-items: center;
          justify-content: flex-end;
          padding-right: 1.356rem;
        }
      }

      .unit1{
        background: #008EF5;
        overflow: hidden;

        .productListBack{
          display: flex;
          width: 26rem;
          height: 26rem;

          .productListLogo{
            display: flex;
            width: 26rem;
            height: 26rem;
            border-radius: 50%;
            background: conic-gradient(rgba(255, 255, 255, 0.4) 0deg 290deg, rgba(255, 255, 255, 0) 290deg 360deg);
            opacity: 0.4;
            right: 50px;

            translate: 6rem -1rem;
            transform: rotate(180deg);

            align-items: center;
            justify-content: center;
            
            .Incircle{
              display: flex;
              height: 10.78rem;
              width: 10.78rem;
              border-radius: 50%;
              background: #008EF5;
            }
          }

        }
      }
      .unit2{
        background: #3E40F4;
        overflow: hidden;

        .productListBack{
          display: flex;
          width: 26rem;
          height: 26rem;

          .productListLogo{
            display: flex;
            width: 26rem;
            height: 26rem;
            border-radius: 50%;
            background: conic-gradient(rgba(255, 255, 255, 0.4) 0deg 180deg, rgba(255, 255, 255, 0));
            opacity: 0.4;
            right: 50px;

            translate: 6rem -10rem;
            transform: rotate(180deg);

            align-items: center;
            justify-content: center;
            
            .Incircle{
              display: flex;
              height: 10.78rem;
              width: 10.78rem;
              border-radius: 50%;
              background: #3E40F4;
            }
          }

        }
      }
      .unit3{
        background: #F8546D;
        overflow: hidden;

        .productListBack{
          display: flex;
          width: 26rem;
          height: 26rem;

          .productListLogo{
            display: flex;
            width: 26rem;
            height: 26rem;
            border-radius: 50%;
            background: conic-gradient(rgba(255, 255, 255, 0.4) 0deg 290deg, rgba(255, 255, 255, 0) 290deg 360deg);
            opacity: 0.4;
            right: 50px;

            translate: 6rem -19rem;
            transform: rotate(180deg);

            align-items: center;
            justify-content: center;
            
            .Incircle{
              display: flex;
              height: 10.78rem;
              width: 10.78rem;
              border-radius: 50%;
              background: #F8546D;
            }

            .underCircle{
              display: flex;
              position: absolute;
              border-radius: 50%;
              background: linear-gradient(rgba(255, 255, 255, 0) 0 50%, rgba(255, 255, 255, 0.4) 50% 100%);
              transform: rotate(90deg);
              translate: 0rem -9.51rem;
              width: 7rem;
              height: 7rem;
            }
          }

        }
      }

    }
  }
}

</style>