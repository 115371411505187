<template>
  <div class="InfoSkip">
    <div class="InfoSkipBack"></div>
    <div class="InfoSkipArea">
      <div class="InfoSkipGuide">
        <div class="InfoSkipGuideUp">
          <div class="InfoSkipGuideUpIcon">
            <img class="InfoSkipGuideUpIconImg" :src="$testControl.imgUrl(stopwatch)" />
          </div>
          <div class="InfoSkipGuideUpLabel">
            15초면 충분해요!
          </div>
        </div>
        <div class="InfoSkipGuideDown">
          15초만 시간 내서 선택하시면,<br/>
          홍길동님께 맞는 문제를 출제해 드릴께요!
        </div>
      </div>
      <div class="InfoSkipBtnArea">
        <div class="InfoSkipBtnSkip" @click="skipInfo">아니오, 건너뛸게요</div>
        <div class="InfoSkipBtnCancel" @click="cancelSkip">네, 선택할게요</div>
      </div>
    </div>
  </div>
</template>

<script>
import stopwatchSVG from "@/assets/img/block/stopwatch.png";

export default {
  name: 'InfoSkip',
  data(){
    return{
      stopwatch: stopwatchSVG
    }
  },
  methods:{
    async skipInfo(){
      console.log('skipInfo');

    this.$emit('skipInfo');
    },
    async cancelSkip(){
      console.log('cancelSkip');

      this.$emit('cancelSkip');
    }
  }
}
</script>

<style lang="scss" scoped>
.InfoSkip{
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: flex-end;
  position: fixed;
  z-index: 10;

  width: 100%;
  height: 100%;

  .InfoSkipBack{
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.7;
    z-index: -1;
  }

  .InfoSkipArea{
    display: flex;
    flex-direction: column;
    //width: 23.438rem;
    width: 100%;
    background: #ffffff;
    border-radius: 1.5rem 1.5rem 0rem 0rem;
    justify-content: center;
    padding: 1.75rem 0;
    animation-name: fadeUp;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    visibility: visible;
    position: absolute;
    bottom: 0;
    @keyframes fadeUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(3.125rem);
        -ms-transform: translateY(3.125rem);
        transform: translateY(3.125rem);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}
    
    

    .InfoSkipGuide{
      display: flex;
      flex-direction: column;

     
      align-items: center;


      .InfoSkipGuideUp{
        display: flex;
        flex-direction: row;
        //width: fit-content;

        .InfoSkipGuideUpIcon{
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .InfoSkipGuideUpIconImg{
          height: 2rem;
          width: 2rem;
        }

        .InfoSkipGuideUpLabel{
          display: flex;
          margin-left: 0.5rem;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 700;
          font-size: 1.5rem;
          /* identical to box height, or 108% */

          text-align: center;

          /* Gray/900 */

          color: #222222;
        }
      }

      .InfoSkipGuideDown{
        display: flex;

        width: fit-content;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        /* or 147% */

        text-align: center;

        /* Gray/700 */

        color: #616161;
        margin-top: 0.75rem;
      }

    }

    .InfoSkipBtnArea{
      display: flex;

      margin-top: 2rem;
      align-self: center;

      .InfoSkipBtnSkip{
        display: flex;
        width: 10.125rem;
        height: 3.5rem;
        background: #EAE9FF;
        border-radius: 0.75rem;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.1875rem;
        text-align: center;

        /* Primary/P500 */

        color: #3E40F4;

        align-items: center;
        justify-content: center;
      }

      .InfoSkipBtnCancel{
        display: flex;

        margin-left: 0.688rem;

        width: 10.125rem;
        height: 3.5rem;
        background: #3E40F4;
        border-radius: 0.75rem;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.1875rem;
        text-align: center;

        /* Primary/P500 */

        color: #FFFFFF;

        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>