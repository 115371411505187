import { createWebHistory, createRouter  } from "vue-router";

import Lobby from '@/components/Lobby.vue';

import Controller from "@/components/Controller.vue";
import Redirection from "@/components/Redirection.vue";

import InfoSelectFirst from '@/components/InfoSelectFirst.vue';
import InfoSelectSecond from '@/components/InfoSelectSecond.vue';
import MicrophoneTest from '@/components/MicrophoneTest.vue';

//import TestListeningWord from '@/components/Test/TestListeningWord.vue';

import TestReadingWord from '@/components/Test/TestReadingWord.vue';
import TestReadingContext from '@/components/Test/TestReadingContext.vue';
import TestListeningNormal from '@/components/Test/TestListeningNormal.vue';
import TestReadingNormal from '@/components/Test/TestReadingNormal.vue';
//import TestWritingContext from '@/components/Test/TestWritingContext.vue';
import TestWritingContext from '@/components/Test/TestWritingContext.vue';
import TestListeningHard from '@/components/Test/TestListeningHard.vue';
import TestListeningContext from '@/components/Test/TestListeningContext.vue';
import TestTalkingText from '@/components/Test/TestTalkingText.vue';
import TestTalkingContext from '@/components/Test/TestTalkingContext.vue';
import TestResult from '@/components/Result/TestResult.vue';
import Enter from "@/components/Enter.vue";
import ControllerForce from "@/components/ControllerForce.vue";

const routes = [
  {
    path: "/",
    name: 'Index',
    component: Controller,
  },
  {
    path: "/lobby",
    name: "lobby",
    component: Lobby,
  },
  {
    path: "/infoSelectFirst",
    name: "infoFirst",
    component: InfoSelectFirst,
  },
  {
    path: "/infoSelectSecond",
    name: "infoSecond",
    component: InfoSelectSecond,
  },
  {
    path: "/microphoneTest",
    name: "microphone",
    component: MicrophoneTest,
  },
  {
    path: "/testReadingWord",
    name: "TestReadingWord",
    component: TestReadingWord,
  },
  {
    path: "/testReadingContext",
    name: "TestReadingContext",
    component: TestReadingContext,
  },
  {
    path: "/testListeningNormal",
    name: "TestListeningNormal",
    component: TestListeningNormal,
  },
  {
    path: "/testReadingNormal",
    name: "TestReadingNormal",
    component: TestReadingNormal,
  },
  {
    path: "/testWritingContext",
    name: "TestWritingContext",
    component: TestWritingContext,
  },
  {
    path: "/testListeningHard",
    name: "TestListeningHard",
    component: TestListeningHard,
  },
  {
    path: "/testListeningContext",
    name: "TestListeningContext",
    component: TestListeningContext,
  },
  {
    path: "/testTalkingText",
    name: "TestTalkingText",
    component: TestTalkingText,
  },
  {
    path: "/testTalkingContext",
    name: "TestTalkingContext",
    component: TestTalkingContext,
  },
  {
    path: "/testResult",
    name: "TestResult",
    component: TestResult,
  },
  {
    path: "/redirection",
    name: "Redirection",
    component: Redirection,
  },
  {
    path: "/enter",
    name: "Enter",
    component: Enter,
  },
  {
    path: '/token/:token',
    name: 'ControllerForce',
    component: ControllerForce,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Controller',
    component: Controller,
  }
]

const router = createRouter({
  routes,
  history: createWebHistory(),
});

export default router;
