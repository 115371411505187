<template>
  <div class="help">
    <div class="helpBack">
    </div>
    <div class="helpClose" @click="closeModel">
      <img class="helpCloseImg" :src="$testControl.imgUrl(outBtn)"/>
    </div>
    <div class="helpArea" @click="toNextHelp">

      <swiper class="mySwiper" ref="mySwiper" :options="swiperOption" :navigation="false" :modules="modules" 
          @slideChange="onSlideChange(idx)" @swiper="onSwiper">
        <swiper-slide>
          <div v-if="helpNum === 1" class="helpUnit">
            <div class="helpAreaUpper">
              <div v-if="helpNum === 1" class="helpVisual">
                <img :src="$testControl.imgUrl(visualFirst)" />
              </div>
            </div>
            <div class="helpAreaDown">
              <div class="helpGuide">
                <div class="helpGuideBold">진행표시줄</div>
                <div class="helpGuideSmall">내가 지금까지 얼마나 풀었는지<br>진행표시줄을 통해 보여줍니다.</div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div v-if="helpNum === 2" class="helpUnit">
            <div class="helpAreaUpper">
              <div v-if="helpNum === 2" class="helpVisual">
                <img class="visualSecond" :src="$testControl.imgUrl(visualSecond)" />
              </div>
            </div>
            <div class="helpAreaDown">
              <div class="helpGuide">
                <div class="helpGuideBold">빈칸채우기</div>
                <div class="helpGuideSmall">단어 선택을 통해 쉽게 빈칸을<br>채우고 취소할 수 있습니다</div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div v-if="helpNum === 3" class="helpUnit">
            <div class="helpAreaUpper">
              <div v-if="helpNum === 3" class="helpVisual">
                <img class="visualThird" :src="$testControl.imgUrl(visualThird)" />
              </div>
            </div>
            <div class="helpAreaDown">
              <div class="helpGuide">
                <div class="helpGuideBold">Listening</div>
                <div class="helpGuideSmall">듣기는 2번만 제공되며<br>음향이 잘 나오는지 기기를 체크해주세요.</div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide>
          <div v-if="helpNum === 4" class="helpUnit">
            <div class="helpAreaUpper">
              <div v-if="helpNum === 4" class="helpVisual">
                <img class="visualFourth" :src="$testControl.imgUrl(visualFourth)" />
              </div>
            </div>
            <div class="helpAreaDown">
              <div class="helpGuide">
                <div class="helpGuideBold">Speaking</div>
                <div class="helpGuideSmall">녹음 버튼을 눌러 중지 버튼으로 변경되었다면<br>녹음을 시작하세요.</div>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper>

      <!--<div class="helpAreaUpper">
        <swiper class="mySwiper" ref="mySwiper" :options="swiperOption" :navigation="false" :modules="modules" 
            @slideChange="onSlideChange(idx)" @swiper="onSwiper">
          <swiper-slide>
            <div v-if="helpNum === 1" class="helpVisual">
              <img :src="visualFisrt" />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div v-if="helpNum === 2" class="helpVisual">
              <img class="visualSecond" :src="visualSecond" />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div v-if="helpNum === 3" class="helpVisual">
              <img class="visualThird" :src="visualThird" />
            </div>
          </swiper-slide>
          <swiper-slide>
            <div v-if="helpNum === 4" class="helpVisual">
              <img class="visualFourth" :src="visualFourth" />
            </div>
          </swiper-slide>
        </swiper>
      </div>

      <div class="helpAreaDown">
        <div v-if="helpNum === 1" class="helpGuide">
          <div class="helpGuideBold">진행표시줄</div>
          <div class="helpGuideSmall">내가 지금까지 얼마나 풀었는지 진행표시줄을 통해 보여줍니다.</div>
        </div>
        <div v-if="helpNum === 2" class="helpGuide">
          <div class="helpGuideBold">빈칸채우기</div>
          <div class="helpGuideSmall">Drag & Drop을 통해 쉽게 빈칸을 채우고 취소할 수 있습니다</div>
        </div>
        <div v-if="helpNum === 3" class="helpGuide">
          <div class="helpGuideBold">Listening</div>
          <div class="helpGuideSmall">듣기는 2번만 제공되며 음향 파형이 움직이는데 소리가 나지 않는 경우 기기를 체크해주세요.</div>
        </div>
        <div v-if="helpNum === 4" class="helpGuide">
          <div class="helpGuideBold">Speaking</div>
          <div class="helpGuideSmall">녹음 버튼을 눌러 중지 버튼으로 변경되었다면 녹음을 시작하세요.</div>
        </div>
      </div>-->
    </div>
    <div class="helpDotArea">
      <div :class="[(helpNum === 1) ? 'helpDotPoint': 'helpDotPointUnselect']" @click="changeDot(1)"></div>
      <div :class="[(helpNum === 2) ? 'helpDotPoint': 'helpDotPointUnselect']" @click="changeDot(2)"></div>
      <div :class="[(helpNum === 3) ? 'helpDotPoint': 'helpDotPointUnselect']" @click="changeDot(3)"></div>
      <div :class="[(helpNum === 4) ? 'helpDotPoint': 'helpDotPointUnselect']" @click="changeDot(4)"></div>
    </div>
  </div>
</template>

<script>
import XOutButton from "@/assets/img/block/XOutButtonHelp.svg"
import helpVisualFirst from "@/assets/img/block/helpVisualFirst.svg"
import helpVisualSecond from "@/assets/img/block/helpVisualSecond.png"
import helpVisualThird from "@/assets/img/block/helpVisualThird.png"
import helpVisualFourth from "@/assets/img/block/helpVisualFourth.png"

import { Swiper, SwiperSlide } from 'swiper/vue';

import { Navigation, Pagination } from 'swiper';
import "swiper/swiper-bundle.css";

export default {
  name: 'Help',
  components:{
    Swiper,
    SwiperSlide,
  },
  data(){
    return{
      outBtn: XOutButton,
      visualFirst: helpVisualFirst,
      visualSecond: helpVisualSecond,
      visualThird: helpVisualThird,
      visualFourth: helpVisualFourth,
      helpNum: 1,
      swiperOption:{
        notNextTick: true,
        setWrapperSize :true,
        autoHeight: true,
      },
      swiper: undefined,
      modules: [Navigation, Pagination],
    }
  },
  methods:{
    async closeModel(){
      this.$emit('closeModal');
    },
    /*async toNextHelp(){
      if(this.helpNum !== 4){
        this.helpNum++;
      }
    },*/
    onSlideChange(idx){
      console.log('onSlideChange', idx);
      //console.log(this.$refs.mySwiper.swiper.activeIndex);
      
      this.helpNum = (this.swiper.activeIndex + 1);
      console.log(this.testGuideNum);
    },
    changeDot(toIdx){
      console.log('changeDot');
      const toIdxTemp = toIdx - 1;
      this.swiper.slideTo(toIdxTemp, 0);
    },
    onSwiper(swiper){
      console.log('onSwiper', swiper);
      this.swiper = swiper;
    }
  }
}
</script>

<style lang="scss" scoped>
.help{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;

  width: 100%;
  height: 100%;

  .helpBack{
    display: flex;
    position: absolute;  
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.8;

    z-index: 10;
  }

  .helpClose{
    display: flex;
    width: 19.688rem;

    justify-content: flex-end;
    margin-bottom: 0.75rem;
    z-index: 20;
    animation-name: fadeDown;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    visibility: visible;   
    @keyframes fadeDown {
      0% {
          opacity: 0;
          -webkit-transform: translateY(2rem);
          -ms-transform: translateY(2rem);
          transform: translateY(2rem);
      }
      100% {
          opacity: 1;
          -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
          transform: translateY(0);
      }
    }

    .helpCloseImg{
      display: flex;
      color: #ffffff;
    }
  }

  .helpArea{
    min-height: 21.875rem;
    //width: calc(100% - 3.75rem);
    width: 19.688rem;

    background: #ffffff;
    border-radius: 24px;

    opacity: 1;
    z-index: 20;
    animation-name: fadeDown;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    visibility: visible;   
    @keyframes fadeDown {
    0% {
        opacity: 0;
        -webkit-transform: translateY(2rem);
        -ms-transform: translateY(2rem);
        transform: translateY(2rem);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}
    .mySwiper{
      display: flex;
      border-radius: 24px 24px 0 0;

      .helpUnit{
        display: flex;
        border-radius: 24px 24px 0 0;
        flex-direction: column;

        .helpAreaUpper{
          display: flex;

          width: 100%;
          min-height: 12.5rem;
          border-radius: 24px 24px 0 0;

          background: #EAE9FF;
          

          .helpVisual{
            display: flex;
            align-items: flex-end;
            justify-items: center;
            justify-content: center;
            width: 100%;

            .visualSecond{
              width: 100%;
              height: 100%;
            }

            .visualThird{
              width: 100%;
              height: 100%;
            }

            .visualFourth{
              align-self: baseline;
              width: 80%;
              height: 80%;
            }
          }
        }

        .helpAreaDown{
          width: 100%;
          min-height: 9.375rem;
          border-radius: 0 0 24px 24px;

          display: flex;
          align-items: center;

          .helpGuide{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .helpGuideBold{
              width: auto;

              font-family: 'Pretendard';
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 26px;

              text-align: center;

              color: #222222;
            }

            .helpGuideSmall{
              
              font-family: 'Pretendard';
              font-style: normal;
              font-weight: 400;
              font-size: 15px;
              line-height: 1.5;

              text-align: center;

              color: #616161;

              margin-top: 0.75rem;
              padding: 0 24px;
            }
          }
        }

      }
    }
  }

  .helpDotArea{
    display: flex;
    flex-direction: row;
    width: 3.625rem;
    height: 0.375rem;

    margin-top: 1.5rem;
    z-index: 20;
    animation-name: fadeUp;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    visibility: visible;   
    @keyframes fadeUp {
    0% {
        opacity: 0;
        -webkit-transform: translateY(3.125rem);
        -ms-transform: translateY(3.125rem);
        transform: translateY(3.125rem);
    }
    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
}

    .helpDotPoint{
      height: 7px;
          width: 7px;
      border-radius: 50%;
      background: #ffffff;

      margin: 0 0.219rem 0 0.219rem;
    }

    .helpDotPointUnselect{
      height: 0.375rem;
      width: 0.375rem;;
      border-radius: 50%;
      background: #ffffff;

      margin: 0 0.219rem 0 0.219rem;
      opacity: 0.3;
    }

  }
}
</style>