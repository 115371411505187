<template>
  <div class="TestListeningContext">
    <Header :num="testListeningContextData.questionNum" :fullCount="quizFullCount"/>
    <div class="box_content">
      <!--<div class="header">
        <div class="headerLogoBox">
          <div class="headerLogo">
            <div class="headerLogoRight"></div>
            <div class="headerLogoIn"></div>
          </div>
        </div>
        <div class="headerText">
          <img src="../../../assets/img/block/titleText.svg"/>
        </div>
        <div class="headerOut">
          <img src="../../../assets/img/block/XOutButton.svg"/>
        </div>
      </div>
      <div class="progressBarArea"> 
        <div class="progressBar"> 
          <div id="bar" class="innerbar"></div>
        </div>       
        <div class="progressBarRunner" id="progressBarRunner"><img src="../../../assets/img/block/runner.svg" /></div>
      </div>-->
      <div class="questionContent">
        <div class="testContentTitle">
          Q{{questionNum}}. 잘 듣고 알맞은 응답을 고르세요.
        </div>
        <div class="audioArea">
          <div class="audioAreaContent">
            <div class="audioAreaTxt">
              남은 듣기 횟수 <div class="audioAreaTxtCount">{{audioTrackCount}}/3</div>
            </div>
            <button class="audioButtonInner" @click="playAudio">
              <img v-if="(!this.audioPlayFlag && this.audioTrackCount > 0)" class="audioButtonImg" id="audioButtonImg" :src="audioButton"/>
              <img v-if="(this.audioPlayFlag && this.audioTrackCount > 0)" class="audioButtonImg" id="audioButtonImg" :src="audioBtnStop"/>
              <img v-if="(this.audioTrackCount === 0)" class="audioButtonImg" id="audioButtonImg" :src="audioButtonFrozen"/>
            </button>
          </div>
        </div>
        <div class="soundWaveBox">
          <canvas class="canvas" ref="canvas" ></canvas>
        </div>
        <div class="testContentAnswer">
          <div v-if="tempSelect==='A'" class="testContentAnswerArea" @click="checkAnswer('A', answer1)">
            <div class="testContentAnswerBtnSelected">A</div>
            <div class="testContentAnswerTxt">{{ answer1 }}</div>
          </div>
          <div v-if="tempSelect!=='A'" class="testContentAnswerAreaUnselect" @click="checkAnswer('A', answer1)">
            <div class="testContentAnswerBtnUnselect">A</div>
            <div class="testContentAnswerTxt">{{ answer1 }}</div>
          </div>

          <div v-if="tempSelect==='B'" class="testContentAnswerArea" @click="checkAnswer('B', answer2)">
            <div class="testContentAnswerBtnSelected">B</div>
            <div class="testContentAnswerTxt">{{ answer2 }}</div>
          </div>
          <div v-if="tempSelect!=='B'" class="testContentAnswerAreaUnselect" @click="checkAnswer('B', answer2)">
            <div class="testContentAnswerBtnUnselect">B</div>
            <div class="testContentAnswerTxt">{{ answer2 }}</div>
          </div>

          <div v-if="tempSelect==='C'" class="testContentAnswerArea" @click="checkAnswer('C', answer3)">
            <div class="testContentAnswerBtnSelected">C</div>
            <div class="testContentAnswerTxt">{{ answer3 }}</div>
          </div>
          <div v-if="tempSelect!=='C'" class="testContentAnswerAreaUnselect" @click="checkAnswer('C', answer3)">
            <div class="testContentAnswerBtnUnselect">C</div>
            <div class="testContentAnswerTxt">{{ answer3 }}</div>
          </div>

          <div v-if="tempSelect==='D'" class="testContentAnswerArea" @click="checkAnswer('D', answer4)">
            <div class="testContentAnswerBtnSelected">D</div>
            <div class="testContentAnswerTxt">{{ answer4 }}</div>
          </div>
          <div v-if="tempSelect!=='D'" class="testContentAnswerAreaUnselect" @click="checkAnswer('D', answer4)">
            <div class="testContentAnswerBtnUnselect">D</div>
            <div class="testContentAnswerTxt">{{ answer4 }}</div>
          </div>
          <!--<div class="testContentAnswerArea" @click="checkAnswer('A', answer1)">
            <div v-if="tempSelect!=='A'" class="testContentAnswerBtnUnselect">A</div>
            <div v-if="tempSelect==='A'" class="testContentAnswerBtnSelected">A</div>
            <div class="testContentAnswerTxt">{{ answer1 }}</div>
          </div>
          <div class="testContentAnswerArea" @click="checkAnswer('B', answer2)">
            <div v-if="tempSelect!=='B'" class="testContentAnswerBtnUnselect">B</div>
            <div v-if="tempSelect==='B'" class="testContentAnswerBtnSelected">B</div>
            <div class="testContentAnswerTxt">{{ answer2 }}</div>
          </div>
          <div class="testContentAnswerArea" @click="checkAnswer('C', answer3)">
            <div v-if="tempSelect!=='C'" class="testContentAnswerBtnUnselect">C</div>
            <div v-if="tempSelect==='C'" class="testContentAnswerBtnSelected">C</div>
            <div class="testContentAnswerTxt">{{ answer3 }}</div>
          </div>
          <div class="testContentAnswerArea" @click="checkAnswer('D', answer4)">
            <div v-if="tempSelect!=='D'" class="testContentAnswerBtnUnselect">D</div>
            <div v-if="tempSelect==='D'" class="testContentAnswerBtnSelected">D</div>
            <div class="testContentAnswerTxt">{{ answer4 }}</div>
          </div>-->
        </div>
        <!--<div class="testContentButtonArea">
          <div v-if="tempSelect !== undefined" class="testContentButtonNext" @click="nextStep(questionNum)">다음 문제</div>
          <div v-if="tempSelect === undefined" class="testContentButtonNextUnselect" @click="nextStep(questionNum)">다음 문제</div>
          <div class="testContentUnder">
            <div class="testContentSkip">모르겠어요</div>
            <div class="testContentHelp"><img class="testContentHelpIcon" src="../../../assets/img/block/helpIcon.svg" />&nbsp;도움말</div>
          </div>
        </div>-->
      </div>
    </div>
    <Footer :readyToNext="readyToNext" @nextStepOrder="nextStep(false)" @doSkip="doSkip" @skipSet="skipSet" :skipDefaultSet="skipDefaultSet"/>
  </div>
</template>

<script>
//import Header from './Header.vue';
import Header from '@/components/Test/Header.vue';
//import Footer from './Footer.vue';
import Footer from '@/components/Test/Footer.vue';

import audioButton from "@//assets/img/block/audioButton.svg";
import audioButtonStop from "@//assets/img/block/audioButtonStop.svg";
import audioButtonFrozen from "@//assets/img/block/audioButtonFrozen.svg";

export default {
  name: 'TestListeningContext',
  components: {
    Header,
    Footer
  },
  props: {
    userId: {
      type: String,
      default: null
    },
    testListeningContextData : {
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      questionNum: this.testListeningContextData.questionNum,
      quizFullCount: this.testListeningContextData.TestQuestionInfo.quizFullCount,
      questionId: this.testListeningContextData.TestQuestionInfo.questionId,
      questionAudio: new Audio(this.testListeningContextData.TestQuestionInfo.audio),
      //questionAudio: new Audio(testAudio1),
      stream: null,
      audioTrackCount: 3,
      audioPlayFlag: false,
      answer1: this.testListeningContextData.TestQuestionInfo.answer1,
      answer2: this.testListeningContextData.TestQuestionInfo.answer2,
      answer3: this.testListeningContextData.TestQuestionInfo.answer3,
      answer4: this.testListeningContextData.TestQuestionInfo.answer4,
      tempSelect: undefined,
      selected: {
        testType: 'TestListeningContext',
        userId: this.userId,
        questionId: undefined,
        select: undefined,
      },
      answer: undefined,
      correctAnswer : this.testListeningContextData.TestQuestionInfo.correctAnswer,

      audioCtx: null,
      analyser: null,
      canvasCtx: null,
      audioSrc: null,
      frequencyData: null,

      canvasH: 0,
      canvasW: 0,
      screenW: 0,
      screenH: 0,

      randomRenderPoint:[], 
      
      readyToNext: false,

      audioButton: audioButton,
      audioBtnStop: audioButtonStop,
      audioButtonFrozen: audioButtonFrozen,
      skipDefaultSet: this.testListeningContextData.skipDefaultSetApp,
    }
  },
  computed: {
    readyToNextCheck: function(){
      console.log('computed readyToNextCheck');

      if(this.tempSelect === undefined){
        return false;
      } else {
        return true;
      }
    }
  },
  watch: {
    testListeningContextData: {
      handler: function (newQuestionSet) {
        console.log('testListeningContextData changed');
        this.questionNum = newQuestionSet.questionNum;
        this.questionId = newQuestionSet.TestQuestionInfo.questionId;
        this.answer1 = newQuestionSet.TestQuestionInfo.answer1;
        this.answer2 = newQuestionSet.TestQuestionInfo.answer2;
        this.answer3 = newQuestionSet.TestQuestionInfo.answer3;
        this.answer4 = newQuestionSet.TestQuestionInfo.answer4;
        this.questionAudio = new Audio(this.testListeningHardData.TestQuestionInfo.audio);
        //this.questionAudio = new Audio(testAudio1);

        document.getElementById("bar").style.width = newQuestionSet.questionNum + "%";
      },
      deep: true
    },
    readyToNextCheck: {
      handler: function (check){
        console.log('readyToNextCheck', check);

        this.readyToNext = check;
      }
    },
  },
  async mounted() {
    await this.setVal();

    this.questionAudio.crossOrigin = "anonymous";
    
    //this.audio = this.$refs.player;
    this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    this.analyser = this.audioCtx.createAnalyser();
    this.canvasCtx = this.$refs.canvas.getContext('2d');
    this.audioSrc = this.audioCtx.createMediaElementSource(this.questionAudio);
    this.audioSrc.connect(this.analyser);
    this.analyser.connect(this.audioCtx.destination);
    this.frequencyData = new Uint8Array(this.analyser.frequencyBinCount);

    this.screenW = window.innerWidth;
    this.screenH = window.innerHeight;
    this.canvasW = this.screenW;
    this.canvasH = (this.screenH / 4);

    let tempRandom = 5;
    let tempK = 5;
    for(let tempR = 0; tempR < 60; tempR++){
      //tempRandom = Math.floor(Math.random() * 120);
      if(24 < tempR && tempR <= 30){
        tempRandom = tempRandom + tempK;
        tempK = tempK + 7;
      }
      if(30 < tempR && tempR <= 34){
        tempRandom = tempRandom - tempK;
        tempK = tempK - 7;
      }
      this.randomRenderPoint.push(tempRandom);
    }

    requestAnimationFrame(this.renderFrame);
  },
  methods: {
    renderFrame() {
      console.log('renderFrame');
      if(this.reRender != undefined){
        requestAnimationFrame(this.renderFrame);
      }
      this.analyser.getByteFrequencyData(this.frequencyData);
      this.canvasCtx.clearRect(0, 0, this.canvasW, this.canvasH);
      //this.canvasCtx.fillStyle = '#F6D565';

      var lingrad = this.canvasCtx.createLinearGradient(0, 75, 300, 75);
      lingrad.addColorStop(0, '#5D62F9');
      //lingrad.addColorStop(0.5, '#fff');
      //lingrad.addColorStop(0.5, '#26C000');
      lingrad.addColorStop(1, '#16C8BE');
      this.canvasCtx.fillStyle = lingrad;

      this.canvasCtx.lineCap = 'round';

      const canvas = this.$refs.canvas;

      if(this.audioTrackCount === 3 && !this.audioPlayFlag){
        for (let i = 0; i < 60; i++) {
          this.canvasCtx.fillRect(i * 5, (canvas.height - this.randomRenderPoint[i]) / 2 , 3, this.randomRenderPoint[i]);
        }
      } else {
        for (let i = 0; i < 1024; i++) {
        
          this.canvasCtx.fillRect(i * 5, (canvas.height/2), 3, 5);
        }
      }

      for (let i = 0; i < this.frequencyData.length; i++) {
        const barHeight = (this.frequencyData[i] / 256 * canvas.height);
        this.canvasCtx.fillRect(i * 5, (canvas.height - barHeight) / 2, 3, barHeight);
      }
    },
    /*updateProgressbar(progressStep) {
      const go = (progressStep * 10) + "%";
      console.log("TestListeningContext updateProgressbar", go);
      document.getElementById("bar").style.width = go;
      document.getElementById("bar").style.height = "100%";

      const progressBarRunnerEl = document.getElementById("progressBarRunner");

      progressBarRunnerEl.style.marginLeft = go;
    },*/
  
    async setVal(){
      console.log("testListeningContextData setVal");
      window.scrollTo({ top: 0});

      /*const progressStep = (this.testListeningContextData !== undefined) ? this.testListeningContextData.questionNum : 3;
      this.updateProgressbar(progressStep);*/
      const element = document.getElementById("audioButtonImg");

       this.questionAudio.onplay = async () => {
        console.log('audio play');
        this.audioPlayFlag = true;
        element.src = "/img/frozenAudioBTN.svg";
      }

      this.questionAudio.onended = () => {
        console.log('audio ended');
        this.audioTrackCount--;
        element.src = "/img/audioBTN.svg";

        cancelAnimationFrame(this.reRender);
        setTimeout(() => {
          this.reRender = undefined;
          this.audioPlayFlag = false;
        }, 1000);
      }

      this.questionAudio.pause = () => {
        console.log('audio paused');
        this.audioPlayFlag = false;
        this.questionAudio.load();
        this.audioTrackCount--;
        element.src = "/img/block/audioButton.svg";

        cancelAnimationFrame(this.reRender);
        setTimeout(() => {
          this.reRender = undefined;
          this.audioPlayFlag = false;
        }, 1000);
      }
    },

    async playAudio(){
      console.log("playAudio", this.questionAudio.played, this.audioTrackCount);

      const requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame ||
                            window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;

      if(this.audioTrackCount > 0){
        if(!this.audioPlayFlag){
          this.questionAudio.play();
          this.reRender = requestAnimationFrame(this.renderFrame);
        } else {
          //console.log('audio is playing. plz wait');
          this.questionAudio.currentTime = 0;
          this.questionAudio.pause();
        }
      } else {
        console.log('audio track is over');
      }
    },

    async checkAnswer(select, val){
      console.log("checkAnswer testContentAnswer" + select, val);

      if(this.tempSelect !== select){
        
        this.answer = val;
        this.tempSelect = select;
      } else if(this.tempSelect === select){
        this.answer = undefined;
        this.tempSelect = undefined;
      }
    },

    async cancelAnswer(){
      console.log("cancelAnswer");

      this.answer = undefined;
      this.tempSelect = undefined;
    },

    async nextStep(skipBool){
      console.log('step : ', this.questionNum, 'skip : ', skipBool);

      if(this.tempSelect === undefined){
        console.log('answer is undefined');
        return;
      } else {
        this.questionAudio.pause();
        this.questionAudio.currentTime = 0;

        cancelAnimationFrame(this.reRender);
        this.reRender = undefined;
        
        this.selected.questionId = this.questionId;
        this.selected.select = this.answer;
        console.log("selected : ", this.selected);

        this.$emit('nextStep', this.selected, this.questionNum, 'TestListeningContext');
        this.answer = undefined;
        this.tempSelect = undefined;

      }

    },
    async doSkip(){
      console.log('doSkip step : ', this.questionNum);
      
      this.selected.questionId = this.questionId;
      this.selected.select = "SKIP_ANSWER";
      console.log("selected : ", this.selected);

      this.$emit('nextStep', this.selected, this.questionNum, 'TestListeningContext');
      this.answer = undefined;
      this.tempSelect = undefined;
    },
    async skipSet(){
      console.log('skipSet');

      this.$emit('skipSet');
    }
  },

}
</script>

<style lang="scss" scoped>
@import "scssCode.scss";

.TestListeningContext {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;

  .box_content {
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;

    /*.header {
      display: flex;

      //height: 100%;
      height: 6rem;
      width: auto;

      align-items: center;

      margin-left: 1.25rem;
      margin-right: 1.25rem;
      //margin-right: 1.5rem;

      .headerLogoBox{
        display: flex;

        width: 1.875rem;
        height: 1.875rem;

        align-items: center;

        .headerLogo{
          display: flex;
          flex-direction: column;

          width: 1.529rem;
          height: 1.529rem;

          background: conic-gradient(#3E40F4, #FFFFFF);
          transform: rotate(180deg);
          border-radius: 50%;
        
          align-items: center;
          margin: auto;
          //vertical-align: middle;

          .headerLogoIn{
            width: 0.679rem;
            height: 0.679rem;
            background: #FFFFFF;
            //margin: auto auto 0 auto;
            margin: 0 0 auto 0;
            border-radius: 50%;
          }

          .headerLogoRight{
            width: 0.425rem;
            height: 0.425rem;
            background: #3E40F4;
            //margin: 0 auto 0 auto;
            margin: 0 auto 0 auto;
            border-radius: 50%;
          }
        }
      }

      .headerText{
        display: flex;
        align-items: center;
      }

      .headerOut{
        display: flex;
        height: 40%;
        width: auto;
        //margin-left: calc(100% - 12rem);
        margin-left: auto;
      }
      
    }

    .progressBarArea{
      display: flex;
      width: 100%;
      height: 1.25rem;

      align-items: center;

      position: relative;

      .progressBar{
        display: flex;
        height: 0.125rem;
        width: 100%;
        //border: 1px solid black;
        background-color: #EAE9FF;

        .innerbar {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #3E40F4;
        }
      }

      .progressBarRunner{
        position: absolute;
        width: 1.25rem;
        height: 1.25rem;
        
      }
    }*/

    .questionContent{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      width: auto;

      .testContentTitle{
        display: flex;
        width: calc(100% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-top: 0.625rem;
        margin-bottom: 1rem;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 1.5;

        //margin-left: 1.25rem;
        /* Gray/900 */
        color: #222222;
      }
    }

    .audioArea{
      display: flex;

      width: calc(100% - 2.5rem);
      margin-left: 1.25rem;
      margin-right: 1.25rem;

      background: #F5F6F7;
      border-radius: 12px;

      justify-content: center;

      .audioAreaContent{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 12px;

        width: fit-content;

        .audioAreaTxt{
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;

          color: #222222;

          .audioAreaTxtCount{
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;

            display:inline;

            color: #3E40F4;
          }
        }

        .audioButtonInner{
          flex: none;
          order: 1;
          flex-grow: 0;

          height: 3rem;
          width: 4rem;

          border: 0;
          background: #F5F6F7;
        }

      }
    }

    .soundWaveBox{
      display: flex;

      width: calc(100% - 2.5rem);
      margin-left: 1.25rem;
      margin-right: 1.25rem;
      align-items: center;
      justify-content: center;

      margin-top: 2rem;
      margin-bottom: 2rem;

      background: #fff;

      .canvas{
        width: 100%;
        height: 6rem;
      }

      .soundWaveBoxInner{
        display: flex;
        width: 100%;
      }
    }

    .testContentAnswer{
      display: flex;
      flex-direction: column;
      
      align-items: flex-start;
      padding: 0px;
      width: 100%;

      //margin-top: 2rem;

      .testContentAnswerAreaUnselect{
        @include defaultTestContentAnswerArea;
      }

      .testContentAnswerArea{
        
        @include defaultTestContentAnswerArea;
        background: #F5F6F7;
        //background-size: 100vw;
        
      }
    }

    /*.testContentButtonArea{
      display: flex;
      flex-direction: column;

      margin-top: 4.25rem;
      margin-bottom: 3rem;
      width: 100%;
      justify-content: center;

      .testContentButtonNext{
        display: flex;
        flex-direction: row;
        align-items: center;

        border-radius: 12px;
        background: #3E40F4;

        height: 3.5rem;
        
        justify-content: center;
        padding: 0px;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
        text-align: center;

        color: #FFFFFF;
      }

      .testContentButtonNextUnselect{
        display: flex;
        flex-direction: row;
        align-items: center;

        border-radius: 12px;
        border: 1px solid #DDDDDD;
        background: #FFFFFF;

        height: 3.4rem;
        
        justify-content: center;
        padding: 0px;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 20px;
        text-align: center;

        color: #DDDDDD;
      }

      .testContentUnder{
        display: flex;
        flex-direction: row;
        margin-top: 1.5rem;

        .testContentSkip{
          display: flex;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;

          text-align: center;
          text-decoration-line: underline;

          color: #9E9E9E;
        }

        .testContentHelp{
          display: flex;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;

          text-align: center;
          flex: none;
          order: 1;
          flex-grow: 0;

          color: #9E9E9E;
          align-items: center;

          margin-left: auto;

          .testContentHelpIcon{
            display: flex;

            height: 0.875rem;
            width: 0.875rem;

            flex: none;
            order: 0;
            flex-grow: 0;
          }
        }
      }
    }*/

  }
}
</style>