<template>
  <div class="enter">
    
    <div class="header">
      <div class="headerLogoBox">
        <div class="headerLogo">
          <div class="headerLogoRight"></div>
          <div class="headerLogoIn"></div>
        </div>
      </div>
      <div class="headerText">
        <img class="svgSet" :src="$testControl.imgUrl(titleText)"/>
      </div>
    </div>

    <div class="inner">
      <div class="view">
        <div class="qr_image_frame">
          <img :src="qr_image_url" class="qr_image"/>
        </div>
        <div class="qr_frame" v-if="qr_image_url">
          <div class="qr">모바일에 최적화되어 있습니다. <br/><span class="inText">QR코드를 스캔하여 모바일에서 응시하세요.</span></div>
        </div>
        <div class="button" @click="goLobby" v-if="qr_image_url && !isShowRecommendPublwizProduct">PC로 응시하겠습니다.</div>
      </div>
      <div class="characterDiv">
        <img :src="$testControl.imgUrl(YBMCharacter)" class="YBMCharacter"/>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store/store";
import testControl from "@/function/testControl";

import titleTextSVG from "@/assets/img/block/titleText.svg";
import YBMCharacterSVG from "@/assets/img/block/YBMCharacter.svg";

export default {
  name: "Enter",
  data() {
    return {
      qr_image_url: undefined,
      titleText: titleTextSVG,
      YBMCharacter: YBMCharacterSVG,
    }
  },
  async created() {
    await this.fetchData(
      this.$store.getters['testManage/companyCode'],
      this.$store.getters['testManage/userToken'],
      this.$store.getters['testManage/name'],
      this.$store.getters['testManage/ingangIdx'],
      this.$store.getters['testManage/quizSetToken'],
    )
  },
  mounted() {
  },
  computed: {
    isShowRecommendPublwizProduct() {
      return this.$store.getters['testManage/companyCode'] === 'ybm_publwiz'
    }
  },
  methods: {
    async fetchData(company_code, user_token, name, ingang_idx, quiz_token) {
      let condition = this.$testControl.validateCompanyCode(company_code)
      // condition = this.$testControl.validateUserToken(user_token)

      if (condition) {
        this.$testControl.showAlert(condition)
        return
      }

      const result = await axios.post(
        `${this.$store.getters.apiUrl}/v1/create_qr`,
        {
          company_code,
          user_token,
          name,
          ingang_idx,
          quiz_token,
          page: 'lobby'
        }
      )

      console.log(`qr fetch data: ${JSON.stringify(result)}`)

      if (!result.data.error) {
        console.log(`qr url: ${result.data.qr_url}`)
        this.qr_image_url = result.data.qr_url
      }
    },
    async goLobby() {
      if (this.$store.getters['testManage/route'] !== 'TestResult' && this.$store.getters['testManage/quizSetToken']) {
        const quizSetUUID = this.$store.getters['testManage/quizSetToken']
        const userUUID = this.$store.getters['testManage/userToken']
        const companyCode = this.$store.getters['testManage/companyCode']
        const name = this.$store.getters['testManage/name']

        const result = await this.restartTest(quizSetUUID, companyCode, userUUID, name)
        if (result) return
      }

      store.commit('testManage/setRoute', 'lobby')
    },
    async restartTest(quiz_set_uuid, company_code, user_uuid, name){
      console.log('startTest');

      const res = await axios.post(`${this.$store.getters.apiUrl}/v1/quiz_set_token_info`, {
        company_code: company_code,
        quiz_set_uuid: quiz_set_uuid,
        user_uuid: user_uuid,
        name: name
      })

      console.log(res.data)

      if (res.data.is_finished !== undefined && !res.data.is_finished) {
        console.log('test1')
        this.$store.commit('testManage/setQuizSetToken', res.data.quiz_set_uuid);

        this.$store.commit('testManage/setQuizFullCount', res.data.quiz_count);
        this.$store.commit('testManage/setNowQuizType', res.data.quiz_type);

        this.$store.commit('testManage/setChallengeCount', 1);
        this.$store.commit('testManage/setTryCount', 1);

        this.$store.commit('testManage/setSkip', false);

        await testControl.toNextType(res.data.quiz_type)

        return true
      }

      return false
    }
  }
}
</script>


<style scoped lang="scss">
.enter {
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;

  background: #EAE9FF;

  flex-direction: column;

  .header{
    display: flex;
    height: auto;
    width: auto;

    align-items: center;

    margin-left: 1rem;
    margin-right: 1rem;   
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;

    .headerLogoBox{
      display: flex;

      width: 1.875rem;
      height: 1.875rem;

      align-items: center;

      .headerLogo{
        display: flex;
        flex-direction: column;

        width: 1.529rem;
        height: 1.529rem;

        background: conic-gradient(#3E40F4, #FFFFFF);
        transform: rotate(180deg);
        border-radius: 50%;
      
        align-items: center;
        margin: auto;
        //vertical-align: middle;

        .headerLogoIn{
          width: 0.679rem;
          height: 0.679rem;
          background: #FFFFFF;
          //margin: auto auto 0 auto;
          margin: 0 0 auto 0;
          border-radius: 50%;
        }

        .headerLogoRight{
          width: 0.425rem;
          height: 0.425rem;
          background: #3E40F4;
          //margin: 0 auto 0 auto;
          margin: 0 auto 0 auto;
          border-radius: 50%;
        }
      }
    }

    .headerText{
      display: flex;
      align-items: center;
      width: 6.938rem;
      height: 1.313rem;

      .svgSet{
        width: inherit;
      }
    }

    .headerOut{
      display: flex;
      
      //margin-left: calc(100% - 12rem);
      margin-left: auto;
    }
    
  }

  .inner{
    display: flex;
    margin-left: 8.25rem;
    .view {
      text-align: center;

      .qr_image_frame {
        display: flex;
        justify-content: center;

        width: 20rem;
        height: 20rem;

        .qr_image {
          width: 20rem;
          height: 20rem;
        }
      }

      .button {
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */

        text-align: center;
        letter-spacing: -0.01em;
        text-decoration-line: underline;

        margin-top: 1.5rem;
        margin-bottom: 2.5rem;

        /* Gray/500 */

        color: #9E9E9E;

        &:hover {
          cursor: pointer;
        }
      }

      .qr_frame {
        margin-top: 1.5rem;

        .qr{
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 28px;
          /* or 140% */

          text-align: center;
          letter-spacing: -0.02em;

          /* Gray/900 */

          color: #222222;
          .inText{
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            /* or 140% */

            text-align: center;
            letter-spacing: -0.02em;

            /* Gray/900 */

            color: #3E40F4;

          }
        }
      }
    }

    .characterDiv{
      display: flex;
      height: 100%;
      width: 8.25rem;
      .YBMCharacter{
        display: flex;
        width: 8.25rem;
        height: 8.25rem;
        margin-top : 14rem;
        //align-self: center;
      }
    }
  }
}
</style>