<template>
  <div class="TestResultCheckAnswer">
    <div class="testResultAreaLabel">
      <div class="label">정답 확인</div>
      <div class="text">!응시자가 제출한 답변 음성파일은 3개월간 다시 듣기가 가능합니다.</div>
    </div>
    <div class="testResultUnit" v-for="(item, idx) in testRecord.filter((i) => i.index < openCount)" :key="idx" :data-index="idx" :data-item="item">
      <div v-if="[1, 4, 7].includes(item.quiz_type)" class="testResultUnitContent normal">
        <div class="testResultUpside">
          <div class="testResultQuestionGuide">Q{{item.index}}. {{item.direction}}</div>
          <div class="testResultIscorrect">
            <img v-if="item.correct" :src="$testControl.imgUrl(correct)"/>
            <img v-if="!item.correct" :src="$testControl.imgUrl(uncorrect)"/>
          </div>
        </div>
        <div class="testResultAudio">
          <button class="audioButton" @click="playAudio(item.question_audio_file, `${idx}-1`)">
            <img class="audioButtonImg" :id="`audioButtonImg${idx}-1`" :src="$testControl.imgUrl(audioButton)"/>
          </button>
        </div>
        <div class="testResultSelectAndCorrect">
          <div v-if="item.correct" class="testResultCorrect"><div class="testResultCorrectLabel1">정답</div> <div class="testResultCorrectTxt">{{item.answer}}</div></div>
          <div v-if="!item.correct" class="testResultUncorrect"><div class="testResultCorrectLabel1">정답</div> <div class="testResultCorrectTxt">{{item.answer}}</div></div>
          <div class="testResultSelect"><div class="testResultCorrectLabel2">제출한 답</div><div class="testResultCorrectTxt">{{item.user_answer}}</div></div>
        </div>
      </div>
      <div v-if="[2, 3, 5, 6].includes(item.quiz_type)" class="testResultUnitContent normal">
        <div class="testResultUpside">
          <div v-if="item.quiz_type === 2" class="testResultQuestionGuide">Q{{item.index}}. {{item.direction}}</div>
          <div v-if="item.quiz_type === 3" class="testResultQuestionGuide">Q{{item.index}}. {{item.direction}}</div>
          <div v-if="item.quiz_type === 5" class="testResultQuestionGuide">Q{{item.index}}. {{item.direction}}</div>
          <div v-if="item.quiz_type === 6" class="testResultQuestionGuide">Q{{item.index}}. {{item.direction}}</div>
          <div class="testResultIscorrect">
            <img v-if="item.correct" :src="$testControl.imgUrl(correct)"/>
            <img v-if="!item.correct" :src="$testControl.imgUrl(uncorrect)"/>
          </div>
        </div>
        <div v-if="item.quiz_type === 2" class="testResultQuestionTextRW">
          {{item.question}}
        </div>
        <div v-if="item.quiz_type === 3" class="testResultQuestionTextRC">
          {{item.question}}
        </div>
        <div v-if="item.quiz_type === 5" class="testResultQuestionTextRN">
          <div class="testResultQuestionTextRNDialog">{{item.dialog1}}</div>
          <div class="testResultQuestionTextRNDialog">{{item.dialog2}}</div>
          <div class="testResultQuestionTextRNDialog">{{item.dialog3}}</div>
          <div class="testResultQuestionTextRNDialog">{{item.dialog4}}</div>
        </div>
        <div v-if="item.quiz_type === 6" class="testResultQuestionTextWC">
          {{item.question}}
        </div>
        <div v-if="item.quiz_type !== 3" class="testResultSelectAndCorrect">
          <div v-if="item.correct" class="testResultCorrect"><div class="testResultCorrectLabel1">정답</div> <div class="testResultCorrectTxt">{{item.answer}}</div></div>
          <div v-if="!item.correct" class="testResultUncorrect"><div class="testResultCorrectLabel1">정답</div> <div class="testResultCorrectTxt">{{item.answer}}</div></div>
          <div class="testResultSelect"><div class="testResultCorrectLabel2">제출한 답</div><div class="testResultCorrectTxt">{{item.user_answer}}</div></div>
        </div>
        <div v-if="item.quiz_type === 3" class="testResultSelectAndCorrect">
          <div v-if="item.correct" class="testResultCorrect"><div class="testResultCorrectLabel1">정답</div> <div class="testResultCorrectTxt">{{item.answer1}}, {{item.answer2}}</div></div>
          <div v-if="!item.correct" class="testResultUncorrect"><div class="testResultCorrectLabel1">정답</div> <div class="testResultCorrectTxt">{{item.answer1}}, {{item.answer2}}</div></div>
          <div class="testResultSelect"><div class="testResultCorrectLabel2">제출한 답</div><div class="testResultCorrectTxt">{{item.user_answer1}}, {{item.user_answer2}}</div></div>
        </div>
      </div>
      <div v-if="[9, 10].includes(item.quiz_type)" :class="[(item.quiz_type === 9) ? 'testResultUnitContent normal' : 'testResultUnitContent']">
        <div class="testResultUpside">
          <div v-if="item.quiz_type === 9" class="testResultQuestionGuide">Q{{item.index}}. {{item.direction}}</div>
          <div v-if="item.quiz_type === 10" class="testResultQuestionGuide">Q{{item.index}}. {{item.direction}}</div>
          <div class="testResultTalkIcon">
            <img class="testResultTalkIconSVG" :src="$testControl.imgUrl(setFaceIcon(item))" />
            <div class="testResultTalkMessage">
              <div class="testResultTalkMessageUp"></div>
              <div class="testResultTalkMessageArea">
                <div class="testResultTalkMessageTxt">{{ setTalkMessage(item) }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="item.quiz_type === 9" class="testResultQuestionTextTT">
          {{item.question}}
        </div>
        <div v-if="item.quiz_type === 10" class="testResultQuestionTextTC">
          {{item.question}}
        </div>
        <div class="testResultTalkingGraphArea" v-if="false">
          <div class="testResultTalkingAssortmentGraph">
            <div class="talkingAssortmentGraphBack" :id="'talkingAssortmentGraphBack'+item.index">
              <div class="talkingAssortmentGraphCircle1" :id="'talkingAssortmentGraphCircle1'+item.index"></div>
              <div class="talkingAssortmentGraphIncircle">
                <div class="talkingAssortmentText">
                  <div class="talkingAssortmentTextLabel">
                    종합
                  </div>
                  <div v-if="item.quiz_type === 9" class="talkingAssortmentTextScore">
                    {{Math.floor((item.pronunciation_score + item.fluency_score) / 2)}}
                  </div>
                  <div v-if="item.quiz_type === 10" class="talkingAssortmentTextScore">
                    {{Math.floor((item.pronunciation_score + item.fluency_score + item.grammar_score + item.relevance_score) / 4)}}
                  </div>
                </div>
              </div>
              <div class="talkingAssortmentGraphCircle2" :id="'talkingAssortmentGraphCircle2'+item.index"></div>
            </div>
          </div>
          <div class="testResultTalkingDetailGraph">
            <div class="testResultTalkingDetailGraphRow">
              <div class="testResultTalkingDetailGraphUnit">
                <div class="testResultTalkingDetailGraphUnitGraph">
                  <div class="testResultTalkingUnitGraphBack" :id="'PronunciationGraphBack'+item.index">
                    <div class="testResultTalkingUnitGraphBackCircle1" :id="'PronunciationGraphCircle1'+item.index"></div>
                    <div class="testResultTalkingUnitGraphBackIncircle">
                      <div class="talkingUnitText">
                        <div class="talkingUnitTextLabel">
                          {{item.pronunciation_score}}
                        </div>
                        <div class="talkingUnitTextScore">
                          발음 정확도
                        </div>
                      </div>
                    </div>
                    <div class="testResultTalkingUnitGraphBackCircle3" :id="'PronunciationGraphCircle3'+item.index"></div>
                    <div class="testResultTalkingUnitGraphBackCircle2" :id="'PronunciationGraphCircle2'+item.index"></div>
                  </div>
                </div>
              </div>
              <div class="testResultTalkingDetailGraphUnit">
                <div class="testResultTalkingDetailGraphUnitGraph">
                  <div class="testResultTalkingUnitGraphBack" :id="'EmphasisGraphBack'+item.index">
                    <div class="testResultTalkingUnitGraphBackCircle1" :id="'EmphasisGraphCircle1'+item.index"></div>
                    <div class="testResultTalkingUnitGraphBackIncircle">
                      <div class="talkingUnitText">
                        <div class="talkingUnitTextLabel">
                          {{item.fluency_score}}
                        </div>
                        <div class="talkingUnitTextScore">
                          유창성
                        </div>
                      </div>
                    </div>
                    <div class="testResultTalkingUnitGraphBackCircle3" :id="'EmphasisGraphCircle3'+item.index"></div>
                    <div class="testResultTalkingUnitGraphBackCircle2" :id="'EmphasisGraphCircle2'+item.index"></div>
                  </div>
                </div>
              </div>
              <div class="testResultTalkingDetailGraphUnit"  v-if="item.quiz_type === 10">
                <div class="testResultTalkingDetailGraphUnitGraph">
                  <div class="testResultTalkingUnitGraphBack" :id="'AccentGraphBack'+item.index">
                    <div class="testResultTalkingUnitGraphBackCircle1" :id="'AccentGraphCircle1'+item.index"></div>
                    <div class="testResultTalkingUnitGraphBackIncircle">
                      <div class="talkingUnitText">
                        <div class="talkingUnitTextLabel">
                          {{item.relevance_score}}
                        </div>
                        <div class="talkingUnitTextScore">
                          관련성
                        </div>
                      </div>
                    </div>
                    <div class="testResultTalkingUnitGraphBackCircle3" :id="'AccentGraphCircle3'+item.index"></div>
                    <div class="testResultTalkingUnitGraphBackCircle2" :id="'AccentGraphCircle2'+item.index"></div>
                  </div>
                </div>
              </div>
            </div>
            <!--<div v-if="item.quiz_type === 10" class="testResultTalkingDetailGraphRow">
              <div class="testResultTalkingDetailGraphUnit">
                <div class="testResultTalkingDetailGraphUnitGraph">
                  <div class="testResultTalkingUnitGraphBack" :id="'TimeGraphBack'+item.index">
                    <div class="testResultTalkingUnitGraphBackCircle1" :id="'TimeGraphCircle1'+item.index"></div>
                    <div class="testResultTalkingUnitGraphBackIncircle">
                      <div class="talkingUnitText">
                        <div class="talkingUnitTextLabel">
                          {{item.grammar_score}}
                        </div>
                        <div class="talkingUnitTextScore">
                          문법
                        </div>
                      </div>
                    </div>
                    <div class="testResultTalkingUnitGraphBackCircle3" :id="'TimeGraphCircle3'+item.index"></div>
                    <div class="testResultTalkingUnitGraphBackCircle2" :id="'TimeGraphCircle2'+item.index"></div>
                  </div>
                </div>
              </div>
            </div>-->
          </div>
        </div>
        <div class="talkingTestAudio">
          <div v-if="item.quiz_type === 9" class="talkingTestCorrectAudio">
            <div class="talkingTestCorrectText">모범 답변</div>
            <div class="talkingTestCorrectAudioBtn">
              <button class="audioButton" @click="playAudio(item.right_audio_file, `${idx}-1`)">
                <img class="audioButtonImg" :id="`audioButtonImg${idx}-1`" :src="$testControl.imgUrl(audioButton)"/>
              </button>
            </div>
          </div>
          <div class="talkingTestRecordAudio">
            <div class="talkingTestCorrectText" v-if="item.audio_file !== undefined && item.audio_file !== ''">제출한 답변</div>
            <div class="talkingTestCorrectText" v-if="item.audio_file === undefined || item.audio_file === ''">제출한 답변 없음</div>
            <div class="talkingTestCorrectAudioBtn" v-if="item.audio_file !== undefined && item.audio_file !== ''">
              <button class="audioButton" @click="playAudio(item.audio_file, `${idx}-2`)">
                <img class="audioButtonImg" :id="`audioButtonImg${idx}-2`" :src="$testControl.imgUrl(audioButton)"/>
              </button>
            </div>
            <div class="talkingTestCorrectAudioBtnUnrecord" v-if="item.audio_file === undefined || item.audio_file === ''">
              <button class="audioButton">
                <img class="audioButtonImgUnselect" :src="$testControl.imgUrl(audioBtnStopUnrecord)"/>
              </button></div>
          </div>
        </div>
      </div>
      <div :class="'middleLine'" v-if="idx < (openCount-2)"></div>
    </div>
    <div class="openDiv" @click="changeOpen()">{{(openSeven ? '닫기' : '모두보기')}}<img :src="openSeven ? $testControl.imgUrl(vectorUp) : $testControl.imgUrl(vectorUnder)"/></div>
  </div>
</template>


<script>
import correctSVG from "@/assets/img/block/correct.svg";
import uncorrectSVG from "@/assets/img/block/uncorrect.svg";
import audioButtonSVG from '@/assets/img/block/audioButton.svg';
import audioButtonStopSVG from '@/assets/img/block/audioButtonStop.svg';
import audioButtonStopUnrecordSVG from '@/assets/img/block/audioButtonStopUnrecord.svg';
import vectorUnderSVG from '@/assets/img/block/vectorUnder.svg';
import vectorUpSVG from '@/assets/img/block/vectorUp.svg';
import bigSmileSVG from '@/assets/img/block/big_smile.svg';
import smileSVG from '@/assets/img/block/smile.svg';
import slightlySmileSVG from '@/assets/img/block/slightly_smile.svg';
import sadSVG from '@/assets/img/block/sad.svg';


export default {
  name: 'TestResultCheckAnswer',
  props:{
    testRecord: {
      type: Object,
      default: null,
    }
  },
  data(){
    return{
      testRecordArr: this.testRecord,
      count: this.testRecord.length,

      correct: correctSVG,
      uncorrect: uncorrectSVG,
      audioButton: audioButtonSVG,
      audioBtnStop: audioButtonStopSVG,
      audioBtnStopUnrecord: audioButtonStopUnrecordSVG,
      bigSmile: bigSmileSVG,
      smile: smileSVG,
      slightlySmile: slightlySmileSVG,
      sad: sadSVG,
      audioPlayFlag: false,
      nowPlayAudioIDX: 0,
      nowPlayAudio: undefined,

      talkingAssortment: 90,
      talkingPronunciation: 88,
      talkingEmphasis: 95,
      talkingTime: 78,
      talkingAccent: 82,

      openSeven: false,
      openCount: 8,

      vectorUnder: vectorUnderSVG,
      vectorUp: vectorUpSVG,
    }
  },
  mounted(){
    
  },
  methods:{
    setTalkingTestGraphAssortment(){
      console.log('setTalkingTestGraph');

      for(let i = 0; i < this.testRecordArr.length; i++){
        if([9, 10].includes(this.testRecordArr[i].quiz_type)){

          console.log('말하기 점수 : ', this.testRecordArr[i]);

          const talkingAssortmentGraphBackEl = document.getElementById("talkingAssortmentGraphBack"+this.testRecordArr[i].index);
          const talkingAssortmentGraphCircle1El = document.getElementById("talkingAssortmentGraphCircle1"+this.testRecordArr[i].index);
          const talkingAssortmentGraphCircle2El = document.getElementById("talkingAssortmentGraphCircle2"+this.testRecordArr[i].index);

          const assorment = (this.testRecordArr[i].quiz_type === 9) ? Math.floor((this.testRecordArr[i].pronunciation_score + this.testRecordArr[i].fluency_score) / 2) 
          : Math.floor((this.testRecordArr[i].pronunciation_score + this.testRecordArr[i].fluency_score + this.testRecordArr[i].grammar_score + this.testRecordArr[i].relevance_score) / 4);

          const perTalkingAssortment = assorment / 100;
          const degNum = (360 * perTalkingAssortment);
          const deg = degNum+'deg';

          const circle2DegNum = degNum - 180;
          const circle2Deg = circle2DegNum +'deg';

          console.log('perLevel & deg', perTalkingAssortment, deg);

          console.log('degNum & circle2DegNum & circle2Deg', talkingAssortmentGraphBackEl, degNum, circle2DegNum, circle2Deg);

          talkingAssortmentGraphBackEl.style.backgroundImage  = `conic-gradient(#3E40F4 0deg ${deg}, rgba(255, 255, 255, 0.2) ${deg} 360deg)`; 
          talkingAssortmentGraphCircle1El.style.transform = `rotate(180deg) translateY(3.625rem)`;
          talkingAssortmentGraphCircle2El.style.transform = `rotate(${circle2Deg}) translateY(3.625rem)`;
        }
      }
    },
    setTalkingTestGraphUnit(){
      console.log('setTalkingTestGraphUnit');

      //const arrUnit = ['Pronunciation', 'Emphasis', 'Time', 'Accent'];
      const arrUnit = ['Pronunciation', 'Emphasis', 'Accent'];
      //const arrNum = [this.talkingPronunciation, this.talkingEmphasis, this.talkingTime, this.talkingAccent];

      for(let i = 0; i < this.testRecordArr.length; i++){
        if([9, 10].includes(this.testRecordArr[i].quiz_type)){

          const arrNum = (this.testRecordArr[i].quiz_type === 9) ? [
            this.testRecordArr[i].pronunciation_score,
            this.testRecordArr[i].fluency_score,
          ] : [
            this.testRecordArr[i].pronunciation_score,
            this.testRecordArr[i].fluency_score,
            //this.testRecordArr[i].grammar_score,
            this.testRecordArr[i].relevance_score,
          ];
          const out = (this.testRecordArr[i].quiz_type === 9) ? 2 : 3; 


          for(let j = 0; j<out; j++){

            const unit = arrUnit[j];
            const num = arrNum[j];
          
            const testResultTalkingUnitGraphBackEl = document.getElementById(`${unit}GraphBack`+this.testRecordArr[i].index);
            const testResultTalkingUnitGraphBackCircle1El = document.getElementById(`${unit}GraphCircle1`+this.testRecordArr[i].index);
            const testResultTalkingUnitGraphBackCircle2El = document.getElementById(`${unit}GraphCircle2`+this.testRecordArr[i].index);
            const testResultTalkingUnitGraphBackCircle3El = document.getElementById(`${unit}GraphCircle3`+this.testRecordArr[i].index);

            const pertalking = num / 100;
            const degNum = (180 * pertalking);
            const deg = degNum+'deg';

            const circle2DegNum = degNum - 180;
            const circle2Deg = circle2DegNum + 'deg';
            console.log('perLevel & deg', pertalking, deg);

            console.log('degNum & circle2DegNum & circle2Deg', degNum, circle2DegNum, circle2Deg);

            testResultTalkingUnitGraphBackEl.style.backgroundImage  = `conic-gradient(#3E40F4 0deg ${deg},#E5E5E5 ${deg} 180deg, #FFFFFF 180deg 360deg)`; 
            //testResultTalkingUnitGraphBackCircle1El.style.transform = `rotate(180deg) translateY(2.125rem)`;
            //testResultTalkingUnitGraphBackCircle2El.style.transform = `rotate(${circle2Deg}) translateY(2.125rem)`;
            //testResultTalkingUnitGraphBackCircle3El.style.transform = `rotate(0deg) translateY(2.125rem)`; 

            testResultTalkingUnitGraphBackCircle1El.style.transform = `rotate(180deg) translateY(1.85rem)`;
            testResultTalkingUnitGraphBackCircle2El.style.transform = `rotate(${circle2Deg}) translateY(1.85rem)`;
            testResultTalkingUnitGraphBackCircle3El.style.transform = `rotate(0deg) translateY(1.85rem)`; 
          }
        }
      }
    },
    setFaceIcon(item){
      console.log('setFaceIcon ', item);

      let talkPoint = 0;      
      if(item.quiz_type === 9){
        talkPoint = Math.floor((item.fluency_score + item.pronunciation_score)/2);
      } else if(item.quiz_type === 10){
        talkPoint = Math.floor((item.fluency_score + item.pronunciation_score + item.relevance_score)/3);
      }

      console.log('setFaceIcon talkPoint ', talkPoint);

      if(talkPoint > 70){
        return this.bigSmile;
      } else if(talkPoint <= 70 && talkPoint > 40){
        return this.smile;
      } else if(talkPoint <= 40 && talkPoint > 1){
        return this.slightlySmile;
      } else if(talkPoint === 0){
        return this.sad;
      }
    },
    setTalkMessage(item){
      console.log('setFaceIcon ', item);

      let talkPoint = 0;      
      if(item.quiz_type === 9){
        talkPoint = Math.floor((item.fluency_score + item.pronunciation_score)/2);
      } else if(item.quiz_type === 10){
        talkPoint = Math.floor((item.fluency_score + item.pronunciation_score + item.relevance_score)/3);
      }

      if(talkPoint > 70){
        return 'Excellent';
      } else if(talkPoint <= 70 && talkPoint > 40){
        return 'Good';
      } else if(talkPoint <= 40 && talkPoint > 1){
        return 'Not bad';
      } else if(talkPoint === 0){
        return '진단할 수 없어요.';
      }


    },
    changeOpen(){

      if(this.openSeven){
        this.openCount = 8;
        this.openSeven = false;

        this.$emit('openAnswer', this.openSeven);
      } else {
        this.openCount = this.testRecord.length+1;
        this.openSeven = true;

        this.$emit('openAnswer', this.openSeven);

        setTimeout(()=>{
          //this.setTalkingTestGraphAssortment();
          //this.setTalkingTestGraphUnit();
          console.log('no graph');
        }, 100);
      }
    },
    async playAudio(audio, idx){
      console.log('playAudio : ', audio, idx);
      
      //const audioButtonImgEl = document.getElementById(`audioButtonImg${idx}`);

      if(audio !== undefined){

        if(!this.audioPlayFlag && this.nowPlayAudioIDX === 0){
          this.nowPlayAudio = new Audio(audio);
          this.nowPlayAudio.play();
        } else if(this.audioPlayFlag && this.nowPlayAudioIDX === idx) {
          this.nowPlayAudio.currentTime = 0;
          this.nowPlayAudio.pause();
        } else if(this.audioPlayFlag && this.nowPlayAudioIDX !== idx){
          console.log('재생중인 다른 오디오를 중단해주세요.');
        }

        if(this.nowPlayAudio !== undefined ){
          this.nowPlayAudio.onplay = () => {
            console.log('audio play');
            this.audioPlayFlag = true;
            this.nowPlayAudioIDX = idx;
            const audioButtonImgEl = document.getElementById(`audioButtonImg${this.nowPlayAudioIDX}`);
            audioButtonImgEl.src = this.$testControl.imgUrl(this.audioBtnStop);
          }

          this.nowPlayAudio.onended = () => {
            console.log('audio ended');
            this.audioPlayFlag = false;
            const audioButtonImgEl = document.getElementById(`audioButtonImg${this.nowPlayAudioIDX}`);
            this.nowPlayAudioIDX = 0;
            this.nowPlayAudio = undefined;
            audioButtonImgEl.src = this.$testControl.imgUrl(this.audioButton);
          }

          this.nowPlayAudio.pause = () => {
            console.log('audio paused');
            this.audioPlayFlag = false;
            this.nowPlayAudio.load();
            const audioButtonImgEl = document.getElementById(`audioButtonImg${this.nowPlayAudioIDX}`);
            this.nowPlayAudioIDX = 0;
            this.nowPlayAudio = undefined;
            audioButtonImgEl.src = this.$testControl.imgUrl(this.audioButton);
          }
        }
      } else {
        console.log('녹음되지 않았습니다.');
      }
      
    },
    
  }
}
</script>

<style lang="scss" scoped>
//@import "@/css/testResult/testResultArea.scss";
.TestResultCheckAnswer{
  scroll-margin-top: 4.375rem;
  display: flex;
  flex-direction: column;
  width: calc(100% - 1.875rem);
  align-self: center;

  .testResultAreaLabel{
    display: flex;

    flex-direction: column;

    //width: calc(100% - 0.625rem);
    width: 100%;
    
    margin-top: 1.375rem;

    .label{
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 30px;

      width: 8rem;

      color: #222222;
    }

    .text{
      font-family: "Pretendard";
      font-style: normal;
      font-weight: 400;
      font-size: 0.7rem;
      line-height: 0.8rem;
      color: #222222;

      width: calc(100% - 1rem);

      margin-top: 0.5rem;

      word-break: keep-all;
      align-self: flex-end;
      text-align: end;
    }
  }

  .testResultUnit{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin-top: 1.25rem;

    .testResultUnitContent{
      display: flex;
      flex-direction: column;
      width: calc(100% - 0.625rem);
      height: 100%;

      padding-bottom: 2rem;
      //border-bottom: 1px solid #E5E5E5;

      .testResultUpside{
        display: flex;
        width: 100%;
        flex-direction: row;

        .testResultQuestionGuide{
          display: flex;
          //width: 100%;
          width: auto;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;

          color: #222222;
        }
        
        .testResultIscorrect{
          display: flex;
          margin-left: auto;
        }

        .testResultTalkIcon{
          display: flex;

          align-self: flex-end;
          margin-left: auto;
          flex-direction: column;

          .testResultTalkIconSVG{
            display: flex;
            width: 1.75rem;
            height: 1.75rem;

            align-self: flex-end;
          }

          .testResultTalkMessage{
            display: flex;
            flex-direction: column;
            width: fit-content;
            height: 3.75rem;
            align-self: center;

            .testResultTalkMessageUp{
              display: flex;
              width: 0px;
              height: 0px;
              border-radius: 1px;

              align-self: flex-end;
              margin-right: 0.575rem;

              border-bottom: 0.5rem solid #424242;
              border-top: 0.3rem solid transparent;
              border-left: 0.35rem solid transparent;
              border-right: 0.35rem solid transparent;
            }

            .testResultTalkMessageArea{
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              padding: 8px 12px 8px 12px;
              width: fit-content;
              background: #424242;
              border-radius: 8px;

              .testResultTalkMessageTxt{
                display: flex;
                font-family: 'Pretendard';
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 15.51px;
                /* identical to box height */

                white-space: nowrap;

                text-align: center;

                /* Gray/900 */

                color: #FFFFFF;
              }
            }
          }
        }
      }

      .testResultQuestionTextRW {
        display: flex;
        width: 100%;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;

        text-align: center;

        color: #222222;

        align-items: center;
        justify-content: center;
        margin-top: 1.5rem;
      }

      .testResultQuestionTextRC {
        display: flex;
        width: 100%;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;

        //text-align: center;

        color: #222222;

        align-items: center;
        margin-top: 1.5rem;
      }

      .testResultQuestionTextRN {
        display: flex;
        flex-direction: column;
        width: 100%;

        //align-items: center;
        margin-top: 1.5rem;
        gap: 1.5rem;

        .testResultQuestionTextRNDialog{
          display: flex;
          width: 100%;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;

          color: #222222;
        }
      }

      .testResultQuestionTextWC {
        display: flex;
        width: 100%;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;

        text-align: center;

        color: #222222;

        align-items: center;
        justify-content: center;
        margin-top: 1.5rem;
      }

      .testResultQuestionTextTT{
        display: flex;
        width: 100%;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;

        //text-align: center;

        color: #222222;

        align-items: center;
        justify-content: center;
        margin-top: 1.5rem;
      }

      .testResultQuestionTextTC{
        display: flex;
        width: 100%;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 22px;

        text-align: center;

        color: #222222;

        align-items: center;
        justify-content: center;
        margin-top: 1.5rem;
      }

      .testResultTalkingGraphArea{
        display: flex;
        flex-direction: row;
        width: 100%;
        padding-top: 2rem;
        justify-content: center;

        .testResultTalkingAssortmentGraph{
          display: flex;
          min-width: 7.75rem;
          min-height: 7.75rem;

          align-items: center;

          //background: #E5E5E5;
          //border-radius: 50%;

          .talkingAssortmentGraphBack{
            display: flex;
            width: 7.75rem;
            height: 7.75rem;
            align-items: center;
            justify-content: center;

            //mix-blend-mode: normal;
            border-radius: 50%;
            background: #E5E5E5;
            
            .talkingAssortmentGraphCircle1{
              position: absolute;
              background: #3E40F4;
              border-radius: 50%;
              width: 0.5rem;
              height: 0.5rem;
            }

            .talkingAssortmentGraphIncircle{
              display: flex;
              background:#FFFFFF;
              border-radius: 50%;
              width: calc(100% - 1rem);
              height: calc(100% - 1rem);

              align-items: center;
              justify-content: center;

              .talkingAssortmentText{
                display: flex;
                flex-direction: column;

                .talkingAssortmentTextLabel{
                  display: flex;

                  font-family: 'Pretendard';
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 19px;
                  text-align: center;

                  /* Gray/900 */

                  color: #222222;
                  align-items: center;
                  justify-content: center;
                }

                .talkingAssortmentTextScore{
                  display: flex;

                  font-family: 'Pretendard';
                  font-style: normal;
                  font-weight: 600;
                  font-size: 32px;
                  line-height: 38px;
                  text-align: center;

                  /* Primary/P500 */

                  color: #3E40F4;
                  align-items: center;
                  justify-content: center;
                }
              }
            }

            .talkingAssortmentGraphCircle2{
              position: absolute;
              background: #3E40F4;
              border-radius: 50%;
              width: 0.5rem;
              height: 0.5rem;
            }
          }
          
        }

        .testResultTalkingDetailGraph{
          display: flex;
          //width: calc(100% - 9rem);
          width: fit-content;
          //height: 7.75rem;
          margin-left: 1.25rem;
          
          flex-direction: column;

          .testResultTalkingDetailGraphRow{
            display: flex;
            width: 100%;
            flex-direction: column;

            gap: 0.5rem;

            justify-content: center;

            .testResultTalkingDetailGraphUnit{
              display: flex;
              //width: 50%;
              height: 100%;

              align-items: center;
              justify-content: center;

              .testResultTalkingDetailGraphUnitGraph{
                display: flex;
                //width: 4.75rem;
                //height: 4.75rem;

                width: 4.25rem;
                height: 4.25rem;

                .testResultTalkingUnitGraphBack{
                  display: flex;
                  width: 100%;
                  height: 100%;
                  align-items: center;
                  justify-content: center;

                  //mix-blend-mode: normal;
                  border-radius: 50%;
                  //background: #E5E5E5;
                  //background: conic-gradient(#E5E5E5 0deg 180deg, rgba(255, 255, 255, 0.2) 180deg 360deg);
                  transform: rotate(270deg);

                  .testResultTalkingUnitGraphBackCircle1{
                    position: absolute;
                    background: #3E40F4;
                    border-radius: 50%;
                    width: 0.5rem;
                    height: 0.5rem;
                  }

                  .testResultTalkingUnitGraphBackIncircle{
                    display: flex;
                    background:#FFFFFF;
                    border-radius: 50%;
                    width: calc(100% - 1rem);
                    height: calc(100% - 1rem);

                    align-items: center;
                    justify-content: center;
                    //rotate: 90deg;
                    transform: rotate(90deg);
                    -webkit-transform: rotate(90deg);
                    -moz-transform: rotate(90deg);

                    .talkingUnitText{
                      display: flex;
                      flex-direction: column;

                      .talkingUnitTextLabel{
                        display: flex;

                        font-family: 'Pretendard';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 17px;
                        line-height: 20px;
                        text-align: center;

                        /* Gray/900 */

                        color: #222222;
                        align-items: center;
                        justify-content: center;
                      }

                      .talkingUnitTextScore{
                        display: flex;

                        font-family: 'Pretendard';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 11px;
                        line-height: 13px;
                        text-align: center;

                        /* Primary/P500 */

                        color: #222222;
                        align-items: center;
                        justify-content: center;

                        white-space:nowrap;
                      }
                    }
                  }

                  .testResultTalkingUnitGraphBackCircle2{
                    position: absolute;
                    background: #3E40F4;
                    border-radius: 50%;
                    width: 0.5rem;
                    height: 0.5rem;
                  }

                  .testResultTalkingUnitGraphBackCircle3{
                    position: absolute;
                    background: #E5E5E5;
                    border-radius: 50%;
                    width: 0.5rem;
                    height: 0.5rem;
                  }
                }

              }
            }
          }
        }
      }

      .testResultAudio{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 1.5rem;
      }

      .testResultSelectAndCorrect{
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-top: 2rem;

        .testResultCorrect{
          display: flex;
          width: 100%;
          flex-direction: row;

          align-items: flex-start;
          padding: 0.813rem 1rem;
          gap: 0.5rem;

          background: #EAE9FF;
          border-radius: 8px;

          .testResultCorrectTxt{
            display: flex;
            
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #3E40F4;
          } 

          .testResultCorrectLabel1{
            display: flex;
            min-width: 1.563rem;

            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;

            color: #3E40F4;
          }
        }

        .testResultUncorrect{
          display: flex;
          width: 100%;
          flex-direction: row;

          align-items: flex-start;
          padding: 0.813rem 1rem;
          gap: 0.5rem;

          background: #FDE9EB;
          border-radius: 8px;

          .testResultCorrectTxt{
            display: flex;

            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #FD2448;
          } 

          .testResultCorrectLabel1{
            display: flex;
            min-width: 1.563rem;

            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;

            color: #FD2448;
          }
        }

        .testResultSelect{
          display: flex;
          width: 100%;
          flex-direction: row;

          align-items: flex-start;
          padding: 0.813rem 1rem;
          gap: 8px;

          background: #F5F6F7;
          border-radius: 8px;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #424242;

          margin-top: 0.75rem;

          .testResultCorrectLabel2{
            display: flex;
            min-width: 3.25rem;

            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #424242;
          }

          .testResultCorrectTxt{
            display: flex;
            width: calc(100% - 3.75rem);

            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;

            color: #424242;
          }
        }
      }

      .talkingTestAudio{
        display: flex;
        width: 100%;
        flex-direction: row;
        margin-top: 2rem;

        gap:0.688rem;

        justify-content: center;

        .talkingTestCorrectAudio{
          display: flex;
          width: calc((100% - 0.688rem) / 2);
          flex-direction: row;

          background: #EAE9FF;
          border-radius: 8px;
          padding: 0px 16px;
          gap: 8px;

          align-items: center;
          justify-content: center;

          .talkingTestCorrectText{
            display: flex;
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #3E40F4;
            white-space:nowrap;
            //width: -webkit-fill-available;
          }

          .talkingTestCorrectAudioBtn{
            display: flex;
          }

          .talkingTestCorrectAudioBtnUnrecord{
            display: flex;

            .audioButton{
              display: flex;

              .audioButtonImgUnselect{
                display: flex;
                color: #222222;
              }
            }
          }
        }

        .talkingTestRecordAudio{
          display: flex;
          width: calc((100% - 0.688rem) / 2);

          background: #F5F6F7;
          border-radius: 8px;
          padding: 0px 10px;
          gap: 8px;

          align-items: center;
          justify-content: center;

          .talkingTestCorrectText{
            display: flex;
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #222222;
            white-space:nowrap;
            //width: -webkit-fill-available;
          }

          .talkingTestCorrectAudioBtn{
            display: flex;
          }
        } 
      }
    }

    .middleLine{
      width: 100%;
      border-bottom: 1px solid #E5E5E5;
    }

    /*.normal{
      border-bottom: 1px solid #E5E5E5;
    }*/
  }

  .openDiv{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0.75rem;
    border: 1px solid #DDDDDD;
    border-radius: 8px;
    margin-top: 2rem;
    margin-bottom: 2rem;

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: #222222;

    gap: 0.456rem;
  }

}
</style>