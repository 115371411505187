<template>
  <div class="TestResultProductForPrescription">
    <div class="guideForProductLabel" @click="openArea()">
      <div class="labelLeft"><img :src="$testControl.imgUrl(tablet)" class="labelLeftImg"/><div>약점 극복 처방전</div></div>
      <div class="labelRight"><img v-if="!show" :src="$testControl.imgUrl(vectorUnder)"/><img v-if="show" :src="$testControl.imgUrl(vectorUp)"/></div>
    </div>
    <div class="labelSVG" v-if="!show"><img :src="$testControl.imgUrl(productLabel)" /></div>
    <div class="guideForProduct" v-if="show">
      <div class="productTab">
        <div class="tabProductType">
          <div :class="'productUnit ' + [(selectedProductTab === 1) ? 'selected' : 'unselected']" @click="selectProductTab(1)">인강</div>
          <div v-if="false" :class="'productUnit ' + [(selectedProductTab === 2) ? 'selected' : 'unselected']" @click="selectProductTab(2)">학원</div>
          <div v-if="false" :class="'productUnit ' + [(selectedProductTab === 3) ? 'selected' : 'unselected']" @click="selectProductTab(3)">교재</div>
        </div>
      </div>
      <div class="productList">
        <div v-for="(item, index) in list" class="productUnit" :class="`unit${(index%3) + 1}`" :key="index" @click="$testControl.newLinkPage(item.url_pc, item.url_mobile)">
          <!--<div class="productListBack">
            <div class="productListLogo">
              <div class="Incircle"></div>
            </div>
          </div>-->
          <div class="unitLeftSide">
            <div class="unitLeftSideBtn">[{{item.contentBtn}}]</div>
            <div class="unitLeftSideLabel">{{item.contentLabel}}</div>
            <div v-if="false" class="unitLeftSideType">{{item.contentType}}</div>
          </div>
          <div class="unitRightSide">
            <img :src="$testControl.imgUrl(toSiteArrow)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import toSiteArrowSVG from '@/assets/img/block/toSiteArrowBlack.svg';
import tabletSVG from '@/assets/img/block/tablet.svg';
import vectorUnderSVG from '@/assets/img/block/vectorUnder.svg';
import vectorUpSVG from '@/assets/img/block/vectorUp.svg';
import productLabelSVG from '@/assets/img/block/productLabel.svg';

export default{
  name: 'TestResultProductForPrescription',
  props:{
    product:{
      type: Object,
      default: null,
    },
    level: {
      type: String,
      default: undefined,
    },
    userName: {
      type: String,
      default: undefined,
    },
    weakness: {
      type: Array,
      default: undefined,
    },
  },
  data(){
    return{
      toSiteArrow: toSiteArrowSVG,

      show: true,

      selectedProductTab: 1,
      selectedClassTab: 1,

      tablet: tabletSVG,
      vectorUnder: vectorUnderSVG,
      vectorUp: vectorUpSVG,
      productLabel: productLabelSVG,
    }
  },
  computed: {
    list() {
      let result = []
      this.weakness.map((item) => {
        const list = this.product.filter(target => target.contentBtn === item)
        result = result.concat(list)
      })
      console.log(result)

      return result
    }
  },
  methods:{
    openArea(){
      console.log('openArea');
      this.show = !this.show;
    },
    selectProductTab(num){
      this.selectedProductTab = num;
    },
    selectClassTab(num){
      this.selectedClassTab = num;
    },
  }
}
</script>

<style lang="scss" scoped>
.TestResultProductForPrescription{
  display: flex;
  width: 100%;
  flex-direction: column;
  
  align-self: center;
  justify-content: center;
  background: #fff;
  border-radius: 8px;

  .guideForProductLabel{
    display: flex;
    flex-direction: row;
    width: 100%;

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;

    color: #222222;
    padding: 1rem;

    .labelLeft{
      display: flex;
      gap: 0.5rem;

      .labelLeftImg {
        width: 1.25rem;
        height: 1.25rem;
        display: flex;
        align-self: center;
      }
    }

    .labelRight{
      display: flex;
      margin-left: auto;
    }
  }

  .labelSVG{
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;

    margin-bottom: 0.688rem;

    width: 100%;
  }
  .guideForProduct{
    display: flex;
    width: calc(100% - 2.5rem);
    //width: 100%;
    align-self: center;
    flex-direction: column;
    margin-bottom: 2.5rem;

    .productTab{
      display: flex;
      width: 100%;
      flex-direction: row;

      .tabProductType{
        display: flex;
        flex-direction: row;

        gap: 0.25rem;
        margin-top: 1.5rem;
        .productUnit{
          display: flex;
          padding: 7px 16px;

          border-radius: 39px;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 18px;
          /* identical to box height */

          letter-spacing: -0.01em;
        }

        .selected{
          background: #3E40F4;
          color: #FFFFFF;
        }

        .unselected{
          background: #F5F6F7;
          color: #9E9E9E;
        }
      }

      .tabClassType{
        display: flex;
        flex-direction: row;

        margin-left: auto;

        gap: 0.75rem;
        margin-top: 1.5rem;
        align-items: flex-end;

        .classUnit{
          display: flex;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          letter-spacing: -0.01em;
        }

        .selected{
          color: #222222;

          text-decoration: underline;
          text-underline-position:under;
        }

        .unselected{
          color: #C2C2C2;

        }
      }
    }

    .productList{
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      width: 100%;
      gap: 1rem;

      .productUnit{
        display: flex;
        width: 100%;
        height: 3.25rem;
        border-radius: 12px;

        align-items: center;

        .unitLeftSide{
          display: flex;
          flex-direction: row;
          position: absolute;
          //width: auto;
          width: calc(100% - 10.5rem);
          height: auto;

          //margin-top: 1.375rem;
          margin-left: 1.25rem;
          align-items: center;

          //gap: 0.375rem;

          .unitLeftSideType{
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;

            color: #FFFFFF;

            margin-top: 0.375rem;
          }

          .unitLeftSideLabel{
            display: -webkit-box;
            display: block;
            -webkit-line-clamp: 2; // 원하는 라인수
            -webkit-box-orient: vertical;
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 700;
            font-size: 15px;
            line-height: 22px;

            height: 1.25rem;
            width: calc(100% - 3.35rem);

            color: #222222;

            //margin-top: 0.375rem;

            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap; 	
            word-break: break-all;
          }

          .unitLeftSideBtn{
            //border: 1px solid rgba(255, 255, 255, 0.5);
            //border-radius: 5px;
            //padding: 6px 10px;
            width: 3rem;
            height: auto;

            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 15.6px;

            color: #222222;

            //margin-top: 0.813rem;
          }
        }

        .unitRightSide{
          display: flex;
          position: absolute;
          width: calc(100% - 6.5rem);
          
          height: 3.25rem;
          align-items: center;
          justify-content: flex-end;
          padding-right: 1.356rem;
        }
      }

      .unit1{
        background: #F5F6F7;
        overflow: hidden;

        .productListBack{
          display: flex;
          width: 26rem;
          height: 26rem;

          .productListLogo{
            display: flex;
            width: 26rem;
            height: 26rem;
            border-radius: 50%;
            background: conic-gradient(rgba(255, 255, 255, 0.4) 0deg 290deg, rgba(255, 255, 255, 0) 290deg 360deg);
            opacity: 0.4;
            right: 50px;

            translate: 6rem -1rem;
            transform: rotate(180deg);

            align-items: center;
            justify-content: center;
            
            .Incircle{
              display: flex;
              height: 10.78rem;
              width: 10.78rem;
              border-radius: 50%;
              background: #F5F6F7;
            }
          }

        }
      }
      .unit2{
        background: #F5F6F7;
        overflow: hidden;

        .productListBack{
          display: flex;
          width: 26rem;
          height: 26rem;

          .productListLogo{
            display: flex;
            width: 26rem;
            height: 26rem;
            border-radius: 50%;
            background: conic-gradient(rgba(255, 255, 255, 0.4) 0deg 180deg, rgba(255, 255, 255, 0));
            opacity: 0.4;
            right: 50px;

            translate: 6rem -10rem;
            transform: rotate(180deg);

            align-items: center;
            justify-content: center;
            
            .Incircle{
              display: flex;
              height: 10.78rem;
              width: 10.78rem;
              border-radius: 50%;
              background: #F5F6F7;
            }
          }

        }
      }
      .unit3{
        background: #F5F6F7;
        overflow: hidden;

        .productListBack{
          display: flex;
          width: 26rem;
          height: 26rem;

          .productListLogo{
            display: flex;
            width: 26rem;
            height: 26rem;
            border-radius: 50%;
            background: conic-gradient(rgba(255, 255, 255, 0.4) 0deg 290deg, rgba(255, 255, 255, 0) 290deg 360deg);
            opacity: 0.4;
            right: 50px;

            translate: 6rem -19rem;
            transform: rotate(180deg);

            align-items: center;
            justify-content: center;
            
            .Incircle{
              display: flex;
              height: 10.78rem;
              width: 10.78rem;
              border-radius: 50%;
              background: #F5F6F7;
            }

            .underCircle{
              display: flex;
              position: absolute;
              border-radius: 50%;
              background: linear-gradient(rgba(255, 255, 255, 0) 0 50%, rgba(255, 255, 255, 0.4) 50% 100%);
              transform: rotate(90deg);
              translate: 0rem -9.51rem;
              width: 7rem;
              height: 7rem;
            }
          }

        }
      }

    }
  }
}

</style>