<template>
  <div class="SoundWave">

    <div class="audioArea">
      <div class="audioAreaContent">
        <div class="audioAreaTxt">
          남은 듣기 횟수 <div class="audioAreaTxtCount">{{audioTrackCount}}/2</div>
        </div>
        <button class="audioButtonInner">
          <img v-if="(!this.audioPlayFlag && !this.renderFlag && this.audioTrackCount > 0)" @click="playAudio()" class="audioButtonImg" id="audioButtonPlay" :src="$testControl.imgUrl(audioButton)"/>
          <img v-if="((this.audioPlayFlag || this.renderFlag) && this.audioTrackCount >= 0)" @click="stopAudio()" class="audioButtonImg" id="audioButtonImgStop" :src="$testControl.imgUrl(audioBtnStop)"/>
          <img v-if="(!this.audioPlayFlag && !this.renderFlag && this.audioTrackCount === 0)" class="audioButtonImg" id="audioButtonImgEnd" :src="$testControl.imgUrl(audioButtonFrozen)"/>
        </button>
      </div>
    </div>

    <div class="soundWaveBoxModelI">
      <canvas class="canvas" ref="canvas" ></canvas>
    </div>

  </div>
</template>

<script>
import audioButtonSVG from "@/assets/img/block/audioButton.svg";
import audioButtonStopSVG from "@/assets/img/block/audioButtonStop.svg";
import audioButtonFrozenSVG from "@//assets/img/block/audioButtonFrozen.svg";

export default {
  name: 'SoundWave',
  props: {
    audio: {
      type: Audio,
      default: null
    },
    key:{
      type: Number,
      default: 0
    },
    qOriginalAudio:{
      type: String,
      default:null
    },
  },
  data() {
    return {

      questionAudio: undefined,

      audioButton: audioButtonSVG,
      audioBtnStop: audioButtonStopSVG,
      audioButtonFrozen: audioButtonFrozenSVG,

      audioTrackCount: 2,
      audioPlayFlag: false,
      audioStopBtnFlag: false,

      renderFlag: false,

      audioCtx: null,
      analyser: null,
      canvasCtx: null,
      audioSrc: null,
      frequencyData: null,

      bufferLength:undefined,
      dataArray:[],

      canvasReadonly: undefined,
      canvasH: 0,
      canvasW: 0,
      screenW: 0,
      screenH: 0,

      readyToNext: false,

      arrayBuffer: undefined,
      audioBuffer: undefined,
      rawData: undefined,
      filteredData:[],
      normalizeData: []

    }
  },
  watch: {
  },
  async mounted() {

    if(this.questionAudio === undefined){
      this.questionAudio = this.audio;
    }

    this.setVal();
    await this.setBasicRenderModelI();
  },
  methods:{
    setVal(){
      console.log('SoundWave setVal');
      
      this.questionAudio.onplay = async () => {
        console.log('audio play');
        this.audioPlayFlag = true;
        this.renderFlag = true;
      }

      this.questionAudio.onended = () => {
        console.log('audio ended');

        cancelAnimationFrame(this.renderFrameModelI);
        
        this.reRender = undefined;
        this.audioPlayFlag = false;
        this.renderFlag = false;

        this.audioTrackCount--;
      }

      this.questionAudio.pause = () => {
        console.log('audio paused');
        this.questionAudio.load();

        cancelAnimationFrame(this.renderFrameModelI);
        
        this.reRender = undefined;
        this.audioPlayFlag = false;
        this.renderFlag = false;

        if(this.audioStopBtnFlag) {
          this.audioStopBtnFlag = false;
        } 
      }
    },
    playAudio(){
      console.log("playAudio");

      if(this.audioTrackCount > 0){
        this.questionAudio.play();
        this.reRender = requestAnimationFrame(this.renderFrameModelI);

        this.$emit('playEvent');
      }
    },
    stopAudio(){
      console.log("stopAudio");

      if(this.audioPlayFlag && this.renderFlag){
        this.questionAudio.currentTime = 0;
        this.questionAudio.pause();
        this.audioStopBtnFlag = true;
        this.audioTrackCount--;
      }
    },
    async setBasicRenderModelI(){
      console.log("setBasicRenderModelI");

      // this.questionAudio.crossOrigin = "anonymous";

      if (!this.$refs.canvas) return
      this.canvasReadonly = this.$refs.canvas;

      this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();

      const response = await fetch(this.qOriginalAudio);
      this.arrayBuffer = await response.arrayBuffer();

      this.audioBuffer = await this.audioCtx.decodeAudioData(this.arrayBuffer);
      this.rawData = this.audioBuffer.getChannelData(0)

      const samplesPerSec = 100 // 1초당 표시할 샘플의 수
      const {
        duration, // 오디오 길이 (초 단위)
        sampleRate, // 샘플링 레이트. 보통 48000 또는 44100.
      } = this.audioBuffer;

      const rawData = this.audioBuffer.getChannelData(0); // 첫번쨰 채널의 AudioBuffer
      const totalSamples = duration * samplesPerSec; // 구간 처리 후 전체 샘플 수
      const blockSize = Math.floor(sampleRate / samplesPerSec); // 샘플링 구간 사이즈

      for (let i = 0; i < totalSamples; i++) {
        const blockStart = blockSize * i; // 샘플 구간 시작 포인트
        let blockSum = 0;

        for (let j = 0; j < blockSize; j++) {
          if (rawData[blockStart + j]) {
            blockSum = blockSum + Math.abs(rawData[blockStart + j]); 
          }
        }

        this.filteredData.push(blockSum / blockSize); // 구간 평균치를 결과 배열에 추가
      }

      const peak = Math.max(...this.filteredData);
      const multiplier = Math.pow(peak, -1);

      for(var i = 0; i < this.filteredData.length; i++){
        this.normalizeData[i] = this.filteredData[i] * multiplier
      }

      if (!this.$refs.canvas) return
      this.canvasCtx = this.$refs.canvas.getContext('2d');

      this.screenW = window.innerWidth;
      this.screenH = window.innerHeight;
      this.canvasW = this.screenW;
      this.canvasH = this.canvasReadonly.height;

      requestAnimationFrame(this.renderFrameModelI);
    },
    renderFrameModelI() {
      console.log('renderFrameModelI');

      this.screenW = window.innerWidth;
      this.screenH = window.innerHeight;

      this.canvasW =this.canvasReadonly.width;
      this.canvasH = this.canvasReadonly.height;

      if(this.reRender != undefined){
        requestAnimationFrame(this.renderFrameModelI);
      }

      //this.canvasCtx.fillStyle = '#FFFFFF';
      //this.canvasCtx.fillRect(0, 0, this.canvasW, this.canvasH);

      const barX = this.canvasW / this.normalizeData.length;

      if(!this.audioPlayFlag){

        this.canvasCtx.fillStyle = '#FFFFFF';
        this.canvasCtx.fillRect(0, 0, this.canvasW, this.canvasH);

        var lingrad = this.canvasCtx.createLinearGradient(0, this.canvasH, this.canvasW, this.canvasH);
        lingrad.addColorStop(0, '#5D62F9');
        lingrad.addColorStop(1, '#16C8BE');
        this.canvasCtx.fillStyle = lingrad;
        this.canvasCtx.lineCap = 'round';

        for (let i = 0; i < this.normalizeData.length; i++) {

          const barH = (this.normalizeData[i] * this.canvasH);
          this.canvasCtx.fillRect(i * barX, (this.canvasH - barH) / 2, barX, barH);

        }
      } else {

        const playTime = (this.questionAudio.duration !== 0 ) ? (this.questionAudio.currentTime/this.questionAudio.duration) : 0;
        const playTimePer = playTime * 100;

        /*var lingrad2 = this.canvasCtx.createLinearGradient(0, this.canvasH, this.canvasW, this.canvasH);
        lingrad2.addColorStop(0, '#5D62F9');
        lingrad2.addColorStop(playTime, '#16C8BE');
        this.canvasCtx.fillStyle = lingrad2;
        this.canvasCtx.lineCap = 'round';

        for (let i = 0; i < this.normalizeData.length; i++) {

          const normalizeRealtime = (i / this.normalizeData.length) * 100;

          if(playTimePer > normalizeRealtime){
            const barH = (this.normalizeData[i] * this.canvasH);
            this.canvasCtx.fillRect(i * barX, (this.canvasH - barH) / 2, barX, barH);
          }
        }*/

        this.canvasCtx.fillStyle = '#FFFFFF';
        this.canvasCtx.fillRect(0, 0, this.canvasW, this.canvasH);

        var lingrad2 = this.canvasCtx.createLinearGradient(0, this.canvasH, this.canvasW, this.canvasH);
        lingrad2.addColorStop(0, '#5D62F9');
        lingrad2.addColorStop(1, '#16C8BE');
        this.canvasCtx.fillStyle = lingrad2;
        this.canvasCtx.lineCap = 'round';

        for (let i = 0; i < this.normalizeData.length; i++) {

          const barH = (this.normalizeData[i] * this.canvasH);
          this.canvasCtx.fillRect(i * barX, (this.canvasH - barH) / 2, barX, barH);

        }

        const perWidthByTime = ((this.canvasW / 100) * playTimePer);
        const perWidthByTimeSet = perWidthByTime + (this.canvasW/100);

        this.canvasCtx.beginPath(); 
        this.canvasCtx.moveTo(perWidthByTimeSet, this.canvasH);
        this.canvasCtx.lineTo(perWidthByTimeSet + barX, 0);
        this.canvasCtx.strokeStyle = '#000';
        this.canvasCtx.stroke();
        

      }
    },
  }
}
</script>

<style lang="scss" scoped>
.SoundWave{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .audioArea{
    display: flex;

    width: 100%;

    background: #F5F6F7;
    border-radius: 12px;

    justify-content: center;

    .audioAreaContent{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px;
      gap: 12px;

      width: fit-content;

      .audioAreaTxt{
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        color: #222222;

        .audioAreaTxtCount{
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;

          display:inline;

          color: #3E40F4;
        }
      }

      .audioButtonInner{
        flex: none;
        order: 1;
        flex-grow: 0;

        height: 3rem;
        width: 4rem;

        border: 0;
        background: #F5F6F7;
      }

    }
  }

  .soundWaveBoxModelI{
    display: flex;

    width: 100%;
    align-items: center;
    justify-content: center;

    margin-top: 3.5rem;
    margin-bottom: 3rem;

    background: #fff;

    .canvas{
      width: 100%;
      height: 6rem;
    }
  }
}
</style>