<template>
  <div class="TestReadingContext">
    <Header :num="questionNum" :fullCount="quizFullCount" />
    <div class="box_content">
      <div class="questionContent" ref="questionContent">
        <div class="testContentTitle">
          Q{{questionNum}}. {{ direction }}
        </div>
        <div class="testContentQuestion">
          <p class="testContentQuestionText">{{arrayForQuestionText[0]}}
            <span @click="clickCancelAnswer(0, selectAnswer1TXT[0])" @touchstart="touchStartCancel(0, selectAnswer1TXT[0])">
              <draggable 
              class="selectedAnswerPoint"
              item-key="answer1"
              :group="{ name: 'people', put: selectAnswer1TXTDragFlag }" 
              v-model="selectAnswer1TXT"
              draggable="false"
              >
                <template #item="{ element }">
                  <div v-if="element !== undefined && element !== null" class="selectedAnswerPointTXT">{{element.exam}}</div>
                </template>
              </draggable>
            </span>
            {{arrayForQuestionText[1]}}
            <span @click="clickCancelAnswer(1, selectAnswer2TXT[0])" @touchstart="touchStartCancel(1, selectAnswer2TXT[0])">
              <draggable 
              class="selectedAnswerPoint"
              item-key="answer2"
              :group="{ name: 'people', put: selectAnswer2TXTDragFlag }"
              v-model="selectAnswer2TXT"
              draggable="false"
              >
                <template #item="{ element }">
                  <div v-if="element !== undefined && element !== null" class="selectedAnswerPointTXT">{{element.exam}}</div>
                </template>
              </draggable>
            </span>
            {{arrayForQuestionText[2]}}
          </p>
        </div>

        <div class="testContentAnswerBox">
          <draggable class="testContentAnswerBoxDraggable"
          v-model="testExamList"
          item-Key="words"
          :group="{name:'people', pull: 'clone'}"
          @change="log">
            <template #item="{ element }" >
              <div v-if="element !== undefined && element !== null " :class="'testContentAnswerArea ' + [(element.check) ? 'check':'uncheck']"
              @click="checkAnswerII(element)" @touchstart="touchStart" @touchend="touchEnd(element)"
              >
                <div :class="'testContentAnswer ' + [(element.check) ? 'check':'uncheck']">{{ element.exam }}</div>
                <!--<img v-if="element.check" class="uncheckSVG" :src="uncheck" />-->
              </div>
            </template>
          </draggable>
        </div>
        
      </div>
    </div>
    <Footer :readyToNext="readyToNext" @nextStepOrder="nextStep(false)" @doSkip="doSkip" @skipSet="skipSet" :skipDefaultSet="skipDefaultSet"/>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

import Header from '@/components/Test/Header.vue';
import Footer from '@/components/Test/Footer.vue';
import uncheckSVG from "@/assets/img/block/uncorrect.svg";

export default {
  name: 'TestReadingContext',
  components: {
    draggable,
    Header,
    Footer,
  },
  props: {
  },
  data() {
    return {
      quizData: undefined,
      selectAnswer1TXT: [],
      selectAnswer2TXT: [],
      selectAnswer1Dragable: [],
      selectAnswer2Dragable: [],
      selectAnswerTXT: [undefined, undefined],
      testExamListBasic: undefined,
      draggableList: [],
      dragData: undefined,
      dragIdx: undefined,
      dragableFlag: false,
      selectAnswer1TXTDragFlag: true,
      selectAnswer2TXTDragFlag: true,
      timer: undefined,
      touchTime: 0,
      readyToNext: false,
      uncheck: uncheckSVG,
    }
  },
  computed: {
    readyToNextCheck: function(){
      console.log('computed readyToNextCheck');

      if(this.selectAnswer1TXT.length < 1){
        console.log('answer 1 is undefined');
        return false;
      } else if(this.selectAnswer2TXT.length < 1){
        console.log('answer 2 is undefined');
        return false;
      } else {
        return true;
      }
    },
    direction() {
      return this.quizData ? this.quizData.direction : ''
    },
    questionNum() {
      return this.$store.getters['testManage/nowQuizNum']
    },
    questionId() {
      return this.quizData ? this.quizData.questionId : 0
    },
    quizFullCount() {
      return this.quizData ? this.quizData.quizFinishNumber : 0
    },
    quizType() {
      return this.quizData ? this.quizData.quizType : 0
    },
    arrayForQuestionText() {
      return this.quizData ? this.quizData.arrayForQuestionText : ''
    },
    questionTextOrigin() {
      return this.quizData ? this.quizData.questionTextOrigin : ''
    },
    testExamList() {
      return this.quizData ? this.quizData.examList : []
    },
    skipDefaultSet() {
      return this.$store.getters['testManage/skipSet']
    },

  },
  watch: {
    selectAnswer1TXT:{
      handler: function (newVal) {
        console.log('selectAnswer1TXT', newVal);

        if(newVal.length === 1){
          if(newVal[0].check === true){
            this.selectAnswer1TXT = [];
          } else {
            console.log('selectAnswer1TXT length 1');
            for(let checkAnswer3 = 0; checkAnswer3 < this.testExamList.length; checkAnswer3++) {
              if(this.testExamList[checkAnswer3].exam === this.selectAnswer1TXT[0].exam) {
                this.testExamList[checkAnswer3].check = true;
              }
            }

            this.selectAnswer1TXTDragFlag = false;
            this.selectAnswerTXT[0] = this.selectAnswer1TXT[0];

          }
        } else {
          this.selectAnswer1TXTDragFlag = true;   
          this.selectAnswerTXT[0] = undefined;
        }

        /*if(this.selectAnswer1TXT.length === 1){

          if(newVal[0].exam !== this.selectAnswer2TXT[0].exam){
  
            this.selectAnswer1TXTDragFlag = false;
            this.selectAnswerTXT[0] = this.selectAnswer1TXT[0];

            for(let checkAnswer3 = 0; checkAnswer3 < this.testExamList.length; checkAnswer3++) {
              if(this.testExamList[checkAnswer3].exam === this.selectAnswer1TXT[0].exam) {
                if(this.testExamList[checkAnswer3].check !== true){
                  this.testExamList[checkAnswer3].check = true;
                }
              }
            }

          } else if(this.selectAnswer2TXT.length !== 0 && newVal[0].exam === this.selectAnswer2TXT[0].exam) {
            this.selectAnswer1TXT = [];
          }
          
        } else if(this.selectAnswer1TXT.length === 0){

          this.selectAnswer1TXTDragFlag = true;   
          this.selectAnswerTXT[0] = undefined;
          
        }*/
        
        
      }
    },
    selectAnswer2TXT:{
      handler: function (newVal) {
        console.log('selectAnswer2TXT', newVal);

        if(newVal.length === 1){
          if(newVal[0].check === true){
            this.selectAnswer2TXT = [];
          } else {
            console.log('selectAnswer2TXT length 1');
            for(let checkAnswer4 = 0; checkAnswer4 < this.testExamList.length; checkAnswer4++) {
              if(this.testExamList[checkAnswer4].exam === this.selectAnswer2TXT[0].exam) {
                this.testExamList[checkAnswer4].check = true;
              }
            }

            this.selectAnswer2TXTDragFlag = false;
            this.selectAnswerTXT[0] = this.selectAnswer2TXT[0];

          }
        } else {
          this.selectAnswer2TXTDragFlag = true;   
          this.selectAnswerTXT[0] = undefined;
        }

        /*
        if(this.selectAnswer2TXT.length === 1){

          if(newVal[0].exam !== this.selectAnswer1TXT[0].exam){

            this.selectAnswer2TXTDragFlag = false;
            this.selectAnswerTXT[1] = this.selectAnswer2TXT[0];

            for(let checkAnswer4 = 0; checkAnswer4 < this.testExamList.length; checkAnswer4++) {
              if(this.testExamList[checkAnswer4].exam === this.selectAnswer2TXT[0].exam) {
                this.testExamList[checkAnswer4].check = true;
              }
            }
          } else if(this.selectAnswer1TXT.length !== 0 && newVal[0].exam === this.selectAnswer1TXT[0].exam){
            this.selectAnswer2TXT = [];
          }
        } else if(this.selectAnswer2TXT.length === 0){
          this.selectAnswer2TXTDragFlag = true;   
          this.selectAnswerTXT[1] = undefined;
        }
        */
      }
    },
    readyToNextCheck: {
      handler: function (check){
        console.log('readyToNextCheck', check);

        this.readyToNext = check;
      }
    }
  },
  async mounted() {
    console.log('in here?')
    await this.setVal();
  },
  methods: {
  
    async setVal(){
      console.log("TestReadingWord setVal");
      // window.scrollTo({ top: 0});
      this.$refs.questionContent.scrollIntoView({block: "start"})

      this.quizData = await this.$testControl.fnTestCall();
      //console.log('getData : ', this.quizData);

      this.testExamListBasic = this.testExamList.slice();

      for(var i = 0; i<this.testExamList.length; i++){

        console.log({name: this.testExamList[i], id: i});
        this.draggableList.push(this.testExamList[i]);
      }
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    timerOn(){
      this.timer = setInterval(() => {
        this.touchTime += 100;
      }, 100);
    },
    timerOff(){
      clearInterval(this.timer);
      return this.touchTime;
    },

    touchStart(){
      console.log('touchStart', this.timer);

      this.timerOn();
    },

    touchEnd(item){
      console.log('touchEnd', item);

      const timecheck = this.timerOff();
      //터치와 드레그를 구분하는 터치시간 계산
      if(timecheck < 100 && this.isMobile()){
        setTimeout(()=>{
          this.checkAnswer(item);
          /*if(this.selectAnswer1TXT[0] !== item  && this.selectAnswer2TXT[0] !== item){
            this.checkAnswer(item);
          }*/
        }, 50)
      }
      this.touchTime = 0;
    },

    touchStartCancel(num, selectAnswer){
      if(this.isMobile()){
        console.log('touchStartCancel', this.timer);
        this.touchEndCancel(num, selectAnswer);
      }
    },
    touchEndCancel(num, selectAnswer){
      
      if(this.isMobile()){
        console.log('touchEndCancel');
        setTimeout(()=>{
          this.cancelAnswer(num, selectAnswer);
          
        }, 50)
      }
      this.touchTime = 0;
    },

    async startDrag(event){
      console.log("startDrag", event, event.item.dataset.item);
      this.dragData = event.item.dataset.item;
      this.dragIdx = event.item.dataset.index;
    },

    async onDrop(event) {
      const selectedItem = this.dragData;
      const selectedIdx = this.dragIdx;

      console.log("onDrop", selectedItem, selectedIdx, event);

      this.dragData = undefined;
      this.dragIdx = undefined;
    },
    async log(evt){
      console.log('log : ', evt);
    },
    checkAnswerII(item){
      console.log('mobile check', this.isMobile())

      if(!this.isMobile()){
        this.checkAnswer(item);
      }
    },
    checkAnswer(item){
      console.log("checkAnswer", item, item.check);

      if(item.check === false){

        if(this.selectAnswer1TXT.length === 0){
          this.selectAnswer1TXT[0] = item;
          this.selectAnswer1TXTDragFlag = false;

          this.selectAnswerTXT[0] = this.selectAnswer1TXT[0];

          for(let checkAnswerI = 0; checkAnswerI < this.testExamList.length; checkAnswerI++) {
            if(this.testExamList[checkAnswerI].exam === item.exam) {
              this.testExamList[checkAnswerI].check = true;
            }
          }
        } else if(this.selectAnswer1TXT.length !== 0 && this.selectAnswer2TXT.length === 0){
          this.selectAnswer2TXT[0] = item;
          this.selectAnswer2TXTDragFlag = false;

          this.selectAnswerTXT[0] = this.selectAnswer2TXT[0];

          for(let checkAnswerII = 0; checkAnswerII < this.testExamList.length; checkAnswerII++) {
            if(this.testExamList[checkAnswerII].exam === item.exam) {
              this.testExamList[checkAnswerII].check = true;
            }
          }
        }

      } else if(item.check === true) {
        if(this.selectAnswer1TXT.length !== 0 && this.selectAnswer1TXT[0].exam === item.exam){
          this.cancelAnswer(0, item);
        } else if(this.selectAnswer2TXT.length !== 0 && this.selectAnswer2TXT[0].exam === item.exam){
          this.cancelAnswer(1, item);
        }

      }
    },

    clickCancelAnswer(idx, val){

      if(!this.isMobile()){
        console.log('clickCancelAnswer', idx, val);
        this.cancelAnswer(idx, val);
      }
    },

    cancelAnswer(idx, val){
      console.log("cancelAnswer", idx, val, this.testExamListBasic);
      
      let basicPosition = undefined;

      if(val !== undefined && val !== ''){
        for(let cancelAnswerI = 0; cancelAnswerI < this.testExamListBasic.length; cancelAnswerI++)
        {
          if(this.testExamListBasic[cancelAnswerI].exam === val.exam) {
            basicPosition = cancelAnswerI;
          }
        }
        this.testExamList[basicPosition].check = false;
      }

      if(idx === 0){
        this.selectAnswer1TXT = [];
      } else if(idx === 1){
        this.selectAnswer2TXT = [];
      }

      console.log('set selected : ', this.selectAnswer1, this.selectAnswer2);
      
    },

    async nextStep(skipBool){
      console.log('step : ', this.questionNum);

      if(!this.readyToNext && !skipBool){
        console.log('answer is undefined');
        return;
      } else {
        const nextType = await this.$testControl.saveAnswer(((!skipBool) ? `${this.selectAnswer1TXT[0].exam}**${this.selectAnswer2TXT[0].exam}` : 'SKIP_ANSWER**SKIP_ANSWER'));

        if(nextType === this.quizType){

          this.selectAnswer1TXT = [];
          this.selectAnswer2TXT = [];

          this.setVal();
        } else {
          await this.$testControl.toNextType(nextType);
        }

      }
    },
    async doSkip(){
      console.log('doSkip step : ', this.questionNum);
      //this.readyToNext = true;

      await this.nextStep(true);
    },
    async skipSet(){
      this.skipDefaultSet = true;

      this.$store.commit('testManage/setSkip', true);
      console.log('skipSet', this.$store.getters['testManage/skipSet']);
    }
  }
}
</script>

<style lang="scss" scoped>
.TestReadingContext {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: inherit;
  
  .box_content{
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;
    overflow-y: scroll;
  
    .header {
      display: flex;
      height: 6rem;
      width: auto;

      align-items: center;

      margin-left: 1.25rem;
      margin-right: 1.25rem;
      //margin-right: 1.5rem;

      .headerLogoBox{
        display: flex;

        width: 1.875rem;
        height: 1.875rem;

        align-items: center;

        .headerLogo{
          display: flex;
          flex-direction: column;

          width: 1.529rem;
          height: 1.529rem;

          background: conic-gradient(#3E40F4, #FFFFFF);
          transform: rotate(180deg);
          border-radius: 50%;
        
          align-items: center;
          margin: auto;
          //vertical-align: middle;

          .headerLogoIn{
            width: 0.679rem;
            height: 0.679rem;
            background: #FFFFFF;
            //margin: auto auto 0 auto;
            margin: 0 0 auto 0;
            border-radius: 50%;
          }

          .headerLogoRight{
            width: 0.425rem;
            height: 0.425rem;
            background: #3E40F4;
            //margin: 0 auto 0 auto;
            margin: 0 auto 0 auto;
            border-radius: 50%;
          }
        }
      }

      .headerText{
        display: flex;
        align-items: center;
      }

      .headerOut{
        display: flex;
        height: 40%;
        width: auto;
        //margin-left: calc(100% - 12rem);
        margin-left: auto;
      }
      
    }

    .progressBarArea{
      display: flex;
      width: 100%;
      height: 1.25rem;

      align-items: center;

      position: relative;

      .progressBar{
        display: flex;
        height: 0.125rem;
        width: 100%;
        background-color: #EAE9FF;

        .innerbar {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #3E40F4;
        }
      }

      .progressBarRunner{
        position: absolute;
        width: 1.25rem;
        height: 1.25rem;
        
      }
    }

    .questionContent{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      width: auto;
      margin-left: 1.25rem;
      margin-right: 1.25rem;

      .testContentTitle{
        display: flex;
        width: 100%;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 1.5;

        margin-top: 0.625rem;
        margin-bottom: 1rem;
        color: #222222;
      }
    }

    .testContentQuestion{
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      flex-wrap: wrap;
      padding: 0px;
      gap: 8px;

      width: 100%;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 500;
      font-size: 22px;

      color: #222222;

      margin-bottom: 2rem;
      justify-content: center;


      .testContentQuestionText{

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 22px;

        color: #222222;
        line-height: 1.3;
        span{

          display: inline-flex;
          vertical-align: bottom;

          .selectedAnswerPoint {
            display: flex;
            padding: 0px;
            gap: 8px;

            min-width: 3rem;
            
            border-bottom: 1px solid #222222;

            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            

            align-items: center;
            text-align: center;
            justify-content: center;
            

            .selectedAnswerPointTXT{
              display: flex;

              height: 100%;
              width: 100%;

              font-family: 'Pretendard';
              font-style: normal;
              font-weight: 500; 
              font-size: 21px;

              text-align: center;
              color: #3E40F4;

              padding: 0 0.4rem 0 0.4rem;
              justify-content: center;
              align-items: center;
            }
          }
          
        }
      }
    }

    .testContentAnswerBox{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 8rem;

      .testContentAnswerBoxDraggable{
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        
        .testContentAnswer {font-family: 'Pretendard';}
        .testContentAnswerArea{
          display: flex;
          
          flex-direction: row;
          align-items: flex-start;
          padding: 6px 14px;

          margin-right: 0.8rem;
          margin-bottom: 0.8rem;

          border-radius: 36px;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          align-items: center;

          .uncheckSVG{
            display: flex;
            position: absolute;
          }

          .uncheck{
            color: #222;
          }

          .check{
            color: #aaa;
          }
        }

        .uncheck{
          background: #F5F6F7;
        }

        .check{
          background-color: #f8f8f8;
          justify-content: center;
        }
      }

    }
  }
}
</style>