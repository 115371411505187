/*import { createApp } from 'vue'
import App from './App.vue'

createApp(App).mount('#app')*/

import { createApp } from 'vue'
import App from './App.vue'
import { AVPlugin } from 'vue-audio-visual'
import stores from './store/store.js'
import router from "./router"
import testControlPlugin from './function/testControlPlugin.js'
import vueGtagNextEsm from "vue-gtag-next";

createApp(App).use(vueGtagNextEsm, {property: { id: 'G-1YFZNBCFSC' },}).use(stores).use(AVPlugin).use(router).use(testControlPlugin).mount('#ybm-leveltest-app')