<template>
  <div class="MicrophoneTest" ref="MicrophoneTest">
    <div class="headerArea">
      <div class="header">
        <div class="headerLogoBox" @click="clickLogo()">
          <div class="headerLogo">
            <div class="headerLogoRight"></div>
            <div class="headerLogoIn"></div>
          </div>
        </div>
        <div class="headerText" @click="clickLogo()">
          <img class="svgSet" :src="$testControl.imgUrl(titleText)"/>
        </div>
        <div class="headerRight">
          <div class="headerOut">
            <img :src="$testControl.imgUrl(XOutButton)"
                 v-if="$testControl.isShowCloseButton()"
                 @click.once="$testControl.closePage()"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="guideLabel">
      마이크 테스트
    </div>

    <div class="guideText">
      <p v-html="guideText"></p>
    </div>

    <div class="soundWaveBox" id="soundWaveBox">
      <div class="soundWaveBoxBack" id="soundWaveBoxBack"></div>
      <canvas class="canvas" ref="canvas" id="oscilloscope"></canvas>
    </div>

    <div class="recordingBtnArea">
      <div class="recordingBtnAreaRecCount">
        <div class="recordingBtnAreaRecCountUp">
          <div class="recordingBtnAreaRecCountUpTXT">
            2번의 마이크테스트를 진행합니다. {{recordCounter}}/2
          </div>
        </div>
        <div class="recordingBtnAreaRecCountDown">
        </div>
      </div>
      <div  class="recordingBtnAreaClick">
        <div :class="[checkStep === 3 && recordCounter > 0 ? 'show' : 'hide']" class="recordRetry" @click="retryRecordMt()">
          <div class="recordRetryIcon"><img :src="$testControl.imgUrl(retryRecord)" /></div>
          <div class="recordRetryText">다시 할게요</div>
        </div>
        <div class="recordRetryBtn" @click="record()">
          <img :src="$testControl.imgUrl(recordButtonImage)" />
        </div>
        <div :class="[checkStep === 3 ? 'show' : 'hide']" class="toNext" @click.once="startTest()">
          <div class="toNextIcon"><img :src="$testControl.imgUrl(vector)" /></div>
          <div class="toNextText">잘 들려요</div>
        </div>
      </div>
      <div class="recordingStatGuide">
        {{ recordStatGuide }}
      </div>
    </div>

    <div>
      <audio ref="audio" class="audio" />
    </div>

  </div>
</template>

<script>
import titleTextSVG from "@/assets/img/block/titleText.svg";
import XOutButtonSVG from "@/assets/img/block/XOutButton.svg";

import recordBtnUnclickSVG from "@/assets/img/block/recordBtnUnclick.svg";
import recordBtnClickSVG from "@/assets/img/block/recordBtnClick.svg";
import recordReplaySVG from "@/assets/img/block/recordReplay.svg";
import returnSVG from "@/assets/img/block/VectorRetrun.svg";
import vectorSVG from "@/assets/img/block/Vector.svg";

import axios from 'axios';
import testControl from "@/function/testControl";

export default {
  name: "MicrophoneTest",
  data() {
    return {
      titleText: titleTextSVG,
      XOutButton: XOutButtonSVG,
      checkStep: 1,

      canvasCtx: null,
      frequencyData: null,

      canvasH: 0,
      canvasW: 0,
      screenW: 0,
      screenH: 0,

      randomRenderPoint: [],

      recordBtnUnclick:recordBtnUnclickSVG,
      recordBtnClick:recordBtnClickSVG,
      recordReplay:recordReplaySVG,

      recordingFlag: false,
      recordCounter: 2,
      replayRecordFlag: false,
      retryRecord: returnSVG,
      vector: vectorSVG,

      mediaRecorder: undefined,
      chunks: [],
      AUDIO_TYPES: ["webm", "mp3", "mp4", "x-matroska", "ogg", "wav"],
      AUDIO_CODECS: ["opus", "vorbis", "aac", "mpeg", "mp4a", "pcm"],
      browser_audio_type: undefined,
      browser_audio_codecs: undefined,
      audioCtx: undefined,
      analyser: undefined,
      bufferLength: undefined,
      dataArray: undefined,
      raf: undefined,
      stream: undefined,
      intervalPerSeconds: 4,
      interval: undefined,
      timeOutCount: 0,

      recordStatGuide: '상단 버튼을 누르시면 녹음이 시작됩니다.',
    }
  },
  computed: {
    recordButtonImage() {
      switch (this.checkStep) {
        case 1:
          return recordBtnUnclickSVG
        
        case 2:
          return recordBtnClickSVG

        default:
          return recordReplaySVG
      }
    },
    guideText() {
      switch (this.checkStep) {
        case 1:
          return '아래 녹음 버튼을 누르고<br><span>Hello</span>라고 말해주세요.'
        
        case 2:
          return '이제 말하세요.'

        default:
          return '잘 안들리면 음량을 조절해보세요.'
      }
    },
    recordButtonStatus() {
      switch (this.checkStep) {
        case 1:
          return 'record ready'

        case 2:
          return 'record stop ready'

        default:
          return 'play ready'
      }
    },
  },
  watch: {
    checkStep() {
      const soundWaveBoxEl = document.getElementById('soundWaveBox');
      
      const color = this.checkStep === 1 ? '#fff' : '#F5F6F7'
      soundWaveBoxEl.style.backgroundColor = color;
    }
  },
  unmounted() {
    this.clear()
  },
  async mounted() {
    //console.log('MicrophoneTest',  this.$store.getters.testToken);
    this.$refs.MicrophoneTest.scrollIntoView({block: "start"})
    // window.scrollTo({ top: 0});

    this.initCanvas()
    this.initAudioAnalyzer()
    this.initWebRTC()
  },
  methods:{
    clear() {
      if (this.raf) {
        cancelAnimationFrame(this.raf) 
      }

      this.audioCtx = null
      this.analyser = null

      if (this.stream) {
        this.stream.getAudioTracks().forEach(track => track.stop());
      }
    },
    clickLogo(){
      console.log('click Logo');

      testControl.goRoute('lobby', false);
    },
    initCanvas() {
      this.canvasCtx = this.$refs.canvas.getContext('2d');
      this.screenW = window.innerWidth;
      this.screenH = window.innerHeight;
      this.canvasW = this.screenW;
      this.canvasH = (this.screenH / 4);
    },
    initAudioAnalyzer() {
      this.audioCtx = new AudioContext();
      this.analyser = this.audioCtx.createAnalyser();
      this.analyser.fftSize = 2048;
      this.bufferLength = this.analyser.frequencyBinCount;
      this.dataArray = new Uint8Array(this.bufferLength);
    },
    draw() {
      const canvasCtx = this.canvasCtx
      const canvas = this.$refs.canvas

      this.raf = requestAnimationFrame(this.draw);
      this.analyser.getByteTimeDomainData(this.dataArray);

      canvasCtx.fillStyle = "rgb(200, 200, 200)";
      canvasCtx.fillRect(0, 0, canvas.width, canvas.height);

      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = "rgb(0, 0, 0)";

      canvasCtx.beginPath();

      const sliceWidth = (canvas.width * 1.0) / this.bufferLength;
      let x = 0;

      for (let i = 0; i < this.bufferLength; i++) {
        const v = this.dataArray[i] / 128.0;
        const y = (v * canvas.height) / 2;

        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }

        x += sliceWidth;
      }

      canvasCtx.lineTo(canvas.width, canvas.height / 2);
      canvasCtx.stroke();
    },
    async initWebRTC() {
      for (let i = 0; i < this.AUDIO_TYPES.length; ++i) {
        const audioType = this.AUDIO_TYPES[i];
        for (let j = 0; j < this.AUDIO_CODECS.length; ++j) {
          const audioCodec = this.AUDIO_CODECS[j];
          this.testType(audioType, audioCodec);
        }
      }

      if (navigator.mediaDevices) {
        const constraints = {
          audio: true,
        };

        console.log(`stream: `)
        this.stream = await navigator.mediaDevices.getUserMedia(constraints).catch(() => {
          alert('사용가능한 마이크가 없습니다')
        })

        if (this.stream) {
          const source = this.audioCtx.createMediaStreamSource(this.stream);
          source.connect(this.analyser);
          this.initMediaRecord(this.stream);
        }

        /*
        navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
          const source = this.audioCtx.createMediaStreamSource(stream);
          source.connect(this.analyser);
          this.initMediaRecord(stream);
        });
        */

        this.draw()
      }
    },
    testType(audioType, audioCodec) {
      const mimeType = `audio/${audioType};codecs=${audioCodec}`;
      if (
        this.browser_audio_type === undefined &&
        this.browser_audio_codecs === undefined &&
        MediaRecorder.isTypeSupported(mimeType)
      ) {
        this.browser_audio_type = audioType;
        this.browser_audio_codecs = audioCodec;

        console.log(` ${mimeType}`);
      }
    },
    startRecord() {
      if (this.mediaRecorder) {
        this.mediaRecorder.start();
        this.recordStatGuide = '상단 버튼을 누르시면 녹음이 중지됩니다.';
      }
    },
    stopRecord() {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
        this.recordStatGuide = '상단 버튼을 누르시면 녹음된 음성이 재생됩니다.';
      }
    },
    initMediaRecord(stream) {
      this.mediaRecorder = new MediaRecorder(stream, {
        mimeType: `audio/${this.browser_audio_type};codecs=${this.browser_audio_codecs}`,
      });

      this.mediaRecorder.ondataavailable = (e) => {
        console.log(e);
        this.chunks.push(e.data);
      };
      console.log(this.mediaRecorder);

      this.mediaRecorder.onstop = (e) => {
        console.log(e);
        console.log(this.chunks);
        const blob = new Blob(this.chunks, {
          type: `audio/${this.browser_audio_type};codecs=${this.browser_audio_codecs}`,
        });

        const audioURL = URL.createObjectURL(blob);
        this.$refs.audio.src = audioURL;

        this.chunks = [];
      };
    },
    startRecordTimeOut() {
      this.initTimeout()

      this.interval = setInterval(() => {
        this.timeOutCount--; 

        if (this.timeOutCount <= 0)  {
          this.initTimeout();
          this.record()
        }
      }, 250);
    },
    initTimeout() {
      if (this.interval) {
        console.log('init interval')
        clearInterval(this.interval);
        this.interval = null
      }

      this.setTimeOutSeconds(60)
    },
    setTimeOutSeconds(sec) {
      this.timeOutCount = sec * this.intervalPerSeconds
    },
    async record(){
      console.log("MicrophoneTest record");

      if (this.recordButtonStatus === 'play ready') {
        this.replayRecord()
      }

      if(this.recordCounter > 0){
        switch(this.recordButtonStatus) {
          case 'record ready':
            this.startRecord()
            this.checkStep++
            break

          case 'record stop ready':
            this.stopRecord()
            this.recordCounter--;
            this.checkStep++
            break
        }
      }
    },
    async retryRecordMt(){
      console.log('retryRecordMt');

      if(this.recordCounter > 0){
        this.checkStep = 1;
        this.recordStatGuide = '상단 버튼을 누르시면 녹음이 시작됩니다.';
      }
    },
    async replayRecord(){
      console.log('replayRecord');
      this.$refs.audio.play();

      const soundWaveBoxBackEl = document.getElementById('soundWaveBoxBack');

      console.log('soundWaveBoxEl', soundWaveBoxBackEl);

      soundWaveBoxBackEl.animate([
        {
          width: '0%'
        },
        {
          width: '100%'
        }
      ], 2000);

    },
    async startTest(){
      console.log('startTest');

      await axios.post(`${this.$store.getters.apiUrl}/v1/start`, {
        "token": this.$store.getters['testManage/userToken'],
        "company_code": this.$store.getters['testManage/companyCode'],
        "add_info": this.$store.getters['testManage/ingangIdx']
      }).then((res) => {
        //console.log("startTest axios res : ", res);

        this.$store.commit('testManage/setQuizSetToken', res.data.quiz_set_uuid);

        this.$store.commit('testManage/setQuizFullCount', res.data.quiz_count);
        this.$store.commit('testManage/setNowQuizType', res.data.quiz_type);

        this.$store.commit('testManage/setChallengeCount', res.data.challenge_count);
        this.$store.commit('testManage/setTryCount', res.data.try_count);

        this.$store.commit('testManage/setSkip', false);

      }).then(() => {
        testControl.goRoute('TestReadingWord', false)
      }).catch((err) => {
        console.log("axios err : ", err);
        const detail = err.response.data.detail[0]
        const message = detail.msg
        const errorCode = detail.type === 'server.exception' ? `오류 코드: ${detail.error_code}` : ''
        alert(`${message}\n사용에 불편을 드려 죄송 합니다.\n새로 고침으로 다시 시작 하시거나 오류 코드를 신고해 주세요.\n\n${errorCode}`)
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.MicrophoneTest{
  display: flex;
  flex-direction: column;

  width: 100%;
  height: auto;

  .headerArea{
    display: flex;
    flex-direction: column;

    height: auto;
    width: 100%;

    background: #FFFFFF;

    .header{
      display: flex;
      height: auto;
      width: auto;

      align-items: center;

      margin-left: 16px;
      margin-right: 16px;   
      margin-top: 16px;

      .headerLogoBox{
        display: flex;

        width: 1.875rem;
        height: 1.875rem;

        align-items: center;

        .headerLogo{
          display: flex;
          flex-direction: column;

          width: 1.529rem;
          height: 1.529rem;

          background: conic-gradient(#3E40F4, #FFFFFF);
          transform: rotate(180deg);
          border-radius: 50%;
        
          align-items: center;
          margin: auto;
          //vertical-align: middle;

          .headerLogoIn{
            width: 0.679rem;
            height: 0.679rem;
            background: #FFFFFF;
            //margin: auto auto 0 auto;
            margin: 0 0 auto 0;
            border-radius: 50%;
          }

          .headerLogoRight{
            width: 0.425rem;
            height: 0.425rem;
            background: #3E40F4;
            //margin: 0 auto 0 auto;
            margin: 0 auto 0 auto;
            border-radius: 50%;
          }
        }
      }

      .headerText{
        display: flex;
        align-items: center;
        width: 6.938rem;
        height: 1.313rem;

        .svgSet{
          width: inherit;
        }
      }

      .headerRight{
        display: flex;
        margin-left: auto;

        .headerOut{
          display: flex;         
          //margin-left: calc(100% - 12rem);
          margin-left: 0.5rem;
        }
      }
    }
  }

  .guideLabel{
    display: flex;
    align-self: center;
    margin-top: 64px;
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 800;
    font-size: 36px;
    line-height: 36px;
    letter-spacing: -0.8px;
    text-align: center;
    color: #222222;

  }

  .guideText{
    display: flex;

    align-self: center;
    flex-wrap: wrap;

    margin-top: 40px;

    
    

    word-break: break-word;
    justify-content: center;

    p{
      font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    color: #222222;
    letter-spacing: -0.4px;
    height: 64px;
      ::v-deep span {
        font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.5;
    text-align: center;
    color: #222222;
    letter-spacing: -0.4px;   
    height: 64px; 

        color: #3E40F4;
      }
    }
  }

  .soundWaveBox{
    display: flex;
    width: 100%;
    height: 5.5rem;
    margin-top: 100px;

    order: 0;

    .soundWaveBoxBack{
      display: flex;
      position: absolute;
      height: 5.5rem;
      z-index: 0;
      background: #E5E5E5;
      
    }

    .canvas{
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }

  .recordingBtnArea{
    display: flex;
    flex-direction: column;

    width: 100%;

    align-items: center;
    margin-top: 96px;
    margin-bottom: 24px;

    .recordingBtnAreaRecCount{

      width: fit-content;
      height: 2.375rem;
      margin-bottom: 1rem;
      animation: recordinginfo .35s ease-in alternate;
      animation-iteration-count: 6;
      @keyframes recordinginfo {
        0% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(8px);
        }
      }
      


      .recordingBtnAreaRecCountUp{
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        padding: 10px 14px;

        width: fit-content;
        height: fit-content;

        background: #424242;
        border-radius: 8px;

        .recordingBtnAreaRecCountUpTXT{
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          /* identical to box height */

          text-align: center;

          /* Gray/White */

          color: #FFFFFF;
        }
        
      }

      .recordingBtnAreaRecCountDown{
        justify-content: center;
        width: 0px;
        height: 0px;
        border-radius: 1px;
        transform: matrix(1, 0, 0, -1.1, 0, 0);
        margin: auto;

        border-bottom: 0.5rem solid #424242;
        border-top: 0.5rem solid transparent;
        border-left: 0.5rem solid transparent;
        border-right: 0.5rem solid transparent;
      }
    }

    .recordingBtnAreaClick{
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      .recordRetry{
        display: flex;
        flex-direction: column;
        width: 7rem;
        height: auto;
        margin-right: 2rem;

        .recordRetryIcon{
          display: flex;
          justify-content: center;
          height: auto;
        }

        .recordRetryText{
          display: flex;
          height: auto;

          margin-top: 0.5rem;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          /* identical to box height */

          text-align: center;
          justify-content: center;

          /* Gray/900 */

          color: #222222;

        }
      }

      .recordRetryBtn{
        display: flex;
        align-self: center;
      }

      .toNext{
        display: flex;
        flex-direction: column;
        width: 7rem;
        height: auto;
        margin-left: 2rem;
        justify-content: center;

        .toNextIcon{
          display: flex;
          justify-content: center;
          height: auto;
        }

        .toNextText{
          display: flex;
          height: auto;

          margin-top: 0.5rem;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 22px;
          /* identical to box height */

          text-align: center;
          justify-content: center;

          /* Gray/900 */

          color: #222222;
        }
      }
    }

    .recordingStatGuide{
      display: flex;
      margin-top: 2rem;

      align-self: center;
      
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 22px;
      color: #444;
      letter-spacing: -.4px;

      word-break: keep-all;
    }
  }

  .show {
    visibility: visible;
  }

  .hide {
    visibility: hidden;
  }
}
</style>