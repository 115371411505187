<template>
  <div class="InfoSelectSecond" ref="InfoSelectSecond">
    <div class="headerArea">
      <div class="header">
        <div class="headerLogoBox">
          <div class="headerLogo">
            <div class="headerLogoRight"></div>
            <div class="headerLogoIn"></div>
          </div>
        </div>
        <div class="headerText">
          <img class="svgSet" :src="$testControl.imgUrl(titleText)"/>
        </div>
        <div class="headerRight">
          <div class="headerOut">
            <img :src="$testControl.imgUrl(XOutButton)"
                 v-if="$testControl.isShowCloseButton()"
                 @click="$testControl.closePage()"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="infoGuide">
      <div class="infoGuideLabel">
        해당되는 점수 구간을<br/> 선택해주세요.
      </div>
      <div class="infoGuideTip">
        홍길동님에게 맞는 문제를 출제해 드립니다.
      </div>
    </div>
    <div class="selectArea">
      <div class="selectAreaLabel">TOEIC</div>
      <div class="selectBtnArea">
        <div class="btnRow">
          <div v-if="selectedToeic !== '없음'" class="selectBtn" @click="selectToeic('없음')">없음</div>
          <div v-if="selectedToeic === '없음'" class="selectBtnSelected" @click="selectToeic('없음')">없음</div>

          <div v-if="selectedToeic !== '500점 미만'" class="selectBtn" @click="selectToeic('500점 미만')">500점 미만</div>
          <div v-if="selectedToeic === '500점 미만'" class="selectBtnSelected" @click="selectToeic('500점 미만')">500점 미만</div>

          <div v-if="selectedToeic !== '501~600'" class="selectBtn" @click="selectToeic('501~600')">501~600</div>
          <div v-if="selectedToeic === '501~600'" class="selectBtnSelected" @click="selectToeic('501~600')">501~600</div>
        </div>
        <div class="btnRow">
          <div v-if="selectedToeic !== '601~700'" class="selectBtn" @click="selectToeic('601~700')">601~700</div>
          <div v-if="selectedToeic === '601~700'" class="selectBtnSelected" @click="selectToeic('601~700')">601~700</div>

          <div v-if="selectedToeic !== '701~800'" class="selectBtn" @click="selectToeic('701~800')">701~800</div>
          <div v-if="selectedToeic === '701~800'" class="selectBtnSelected" @click="selectToeic('701~800')">701~800</div>

          <div v-if="selectedToeic !== '801~900'" class="selectBtn" @click="selectToeic('801~900')">801~900</div>
          <div v-if="selectedToeic === '801~900'" class="selectBtnSelected" @click="selectToeic('801~900')">801~900</div>
        </div>
          <div class="btnRow under">
          <div v-if="selectedToeic !== '901~955'" class="selectBtn" @click="selectToeic('901~955')">901~955</div>
          <div v-if="selectedToeic === '901~955'" class="selectBtnSelected" @click="selectToeic('901~955')">901~955</div>

          <div v-if="selectedToeic !== '955 초과'" class="selectBtn" @click="selectToeic('955 초과')">955 초과</div>
          <div v-if="selectedToeic === '955 초과'" class="selectBtnSelected" @click="selectToeic('955 초과')">955 초과</div>
        </div>
      </div>
    </div>
    <div class="selectArea">
      <div class="selectAreaLabel">TOEIC S&W</div>
      <div class="selectBtnArea">
        <div class="btnRow">
          <div v-if="selectedToeicSW !== '없음'" class="selectBtn" @click="selectToeicSW('없음')">없음</div>
          <div v-if="selectedToeicSW === '없음'" class="selectBtnSelected" @click="selectToeicSW('없음')">없음</div>

          <div v-if="selectedToeicSW !== 'NM~NH (0~80)'" class="selectBtn" @click="selectToeicSW('NM~NH (0~80)')">NM~NH<br>(0~80)</div>
          <div v-if="selectedToeicSW === 'NM~NH (0~80)'" class="selectBtnSelected" @click="selectToeicSW('NM~NH (0~80)')">NM~NH<br>(0~80)</div>

          <div v-if="selectedToeicSW !== 'IL (90~100)'" class="selectBtn" @click="selectToeicSW('IL (90~100)')">IL<br>(90~100)</div>
          <div v-if="selectedToeicSW === 'IL (90~100)'" class="selectBtnSelected" @click="selectToeicSW('IL (90~100)')">IL<br>(90~100)</div>
        </div>
        <div class="btnRow">
          <div v-if="selectedToeicSW !== 'IM1~IM3 (110~130)'" class="selectBtn" @click="selectToeicSW('IM1~IM3 (110~130)')">IM1~IM3<br>(110~130)</div>
          <div v-if="selectedToeicSW === 'IM1~IM3 (110~130)'" class="selectBtnSelected" @click="selectToeicSW('IM1~IM3 (110~130)')">IM1~IM3<br>(110~130)</div>

          <div v-if="selectedToeicSW !== 'IH (140~150)'" class="selectBtn" @click="selectToeicSW('IH (140~150)')">IH<br>(140~150)</div>
          <div v-if="selectedToeicSW === 'IH (140~150)'" class="selectBtnSelected" @click="selectToeicSW('IH (140~150)')">IH<br>(140~150)</div>

          <div v-if="selectedToeicSW !== 'AL (160~170)'" class="selectBtn" @click="selectToeicSW('AL (160~170)')">AL<br>(160~170)</div>
          <div v-if="selectedToeicSW === 'AL (160~170)'" class="selectBtnSelected" @click="selectToeicSW('AL (160~170)')">AL<br>(160~170)</div>
        </div>
        <div class="btnRow under">
          <div v-if="selectedToeicSW !== 'AM (180~190)'" class="selectBtn" @click="selectToeicSW('AM (180~190)')">AM<br>(180~190)</div>
          <div v-if="selectedToeicSW === 'AM (180~190)'" class="selectBtnSelected" @click="selectToeicSW('AM (180~190)')">AM<br>(180~190)</div>
          
          <div v-if="selectedToeicSW !== 'AH (200)'" class="selectBtn" @click="selectToeicSW('AH (200)')">AH<br>(200)</div>
          <div v-if="selectedToeicSW === 'AH (200)'" class="selectBtnSelected" @click="selectToeicSW('AH (200)')">AH<br>(200)</div>
        </div>
      </div>
    </div>
    <div class="selectArea">
      <div class="selectAreaLabel">OPIC</div>
      <div class="selectBtnArea">
        <div class="btnRow">
          <div v-if="selectedOpic !== '없음'" class="selectBtn" @click="selectOpic('없음')">없음</div>
          <div v-if="selectedOpic === '없음'" class="selectBtnSelected" @click="selectOpic('없음')">없음</div>

          <div v-if="selectedOpic !== 'Novice Mid 이하'" class="selectBtn" @click="selectOpic('Novice Mid 이하')">Novice Mid<br>이하</div>
          <div v-if="selectedOpic === 'Novice Mid 이하'" class="selectBtnSelected" @click="selectOpic('Novice Mid 이하')">Novice Mid<br>이하</div>

          <div v-if="selectedOpic !== 'Novice High'" class="selectBtn" @click="selectOpic('Novice High')">Novice<br>High</div>
          <div v-if="selectedOpic === 'Novice High'" class="selectBtnSelected" @click="selectOpic('Novice High')">Novice<br>High</div>
        </div>
        <div class="btnRow">
          <div v-if="selectedOpic !== 'Intermediate Low'" class="selectBtn" @click="selectOpic('Intermediate Low')">Intermediate<br>Low</div>
          <div v-if="selectedOpic === 'Intermediate Low'" class="selectBtnSelected" @click="selectOpic('Intermediate Low')">Intermediate<br>Low</div>

          <div v-if="selectedOpic !== 'Intermediate Mid1'" class="selectBtn" @click="selectOpic('Intermediate Mid1')">Intermediate<br>Mid1</div>
          <div v-if="selectedOpic === 'Intermediate Mid1'" class="selectBtnSelected" @click="selectOpic('Intermediate Mid1')">Intermediate<br>Mid1</div>

          <div v-if="selectedOpic !== 'Intermediate Mid2'" class="selectBtn" @click="selectOpic('Intermediate Mid2')">Intermediate<br>Mid2</div>
          <div v-if="selectedOpic === 'Intermediate Mid2'" class="selectBtnSelected" @click="selectOpic('Intermediate Mid2')">Intermediate<br>Mid2</div>
        </div>
        <div class="btnRow under">
          <div v-if="selectedOpic !== 'Intermediate Mid3/High'" class="selectBtn" @click="selectOpic('Intermediate Mid3/High')">Intermediate<br>Mid3/High</div>
          <div v-if="selectedOpic === 'Intermediate Mid3/High'" class="selectBtnSelected" @click="selectOpic('Intermediate Mid3/High')">Intermediate<br>Mid3/High</div>
          
          <div v-if="selectedOpic !== 'Advanced Low 이상'" class="selectBtn" @click="selectOpic('Advanced Low 이상')">Advanced Low<br>이상</div>
          <div v-if="selectedOpic === 'Advanced Low 이상'" class="selectBtnSelected" @click="selectOpic('Advanced Low 이상')">Advanced Low<br>이상</div>
        </div>
      </div>
    </div>
    <div class="pageSet">
      2<div class="pageSetRight">/2</div>
    </div>
    <div :class="'testStartBtnArea' + ((selectedToeic === undefined || selectedToeicSW === undefined || selectedOpic === undefined) ? ' unselected' : ' selected')" @click="toNextInfo()">
      다음
    </div>
    <div class="skip" @click="opneModel">
      건너뛰기
    </div>
    <InfoSkip v-if="skipModal" @skipInfo="skipInfo()" @cancelSkip="cancelSkip()"/>
  </div>
</template>

<script>
import titleTextSVG from "@/assets/img/block/titleText.svg";
import XOutButtonSVG from "@/assets/img/block/XOutButton.svg";
import InfoSkip from "@/components/InfoSkip.vue";

import axios from 'axios';
import testControl from "@/function/testControl";

export default {
  name: 'InfoSelectSecond',
  components:{
    InfoSkip,
  },
  data() {
    return {
      titleText: titleTextSVG,
      XOutButton: XOutButtonSVG,
      selected: {
        selectedToeic: undefined,
        selectedToeicSW: undefined,
        selectedOpic: undefined,
        score: 0,
        level: undefined,
      },
      selectedToeic: undefined,
      selectedToeicSW: undefined,
      selectedOpic: undefined,
      skipModal: false,
    }
  },
  async mounted() {
    this.$refs.InfoSelectSecond.scrollIntoView({block: "start"})
    // window.scrollTo({ top: 0});
    console.log('infoSelectSecond',  this.$store.getters.userSelectedSkillInfo);
    this.selected.level = (this.$store.getters.userSelectedSkillInfo !== undefined) ? this.$store.getters.userSelectedSkillInfo : 'SKIP';
  },
  methods:{
    async selectToeic(level){

      if(this.selectedToeic === level){
        this.selectedToeic = undefined;
      } else {
        this.selectedToeic = level;
      }

      console.log('selectToeic', level);
    },
    async selectToeicSW(level){
      console.log('selectToeicSW', level);

      if(this.selectedToeicSW === level){
        this.selectedToeicSW = undefined;
      } else {
        this.selectedToeicSW = level;
      }
      
    },
    async selectOpic(level){
      console.log('selectOpic', level);

      if(this.selectedOpic === level){
        this.selectedOpic = undefined;
      } else {
        this.selectedOpic = level;
      }

    },
    async createMember(){
      const payload = {
        "score" : this.selected.score,
        "info": this.selected.level,
        "toeic_score":this.selected.selectedToeic,
        "toeic_sw_score":this.selected.selectedToeicSW,
        "opic_score":this.selected.selectedOpic,
      }

      if (this.$store.getters['testManage/userToken']) {
        payload.token = this.$store.getters['testManage/userToken']
      }

      return await axios.post(`${this.$store.getters.apiUrl}/create_member`, payload).then((res) => {
        console.log("axios res : ", res);

        this.$store.commit('setUserSelectedToeicSkillInfo', this.selected.selectedToeic);
        this.$store.commit('setUserSelectedSWSkillInfo', this.selected.selectedToeicSW);
        this.$store.commit('setUserSelectedOPICSkillInfo', this.selected.selectedOpic);

        this.$store.commit('testManage/setUserToken', res.data.token);

        testControl.goRoute('microphone', false)
      }).catch((err) => {
        console.log("axios err : ", err);
      })
    },
    async toNextInfo(){
      console.log('toNextInfo');
      
      if(this.selectedToeic !== undefined && this.selectedToeicSW !== undefined && this.selectedOpic !== undefined){
        this.selected.selectedToeic = this.selectedToeic;
        this.selected.selectedToeicSW = this.selectedToeicSW;
        this.selected.selectedOpic = this.selectedOpic;

        if(this.selectedToeic === '없음') { this.selected.score = -1 }
        else if(this.selectedToeic === '500점 미만') { this.selected.score = 0 }
        else if(this.selectedToeic === '501~600') { this.selected.score = 500 }
        else if(this.selectedToeic === '601~700') { this.selected.score = 600 }
        else if(this.selectedToeic === '701~800') { this.selected.score = 700 }
        else if(this.selectedToeic === '801~900') { this.selected.score = 800 }
        else if(this.selectedToeic === '901~955') { this.selected.score = 900 }
        else if(this.selectedToeic === '955 초과') { this.selected.score = 955 }

        await this.createMember();
      } else {
        console.log('not select');
      }
    },
    async skipInfo(){
      console.log('skipInfo');

      this.selected.selectedToeic = 'SKIP';
      this.selected.selectedToeicSW = 'SKIP';
      this.selected.selectedOpic = 'SKIP';
      this.selected.score = -1;

      this.createMember();
      
    },
    async opneModel(){
      console.log('skipModal', this.skipModal);
      this.skipModal = !this.skipModal;
    },
    async cancelSkip(){
      console.log('cancelSkip');
      this.skipModal = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.InfoSelectSecond{
  display: flex;
  flex-direction: column;

  width: 100%;
  height: auto;

  .headerArea{
    display: flex;
    flex-direction: column;

    height: auto;
    width: 100%;

    background: #FFFFFF;

    .header{
      display: flex;
      height: auto;
      width: auto;

      align-items: center;

      margin-left: 16px;
      margin-right: 16px;   
      margin-top: 16px;

      .headerLogoBox{
        display: flex;

        width: 1.875rem;
        height: 1.875rem;

        align-items: center;

        .headerLogo{
          display: flex;
          flex-direction: column;

          width: 1.529rem;
          height: 1.529rem;

          background: conic-gradient(#3E40F4, #FFFFFF);
          transform: rotate(180deg);
          border-radius: 50%;
        
          align-items: center;
          margin: auto;
          //vertical-align: middle;

          .headerLogoIn{
            width: 0.679rem;
            height: 0.679rem;
            background: #FFFFFF;
            //margin: auto auto 0 auto;
            margin: 0 0 auto 0;
            border-radius: 50%;
          }

          .headerLogoRight{
            width: 0.425rem;
            height: 0.425rem;
            background: #3E40F4;
            //margin: 0 auto 0 auto;
            margin: 0 auto 0 auto;
            border-radius: 50%;
          }
        }
      }

      .headerText{
        display: flex;
        align-items: center;
        width: 6.938rem;
        height: 1.313rem;

        .svgSet{
          width: inherit;
        }
      }

      .headerRight{
        display: flex;
        margin-left: auto;

        .headerOut{
          display: flex;
          height: 1.5rem;
          width: auto;
          //margin-left: calc(100% - 12rem);
          margin-left: 0.5rem;
        }
      }
    }
  }

  .infoGuide{
    display: flex;
    width: calc(100% - 2.5rem);
    height: 6.5rem;
    margin-top: 48px;
    flex-direction: column;
    align-self: center;
    margin-bottom: 1rem;

    .infoGuideLabel{
      display: flex;
      //width: 14.438rem;
      //height: 4.5rem;
      //width: 14.438rem;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 800;
      font-size: 28px;
      line-height: 36px;

      color: #222222;
      word-break: keep-all;
      letter-spacing: -0.5px;
    }

    .infoGuideTip{
      display: flex;
      margin-top: 0.875rem;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;

      color: #757575;
    }
  }

  .selectArea{
    display: flex;
    margin-top: 1.5rem;
    width: calc(100% - 2.5rem);
    align-self: center;
    
    flex-direction: column;
   
    .selectAreaLabel{
      display: flex;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;

      color: #222222;
    }

    .selectBtnArea{
      display: flex;
      width: 100%;

      flex-direction: column;

      flex-wrap: wrap;

      margin-top: 0.75rem;
      justify-content: flex-start;

      gap: 0.5rem;

      .btnRow{
        display: flex;
        flex-direction: row;
        gap:0.5rem;
        width: 100%;
        
        //align-self: center;
        justify-content: flex-start;
        

        .selectBtn{
          display: flex;

          flex: none;
          order: 0;
          flex-grow: 0;

          //width: 6.75rem;
          width: 32%;
          height: 3rem;

          background: #F5F6F7;
          border-radius: 8px;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          /* identical to box height */

          display: flex;
          align-items: center;
          text-align: center;
          
          

          /* Gray/900 */

          color: #222222;
          border: solid 2px #F5F6F7;          

          align-items: center;
          justify-content: center;

          padding: 0.5rem;
          transition: all .15s ease-in;
        }

        .selectBtnSelected{
          display: flex;

          flex: none;
          order: 0;
          flex-grow: 0;

          //width: 6.75rem;
          width: 32%;
          height: 3rem;

          background: #EAE9FF;
          border-radius: 8px;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 16px;
          /* identical to box height */

          display: flex;
          align-items: center;
          text-align: center;

          /* Gray/900 */

          color: #3E40F4;
          border: solid 2px #3E40F4;
          //outline-style: solid 2px #3E40F4;

          align-items: center;
          justify-content: center;

          padding: 0.5rem;
          word-break: keep-all;

        }
      }

      .selectBtnSelected:nth-child(2){
        white-space: nowrap;
      }
    }
  }

  .pageSet{
    display: flex;
    margin-top: 24px;
    align-self: center;

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    letter-spacing: 0.2em;

    /* Gray/900 */

    color: #222222;

    .pageSetRight{
      color: #C2C2C2;
    }
  }

  .testStartBtnArea{
    display: flex;
    width: calc(100% - 40px);
    height: 60px;

    border-radius: 12px;

    align-self: center;

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    text-align: center;

    align-items: center;
    justify-content: center;
    margin: 40px 0 20px;
  }

  .unselected{
    background: #EAE9FF;
    color: #A4A5FC;
  }

  .selected{
    background: #3E40F4;
    color: #FFFFFF;
  }

  .skip{
    display: flex;
    align-self: center;
    margin-bottom: 3rem;

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    text-align: center;    

    /* Gray/500 */

    color: #9E9E9E
  }

}
</style>