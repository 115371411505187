<template>
  <div class="B2BResultGuideModal">
    <div class="guideBack"></div>
    <div class="guideArea">
      <div class="guideLabel">레벨 가이드
        <img class="helpCloseImg" :src="$testControl.imgUrl(outBtn)" @click="outB2BModal()"/>
      </div>
      <div class="guideTxtArea">
        <div class="guideVal" v-for="(item, idx) in guideVal" :key="idx">
          <div class="guideValLabel">{{ item.level }}</div>
          <div class="guideValText">{{ item.text }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import XOutBtn from "@/assets/img/block/XOutBtn.svg"

export default {
  name: 'B2BResultGuideModal',
  data(){
    return{
      outBtn: XOutBtn,
      guideVal: [
        {
          level: "1~3 레벨",
          text: "영어 입문자 수준의 실력을 가지고 있습니다. 일상생활에서 반복적으로 사용하는 친숙한 단어나 기초적인 표현 위주로 이해할 수 있습니다. 간단하게 자기소개를 할 수 있고, 어디에 사는지, 무엇을 알고 있는지, 어떤 것을 가지고 있는지 등 개인적인 것에 대해 간단하게 묻고 답할 수 있습니다."
        },
        {
          level: "4~5 레벨",
          text: "영어 초급자 수준의 실력을 가지고 있습니다. 일상생활에서 규칙적으로 마주하는 상황에서 자주 사용되는 기본적인 표현이나 문장을 이해할 수 있고, 요점을 파악할 수 있습니다. 가족, 학교, 직장, 취미, 여가시간 등 본인과 가장 밀접하게 관련있는 영역에서 쓰이는 표현과 문장을 이해할 수 있습니다. 친숙한 분야, 개인적인 관심사와 관련한 간단한 지문을 만들고, 이해할 수 있습니다."
        },
        {
          level: "6~7 레벨",
          text: "영어 중급자 수준의 실력을 가지고 있습니다. 자신의 경험과 희망 사항에 대한 것들을 묘사할 수 있고, 이유와 설명을 통해 자신의 의견과 계획을 말할 수 있습니다. 개인적인 관심사나 친숙한 주제와 연관된 문장을 자유롭게 만들 수 있습니다. 구체적인 주제와 관련한 다소 긴 지문의 논점을 이해할 수 있습니다. 원어민과 일상적이고 친숙한 주제에 대해서 말할 수 있습니다."
        },
        {
          level: "8~9 레벨",
          text: "영어 중·고급자 수준의 실력을 가지고 있습니다. 세부적이고 추상적인 주제와 관련한 복잡한 지문의 논점을 이해할 수 있습니다. 원어민과 일상적이고 친숙한 주제에 대해서 꽤 유창하게 말할 수 있습니다. 다양한 주제로 명확하고 상세한 지문을 만들 수 있고, 찬·반 이슈에 대한 자신의 관점을 설명할 수 있습니다."
        },
        {
          level: "10 레벨",
          text: "고급 수준의 영어 실력을 가지고 있습니다. 광범위한 주제의 어렵고, 긴 지문을 읽고 듣고 이해할 수 있으며, 그 안에 함축된 의미를 파악할 수 있습니다. 자발적으로 유창하게 의견을 표현할 수 있고, 영어를 사회적인 목적, 학구적인 목적, 기타 전문적인 목적으로 유연하게 사용할 수 있습니다. 복잡한 주제에 대하여 명확하고 잘 구조화된 상세한 지문을 만들 수 있습니다."
        }
      ]
    }
  }, 
  methods:{
    outB2BModal(){
      this.$emit("outB2BModal")
    }
  }
}
</script>

<style lang="scss" scoped>
.B2BResultGuideModal{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;

  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);

  width: 100%;
  height: 100vh;

  z-index: 10;

  .guideBack{
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.8;

    z-index: 15;
  }

  .guideArea{
    display: flex;
    flex-direction: column;
    max-height: 45rem;
    height: calc(100% - 3rem);
    //width: calc(100% - 3.75rem);
    max-width: 20rem;
    width: calc(100% - 3rem);

    padding: 1rem;

    background: #ffffff;
    border-radius: 24px;

    opacity: 1;
    
    justify-content: center;
    align-items: center;

    z-index: 20;
    animation-name: fadeDown;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    visibility: visible;   
    
    @keyframes fadeDown {
      0% {
        opacity: 0;
        -webkit-transform: translateY(-2rem);
        -ms-transform: translateY(-2rem);
        transform: translateY(-2rem);
      }
      100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
      }
    }

    .guideLabel{
      display: flex;

      width: calc(100% - 2rem);

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 700;
      font-size: 1.125rem;

      color: #222222;

      .helpCloseImg{
        display: flex;
        margin-left: auto;
      }
    }

    .guideTxtArea{
      display: flex;
      flex-direction: column;
      
      width: calc(100% - 1rem);
      margin-top: 0.5rem;
      gap: 1rem;

      overflow: scroll;

      .guideVal{
        display: flex;
        flex-direction: column;
        width: 100%;

        background: #F5F6F7;
        padding: 1rem;
        border-radius: 0.5rem;

        gap: 0.375rem;

        .guideValLabel{
          display: flex;
          width: 100%;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 700;
          font-size: 1rem;

          color: #3E40F4;
        }

        .guideValText{
          display: flex;
          width: 100%;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 400;
          font-size: 0.813rem;

          line-height: 1.25rem;

          color: #616161;
        }
      }
    }
  }
}
</style>