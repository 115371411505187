<template>
  <div class="redirection"></div>
</template>

<script>
import testControl from "@/function/testControl";
import axios from "axios";

export default {
  name: 'redirection',
  created() {
    this.$store.commit('testManage/init')
    this.$testControl.loadUrlParams(this.$route.query)

    if (this.$store.getters['testManage/route'] !== 'TestResult' && this.$store.getters['testManage/quizSetToken']) {
      const quizSetUUID = this.$store.getters['testManage/quizSetToken']
      const userUUID = this.$store.getters['testManage/userToken']
      const companyCode = this.$store.getters['testManage/companyCode']
      const name = this.$store.getters['testManage/name']

      if (this.restartTest(quizSetUUID, companyCode, userUUID, name)) return
    }

    this.$testControl.goRoute(this.$store.getters['testManage/route'])
  },
  methods: {
    async restartTest(quiz_set_uuid, company_code, user_uuid, name){
      console.log('startTest');

      const res = await axios.post(`${this.$store.getters.apiUrl}/v1/quiz_set_token_info`, {
        company_code: company_code,
        quiz_set_uuid: quiz_set_uuid,
        user_uuid: user_uuid,
        name: name
      })

      if (!res.data.is_finished) {
        this.$store.commit('testManage/setQuizSetToken', res.data.quiz_set_uuid);

        this.$store.commit('testManage/setQuizFullCount', res.data.quiz_count);
        this.$store.commit('testManage/setNowQuizType', res.data.quiz_type);

        this.$store.commit('testManage/setChallengeCount', 1);
        this.$store.commit('testManage/setTryCount', 1);

        this.$store.commit('testManage/setSkip', false);

        testControl.toNextType(res.data.quiz_type)

        return false
      }

      return true
    }
  }
}
</script>