<template>
  <div class="TestReadingNormal">
    <Header :num="questionNum" :fullCount="quizFullCount"/>
    <div class="box_content">
      <div class="questionContent" ref="questionContent">
        <div class="testContentTitle">
          Q{{questionNum}}. {{ direction }}
        </div>
        <div class="testContentQuestion">
          <div v-if="!(Array.isArray(dialog1))" class="testContentQuestionArea">
            <div class="testContentQuestionTxt">{{dialog1}}</div>
          </div>
          <div v-if="(Array.isArray(dialog1))" class="testContentQuestionArea">
            <div class="selectedAnswerPoint">{{dialog1[0]}}
            <div class="selectedAnswerPointTXT" id="selectedAnswerPoint" style="display:inline;" @click="cancelAnswer()">{{selectAnswerTXT}}</div>
            {{dialog1[1]}}</div>
          </div>

          <div v-if="!(Array.isArray(dialog2))" class="testContentQuestionArea">
            <div class="testContentQuestionTxt">{{dialog2}}</div>
          </div>
          <div v-if="(Array.isArray(dialog2))" class="testContentQuestionArea">
            <div class="selectedAnswerPoint">{{dialog2[0]}}
            <div class="selectedAnswerPointTXT" id="selectedAnswerPoint" style="display:inline;" @click="cancelAnswer()">{{selectAnswerTXT}}</div>
            {{dialog2[1]}}</div>
          </div>

          <div v-if="!(Array.isArray(dialog3))" class="testContentQuestionArea">
            <div class="testContentQuestionTxt">{{dialog3}}</div>
          </div>
          <div v-if="(Array.isArray(dialog3))" class="testContentQuestionArea">
            <div class="selectedAnswerPoint">{{dialog3[0]}}
            <div class="selectedAnswerPointTXT" id="selectedAnswerPoint" style="display:inline;" @click="cancelAnswer()">{{selectAnswerTXT}}</div>
            {{dialog3[1]}}</div>
          </div>

          <div v-if="!(Array.isArray(dialog4))" class="testContentQuestionArea">
            <div class="testContentQuestionTxt">{{dialog4}}</div>
          </div>
          <div v-if="(Array.isArray(dialog4))" class="testContentQuestionArea">
            <div class="selectedAnswerPoint">{{dialog4[0]}}
            <div class="selectedAnswerPointTXT" id="selectedAnswerPoint" style="display:inline;" @click="cancelAnswer()">{{selectAnswerTXT}}</div>
            {{dialog4[1]}}</div>
          </div>
        </div>
        <div class="testContentAnswer">
          <div v-if="tempSelect==='A'" class="testContentAnswerArea" @click="checkAnswer('A', answer1)">
            <div class="testContentAnswerBtnSelected">A</div>
            <div class="testContentAnswerTxt">{{ answer1 }}</div>
          </div>
          <div v-if="tempSelect!=='A'" class="testContentAnswerAreaUnselect" @click="checkAnswer('A', answer1)">
            <div class="testContentAnswerBtnUnselect">A</div>
            <div class="testContentAnswerTxt">{{ answer1 }}</div>
          </div>

          <div v-if="tempSelect==='B'" class="testContentAnswerArea" @click="checkAnswer('B', answer2)">
            <div class="testContentAnswerBtnSelected">B</div>
            <div class="testContentAnswerTxt">{{ answer2 }}</div>
          </div>
          <div v-if="tempSelect!=='B'" class="testContentAnswerAreaUnselect" @click="checkAnswer('B', answer2)">
            <div class="testContentAnswerBtnUnselect">B</div>
            <div class="testContentAnswerTxt">{{ answer2 }}</div>
          </div>

          <div v-if="tempSelect==='C'" class="testContentAnswerArea" @click="checkAnswer('C', answer3)">
            <div class="testContentAnswerBtnSelected">C</div>
            <div class="testContentAnswerTxt">{{ answer3 }}</div>
          </div>
          <div v-if="tempSelect!=='C'" class="testContentAnswerAreaUnselect" @click="checkAnswer('C', answer3)">
            <div class="testContentAnswerBtnUnselect">C</div>
            <div class="testContentAnswerTxt">{{ answer3 }}</div>
          </div>

          <div v-if="tempSelect==='D'" class="testContentAnswerArea" @click="checkAnswer('D', answer4)">
            <div class="testContentAnswerBtnSelected">D</div>
            <div class="testContentAnswerTxt">{{ answer4 }}</div>
          </div>
          <div v-if="tempSelect!=='D'" class="testContentAnswerAreaUnselect" @click="checkAnswer('D', answer4)">
            <div class="testContentAnswerBtnUnselect">D</div>
            <div class="testContentAnswerTxt">{{ answer4 }}</div>
          </div>
        </div>
      </div>
    </div>
    <Footer :readyToNext="readyToNext" @nextStepOrder="nextStep(false)" @doSkip="doSkip" @skipSet="skipSet" :skipDefaultSet="skipDefaultSet"/>
  </div>
</template>

<script>
import Header from '@/components/Test/Header.vue';
import Footer from '@/components/Test/Footer.vue';

export default {
  name: 'TestReadingNormal',
  components: {
    Header,
    Footer
  },
  props: {
  },
  data() {
    return {
      quizData: undefined,
      tempSelect: undefined,
      selectAnswerTXT: '______________________',
      readyToNext: false,
    }
  },
  computed: {
    readyToNextCheck() {
      return this.tempSelect !== undefined
    },
    direction() {
      return this.quizData ? this.quizData.direction : ''
    },
    questionNum() {
      return this.$store.getters['testManage/nowQuizNum']
    },
    questionId() {
      return this.quizData ? this.quizData.questionId : 0
    },
    quizFullCount() {
      return this.quizData ? this.quizData.quizFinishNumber : 0
    },
    quizType() {
      return this.quizData ? this.quizData.quizType : 0
    },
    dialog1() {
      return this.quizData ? this.quizData.dialog1 : ''
    },
    dialog2() {
      return this.quizData ? this.quizData.dialog2 : ''
    },
    dialog3() {
      return this.quizData ? this.quizData.dialog3 : ''
    },
    dialog4() {
      return this.quizData ? this.quizData.dialog4 : ''
    },
    originalDialogArr() {
      return this.quizData ? this.quizData.originalDialogArr : ''
    },
    answer1() {
      return this.quizData ? this.quizData.answerArr[0] : ''
    },
    answer2() {
      return this.quizData ? this.quizData.answerArr[1] : ''
    },
    answer3() {
      return this.quizData ? this.quizData.answerArr[2] : ''
    },
    answer4() {
      return this.quizData ? this.quizData.answerArr[3] : ''
    },
    skipDefaultSet() {
      return this.$store.getters['testManage/skipSet']
    },

  },
  watch: {
    questionNum: {
      handler: function (){
        console.log('questionNum', this.questionNum);
      }
    },
    readyToNextCheck: {
      handler: function (check){
        console.log('readyToNextCheck', check);

        this.readyToNext = check;
      }
    }
  },
  async mounted() {
    await this.setVal();
  },
  methods: {
  
    async setVal(){
      console.log("TestReadingNormal setVal");
      this.$refs.questionContent.scrollIntoView({block: "start"})
      // window.scrollTo({ top: 0});
      this.quizData = await this.$testControl.fnTestCall();
      console.log('getData : ', this.quizData);
    },

    async checkAnswer(select, answer){
      console.log("checkAnswer testContentAnswer" + select, answer);
      const answerPoint = document.getElementById("selectedAnswerPoint");

      if(this.tempSelect !== select){
        
        this.tempSelect = select;
        this.selectAnswerTXT = answer;
        answerPoint.style.color = "#FD2448";
      } else if(this.tempSelect === select){
        this.tempSelect = undefined;
        this.selectAnswerTXT = '______________________';
        answerPoint.style.color = "#000000";
      }
    },

    async cancelAnswer(){
      console.log("cancelAnswer");
      const answerPoint = document.getElementById("selectedAnswerPoint");

      this.tempSelect = undefined;
      this.selectAnswerTXT = '______________________';
      answerPoint.style.color = "#000000";
    },

    async nextStep(skipBool){
      console.log('step : ', this.questionNum);

      if(!this.readyToNext && !skipBool){
        console.log('answer is undefined');
        return;
      } else {
        const nextType = await this.$testControl.saveAnswer((!skipBool ? this.selectAnswerTXT : 'SKIP_ANSWER'));

        if(nextType === this.quizType){
          
          this.selectAnswerTXT = '______________________';
          this.tempSelect = undefined;
          
          const answerPoint = document.getElementById("selectedAnswerPoint");
          answerPoint.style.color = "#000000";

          await this.setVal();
        } else {
          await this.$testControl.toNextType(nextType);
        }

      }

    },
    async doSkip(){
      console.log('doSkip step : ', this.questionNum);

      this.nextStep(true);
    },
    async skipSet(){
      this.skipDefaultSet = true;

      this.$store.commit('testManage/setSkip', true);
      console.log('skipSet', this.$store.getters['testManage/skipSet']);
    }
  },
}
</script>

<style lang="scss" scoped>
@import "scssCode.scss";

.TestReadingNormal {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: inherit;

  .box_content {
    display: flex;
    flex-direction: column;

    height: auto;
    width: 100%;
    overflow-y: scroll;

    .questionContent{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      //align-items: center;
      //height: 80%;
      width: auto;

      .testContentTitle{
        display: flex;
        width: calc(100% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-top: 0.625rem;
        margin-bottom: 1rem;
        
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 1.5;

        //margin-left: 1.25rem;
        /* Gray/900 */
        color: #222222;
      }

      .testContentQuestion{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        
        width: calc(100% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        //margin-top: 0.75rem;
        margin-bottom: 1.25rem;

        background-color: #F5F6F7;
        border-radius: 12px;
        padding: 0.6rem 1rem;

        word-break: break-word;

        .testContentQuestionArea{
          display: inline-block;
          
          flex-wrap: wrap;
          padding: 0.5rem 0rem 0.5rem 0rem;
          

          .testContentQuestionTxt{
            display: inline-block;
            flex-wrap: wrap;

            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 500;
            font-size: 17px;
            line-height: 1.625rem;

            color: #222222;
          }
            
          .selectedAnswerPoint{
            display: inline-block;
            flex-wrap: wrap;

            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 1.625rem;

            .selectedAnswerPointTXT{
              display: inline-flex;

              font-family: 'Pretendard';
              font-style: normal;
              font-weight: 500;
              font-size: 17px;
              line-height: 1.625rem;
            }
          }

          
        }
      }

      .testContentAnswer{
        display: flex;
        flex-direction: column;
        
        align-items: flex-start;
        padding-bottom: 8rem;
        width: 100%;

        //margin-top: 2rem;

        .testContentAnswerAreaUnselect{
          @include defaultTestContentAnswerArea;
        }

        .testContentAnswerArea{
          
          @include defaultTestContentAnswerArea;
          background: #F5F6F7;
          //background-size: 100vw;
          
        }
      }
    }
  }
}
</style>