<template>
  <div class="confirmToLobby">
    <div class="confirmToLobbyBack"></div>
    <div class="confirmModalArea">
      <div class="confirmTxt">시험응시를 중지하고 메인페이지로 이동 합니다.
        <div class="confirmInTxt">재접속시 문제를 이어서 푸실 수 있습니다.</div>
      </div>
      <div class="confirmBtnArea">
        <div class="confirmBtn Check" @click="check()">예</div>
        <div class="confirmBtn Uncheck" @click="uncheck()">아니오</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'confirmToLobbyModal',
  data(){
    return{

    }
  },
  methods:{
    uncheck(){
      this.$emit('uncheckLobby');
    },
    check(){
      console.log('check');
      this.$emit('checkLobby');
    }
  }
}
</script>

<style lang="scss" scoped>
.confirmToLobby{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;

  width: 100%;
  height: 100%;

  left: 0;
  top: 0;

  .confirmToLobbyBack{
    display: flex;
    position: absolute;  
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.8;

    align-self: center;

    z-index: -1;
  }

  .confirmModalArea{
    display: flex;
    flex-direction: column;
    width: 18.438rem;
    height: 13.375rem;
    background: #fff;
    border-radius: 20px;

    align-items: center;
    justify-content: center;
    animation-name: fadeDown;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    visibility: visible;   

    @keyframes fadeDown {
      0% {
          opacity: 0;
          -webkit-transform: translateY(-2rem);
          -ms-transform: translateY(-2rem);
          transform: translateY(-2rem);
      }
      100% {
          opacity: 1;
          -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
          transform: translateY(0);
      }
    }
  }

  .confirmTxt{
    display: flex;
    flex-direction: column;
    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    
    width: calc(100% - 2.5rem);
    word-break: keep-all;

    text-align: center;
    color: #222222;
    word-break: keep-all;

    .confirmInTxt{
      display: flex;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;

      width: 100%;
      word-break: keep-all;

      text-align: center;
      color: #616161;
      word-break: keep-all;

      align-self: center;

      margin-top: 1rem;
    }
  }

  .confirmBtnArea{
    display: flex;
    flex-direction: row;
    gap: 0.563rem;

    .confirmBtn{
      display: flex;
      width: 7.688rem;
      height: 2.75rem;

      margin-top: 1.25rem;

      border-radius: 8px;

      align-items: center;
      justify-content: center;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 18px;

      text-align: center;
    }

    .Check{
      background: #EAE9FF;
      color: #3E40F4;
    }

    .Uncheck{
      background: #3E40F4;
      color: #FFFFFF;
    }
  }

}
</style>