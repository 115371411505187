<template>
  <div class="TestListeningHard">
    <Header :num="questionNum" :fullCount="quizFullCount"/>
    <div class="box_content">
      <div class="questionContent" ref="questionContent">
        <div class="testContentTitle">
          Q{{questionNum}}. {{ direction }}
        </div>

        <SoundWave v-if="formRender" class="SoundWave" :audio="questionAudio" @playEvent="playEvent()" :key="componentKey" :qOriginalAudio="qOriginalAudio"/>

        <div class="testContentAnswer">
          <div v-if="tempSelect==='A'" class="testContentAnswerArea" @click="checkAnswer('A', answer1)">
            <div class="testContentAnswerBtnSelected">A</div>
            <div class="testContentAnswerTxt">{{ answer1 }}</div>
          </div>
          <div v-if="tempSelect!=='A'" class="testContentAnswerAreaUnselect" @click="checkAnswer('A', answer1)">
            <div class="testContentAnswerBtnUnselect">A</div>
            <div class="testContentAnswerTxt">{{ answer1 }}</div>
          </div>

          <div v-if="tempSelect==='B'" class="testContentAnswerArea" @click="checkAnswer('B', answer2)">
            <div class="testContentAnswerBtnSelected">B</div>
            <div class="testContentAnswerTxt">{{ answer2 }}</div>
          </div>
          <div v-if="tempSelect!=='B'" class="testContentAnswerAreaUnselect" @click="checkAnswer('B', answer2)">
            <div class="testContentAnswerBtnUnselect">B</div>
            <div class="testContentAnswerTxt">{{ answer2 }}</div>
          </div>

          <div v-if="tempSelect==='C'" class="testContentAnswerArea" @click="checkAnswer('C', answer3)">
            <div class="testContentAnswerBtnSelected">C</div>
            <div class="testContentAnswerTxt">{{ answer3 }}</div>
          </div>
          <div v-if="tempSelect!=='C'" class="testContentAnswerAreaUnselect" @click="checkAnswer('C', answer3)">
            <div class="testContentAnswerBtnUnselect">C</div>
            <div class="testContentAnswerTxt">{{ answer3 }}</div>
          </div>

          <div v-if="tempSelect==='D'" class="testContentAnswerArea" @click="checkAnswer('D', answer4)">
            <div class="testContentAnswerBtnSelected">D</div>
            <div class="testContentAnswerTxt">{{ answer4 }}</div>
          </div>
          <div v-if="tempSelect!=='D'" class="testContentAnswerAreaUnselect" @click="checkAnswer('D', answer4)">
            <div class="testContentAnswerBtnUnselect">D</div>
            <div class="testContentAnswerTxt">{{ answer4 }}</div>
          </div>
        </div>
      </div>
    </div>
    <Footer :readyToNext="readyToNext" @nextStepOrder="nextStep(false)" @doSkip="doSkip" @skipSet="skipSet" :skipDefaultSet="skipDefaultSet"/>
    <ListeningModal v-if="listeningModalOpen" @closeListeningModal="closeListeningModal"/>
  </div>
</template>

<script>
import Header from '@/components/Test/Header.vue';
import Footer from '@/components/Test/Footer.vue';
import ListeningModal from '@/components/Test/ListeningModal.vue'

import SoundWave from '@/components/Test/testModule/SoundWaveForm.vue';

export default {
  name: 'TestListeningHard',
  components: {
    Header,
    Footer,
    ListeningModal,
    SoundWave
  },
  props: {
    /*userId: {
      type: String,
      default: null
    },
    testListeningHardData : {
      type: Object,
      default: null,
    },
    quizSetToken: {
      type: String,
      default: null
    },*/
  },
  data() {
    return {
      quizData: undefined,
      formRender: false,
      questionAudio: undefined,
      audioTrackCount: 2,
      audioPlayFlag: false,
      tempSelect: undefined,
      selectAnswer: undefined,
      readyToNext: false,
      listeningModalOpen: false,
      componentKey:0,
    }
  },
  computed: {
    readyToNextCheck: function(){
      console.log('computed readyToNextCheck');
      return this.tempSelect !== undefined
    },
    direction() {
      return this.quizData ? this.quizData.direction : ''
    },
    questionNum() {
      return this.$store.getters['testManage/nowQuizNum']
    },
    questionId() {
      return this.quizData ? this.quizData.questionId : 0
    },
    quizFullCount() {
      return this.quizData ? this.quizData.quizFinishNumber : 0
    },
    quizType() {
      return this.quizData ? this.quizData.quizType : 0
    },
    answer1() {
      return this.quizData ? this.quizData.answerArr[0] : ''
    },
    answer2() {
      return this.quizData ? this.quizData.answerArr[1] : ''
    },
    answer3() {
      return this.quizData ? this.quizData.answerArr[2] : ''
    },
    answer4() {
      return this.quizData ? this.quizData.answerArr[3] : ''
    },
    qOriginalAudio() {
      return this.quizData ? this.quizData.audio: ''
    },
    skipDefaultSet() {
      return this.$store.getters['testManage/skipSet']
    },
  },
  watch: {
    readyToNextCheck: {
      handler: function (check){
        console.log('readyToNextCheck', check);

        this.readyToNext = check;
      }
    },
  },
  async mounted() {
    await this.setVal();
  },
  methods: {
    async setVal(){
      console.log("testListeningHardData setVal");
      // window.scrollTo({ top: 0});
      this.$refs.questionContent.scrollIntoView({block: "start"})
      
      this.quizData = await this.$testControl.fnTestCall();
      console.log('getData : ', this.quizData);

      this.questionAudio = new Audio(this.qOriginalAudio);

      if(this.questionAudio !== undefined){
        this.forceRerender();
        this.formRender = true;
      }
    },
    forceRerender() {
      console.log('forceRerender');
      this.componentKey += 1;  
    },
    playEvent(){
      console.log('it\'s played');

      this.audioTrackCount--;
    },
    closeListeningModal(){
      this.listeningModalOpen = false;
    },
    

    async checkAnswer(select, val){
      console.log("checkAnswer testContentAnswer" + select, val);

      if(this.tempSelect !== select){
        
        this.selectAnswer = val;
        this.tempSelect = select;
      } else if(this.tempSelect === select){
        this.selectAnswer = undefined;
        this.tempSelect = undefined;
      }
    },

    async cancelAnswer(){
      console.log("cancelAnswer");

      this.selectAnswer = undefined;
      this.tempSelect = undefined;
    },

    async nextStep(skipBool){
      console.log('step : ', this.questionNum);

      if(this.audioTrackCount === 2 && !skipBool){
        console.log('plz audio');
        this.listeningModalOpen = true;
        return;
      }

      if(!this.readyToNext && !skipBool){
        console.log('answer is undefined');
        return;
      } else {
        if (this.questionAudio) {
          this.questionAudio.pause();
          this.questionAudio.currentTime = 0;
        }
        this.formRender = false;

        cancelAnimationFrame(this.reRender);
        this.reRender = undefined;

        const nextType = await this.$testControl.saveAnswer((!skipBool ? this.selectAnswer : 'SKIP_ANSWER'));

        if(nextType === this.quizType){
          this.selectAnswer = undefined;
          this.tempSelect = undefined;

          this.audioTrackCount = 2;
          this.audioPlayFlag = false;

          this.forceRerender();

          await this.setVal();
        } else {
          await this.$testControl.toNextType(nextType);
        }

      }

    },
    async doSkip(){
      console.log('doSkip step : ', this.questionNum);

      await this.nextStep(true);
    },
    async skipSet(){
      this.skipDefaultSet = true;

      this.$store.commit('testManage/setSkip', true);
      console.log('skipSet', this.$store.getters['testManage/skipSet']);
    }
  },

}
</script>

<style lang="scss" scoped>
@import "scssCode.scss";

.TestListeningHard {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: inherit;

  .box_content {
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;
    overflow-y: scroll;

    .questionContent{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      width: auto;

      .testContentTitle{
        display: flex;
        width: calc(100% - 2.5rem);
        margin-left: 1.25rem;
        margin-right: 1.25rem;
        margin-top: 0.625rem;
        margin-bottom: 1rem;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 1.5;

        //margin-left: 1.25rem;
        /* Gray/900 */
        color: #222222;
      }

      .SoundWave{
        width: calc(100% - 2.5rem);
        align-self: center;        
      }
    }

    .audioArea{
      display: flex;

      width: calc(100% - 2.5rem);
      margin-left: 1.25rem;
      margin-right: 1.25rem;

      background: #F5F6F7;
      border-radius: 12px;

      justify-content: center;

      .audioAreaContent{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;
        gap: 12px;

        width: fit-content;

        .audioAreaTxt{
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;

          color: #222222;

          .audioAreaTxtCount{
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;

            display:inline;

            color: #3E40F4;
          }
        }

        .audioButtonInner{
          flex: none;
          order: 1;
          flex-grow: 0;

          height: 3rem;
          width: 4rem;

          border: 0;
          background: #F5F6F7;
        }

      }
    }

    .soundWaveBox{
      display: flex;

      //width: 100%;
      width: calc(100% - 2.5rem);
      margin-left: 1.25rem;
      margin-right: 1.25rem;
      
      align-items: center;
      justify-content: center;

      margin-top: 2rem;
      margin-bottom: 2rem;

      background: #fff;

      .canvas{
        width: 100%;
        height: 6rem;
      }

      .soundWaveBoxInner{
        display: flex;
        width: 100%;
      }
    }

    .testContentAnswer{
      display: flex;
      flex-direction: column;
      
      align-items: flex-start;
      padding: 0px;
      width: 100%;
      margin-bottom: 8rem;

      //margin-top: 2rem;

      .testContentAnswerAreaUnselect{
        @include defaultTestContentAnswerArea;
      }

      .testContentAnswerArea{
        
        @include defaultTestContentAnswerArea;
        background: #F5F6F7;
        //background-size: 100vw;
        
      }

    }

  }
}
</style>