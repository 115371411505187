<template>
  <div class="proposalSendModal">
    <div class="proposalSendModalBack"></div>
    <div class="proposalModalArea">
      <div class="proposalTxt">입력하신 내용을 제출하였습니다.
        <div class="proposalInTxt">제안해주셔서 감사합니다.</div>
      </div>
      <div class="proposalBtnArea">
        <div class="proposalBtn Check" @click="check()">예</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProposalSendModal',
  data(){
    return{

    }
  },
  methods:{
    check(){
      console.log('check');
      this.$emit('checkProposal');
    }
  }
}
</script>

<style lang="scss" scoped>
.proposalSendModal{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 4;

  width: 100%;
  height: 100%;

  left: 0;
  top: 0;

  .proposalSendModalBack{
    display: flex;
    position: absolute;  
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0.8;

    align-self: center;

    z-index: 3;
  }

  .proposalModalArea{
    display: flex;
    flex-direction: column;
    width: 18.438rem;
    height: 13.375rem;
    background: #fff;
    border-radius: 20px;

    align-items: center;
    justify-content: center;
    animation-name: fadeDown;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    visibility: visible;   
    z-index: 4;

    @keyframes fadeDown {
      0% {
          opacity: 0;
          -webkit-transform: translateY(-2rem);
          -ms-transform: translateY(-2rem);
          transform: translateY(-2rem);
      }
      100% {
          opacity: 1;
          -webkit-transform: translateY(0);
          -ms-transform: translateY(0);
          transform: translateY(0);
      }
    }

    .proposalTxt{
      display: flex;
      flex-direction: column;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      
      width: calc(100% - 2.5rem);
      word-break: keep-all;

      text-align: center;
      color: #222222;
      word-break: keep-all;

      .proposalInTxt{
        display: flex;
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;

        width: 100%;
        word-break: keep-all;

        text-align: center;
        color: #616161;
        word-break: keep-all;

        align-self: center;
        justify-content: center;

        margin-top: 1rem;
      }
    }

    .proposalBtnArea{
      display: flex;
      flex-direction: row;
      gap: 0.563rem;

      .proposalBtn{
        display: flex;
        width: 7.688rem;
        height: 2.75rem;

        margin-top: 1.25rem;

        border-radius: 8px;

        align-items: center;
        justify-content: center;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;

        text-align: center;
      }

      .Check{
        background: #3E40F4;
        color: #FFFFFF;
      }
    }
  }
}
</style>