<template>
  <div class="TestListeningNormal">
    <Header :num="questionNum" :fullCount="quizFullCount"/>
    <div class="box_content">
      <div class="questionContent" ref="questionContent">
        <div class="testContentTitle">
          Q{{questionNum}}. {{ direction }}
        </div>
        <SoundWave v-if="formRender" :audio="questionAudio" @playEvent="playEvent()" :key="componentKey" :qOriginalAudio="qOriginalAudio"/>

        <div class="testContentAnswer">
          <div class="testContentAnswerArea" @click="checkAnswer('A')">
            <div v-if="selectAnswer!=='A'" class="testContentAnswerBtnUnselect">A</div>
            <div v-if="selectAnswer==='A'" class="testContentAnswerBtnSelected">A</div>
          </div>
          <div class="testContentAnswerArea" @click="checkAnswer('B')">
            <div v-if="selectAnswer!=='B'" class="testContentAnswerBtnUnselect">B</div>
            <div v-if="selectAnswer==='B'" class="testContentAnswerBtnSelected">B</div>
          </div>
          <div class="testContentAnswerArea" @click="checkAnswer('C')">
            <div v-if="selectAnswer!=='C'" class="testContentAnswerBtnUnselect">C</div>
            <div v-if="selectAnswer==='C'" class="testContentAnswerBtnSelected">C</div>
          </div>
        </div>
      </div>
    </div>
    <Footer :readyToNext="readyToNext" @nextStepOrder="nextStep(false)" @doSkip="doSkip" @skipSet="skipSet" :skipDefaultSet="skipDefaultSet"/>
    <ListeningModal v-if="listeningModalOpen" @closeListeningModal="closeListeningModal"/>
  </div>
</template>

<script>
import Header from '@/components/Test/Header.vue';
import Footer from '@/components/Test/Footer.vue';
import ListeningModal from '@/components/Test/ListeningModal.vue';

//import SoundWave from '@/components/Test/tempTest/SoundWave.vue';
import SoundWave from '@/components/Test/testModule/SoundWaveForm.vue';
//import axios from 'axios';

export default {
  name: 'TestListeningNormal',
  components: {
    //AVMedia
    //useAVLine
    Header,
    Footer,
    ListeningModal,
    SoundWave
  },
  props: {
    /*userId: {
      type: String,
      default: null
    },
    testListeningNormalData : {
      type: Object,
      default: null,
    },
    quizSetToken: {
      type: String,
      default: null
    },*/
  },
  data() {
    return {
      quizData: undefined,
      formRender: false,
      questionAudio: undefined,
      audioTrackCount: 2,
      audioPlayFlag: false,
      selectAnswer: undefined,
      listeningModalOpen: false,
      componentKey:0,
    }
  },
  computed: {
    readyToNextCheck: function(){
      console.log('computed readyToNextCheck');
      return this.selectAnswer !== undefined
    },
    direction() {
      return this.quizData ? this.quizData.direction : ''
    },
    questionNum() {
      return this.$store.getters['testManage/nowQuizNum']
    },
    questionId() {
      return this.quizData ? this.quizData.questionId : 0
    },
    quizFullCount() {
      return this.quizData ? this.quizData.quizFinishNumber : 0
    },
    quizType() {
      return this.quizData ? this.quizData.quizType : 0
    },
    qOriginalAudio() {
      return this.quizData ? this.quizData.audio: ''
    },
    skipDefaultSet() {
      return this.$store.getters['testManage/skipSet']
    },

  },
  watch: {
    readyToNextCheck: {
      handler: function (check){
        console.log('readyToNextCheck', check);

        this.readyToNext = check;
      }
    }
  },
  async mounted() {
    await this.setVal();
  },
  methods: {
    async setVal(){
      console.log("testListeningNormalData setVal");
      this.$refs.questionContent.scrollIntoView({block: "start"})
      // window.scrollTo({ top: 0});

      const userBrowser = window.navigator.userAgent.toLowerCase();
      console.log('window', userBrowser);

      this.quizData = await this.$testControl.fnTestCall();
      //console.log('getData : ', this.quizData, this.$store.getters['testManage/nowQuizNum'], this.$store.getters['testManage/nowQuizType']);

      this.questionAudio = new Audio(this.qOriginalAudio);

      if(this.questionAudio !== undefined){
        this.forceRerender();
        this.formRender = true;
      }
    },
    forceRerender() {
      console.log('forceRerender');
      this.componentKey += 1;  
    },
    playEvent(){
      console.log('it\'s played');

      this.audioTrackCount--;
    },
    closeListeningModal(){
      this.listeningModalOpen = false;
    },
    async checkAnswer(select){
      console.log("checkAnswer testContentAnswer" + select);

      if(this.selectAnswer !== select){
        
        this.selectAnswer = select;
      } else if(this.selectAnswer === select){
        this.selectAnswer = undefined;
      }
    },

    async cancelAnswer(){
      console.log("cancelAnswer");

      this.selectAnswer = undefined;
    },

    async nextStep(skipBool){
      console.log('step : ', this.questionNum, this.audioTrackCount);

      if(this.audioTrackCount === 2 && !skipBool){
        console.log('plz audio');
        this.listeningModalOpen = true;
        return;
      }

      if(!this.readyToNext && !skipBool){
        console.log('answer is undefined');
        return;
      } else {
        if (this.questionAudio) {
          this.questionAudio.pause();
          this.questionAudio.currentTime = 0;
        }
        this.formRender = false;

        const nextType = await this.$testControl.saveAnswer((!skipBool ? this.selectAnswer : 'SKIP_ANSWER'));

        if(nextType === this.quizType){

          this.tempSelect = undefined;
          this.selectAnswer = undefined;

          this.audioTrackCount = 2;
          this.audioPlayFlag = false;
          this.forceRerender();
          await this.setVal();
        } else {
          await this.$testControl.toNextType(nextType);
        }

      }

    },
    async doSkip(){
      this.nextStep(true);
    },
    async skipSet(){
      this.skipDefaultSet = true;

      this.$store.commit('testManage/setSkip', true);
      console.log('skipSet', this.$store.getters['testManage/skipSet']);
    }

  },

}
</script>

<style lang="scss" scoped>
.TestListeningNormal {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: inherit;

  .box_content {
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;
    overflow-y: scroll;

    .questionContent{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      width: auto;
      margin-left: 1.25rem;
      margin-right: 1.25rem;

      .testContentTitle{
        display: flex;
        width: 100%;

        margin-top: 0.625rem;
        margin-bottom: 1rem;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 1.5;

        //margin-left: 1.25rem;
        /* Gray/900 */
        color: #222222;
      }
    }
    

    .soundWaveBox{
      display: flex;
      flex-direction: column;

      width: 100%;
      align-items: center;
      justify-content: center;

      margin-top: 2rem;
      margin-bottom: 2rem;

      background: #fff;

      .canvas1{
        width: 100%;
        height: 6rem;
      }

      .canvas2{
        width: 100%;
        height: 6rem;
        margin-top: 0.3rem;
      }

      .progressMiddle{
        display: flex;
        width: 99%;
        height: 0.2rem;
        margin-top: 0.3rem;
        background: linear-gradient(90deg, #5D62F9, #16C8BE);

        .progressMiddleInner{
          height: 100%;
          background-color: #fff;
          align-self: flex-end;
        }
      }
    }

    .testContentQuestion{
      display: inline-block;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      
      width: 100%;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 1.625rem;

      color: #222222;

      margin-top: 2rem;
      margin-bottom: 2rem;

      word-break: break-word;

      .selectedAnswerPoint{
        display: inline-block;
        background: #FFFFFF;

        height: 2.25rem;
        width: 6.5rem;

        border: 1px solid #DDDDDD;
        border-radius: 8px;

        align-items: center;
        justify-content: center;
        align-content: center;
        text-align: center;

        margin-left: 0.5rem;
        margin-right: 0.5rem;
        
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;

        color: #3E40F4;

        .selectedAnswerPointTXT{
          display: inline-flex;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 500;
          font-size: 19px;
          line-height: 1.625rem;
          vertical-align: middle;

          text-align: center;
          
        }
      }
    
    }

    .testContentAnswer{
      display: flex;
      flex-direction: center;
      align-items: flex-start;
      margin: auto;
      padding: 0px;
      margin-bottom: 8rem;

      .testContentAnswerArea{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1rem 1rem 1rem 1rem;
        gap: 10px;

        width: auto;

        .testContentAnswerBtnUnselect{
          flex: none;
          order: 0;
          flex-grow: 0;

          width: 2.25rem;
          height: 2.25rem;

          background: #FFFFFF;

          border: 1px solid #222222;
          border-radius: 100%;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          display: flex;

          align-items: center;
          text-align: center;
          justify-content: center;

          color: #222222;
        }

        .testContentAnswerBtnSelected{
          flex: none;
          order: 0;
          flex-grow: 0;

          width: 2.25rem;
          height: 2.25rem;

          background: #222222;

          border: 1px solid #222222;
          border-radius: 100%;

          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          display: flex;

          align-items: center;
          text-align: center;
          justify-content: center;

          color: #FFFFFF;
        }

      }
    }
  }
}
</style>