<template>
  <div class="TestTalkingContext">
    <Header :num="questionNum" :fullCount="quizFullCount"/>
    <div class="box_content" ref="box_content">
      <div class="questionContent">
        <div class="testContentTitle">
          Q{{questionNum}}. {{ direction }}
        </div>
        <div class="testContentQuestion">
          <div class="testContentQuestionKor">{{ guideKor }}</div>
          <div class="testContentQuestionEng">{{ guideEng }}</div>
        </div>
        <div class="talkingGuide">
          Tip : 30초 이내로 말해주세요. 30초가 지나면 녹음이 강제중지 됩니다.
        </div>
        <div class="recordingBtnArea">
          <div class="recordingBtnAreaRecCount">
            <div class="recordingBtnAreaRecCountUp">
              <div class="recordingBtnAreaRecCountUpTXT">
                녹음 가능 횟수 : {{recordCounter}}/2
              </div>
            </div>
            <div class="recordingBtnAreaRecCountDown">
            </div>
          </div>
          <div class="recordingBtnAreaClick" @click="record()">
            <div class="recordingBtnBackCircle" id="recordingBtnBackCircle" ref="recordingBtnBackCircle">
              <div class="recordingBtnInCircleI" id="recordingBtnInCircleI" ref="recordingBtnInCircleI">
                <div class="recordingBtnInCircleII">
                  <img class="recordingBtnInCircleIIImg" :src="$testControl.imgUrl(RecordBtnImage)" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="recordingStatGuide">
          {{ recordStatGuide }}
        </div>
      </div>
    </div>
    <transition name="fade" @after-enter="resultVoiceRecord = null">
      <div class="record-result-icon-area" v-if="resultVoiceRecord" :class="resultVoiceRecord.bgClass">
        <div class="icon">
          <img :src="$testControl.imgUrl(resultVoiceRecord.icon)" class="iconImg"/>
        </div>
        <div class="text">{{ resultVoiceRecord.text }}</div>
      </div>
    </transition>
    <Footer :readyToNext="readyToNext" @nextStepOrder="nextStep" @doSkip="doSkip" @skipSet="skipSet" :skipDefaultSet="skipDefaultSet"/>
    <div class="timeOutText" v-if="isLoading && timeOutTextCheck">30초가 경과하여 녹음이 중지됩니다.<br/>지금까지 녹음된 음성은 자동 저장됩니다</div>
    <div class="spinner" v-if="isLoading">
      <img class="spinnerImg" :src="$testControl.imgUrl(SpinnerImg)"/>
    </div>
    <div class="dimm" v-if="isLoading || isFailSpeech"/>
    <div class="description" v-if="isLoading && isSaving">
      <div class="thanks">수고하셨습니다!</div>
      <div class="info">
        <!--<div>현재 AI 가 테스트 결과를 분석하고 있습니다.</div>-->
        <div class="infoList">
          <div class="infoChange">{{ textByLoading[0] }}</div>
          <div class="infoChange">{{ textByLoading[1] }}</div>
          <div class="infoChange">{{ textByLoading[2] }}</div>
          <div class="infoChange">{{ textByLoading[3] }}</div>
        </div>
        <div class="infoFix">잠시 후 결과 페이지로 이동합니다.</div>
      </div>
    </div>
    <div class="fail-speech" v-if="isFailSpeech">
      <div class="fail-icon">
        <img :src="$testControl.imgUrl(failIconImg)"/>
      </div>
      <div class="fail-desc">단답형 혹은 단어만을 나열한 답변과 영어 이외 답변은 진단이 불가능 합니다.</div>
      <div class="fail-button-area">
        <div class="fail-button" @click="setShowFailSpeechAlert(false)">
          <div>확인</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Test/Header.vue';
import Footer from '@/components/Test/Footer.vue';
import axios from 'axios';

import RecordBtnClick from '@/assets/img/block/recordBtnClick.svg'
import RecordBtnUnclick from '@/assets/img/block/recordBtnUnclick.svg'
import RecordBtnCountZero from '@/assets/img/block/recordBtnCountZero.svg'

import GrinningFace from '@/assets/img/block/grinning-face.svg'
import SmileFace from '@/assets/img/block/slightly-smiling-face.svg'
import SadFace from '@/assets/img/block/sad-but-relieved-face.svg'

import Spinner from '@/assets/img/block/spinner.png'
import FailIcon from '@/assets/img/fail_speech.svg'

export default {
  name: 'TestTalkingContext',
  components: {
    Header,
    Footer
  },
  props: {
    userId: {
      type: String,
      default: null
    },
    testTalkingContextData : {
      type: Object,
      default: null,
    },
    quizSetToken: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      questionData: undefined,
      selectAnswer: undefined,
      recordingFlag: false,
      recordCounter: 2,
      status: "ready",
      mediaRecorder: undefined,
      chunks: [],
      AUDIO_TYPES: ["webm", "mp3", "mp4", "x-matroska", "ogg", "wav"],
      AUDIO_CODECS: ["opus", "vorbis", "aac", "mpeg", "mp4a", "pcm"],
      browser_audio_type: undefined,
      browser_audio_codecs: undefined,
      isLoading: false,
      resultVoiceRecord: undefined,
      intervalPerSeconds: 40,
      stream: undefined,
      timeOutCountCircle: 0,
      recordingBtnBackCircleContext: undefined,
      recordingBtnInCircleIContext: undefined,
      recCircleInterval: undefined,
      recordStatGuide:'상단 버튼을 누르시면 녹음이 시작됩니다.',
      limitSecond: 30,
      timeOutTextCheck: false,
      isSaving: false,
      mikeCheck: true,
      isFailSpeech: false,
      recordCheck: false,

      textByLoading: ['정답여부를 체점중 입니다.', '음성파일을 분석중 입니다.', '5대영역 레벨 부여중 입니다.', '적합한 추천 콘텐츠를 생성중 입니다.'],
    }
  },
  computed: {
    failIconImg: () => FailIcon,
    SpinnerImg: () => Spinner,
    direction() {
      return this.questionData ? this.questionData.direction : ''
    },
    questionNum() {
      return this.$store.getters['testManage/nowQuizNum']
    },
    quizFullCount() {
      return this.questionData ? this.questionData.count : 0
    },
    guideKor() {
      return this.questionData ? this.questionData.guide_kor : ''
    },
    guideEng() {
      return this.questionData ? this.questionData.guide_eng : ''
    },
    RecordBtnImage() {
      if (this.recordCounter > 0) {
        return this.recordingFlag ? RecordBtnClick : RecordBtnUnclick
      } else {
        return RecordBtnCountZero
      }
    },
    speech_score_api() {
      return `${this.$store.getters.apiUrl}/v1/speech_type10_score`;
    },
    skipDefaultSet() {
      return this.$store.getters['testManage/skipSet']
    },
    quizSetUUID() {
      return this.$store.getters['testManage/quizSetToken']
    },
    readyToNext() {
      return (this.recordCounter < 2 && !this.recordingFlag) || !this.mikeCheck
    },
  },
  unmounted() {
    this.clear()
  },
  async mounted() {
    await this.init();
  },
  watch:{
    timeOutCountCircle:{
      handler: function () {
        let showDeg = 360 - (this.timeOutCountCircle / (this.limitSecond * this.intervalPerSeconds) * 360)

        this.recordingBtnBackCircleContext.style.backgroundImage  = `conic-gradient(#3E40F4 0deg ${showDeg}deg, #E5E5E5 ${showDeg}deg 360deg)`;
        this.recordingBtnInCircleIContext.style.backgroundImage  = `conic-gradient(#CAC8FD 0deg ${showDeg}deg, #E5E5E5 ${showDeg}deg 360deg)`;
      }
    },
  },
  methods: {
    setResultVoiceRecordBox(text) {
      let image = SadFace
      let result = '녹음 실패'
      let bgClass = 'record-result-icon-area-bad'

      if (text === 'GOOD') {
        image = GrinningFace
        result = '녹음 성공'
        bgClass = 'record-result-icon-area-good'
      
      } else if (text === 'SHORT') {
        image = SmileFace
        result = '짧은 녹음'
        bgClass = 'record-result-icon-area-good'
      }

      this.resultVoiceRecord = {
        icon: image,
        text: result,
        bgClass
      }
    },
    async init(){
      console.log("TestReadingWord setVal");
      this.initValues()
      await this.initWebRTC()
      await this.fetchData()
      this.recordingBtnBackCircleContext = this.$refs.recordingBtnBackCircle
      this.recordingBtnInCircleIContext = this.$refs.recordingBtnInCircleI
    },
    initValues() {
      this.$refs.box_content.scrollIntoView({block: "start"})
      this.selectAnswer = undefined
      this.recordingFlag = false
      this.recordCounter = 2
      this.status = "ready"
      this.chunks = []
    },
    async fetchData() {
      this.questionData = await this.$testControl.fnTestCall();
    },
    clear() {
      if (this.stream) {
        this.stream.getAudioTracks().forEach(track => track.stop());
      }
    },
    record(){
      console.log("TestTalkingContext record");
      
      if(this.mikeCheck){
        if(this.recordCounter > 0) {
          this.recordingFlag = !this.recordingFlag;

          if (!this.recordingFlag) this.recordCounter -= 1

          if (this.recordingFlag) {
            this.resultVoiceRecord = null
            this.startRecordTimeOut()
            this.startRecord();
          } else {
            this.initTimeout()
            this.stopRecord();
          }
        }
      } else {
        console.log("마이크가 준비되어있지 않습니다.");
      }
    },
    async nextStep(skipBool){
      console.log('step : ', this.questionNum, 'skip : ', skipBool);

      if(!this.mikeCheck || !this.recordCheck){
        skipBool = true;
      }

      if(!this.readyToNext && !skipBool){
        console.log('answer is undefined');
        return;
      } else {

        this.isLoading = true
        if(this.$store.getters['testManage/quizFullCount'] === this.$store.getters['testManage/nowQuizNum']) this.isSaving = true
        this.selectAnswer = this.mikeCheck ? this.selectAnswer : 'SKIP_ANSWER'
        const nextType = await this.$testControl.saveAnswer(((!skipBool) ? this.selectAnswer : 'SKIP_ANSWER'));
        this.isSaving = false
        this.isLoading = false

        if (this.questionData.type === nextType) {
          this.initValues();
          this.recordCheck = false;
          await this.fetchData()
        } else if (nextType) {
          await this.$testControl.toNextType(nextType);
        }
      }
    },
    async doSkip(){
      console.log('doSkip step : ', this.questionNum);

      if(!this.recordingFlag){
        await this.nextStep(true);
      } else {
        console.log('녹음중엔 스킵할 순 없습니다.');
      }
    },
    skipSet(){
      console.log('skipSet');

      this.skipDefaultSet = true;
      this.$store.commit('testManage/setSkip', true);
      console.log('skipSet', this.$store.getters['testManage/skipSet']);
    },
    testType(audioType, audioCodec) {
      const mimeType = `audio/${audioType};codecs=${audioCodec}`;
      if (
        this.browser_audio_type === undefined &&
        this.browser_audio_codecs === undefined &&
        MediaRecorder.isTypeSupported(mimeType)
      ) {
        this.browser_audio_type = audioType;
        this.browser_audio_codecs = audioCodec;

        console.log(` ${mimeType}`);
      }
    },
    async initWebRTC() {
      for (let i = 0; i < this.AUDIO_TYPES.length; ++i) {
        const audioType = this.AUDIO_TYPES[i];
        for (let j = 0; j < this.AUDIO_CODECS.length; ++j) {
          const audioCodec = this.AUDIO_CODECS[j];
          this.testType(audioType, audioCodec);
        }
      }

      if (navigator.mediaDevices) {
        const constraints = {
          audio: true,
        };

        this.stream = await navigator.mediaDevices.getUserMedia(constraints).catch(() => {
          alert('사용가능한 마이크가 없습니다')
          this.mikeCheck = false;
        })

        if (this.stream) {
          this.initMediaRecord(this.stream);
        }
      }
    },
    clickButton() {
      if (this.status === "stop" || this.status === "ready") {
        this.startRecord();
      } else {
        this.stopRecord();
      }
    },
    startRecord() {
      this.status = "recording";

      if (this.mediaRecorder) {
        this.mediaRecorder.start();
        this.recordStatGuide = '상단 버튼을 누르시면 녹음이 중지됩니다.';
      }
    },
    stopRecord() {
      this.status = "stop";

      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
        this.recordStatGuide = '상단 버튼을 누르시면 녹음이 시작됩니다.';
      }
    },
    initMediaRecord(stream) {
      this.mediaRecorder = new MediaRecorder(stream, {
        mimeType: `audio/${this.browser_audio_type};codecs=${this.browser_audio_codecs}`,
      });

      this.mediaRecorder.ondataavailable = (e) => {
        console.log(e);
        this.chunks.push(e.data);
      };
      console.log(this.mediaRecorder);

      this.mediaRecorder.onstop = (e) => {
        console.log(e);
        console.log(this.chunks);
        const vidTrack = stream.getVideoTracks();
        vidTrack.forEach(track => track.enabled = false);

        const blob = new Blob(this.chunks, {
          type: `audio/${this.browser_audio_type};codecs=${this.browser_audio_codecs}`,
        });

        const audioURL = URL.createObjectURL(blob);
        // this.$refs.audio.src = audioURL;
        // this.$refs.audio.play();
        const sound = new File([audioURL], "soundBlob.webm", {
          lastModified: new Date().getTime(),
          type: "audio",
        });
        console.log(sound);

        this.chunks = [];
        this.getScore(blob);
      };
    },
    async getScore(blob) {
      this.isLoading = true;

      const form = new FormData();
      form.append("answer", blob);
      form.append("quiz_set_uuid", this.quizSetUUID);
      form.append("index", this.questionNum);
      form.append("text", this.guideEng);
      form.append("file_ext", this.browser_audio_type);
      form.append("count", 2 - this.recordCounter);

      await axios
      .post(this.speech_score_api, form)
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);

          if (
            response.data !== undefined &&
            response.data.status !== undefined &&
            response.data.status === "error"
          ) {
            this.setResultVoiceRecordBox('BAD')
          } else if (
            response.data !== undefined &&
            response.data.status !== undefined &&
            response.data.status === "fail"
          ) {
            this.setShowFailSpeechAlert(true)
          } else {
            if (response.data.text_score && response.data.text_score.score_issue_list) {
              this.setResultVoiceRecordBox('SHORT')
            } else {
              this.setResultVoiceRecordBox('GOOD')
              this.recordCheck = true; //녹음이 성공했을 경우에만 다음버튼 활성화 추가.
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.setResultVoiceRecordBox('BAD')
      })
      .finally(() => {
        this.isLoading = false;
        this.timeOutTextCheck = false;
      });
    },
    startRecordTimeOut() {
      this.initTimeout()

      this.recCircleInterval = setInterval(() => {
        this.timeOutCountCircle--;

        if (this.timeOutCountCircle <= 0)  {
          this.timeOutTextCheck = true;
          this.initTimeout();
          this.record()
        }
      }, 1000 / this.intervalPerSeconds);
    },
    initTimeout() {
      if (this.interval || this.recCircleInterval) {
        console.log('init interval')
        clearInterval(this.recCircleInterval);
        this.recCircleInterval = null;
      }

      this.setTimeOutSeconds(this.limitSecond)
    },
    setTimeOutSeconds(sec) {
      this.timeOutCountCircle = sec * this.intervalPerSeconds;
    },
    setShowFailSpeechAlert(isShow) {
      this.isFailSpeech = isShow
    },
  }
}
</script>


<style lang="scss" scoped>
.TestTalkingContext {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: inherit;

  .box_content{
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;
    overflow-y: scroll;
    margin-bottom: 8rem;

    .questionContent{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      width: auto;
      margin-left: 1.25rem;
      margin-right: 1.25rem;

      .testContentTitle{
        display: flex;
        width: 100%;

        margin-top: 0.625rem;
        margin-bottom: 1rem;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 1.5;

        //margin-left: 1.25rem;
        /* Gray/900 */
        color: #222222;
      }
    }

    .testContentQuestion{
      padding: 0.6rem 1rem ;

      width: 100%;

      margin-bottom: 1.2rem;

      background-color: #F5F6F7;
      border-radius: 12px;

      .testContentQuestionKor, .testContentQuestionEng {
        display: flex;

        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.5;

        color: #222222;

        justify-content: center;
        align-items: center;
        align-content: center;
        margin: 0.625rem 0;
        word-break: break-word;
      }

      
    }

    .talkingGuide{
      display: flex;
      align-self: flex-end;
      //margin-top: 1.5rem;
      text-align: end;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 500;
      font-size: 0.75rem;
      line-height: 1;
      white-space: nowrap;

      color: #222222;

      width: auto;
    }

    .recordingBtnArea{
      display: flex;
      flex-direction: column;

      width: 100%;

      align-items: center;
      margin-top: 2.5rem;
      //padding-bottom: 8rem;

      .recordingBtnAreaRecCount{

        width: fit-content;
        height: 2.375rem;

        margin-bottom: 1rem;
        animation: recordinginfo .35s ease-in alternate;
        animation-iteration-count: 6;
        @keyframes recordinginfo {
          0% {
            transform: translateY(0);
          }
          100% {
            transform: translateY(8px);
          }
        }


        .recordingBtnAreaRecCountUp{
          display: flex;
          flex-direction: row;
          align-items: flex-start;

          padding: 8px 12px;

          width: fit-content;
          height: fit-content;

          background: #424242;
          border-radius: 6px;

          .recordingBtnAreaRecCountUpTXT{
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            /* identical to box height */

            text-align: center;

            /* Gray/White */

            color: #FFFFFF;
          }

        }

        .recordingBtnAreaRecCountDown{
          justify-content: center;
          width: 0px;
          height: 0px;
          border-radius: 1px;
          transform: matrix(1, 0, 0, -1.1, 0, 0);
          margin: auto;

          border-bottom: 0.5rem solid #424242;
          border-top: 0.5rem solid transparent;
          border-left: 0.625rem solid transparent;
          border-right: 0.625rem solid transparent;
        }
      }

      .recordingBtnAreaClick{
        display: flex;
        width: 8rem;
        height: 8rem;
        justify-content: center;

        margin-bottom: auto;

        .recordingBtnBackCircle{
          display: flex;
          width: 100%;
          height: 100%;

          border-radius: 50%;

          align-items: center;
          justify-content: center;

          background-color: #E5E5E5;

          .recordingBtnInCircleI{
            display: flex;
            width: 95%;
            height: 95%;
            border-radius: 50%;

            background-color: #E5E5E5;

            align-items: center;
            justify-content: center;

            .recordingBtnInCircleII{
              display: flex;
              width: 95%;
              height: 95%;
              border-radius: 50%;

              background-color: #fff;

              align-items: center;
              justify-content: center;

              .recordingBtnInCircleIIImg{
                display: flex;
                width: 50%;
              }
            }

          }
        }
      }
    }

    .recordingStatGuide{
      display: flex;
      margin-top: 2rem;

      align-self: center;

      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.4px;

      word-break: keep-all;


      //margin-bottom: 10rem;
    }
  }

  .record-result-icon-area-bad {
    background: #FD2448;
  }

  .record-result-icon-area-good {
    background: #3E40F4;
  }
  .record-result-icon-area {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 1.5rem;

    .icon {
      display: flex;
      margin: 1.375rem 2.5rem .5rem 2.5rem;
      .iconImg {
        width: 3.75rem;
        height: 3.75rem;
        margin: 0 auto;
      }
    }

    .text {
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 600;
      font-size: 1.25rem;
      color: #FFFFFF;
      margin-bottom: 1.625rem;
      display: flex;
      justify-content: center;
    }
  }

  .dimm {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.8;
    z-index: 50;
  }

  @keyframes rotate {
    from {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
    to {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  }

  .timeOutText{
    position: absolute;
    display: flex;
    align-self: center;
    z-index: 100;

    top: 30%;

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 600;
    font-size: 1.1rem;

    color: #F2f2f2;
    text-align: center;
  }

  .spinner {
    position: absolute;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 4.5rem;
    height: 4.5rem;

    .spinnerImg {
      animation: rotate 2s infinite linear;
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 1.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }

  .description {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 51;
    width: 90%;

    .thanks {
      display: flex;
      justify-content: center;
      color: #fff;
      font-family: 'Pretendard';
      font-style: normal;
      font-weight: 700;
      font-size: 2rem;
      margin-bottom: 1.25rem;
    }

    .info {
      display: flex;
      flex-direction: column;
      height: 2.5rem;

      .infoList{
        display: flex;
        height: 1.25rem;
        flex-direction: column;
        overflow: hidden;

        .infoChange{
          display: flex;
          height: 1.25rem;
          justify-content: center;
          color: #fff;
          font-family: 'Pretendard';
          font-style: normal;
          font-weight: 400;
          font-size: .875rem;
          line-height: 1.25rem;

          animation: changeLoadingInfo 10s infinite forwards;
        }
      }

      .infoFix {
        display: flex;
        justify-content: center;
        color: #fff;
        font-family: 'Pretendard';
        font-style: normal;
        font-weight: 400;
        font-size: .875rem;
        line-height: 1.25rem;
      }
    }
  }

  .fail-speech {
    z-index: 100;
    background-color: #fff;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1.25rem;
    padding: 1.5rem;

    div {
      display: flex;
      justify-content: center;
    }

    .fail-icon {
      img {
        width: 2.75rem;
        height: 2.75rem;
      }
    }

    .fail-desc {
      margin: 1rem 0 1.25rem 0;
      word-wrap: break-word;
      word-break: keep-all;
      color: var(--gray-900, #222);
      text-align: center;
      font-size: 1.125rem;
      font-family: Pretendard;
      font-weight: 500;
      line-height: 1.625rem;
      letter-spacing: -0.0225rem;
    }

    .fail-button-area {
      .fail-button {
        padding: 0.8rem 3rem;
        display: inline-block;
        border-radius: 0.5rem;
        background: var(--primary-p-500, #3E40F4);

        &:hover {
          cursor: pointer;
          transition: all 0.3s;
          opacity: .9;
        }

        div {
          color: var(--gray-white, #FFF);
          text-align: center;
          font-size: 0.9375rem;
          font-family: Pretendard;
          font-weight: 600;
        }
      }
    }
  }
}

@keyframes changeLoadingInfo{
  0%{
    -webkit-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  25%{
    -webkit-transform: translate(0, -1.25rem);
    -o-transform: translate(0, -1.25rem);
    transform: translate(0, -1.25rem);
  }
  50%{
    -webkit-transform: translate(0, -2.5rem);
    -o-transform: translate(0, -2.5rem);
    transform: translate(0, -2.55rem);
  }
  75%{
    -webkit-transform: translate(0, -3.75rem);
    -o-transform: translate(0, -3.75rem);
    transform: translate(0, -3.75rem);
  }
}
</style>


