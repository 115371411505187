import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";
import Config from '@/Config.js'
import testManage from '@/store/modules/testManage.js';

// [store 데이터 설정 실시] 
const store = createStore ({
  plugins: [createPersistedState(
    {
      paths: ['testManage'],
      storage: window.sessionStorage,
    }
  )],
  modules:{
    testManage,
  },
  state: { // [변수들의 집합]
    apiUrl: Config.api_server_url,
    feUrl: Config.fe_url,

    userSelectedSkillInfo: undefined,
    userSelectedToeicSkillInfo: undefined,
    userSelectedSWSkillInfo: undefined,
    userSelectedOPICSkillInfo: undefined,
  },
  getters: { // [state의 변수들을 get 호출]
    apiUrl(state){
      return state.apiUrl
    },
    feUrl(state){
      return state.feUrl
    },
    userSelectedSkillInfo(state){
      return state.userSelectedSkillInfo
    },
    userSelectedToeicSkillInfo(state){
      return state.userSelectedToeicSkillInfo
    },
    userSelectedSWSkillInfo(state){
      return state.userSelectedSWSkillInfo
    },
    userSelectedOPICSkillInfo(state){
      return state.userSelectedOPICSkillInfo
    },
  },
  mutations: { // [변수들을 조작하는 함수들]
    setUserSelectedSkillInfo: (state, value) => {
      state.userSelectedSkillInfo = value
    },
    setUserSelectedToeicSkillInfo: (state, value) => {
      state.userSelectedToeicSkillInfo = value
    },
    setUserSelectedSWSkillInfo: (state, value) => {
      state.userSelectedSWSkillInfo = value
    },
    setUserSelectedOPICSkillInfo: (state, value) => {
      state.userSelectedOPICSkillInfo = value
    },
  },
  actions: { // [비동기 처리를 하는 함수들]
  },
});

export default store;
