<template>
  <div class="RaiderChart">
    <canvas ref="raiderChart"></canvas>
  </div>
</template>


<script>
import Chart from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
//import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(annotationPlugin);
//Chart.register(annotationPlugin, ChartDataLabels);

export default {
  name: 'RaiderChart',
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    name:{
      type:String,
      required: true,
    },
    key:{
      type: Number,
      default: 0
    }
  },
  data(){
    this.setChart=undefined
    return{
      raiderChartCanvasCTX: undefined,
      canvasH: 0,
      canvasw: 0,
      
    }
  },
  watch:{
    chartData: {
      handler: function (val){
        console.log('watch this?', val);
        if(val !== undefined){
          this.setChart.data.datasets.forEach((dataset) => {
            dataset.data.pop();
          });
          this.setChart.data.datasets[0].data = [this.chartData.userWordPer, this.chartData.userLcPer, this.chartData.userRcPer, this.chartData.userWcPer, this.chartData.userSpPer];
          this.setChart.data.datasets[1].data = [this.chartData.avgWordPer, this.chartData.avgLcPer, this.chartData.avgRcPer, this.chartData.avgWcPer, this.chartData.avgSpPer];
          this.setChart.update();
        }
        
        //this.setChart.update();
        
      }
    }
  },
  async mounted() {
    //this.chartData = this.chartData;
    console.log('chartData?', this.chartData);
    
    this.raiderChartCanvasCTX = this.$refs.raiderChart.getContext('2d');
    this.canvasH = this.raiderChartCanvasCTX.height;
    this.canvasW = this.raiderChartCanvasCTX.width;

    this.setChart = new Chart(this.raiderChartCanvasCTX, {
      type: 'radar',
      data: this.generateData(),
      options: this.generateConfig(),
    })

    
  },
  methods: {
    generateData() {
      const data = {
        labels: ['어휘', '듣기', '읽기', '쓰기', '말하기'],
        datasets: [
          {
            label: '내 점수',
            borderColor: '#3354F4',
            backgroundColor: 'rgba(234, 233, 255, 0.5)',
            data: [this.chartData.userWordPer, this.chartData.userLcPer, this.chartData.userRcPer, this.chartData.userWcPer, this.chartData.userSpPer],
            fill: true,
          },
          {
            label: '레벨 평균 점수',
            borderColor: 'rgba(223, 64, 61, 1)',
            backgroundColor: 'rgba(223, 64, 61, 0.1)',
            data: [this.chartData.avgWordPer, this.chartData.avgLcPer, this.chartData.avgRcPer, this.chartData.avgWcPer, this.chartData.avgSpPer],
            fill: true,
          }
        ],
      }

      return data;
    },
    generateConfig(){
      return {
        responsive: true,
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          r: {
            pointLabels:{
              centerPointLabels: false,
              color: '#616161',
              font: {
                size: 13,
              },
              /*callback: function(label){
              },*/
            },
            beginAtZero: true,
            angleLines: {
              display: false,
            },
            suggestedMin: 0,
            suggestedMax: 100,
            grid: {
              circular: false,
            },
            
          },
        },
        plugins:{
          legend: {
            display: false,
          }
        },
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.RaiderChart{
  display: flex;
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;

  canvas{
  }
}
</style>