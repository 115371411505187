<template>
  <div class="RadialChart">
    <div class="radialVisual">
      
      <div class="radialVisualBackUser" :id="[(type === 'level') ? 'levelVisualBackUser' : 'timeVisualBackUser']">
        <div class="radialVisualBack" :id="[(type === 'level') ? 'levelVisualBack' : 'timeVisualBack']">
          <div class="radialVisualBarIncircle">
            <div class="radialVisualBarIncircleMessage">
              <div class="radialVisualBarIncircleMessageText">{{(type === 'level') ? '나의 레벨' : '나의 소요 시간' }}</div>
              <div class="radialVisualBarIncircleMessageLevel">{{(type === 'level') ? 'Level ' + level : userTime }}</div>
              <div class="radialVisualBarIncircleMessageOther">
                <div class="colorPoint"></div>
                <div class="colorText">{{(type === 'level') ? `평균 Level ${avgLevel}` : `평균 ${avgTime}` }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>

export default {
  name: 'RadialChart',
  props:{
    radialData:{
      type: Object,
      default: null,
    }
  },
  data(){
    return{
      avgLevel: undefined,
      level: undefined,

      avgTime: undefined,
      userTime: undefined,

      type: this.radialData.type,
    }
  },
  async mounted() {
    console.log('Radial Chart', this.radialData);
    
    this.type = await this.radialData.type;

    if(this.type === 'level'){
      this.avgLevel = await this.radialData.avgLevel;
      this.level = await this.radialData.level;

      this.setLevelAvgVisual();
    } else if(this.type === 'time'){
      this.avgTime = await this.radialData.avgTime;
      this.userTime = await this.radialData.userTime;

      this.setTimeAvgVisual();
    }
  },
  methods:{
    setLevelAvgVisual(){
      console.log('setLevelAvgVisual setVal');

      let intervalId = null;
      clearInterval(intervalId);
      
      const levelVisualBackUser = document.getElementById("levelVisualBackUser");

      const perLevelUser = Math.round((this.level / 10) * 100) / 100;
      const degNumUser = Math.floor(360 * perLevelUser);
      const degUser = degNumUser+'deg';

      const perLevel = Math.round((this.avgLevel / 10) * 100) / 100;
      const degNum = Math.floor(360 * perLevel);
      const deg = degNum+'deg';

      const circle2DegNumUser = degNumUser - 180;

      console.log('perLevelUser & degUser', perLevelUser, degUser);

      console.log('degNumUser & circle2DegNumUser', degNumUser, circle2DegNumUser);

      //levelVisualBackUser.style.backgroundImage  = `conic-gradient(#3E40F4 0deg ${degUser}, rgba(255, 255, 255, 0) ${degUser} 360deg)`;
      let intervalIdxUser = 0;
      function radialAnimate(){
        //console.log('radialAnimate', intervalIdxUser, degUser);
        if(intervalIdxUser === degNumUser){
          clearInterval(intervalId);
        } else {
          if(levelVisualBackUser !== undefined){
            levelVisualBackUser.style.backgroundImage  = `conic-gradient(#3E40F4 0deg ${intervalIdxUser}deg, #16C8BE ${intervalIdxUser}deg ${degNum}deg, rgba(255, 255, 255, 0) ${intervalIdxUser}deg 360deg)`;
          } else {
            console.log('levelVisualBackUser undifined')
          }
          intervalIdxUser++;
        }
      }
      if(levelVisualBackUser !== undefined && levelVisualBackUser !== null){
        intervalId = setInterval(radialAnimate, 5);
      }
      

      const levelVisualBack = document.getElementById("levelVisualBack");

      const circle2DegNum = degNum - 180;

      console.log('perLevel & deg', perLevel, deg);

      console.log('degNum & circle2DegNum', degNum, circle2DegNum);

      levelVisualBack.style.backgroundImage  = `conic-gradient(#16C8BE 0deg ${deg}, #E5E5E5 ${deg} 360deg)`; 

    },

    setTimeAvgVisual(){
      console.log('setTimeAvgVisual setVal', this.avgTime, this.userTime);

      let intervalId = null;
      clearInterval(intervalId);

      const avgTimeDataArr = this.avgTime.split(':');
      const avgTimeDataMin = Number(avgTimeDataArr[0]) * 60;
      const avgTimeDataSecond = Number(avgTimeDataArr[1]);
      const avgTimeData = (avgTimeDataMin + avgTimeDataSecond) / 100;

      const userTimeDataArr = this.userTime.split(':');
      const userTimeDataMin = Number(userTimeDataArr[0]) * 60;
      const userTimeDataSecond = Number(userTimeDataArr[1]);
      const userTimeData = (userTimeDataMin + userTimeDataSecond) / 100;

      console.log('avgTimeData, userTimeData : ', avgTimeData, userTimeData);


      const timeVisualBackUser = document.getElementById("timeVisualBackUser");

      const perTimeUser = Math.round((userTimeData / 18) * 100) / 100;
      const degNumUser = Math.floor(360 * perTimeUser);
      const degUser = degNumUser+'deg';


      const perTime = Math.round((avgTimeData / 18) * 100) / 100;
      const degNum = Math.floor(360 * perTime);
      const deg = degNum+'deg';


      const circle2DegNumUser = degNumUser - 180;

      console.log('perTimeUser & degUser', perTimeUser, degUser);

      console.log('degNumUser & circle2DegNumUser', degNumUser, circle2DegNumUser);

      //timeVisualBackUser.style.backgroundImage  = `conic-gradient(#3E40F4 0deg ${degUser}, #E5E5E5 ${degUser} 360deg)`;
      let intervalIdxUser = 0;
      function radialAnimate(){
        
        if(intervalIdxUser === degNumUser){
          clearInterval(intervalId);
        } else {
          timeVisualBackUser.style.backgroundImage  = `conic-gradient(#3E40F4 0deg ${intervalIdxUser}deg, #16C8BE ${intervalIdxUser}deg ${degNum}deg, rgba(255, 255, 255, 0) ${intervalIdxUser}deg 360deg)`;
          intervalIdxUser++;
        }
      }
      if(timeVisualBackUser !== undefined && timeVisualBackUser !== null){
        intervalId = setInterval(radialAnimate, 5);
      }

      

      const timeVisualBack = document.getElementById("timeVisualBack");

      const circle2DegNum = degNum - 180;

      console.log('perTime & deg', perTime, deg);

      console.log('degNum & circle2DegNum', degNum, circle2DegNum);

      timeVisualBack.style.backgroundImage  = `conic-gradient(#16C8BE 0deg ${deg}, #E5E5E5 ${deg} 360deg)`; 
    },
  }
}
</script>

<style lang="scss" scoped>
.RadialChart{
  display: flex;
  width: 100%;
  //height: 13.25rem;
  flex-direction: column;
  
  .radialVisual{
    display: flex;
    //width: 9.25rem;
    //height: 9.25rem;

    margin-top: 1rem;
    width: 90%;
    aspect-ratio: 1/1;
    max-width: 30rem;

    border-radius: 50%;
    background: #E5E5E5;
    align-self: center;

    .radialVisualBackUser{
      display: flex;
      align-items: center;
      justify-content: center;
      mix-blend-mode: normal;
      border-radius: 50%;

      height: 100%;
      width: 100%;

      .radialVisualBack{
        display: flex;
        align-items: center;
        justify-content: center;
        mix-blend-mode: normal;
        
        //border: 0.3rem solid transparent;
        border-radius: 50%;

        height: 90%;
        width: 90%;
      }


      .radialVisualBarCircleUser1{
        position: absolute;
        background: #3E40F4;
        border-radius: 50%;
        width: 0.5rem;
        height: 0.5rem;
      }

      .radialVisualBarIncircle{
        display: flex;
        background:#FFFFFF;
        border-radius: 50%;
        width: calc(100% - 0.9rem);
        height: calc(100% - 0.9rem);

        align-items: center;
        justify-content: center;

        .radialVisualBarIncircleMessage{
          display: flex;
          width: fit-content;
          height: fit-content;
          flex-direction: column;

          .radialVisualBarIncircleMessageText{
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;

            text-align: center;

            color: #3E40F4;

            flex: none;
            order: 0;
            flex-grow: 0;
          }

          .radialVisualBarIncircleMessageLevel{
            font-family: 'Pretendard';
            font-style: normal;
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 1.5rem;

            text-align: center;

            color: #3E40F4;

            flex: none;
            order: 1;
            flex-grow: 0;
          }

          .radialVisualBarIncircleMessageOther{
            display: flex;
            flex-direction: row;
            font-family: 'Pretendard';
            font-style: normal;
            font-size: 13px;
            line-height: 16px;

            margin-top: 0.5rem;
            /* identical to box height */

            letter-spacing: -0.01em;
            font-weight: 700;

            align-self: center;

            order: 2;

            gap: 0.25rem;

            .colorPoint{
              width: 0.438rem;
              height: 0.438rem;
              background-color: #16C8BE;
              border-radius: 50%;
              align-self: center;
            }
          }
        }
      }

      .radialVisualBarCircle2{
        position: absolute;
        background: #C2C2C2;
        border-radius: 50%;
        width: 0.5rem;
        height: 0.5rem;
      }

      .radialVisualBarCircleUser2{
        position: absolute;
        background: #3E40F4;
        border-radius: 50%;
        width: 0.5rem;
        height: 0.5rem;
      }
    }
  }

  .radialUnderText{
    display: flex;

    width: 100%;

    font-family: 'Pretendard';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    text-align: center;
    align-items: center;
    justify-content: center;

    color: #616161;

    margin-top: 1rem;
  }
  
}
</style>